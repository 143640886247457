/* tslint:disable */
/* eslint-disable */
/**
 * 블랙랏 API
 * Blacklot API 공유 문서
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AdminCreateSettingsDTO
 */
export interface AdminCreateSettingsDTO {
    /**
     * 
     * @type {string}
     * @memberof AdminCreateSettingsDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateSettingsDTO
     */
    value?: string;
}
/**
 * 
 * @export
 * @interface AdminEventDTO
 */
export interface AdminEventDTO {
    /**
     * 
     * @type {string}
     * @memberof AdminEventDTO
     */
    bannerUrl1?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEventDTO
     */
    bannerUrl2?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminEventDTO
     */
    category?: Array<string>;
    /**
     * 
     * @type {Array<EventCategoryCountWithImageDTO>}
     * @memberof AdminEventDTO
     */
    categoryMeta?: Array<EventCategoryCountWithImageDTO>;
    /**
     * 
     * @type {Array<EventCategoryDTO>}
     * @memberof AdminEventDTO
     */
    categoryV2?: Array<EventCategoryDTO>;
    /**
     * 
     * @type {string}
     * @memberof AdminEventDTO
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEventDTO
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEventDTO
     */
    endDate?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminEventDTO
     */
    eventId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdminEventDTO
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminEventDTO
     */
    mobileBannerUrl1?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEventDTO
     */
    mobileBannerUrl2?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEventDTO
     */
    mobileBannerUrl3?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEventDTO
     */
    mobileBannerUrl4?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEventDTO
     */
    mobileBannerUrl5?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEventDTO
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEventDTO
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEventDTO
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEventDTO
     */
    webBannerUrl1?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEventDTO
     */
    webBannerUrl2?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEventDTO
     */
    webBannerUrl3?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEventDTO
     */
    webBannerUrl4?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminEventDTO
     */
    webBannerUrl5?: string;
}
/**
 * 
 * @export
 * @interface AdminLotInsideCreateDTO
 */
export interface AdminLotInsideCreateDTO {
    /**
     * 
     * @type {string}
     * @memberof AdminLotInsideCreateDTO
     */
    contents: string;
    /**
     * 
     * @type {string}
     * @memberof AdminLotInsideCreateDTO
     */
    thumbnailImage: string;
    /**
     * 
     * @type {string}
     * @memberof AdminLotInsideCreateDTO
     */
    title: string;
}
/**
 * 
 * @export
 * @interface AdminMemberDTO
 */
export interface AdminMemberDTO {
    /**
     * 
     * @type {MemberDTO}
     * @memberof AdminMemberDTO
     */
    member?: MemberDTO;
    /**
     * 
     * @type {string}
     * @memberof AdminMemberDTO
     */
    memberType?: AdminMemberDTOMemberTypeEnum;
    /**
     * 
     * @type {PartnerDTO}
     * @memberof AdminMemberDTO
     */
    partner?: PartnerDTO;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminMemberDTOMemberTypeEnum {
    Member = 'MEMBER',
    Partner = 'PARTNER'
}

/**
 * 
 * @export
 * @interface AdminMemberPatchPasswordDTO
 */
export interface AdminMemberPatchPasswordDTO {
    /**
     * 
     * @type {string}
     * @memberof AdminMemberPatchPasswordDTO
     */
    password: string;
}
/**
 * 
 * @export
 * @interface AdminOptionDTO
 */
export interface AdminOptionDTO {
    /**
     * 
     * @type {string}
     * @memberof AdminOptionDTO
     */
    eng?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminOptionDTO
     */
    kor?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminOptionDTO
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface AdminOptionTypeDTO
 */
export interface AdminOptionTypeDTO {
    /**
     * 
     * @type {string}
     * @memberof AdminOptionTypeDTO
     */
    eng?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminOptionTypeDTO
     */
    kor?: string;
}
/**
 * 
 * @export
 * @interface AdminPatchSettingsDTO
 */
export interface AdminPatchSettingsDTO {
    /**
     * 
     * @type {string}
     * @memberof AdminPatchSettingsDTO
     */
    value?: string;
}
/**
 * 
 * @export
 * @interface AdminRegisterTokenCreateDTO
 */
export interface AdminRegisterTokenCreateDTO {
    /**
     * 
     * @type {number}
     * @memberof AdminRegisterTokenCreateDTO
     */
    memberId: number;
    /**
     * 
     * @type {number}
     * @memberof AdminRegisterTokenCreateDTO
     */
    productId: number;
}
/**
 * 
 * @export
 * @interface AdminSettlementHoldParamDTO
 */
export interface AdminSettlementHoldParamDTO {
    /**
     * 
     * @type {string}
     * @memberof AdminSettlementHoldParamDTO
     */
    holdReason?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminSettlementHoldParamDTO
     */
    orderId?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminSettlementHoldParamDTO
     */
    settlementStatus?: AdminSettlementHoldParamDTOSettlementStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AdminSettlementHoldParamDTOSettlementStatusEnum {
    Complete = 'complete',
    Hold = 'hold',
    PurchaseComplete = 'purchase_complete',
    Waiting = 'waiting'
}

/**
 * 
 * @export
 * @interface AuthParamDTO
 */
export interface AuthParamDTO {
    /**
     * 
     * @type {string}
     * @memberof AuthParamDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthParamDTO
     */
    password?: string;
}
/**
 * 
 * @export
 * @interface EventCategoryCountWithImageDTO
 */
export interface EventCategoryCountWithImageDTO {
    /**
     * 
     * @type {number}
     * @memberof EventCategoryCountWithImageDTO
     */
    counts?: number;
    /**
     * 
     * @type {string}
     * @memberof EventCategoryCountWithImageDTO
     */
    eventCategory?: string;
    /**
     * 
     * @type {Array<ProductImageCategoryDTO>}
     * @memberof EventCategoryCountWithImageDTO
     */
    productImageList?: Array<ProductImageCategoryDTO>;
    /**
     * 
     * @type {string}
     * @memberof EventCategoryCountWithImageDTO
     */
    profileImage?: string;
}
/**
 * 
 * @export
 * @interface EventCategoryDTO
 */
export interface EventCategoryDTO {
    /**
     * 
     * @type {string}
     * @memberof EventCategoryDTO
     */
    caption?: string;
    /**
     * 
     * @type {string}
     * @memberof EventCategoryDTO
     */
    eventCode?: string;
    /**
     * 
     * @type {number}
     * @memberof EventCategoryDTO
     */
    eventId?: number;
    /**
     * 
     * @type {number}
     * @memberof EventCategoryDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof EventCategoryDTO
     */
    level?: number;
    /**
     * 
     * @type {string}
     * @memberof EventCategoryDTO
     */
    mobileBannerUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof EventCategoryDTO
     */
    parentId?: number;
    /**
     * 
     * @type {number}
     * @memberof EventCategoryDTO
     */
    sequence?: number;
    /**
     * 
     * @type {string}
     * @memberof EventCategoryDTO
     */
    sort?: EventCategoryDTOSortEnum;
    /**
     * 
     * @type {string}
     * @memberof EventCategoryDTO
     */
    webBannerUrl?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum EventCategoryDTOSortEnum {
    BuynowPriceAsc = 'buynow_price_asc',
    BuynowPriceDesc = 'buynow_price_desc',
    Imminent = 'imminent',
    Latest = 'latest',
    NowPriceAsc = 'now_price_asc',
    NowPriceDesc = 'now_price_desc',
    Oldest = 'oldest',
    PartnerIdAsc = 'partner_id_asc',
    PartnerIdDesc = 'partner_id_desc',
    Recommend = 'recommend',
    StartPriceAsc = 'start_price_asc',
    StartPriceDesc = 'start_price_desc'
}

/**
 * 
 * @export
 * @interface FindMemberEmailParamDTO
 */
export interface FindMemberEmailParamDTO {
    /**
     * 
     * @type {string}
     * @memberof FindMemberEmailParamDTO
     */
    mobile: string;
    /**
     * 
     * @type {string}
     * @memberof FindMemberEmailParamDTO
     */
    name: string;
}
/**
 * 
 * @export
 * @interface FindMemberPasswordParamDTO
 */
export interface FindMemberPasswordParamDTO {
    /**
     * 
     * @type {string}
     * @memberof FindMemberPasswordParamDTO
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof FindMemberPasswordParamDTO
     */
    name: string;
}
/**
 * 
 * @export
 * @interface MakeOfferParamDTO
 */
export interface MakeOfferParamDTO {
    /**
     * 
     * @type {number}
     * @memberof MakeOfferParamDTO
     */
    memberId?: number;
    /**
     * 
     * @type {number}
     * @memberof MakeOfferParamDTO
     */
    offerPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof MakeOfferParamDTO
     */
    productId?: number;
}
/**
 * 
 * @export
 * @interface MemberDTO
 */
export interface MemberDTO {
    /**
     * 
     * @type {string}
     * @memberof MemberDTO
     */
    birthday?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDTO
     */
    createdAt?: string;
    /**
     * 
     * @type {ShippingAddressDTO}
     * @memberof MemberDTO
     */
    defaultShippingAddress?: ShippingAddressDTO;
    /**
     * 
     * @type {string}
     * @memberof MemberDTO
     */
    dupInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDTO
     */
    email?: string;
    /**
     * 
     * @type {number}
     * @memberof MemberDTO
     */
    memberId?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberDTO
     */
    mobile?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDTO
     */
    profileImage?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDTO
     */
    sex?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDTO
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface MemberJoinDTO
 */
export interface MemberJoinDTO {
    /**
     * 
     * @type {string}
     * @memberof MemberJoinDTO
     */
    address1?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberJoinDTO
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberJoinDTO
     */
    birthday?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberJoinDTO
     */
    connInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberJoinDTO
     */
    dupInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberJoinDTO
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberJoinDTO
     */
    marketingAgreement?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MemberJoinDTO
     */
    mobile?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberJoinDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberJoinDTO
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof MemberJoinDTO
     */
    passwordConfirm: string;
    /**
     * 
     * @type {string}
     * @memberof MemberJoinDTO
     */
    postcode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberJoinDTO
     */
    privacyCollection?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MemberJoinDTO
     */
    sex?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberJoinDTO
     */
    termsConditions?: boolean;
}
/**
 * 
 * @export
 * @interface MemberUpdateDTO
 */
export interface MemberUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateDTO
     */
    birthday?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateDTO
     */
    connInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateDTO
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateDTO
     */
    dupInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateDTO
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberUpdateDTO
     */
    marketingAgreement?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MemberUpdateDTO
     */
    memberId?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateDTO
     */
    mobile?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateDTO
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateDTO
     */
    passwordConfirm?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateDTO
     */
    profileImage?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateDTO
     */
    sex?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberUpdateDTO
     */
    useAvater?: boolean;
}
/**
 * 
 * @export
 * @interface ModelAndView
 */
export interface ModelAndView {
    /**
     * 
     * @type {boolean}
     * @memberof ModelAndView
     */
    empty?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ModelAndView
     */
    model?: object;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ModelAndView
     */
    modelMap?: { [key: string]: object; };
    /**
     * 
     * @type {boolean}
     * @memberof ModelAndView
     */
    reference?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelAndView
     */
    status?: ModelAndViewStatusEnum;
    /**
     * 
     * @type {View}
     * @memberof ModelAndView
     */
    view?: View;
    /**
     * 
     * @type {string}
     * @memberof ModelAndView
     */
    viewName?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ModelAndViewStatusEnum {
    Accepted = 'ACCEPTED',
    AlreadyReported = 'ALREADY_REPORTED',
    BadGateway = 'BAD_GATEWAY',
    BadRequest = 'BAD_REQUEST',
    BandwidthLimitExceeded = 'BANDWIDTH_LIMIT_EXCEEDED',
    Checkpoint = 'CHECKPOINT',
    Conflict = 'CONFLICT',
    Continue = 'CONTINUE',
    Created = 'CREATED',
    DestinationLocked = 'DESTINATION_LOCKED',
    ExpectationFailed = 'EXPECTATION_FAILED',
    FailedDependency = 'FAILED_DEPENDENCY',
    Forbidden = 'FORBIDDEN',
    Found = 'FOUND',
    GatewayTimeout = 'GATEWAY_TIMEOUT',
    Gone = 'GONE',
    HttpVersionNotSupported = 'HTTP_VERSION_NOT_SUPPORTED',
    ImUsed = 'IM_USED',
    InsufficientSpaceOnResource = 'INSUFFICIENT_SPACE_ON_RESOURCE',
    InsufficientStorage = 'INSUFFICIENT_STORAGE',
    InternalServerError = 'INTERNAL_SERVER_ERROR',
    IAmATeapot = 'I_AM_A_TEAPOT',
    LengthRequired = 'LENGTH_REQUIRED',
    Locked = 'LOCKED',
    LoopDetected = 'LOOP_DETECTED',
    MethodFailure = 'METHOD_FAILURE',
    MethodNotAllowed = 'METHOD_NOT_ALLOWED',
    MovedPermanently = 'MOVED_PERMANENTLY',
    MovedTemporarily = 'MOVED_TEMPORARILY',
    MultipleChoices = 'MULTIPLE_CHOICES',
    MultiStatus = 'MULTI_STATUS',
    NetworkAuthenticationRequired = 'NETWORK_AUTHENTICATION_REQUIRED',
    NonAuthoritativeInformation = 'NON_AUTHORITATIVE_INFORMATION',
    NotAcceptable = 'NOT_ACCEPTABLE',
    NotExtended = 'NOT_EXTENDED',
    NotFound = 'NOT_FOUND',
    NotImplemented = 'NOT_IMPLEMENTED',
    NotModified = 'NOT_MODIFIED',
    NoContent = 'NO_CONTENT',
    Ok = 'OK',
    PartialContent = 'PARTIAL_CONTENT',
    PayloadTooLarge = 'PAYLOAD_TOO_LARGE',
    PaymentRequired = 'PAYMENT_REQUIRED',
    PermanentRedirect = 'PERMANENT_REDIRECT',
    PreconditionFailed = 'PRECONDITION_FAILED',
    PreconditionRequired = 'PRECONDITION_REQUIRED',
    Processing = 'PROCESSING',
    ProxyAuthenticationRequired = 'PROXY_AUTHENTICATION_REQUIRED',
    RequestedRangeNotSatisfiable = 'REQUESTED_RANGE_NOT_SATISFIABLE',
    RequestEntityTooLarge = 'REQUEST_ENTITY_TOO_LARGE',
    RequestHeaderFieldsTooLarge = 'REQUEST_HEADER_FIELDS_TOO_LARGE',
    RequestTimeout = 'REQUEST_TIMEOUT',
    RequestUriTooLong = 'REQUEST_URI_TOO_LONG',
    ResetContent = 'RESET_CONTENT',
    SeeOther = 'SEE_OTHER',
    ServiceUnavailable = 'SERVICE_UNAVAILABLE',
    SwitchingProtocols = 'SWITCHING_PROTOCOLS',
    TemporaryRedirect = 'TEMPORARY_REDIRECT',
    TooEarly = 'TOO_EARLY',
    TooManyRequests = 'TOO_MANY_REQUESTS',
    Unauthorized = 'UNAUTHORIZED',
    UnavailableForLegalReasons = 'UNAVAILABLE_FOR_LEGAL_REASONS',
    UnprocessableEntity = 'UNPROCESSABLE_ENTITY',
    UnsupportedMediaType = 'UNSUPPORTED_MEDIA_TYPE',
    UpgradeRequired = 'UPGRADE_REQUIRED',
    UriTooLong = 'URI_TOO_LONG',
    UseProxy = 'USE_PROXY',
    VariantAlsoNegotiates = 'VARIANT_ALSO_NEGOTIATES'
}

/**
 * 
 * @export
 * @interface OrderCancelParamDTO
 */
export interface OrderCancelParamDTO {
    /**
     * 
     * @type {number}
     * @memberof OrderCancelParamDTO
     */
    productId?: number;
}
/**
 * 
 * @export
 * @interface OrderCompleteConfirmDTO
 */
export interface OrderCompleteConfirmDTO {
    /**
     * 
     * @type {string}
     * @memberof OrderCompleteConfirmDTO
     */
    orderNo?: string;
}
/**
 * 
 * @export
 * @interface OrderParamDTO
 */
export interface OrderParamDTO {
    /**
     * 
     * @type {string}
     * @memberof OrderParamDTO
     */
    address1?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderParamDTO
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderParamDTO
     */
    addressName?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderParamDTO
     */
    creditCardTypeId?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderParamDTO
     */
    memo?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderParamDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderParamDTO
     */
    orderNo?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderParamDTO
     */
    postcode?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderParamDTO
     */
    tel1?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderParamDTO
     */
    tel2?: string;
}
/**
 * 
 * @export
 * @interface OrderStatusParamDTO
 */
export interface OrderStatusParamDTO {
    /**
     * 
     * @type {number}
     * @memberof OrderStatusParamDTO
     */
    orderId?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderStatusParamDTO
     */
    orderStatus?: OrderStatusParamDTOOrderStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof OrderStatusParamDTO
     */
    productId?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum OrderStatusParamDTOOrderStatusEnum {
    Complete = 'complete',
    Delivered = 'delivered',
    DepositWait = 'deposit_wait',
    None = 'none',
    PaymentComplete = 'payment_complete',
    PaymentTimeOut = 'payment_time_out',
    PaymentWait = 'payment_wait',
    Proccessed = 'proccessed',
    PurchaseCancel = 'purchase_cancel',
    ReturnComplete = 'return_complete',
    ReturnRequest = 'return_request',
    Shipped = 'shipped'
}

/**
 * 
 * @export
 * @interface PartnerBankCheckDTO
 */
export interface PartnerBankCheckDTO {
    /**
     * 
     * @type {string}
     * @memberof PartnerBankCheckDTO
     */
    bankAccount: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerBankCheckDTO
     */
    bankCode: string;
}
/**
 * 
 * @export
 * @interface PartnerCompanCheckDTO
 */
export interface PartnerCompanCheckDTO {
    /**
     * 
     * @type {string}
     * @memberof PartnerCompanCheckDTO
     */
    businessNo1: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerCompanCheckDTO
     */
    businessNo2: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerCompanCheckDTO
     */
    businessNo3: string;
}
/**
 * 
 * @export
 * @interface PartnerDTO
 */
export interface PartnerDTO {
    /**
     * 
     * @type {string}
     * @memberof PartnerDTO
     */
    accountHolder?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerDTO
     */
    address1?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerDTO
     */
    address2?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerDTO
     */
    approved?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerDTO
     */
    bankAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerDTO
     */
    bankCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerDTO
     */
    bankName?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerDTO
     */
    bankbookCopy?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerDTO
     */
    businessLicense?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerDTO
     */
    businessNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerDTO
     */
    ceoMobile?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerDTO
     */
    ceoName?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerDTO
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerDTO
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerDTO
     */
    csEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerDTO
     */
    csTelNo?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerDTO
     */
    homeBannerImage?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerDTO
     */
    industry?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerDTO
     */
    managerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerDTO
     */
    managerMobile?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerDTO
     */
    managerName?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerDTO
     */
    onlineSalesBusinessNo?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerDTO
     */
    partnerId?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerDTO
     */
    paymentManagerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerDTO
     */
    paymentManagerMobile?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerDTO
     */
    paymentManagerName?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerDTO
     */
    postNo?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerDTO
     */
    profileImage?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerDTO
     */
    sector?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerDTO
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface PartnerJoinDTO
 */
export interface PartnerJoinDTO {
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    accountHolder: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    address1: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    bankAccount: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    bankCode: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    bankName?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    bankbookCopy?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    businessLicense: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    businessNumber: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    ceoMobile: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    ceoMobile1?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    ceoMobile2?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    ceoMobile3?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    ceoName: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    csEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    csTelNo: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    industry: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    managerEmail: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    managerMobile: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    managerMobile1?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    managerMobile2?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    managerMobile3?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    managerName: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    mobile?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    onlineSalesBusinessNo?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    passwordConfirm: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    paymentManagerEmail: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    paymentManagerMobile: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    paymentManagerName: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    postNo: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerJoinDTO
     */
    receiveMarketingEmail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerJoinDTO
     */
    receiveMarketingSms?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerJoinDTO
     */
    sector: string;
}
/**
 * 
 * @export
 * @interface PartnerMakeOfferParamDTO
 */
export interface PartnerMakeOfferParamDTO {
    /**
     * 
     * @type {string}
     * @memberof PartnerMakeOfferParamDTO
     */
    accept?: PartnerMakeOfferParamDTOAcceptEnum;
    /**
     * 
     * @type {number}
     * @memberof PartnerMakeOfferParamDTO
     */
    productMakeOfferId?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PartnerMakeOfferParamDTOAcceptEnum {
    Accept = 'accept',
    Cancel = 'cancel',
    Deny = 'deny',
    Request = 'request'
}

/**
 * 
 * @export
 * @interface ProductImage
 */
export interface ProductImage {
    /**
     * 
     * @type {Timestamp}
     * @memberof ProductImage
     */
    createdAt?: Timestamp;
    /**
     * 
     * @type {number}
     * @memberof ProductImage
     */
    order?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductImage
     */
    productId?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductImage
     */
    productImageId?: number;
    /**
     * 
     * @type {Timestamp}
     * @memberof ProductImage
     */
    updatedAt?: Timestamp;
    /**
     * 
     * @type {string}
     * @memberof ProductImage
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface ProductImageCategoryDTO
 */
export interface ProductImageCategoryDTO {
    /**
     * 
     * @type {string}
     * @memberof ProductImageCategoryDTO
     */
    eventCategory?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductImageCategoryDTO
     */
    productId?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductImageCategoryDTO
     */
    productImageId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductImageCategoryDTO
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface ProductParamDTO
 */
export interface ProductParamDTO {
    /**
     * 
     * @type {string}
     * @memberof ProductParamDTO
     */
    artistInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductParamDTO
     */
    artistNameEn?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductParamDTO
     */
    artistNameKr?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductParamDTO
     */
    categoryDepth1Id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductParamDTO
     */
    categoryDepth2Id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductParamDTO
     */
    categoryDepth3Id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductParamDTO
     */
    certificate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductParamDTO
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductParamDTO
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductParamDTO
     */
    edition?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductParamDTO
     */
    eventCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductParamDTO
     */
    exhibitionHistory?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductParamDTO
     */
    frameSize?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductParamDTO
     */
    makeYear?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductParamDTO
     */
    medium?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductParamDTO
     */
    ownHistory?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductParamDTO
     */
    period?: string;
    /**
     * 
     * @type {Array<ProductImage>}
     * @memberof ProductParamDTO
     */
    productImageList?: Array<ProductImage>;
    /**
     * 
     * @type {string}
     * @memberof ProductParamDTO
     */
    purchaseDate?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductParamDTO
     */
    purchasePrice?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductParamDTO
     */
    recordedPlace?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductParamDTO
     */
    salesStatus?: ProductParamDTOSalesStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductParamDTO
     */
    signature?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductParamDTO
     */
    signatureExist?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductParamDTO
     */
    size?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductParamDTO
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductParamDTO
     */
    tags?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductParamDTO
     */
    titleEn?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductParamDTO
     */
    titleKr?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductParamDTO
     */
    videoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductParamDTO
     */
    weight?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductParamDTO
     */
    whereBuy?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ProductParamDTOSalesStatusEnum {
    BidComplete = 'bid_complete',
    BidPaymentCancel = 'bid_payment_cancel',
    BidPaymentTimeOut = 'bid_payment_time_out',
    Display = 'display',
    Invisible = 'invisible',
    Sale = 'sale',
    SoldOut = 'sold_out',
    TimeOut = 'time_out'
}

/**
 * 
 * @export
 * @interface ProductQnaDTO
 */
export interface ProductQnaDTO {
    /**
     * 
     * @type {number}
     * @memberof ProductQnaDTO
     */
    memberId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductQnaDTO
     */
    memo?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductQnaDTO
     */
    productId?: number;
}
/**
 * 
 * @export
 * @interface ProductTemp
 */
export interface ProductTemp {
    /**
     * 
     * @type {boolean}
     * @memberof ProductTemp
     */
    allPass?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductTemp
     */
    artistInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductTemp
     */
    artistNameEn?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductTemp
     */
    artistNameKr?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductTemp
     */
    buyLimit?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductTemp
     */
    buynowOnly?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductTemp
     */
    buynowPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductTemp
     */
    categoryDepth1?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductTemp
     */
    categoryDepth1Id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductTemp
     */
    categoryDepth2?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductTemp
     */
    categoryDepth2Id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductTemp
     */
    certificate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductTemp
     */
    country?: string;
    /**
     * 
     * @type {Timestamp}
     * @memberof ProductTemp
     */
    createdAt?: Timestamp;
    /**
     * 
     * @type {string}
     * @memberof ProductTemp
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductTemp
     */
    edition?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductTemp
     */
    enableBid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductTemp
     */
    enableBuyNow?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductTemp
     */
    enableRefund?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductTemp
     */
    enableRegisterToken?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductTemp
     */
    endDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductTemp
     */
    eventCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductTemp
     */
    exhibitionHistory?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductTemp
     */
    feePrepaidShipping?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductTemp
     */
    feeRateBid?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductTemp
     */
    feeRateBuyNow?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductTemp
     */
    lot?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductTemp
     */
    makeOffer?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductTemp
     */
    makeYear?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductTemp
     */
    medium?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductTemp
     */
    no?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductTemp
     */
    ownHistory?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductTemp
     */
    partnerId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductTemp
     */
    period?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductTemp
     */
    productId?: number;
    /**
     * 
     * @type {Array<ProductTempImage>}
     * @memberof ProductTemp
     */
    productImageList?: Array<ProductTempImage>;
    /**
     * 
     * @type {number}
     * @memberof ProductTemp
     */
    productTempId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductTemp
     */
    recordedPlace?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductTemp
     */
    salesType?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductTemp
     */
    sellerId?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductTemp
     */
    shippingFee?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductTemp
     */
    shippingType?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductTemp
     */
    shippingTypeId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductTemp
     */
    signature?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductTemp
     */
    signatureExist?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductTemp
     */
    size?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductTemp
     */
    startDate?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductTemp
     */
    startPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductTemp
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductTemp
     */
    supplyFeeRate?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductTemp
     */
    tags?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductTemp
     */
    taxFree?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductTemp
     */
    titleEn?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductTemp
     */
    titleKr?: string;
    /**
     * 
     * @type {Timestamp}
     * @memberof ProductTemp
     */
    updatedAt?: Timestamp;
    /**
     * 
     * @type {ProductTempValidation}
     * @memberof ProductTemp
     */
    validation?: ProductTempValidation;
}
/**
 * 
 * @export
 * @interface ProductTempImage
 */
export interface ProductTempImage {
    /**
     * 
     * @type {Timestamp}
     * @memberof ProductTempImage
     */
    createdAt?: Timestamp;
    /**
     * 
     * @type {number}
     * @memberof ProductTempImage
     */
    order?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductTempImage
     */
    productTempId?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductTempImage
     */
    productTempImageId?: number;
    /**
     * 
     * @type {Timestamp}
     * @memberof ProductTempImage
     */
    updatedAt?: Timestamp;
    /**
     * 
     * @type {string}
     * @memberof ProductTempImage
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface ProductTempValidation
 */
export interface ProductTempValidation {
    /**
     * 
     * @type {string}
     * @memberof ProductTempValidation
     */
    artistInfo?: ProductTempValidationArtistInfoEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductTempValidation
     */
    artistName?: ProductTempValidationArtistNameEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductTempValidation
     */
    buyLimit?: ProductTempValidationBuyLimitEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductTempValidation
     */
    buynowPrice?: ProductTempValidationBuynowPriceEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductTempValidation
     */
    categoryDepth1?: ProductTempValidationCategoryDepth1Enum;
    /**
     * 
     * @type {string}
     * @memberof ProductTempValidation
     */
    categoryDepth2?: ProductTempValidationCategoryDepth2Enum;
    /**
     * 
     * @type {string}
     * @memberof ProductTempValidation
     */
    certificate?: ProductTempValidationCertificateEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductTempValidation
     */
    country?: ProductTempValidationCountryEnum;
    /**
     * 
     * @type {Timestamp}
     * @memberof ProductTempValidation
     */
    createdAt?: Timestamp;
    /**
     * 
     * @type {string}
     * @memberof ProductTempValidation
     */
    description?: ProductTempValidationDescriptionEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductTempValidation
     */
    edition?: ProductTempValidationEditionEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductTempValidation
     */
    endDate?: ProductTempValidationEndDateEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductTempValidation
     */
    exhibitionHistory?: ProductTempValidationExhibitionHistoryEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductTempValidation
     */
    feePrepaidShipping?: ProductTempValidationFeePrepaidShippingEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductTempValidation
     */
    makeOffer?: ProductTempValidationMakeOfferEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductTempValidation
     */
    makeYear?: ProductTempValidationMakeYearEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductTempValidation
     */
    medium?: ProductTempValidationMediumEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductTempValidation
     */
    ownHistory?: ProductTempValidationOwnHistoryEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductTempValidation
     */
    period?: ProductTempValidationPeriodEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductTempValidation
     */
    productTempId?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductTempValidation
     */
    productTempValidationId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductTempValidation
     */
    recordedPlace?: ProductTempValidationRecordedPlaceEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductTempValidation
     */
    salesType?: ProductTempValidationSalesTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductTempValidation
     */
    shippingFee?: ProductTempValidationShippingFeeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductTempValidation
     */
    shippingType?: ProductTempValidationShippingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductTempValidation
     */
    signature?: ProductTempValidationSignatureEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductTempValidation
     */
    size?: ProductTempValidationSizeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductTempValidation
     */
    startDate?: ProductTempValidationStartDateEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductTempValidation
     */
    startPrice?: ProductTempValidationStartPriceEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductTempValidation
     */
    status?: ProductTempValidationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductTempValidation
     */
    tags?: ProductTempValidationTagsEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductTempValidation
     */
    taxFree?: ProductTempValidationTaxFreeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductTempValidation
     */
    title?: ProductTempValidationTitleEnum;
    /**
     * 
     * @type {Timestamp}
     * @memberof ProductTempValidation
     */
    updatedAt?: Timestamp;
}

/**
    * @export
    * @enum {string}
    */
export enum ProductTempValidationArtistInfoEnum {
    Before = 'before',
    Error = 'error',
    Pass = 'pass'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductTempValidationArtistNameEnum {
    Before = 'before',
    Error = 'error',
    Pass = 'pass'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductTempValidationBuyLimitEnum {
    Before = 'before',
    Error = 'error',
    Pass = 'pass'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductTempValidationBuynowPriceEnum {
    Before = 'before',
    Error = 'error',
    Pass = 'pass'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductTempValidationCategoryDepth1Enum {
    Before = 'before',
    Error = 'error',
    Pass = 'pass'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductTempValidationCategoryDepth2Enum {
    Before = 'before',
    Error = 'error',
    Pass = 'pass'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductTempValidationCertificateEnum {
    Before = 'before',
    Error = 'error',
    Pass = 'pass'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductTempValidationCountryEnum {
    Before = 'before',
    Error = 'error',
    Pass = 'pass'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductTempValidationDescriptionEnum {
    Before = 'before',
    Error = 'error',
    Pass = 'pass'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductTempValidationEditionEnum {
    Before = 'before',
    Error = 'error',
    Pass = 'pass'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductTempValidationEndDateEnum {
    Before = 'before',
    Error = 'error',
    Pass = 'pass'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductTempValidationExhibitionHistoryEnum {
    Before = 'before',
    Error = 'error',
    Pass = 'pass'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductTempValidationFeePrepaidShippingEnum {
    Before = 'before',
    Error = 'error',
    Pass = 'pass'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductTempValidationMakeOfferEnum {
    Before = 'before',
    Error = 'error',
    Pass = 'pass'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductTempValidationMakeYearEnum {
    Before = 'before',
    Error = 'error',
    Pass = 'pass'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductTempValidationMediumEnum {
    Before = 'before',
    Error = 'error',
    Pass = 'pass'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductTempValidationOwnHistoryEnum {
    Before = 'before',
    Error = 'error',
    Pass = 'pass'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductTempValidationPeriodEnum {
    Before = 'before',
    Error = 'error',
    Pass = 'pass'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductTempValidationRecordedPlaceEnum {
    Before = 'before',
    Error = 'error',
    Pass = 'pass'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductTempValidationSalesTypeEnum {
    Before = 'before',
    Error = 'error',
    Pass = 'pass'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductTempValidationShippingFeeEnum {
    Before = 'before',
    Error = 'error',
    Pass = 'pass'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductTempValidationShippingTypeEnum {
    Before = 'before',
    Error = 'error',
    Pass = 'pass'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductTempValidationSignatureEnum {
    Before = 'before',
    Error = 'error',
    Pass = 'pass'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductTempValidationSizeEnum {
    Before = 'before',
    Error = 'error',
    Pass = 'pass'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductTempValidationStartDateEnum {
    Before = 'before',
    Error = 'error',
    Pass = 'pass'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductTempValidationStartPriceEnum {
    Before = 'before',
    Error = 'error',
    Pass = 'pass'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductTempValidationStatusEnum {
    Before = 'before',
    Error = 'error',
    Pass = 'pass'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductTempValidationTagsEnum {
    Before = 'before',
    Error = 'error',
    Pass = 'pass'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductTempValidationTaxFreeEnum {
    Before = 'before',
    Error = 'error',
    Pass = 'pass'
}
/**
    * @export
    * @enum {string}
    */
export enum ProductTempValidationTitleEnum {
    Before = 'before',
    Error = 'error',
    Pass = 'pass'
}

/**
 * 
 * @export
 * @interface ProductUpdateParamDTO
 */
export interface ProductUpdateParamDTO {
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateParamDTO
     */
    artistInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateParamDTO
     */
    artistNameEn?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateParamDTO
     */
    artistNameKr?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductUpdateParamDTO
     */
    buyLimit: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductUpdateParamDTO
     */
    buynowOnly?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateParamDTO
     */
    buynowPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateParamDTO
     */
    categoryDepth1Id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateParamDTO
     */
    categoryDepth2Id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateParamDTO
     */
    categoryDepth3Id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductUpdateParamDTO
     */
    certificate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateParamDTO
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateParamDTO
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateParamDTO
     */
    edition?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductUpdateParamDTO
     */
    enableBid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductUpdateParamDTO
     */
    enableBuyNow?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductUpdateParamDTO
     */
    enableRefund?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductUpdateParamDTO
     */
    enableRegisterToken?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateParamDTO
     */
    endDate: string;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateParamDTO
     */
    eventCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateParamDTO
     */
    exhibitionHistory?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateParamDTO
     */
    expectEndPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateParamDTO
     */
    expectStartPrice?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductUpdateParamDTO
     */
    feePrepaidShipping?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateParamDTO
     */
    frameSize?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductUpdateParamDTO
     */
    makeOffer?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateParamDTO
     */
    makeYear?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateParamDTO
     */
    medium: string;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateParamDTO
     */
    mediumDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateParamDTO
     */
    ownHistory?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateParamDTO
     */
    period: string;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateParamDTO
     */
    productId?: number;
    /**
     * 
     * @type {Array<ProductImage>}
     * @memberof ProductUpdateParamDTO
     */
    productImageList?: Array<ProductImage>;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateParamDTO
     */
    purchaseDate?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateParamDTO
     */
    purchasePrice?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateParamDTO
     */
    recordedPlace?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateParamDTO
     */
    salesStatus?: ProductUpdateParamDTOSalesStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateParamDTO
     */
    sellerId?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateParamDTO
     */
    shippingFee: number;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateParamDTO
     */
    shippingTypeId: number;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateParamDTO
     */
    signature?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductUpdateParamDTO
     */
    signatureExist: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateParamDTO
     */
    size: string;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateParamDTO
     */
    startDate: string;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateParamDTO
     */
    startPrice: number;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateParamDTO
     */
    status: string;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateParamDTO
     */
    supplyFeeRate?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateParamDTO
     */
    tags: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductUpdateParamDTO
     */
    taxFree?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateParamDTO
     */
    titleEn?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateParamDTO
     */
    titleKr?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateParamDTO
     */
    videoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateParamDTO
     */
    weight?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateParamDTO
     */
    whereBuy?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateParamDTO
     */
    winMemberId?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateParamDTO
     */
    winPrice?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ProductUpdateParamDTOSalesStatusEnum {
    BidComplete = 'bid_complete',
    BidPaymentCancel = 'bid_payment_cancel',
    BidPaymentTimeOut = 'bid_payment_time_out',
    Display = 'display',
    Invisible = 'invisible',
    Sale = 'sale',
    SoldOut = 'sold_out',
    TimeOut = 'time_out'
}

/**
 * 
 * @export
 * @interface ResetPasswordParamDTO
 */
export interface ResetPasswordParamDTO {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordParamDTO
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordParamDTO
     */
    verificationCode: string;
}
/**
 * 
 * @export
 * @interface RestDTO
 */
export interface RestDTO {
    /**
     * 
     * @type {object}
     * @memberof RestDTO
     */
    data?: object;
    /**
     * 
     * @type {object}
     * @memberof RestDTO
     */
    message?: object;
    /**
     * 
     * @type {boolean}
     * @memberof RestDTO
     */
    success?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RestDTO
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface ReturnMemoParamDTO
 */
export interface ReturnMemoParamDTO {
    /**
     * 
     * @type {number}
     * @memberof ReturnMemoParamDTO
     */
    orderId?: number;
    /**
     * 
     * @type {string}
     * @memberof ReturnMemoParamDTO
     */
    returnMemo?: string;
}
/**
 * 
 * @export
 * @interface SalesInfoParamDTO
 */
export interface SalesInfoParamDTO {
    /**
     * 
     * @type {boolean}
     * @memberof SalesInfoParamDTO
     */
    buyLimit: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SalesInfoParamDTO
     */
    buynowOnly?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SalesInfoParamDTO
     */
    buynowPrice?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SalesInfoParamDTO
     */
    enableBid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SalesInfoParamDTO
     */
    enableBuyNow?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SalesInfoParamDTO
     */
    enableRegisterToken?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SalesInfoParamDTO
     */
    endDate?: string;
    /**
     * 
     * @type {number}
     * @memberof SalesInfoParamDTO
     */
    expectEndPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesInfoParamDTO
     */
    expectStartPrice?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SalesInfoParamDTO
     */
    feePrepaidShipping?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SalesInfoParamDTO
     */
    makeOffer?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SalesInfoParamDTO
     */
    productId?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesInfoParamDTO
     */
    shippingFee?: number;
    /**
     * 
     * @type {number}
     * @memberof SalesInfoParamDTO
     */
    shippingTypeId: number;
    /**
     * 
     * @type {string}
     * @memberof SalesInfoParamDTO
     */
    startDate: string;
    /**
     * 
     * @type {number}
     * @memberof SalesInfoParamDTO
     */
    startPrice?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SalesInfoParamDTO
     */
    taxFree?: boolean;
}
/**
 * 
 * @export
 * @interface ShippingAddress
 */
export interface ShippingAddress {
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    address1?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    addressName?: string;
    /**
     * 
     * @type {Timestamp}
     * @memberof ShippingAddress
     */
    createdAt?: Timestamp;
    /**
     * 
     * @type {number}
     * @memberof ShippingAddress
     */
    memberId?: number;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    memo?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    postcode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShippingAddress
     */
    selected?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ShippingAddress
     */
    shippingAddressId?: number;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    tel1?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddress
     */
    tel2?: string;
    /**
     * 
     * @type {Timestamp}
     * @memberof ShippingAddress
     */
    updatedAt?: Timestamp;
}
/**
 * 
 * @export
 * @interface ShippingAddressDTO
 */
export interface ShippingAddressDTO {
    /**
     * 
     * @type {string}
     * @memberof ShippingAddressDTO
     */
    address1?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddressDTO
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddressDTO
     */
    addressName?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddressDTO
     */
    memo?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddressDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddressDTO
     */
    postcode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShippingAddressDTO
     */
    requireUpdate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShippingAddressDTO
     */
    selected?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ShippingAddressDTO
     */
    shippingAddressId?: number;
    /**
     * 
     * @type {string}
     * @memberof ShippingAddressDTO
     */
    tel1?: string;
}
/**
 * 
 * @export
 * @interface ShippingParamDTO
 */
export interface ShippingParamDTO {
    /**
     * 
     * @type {string}
     * @memberof ShippingParamDTO
     */
    address1?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingParamDTO
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingParamDTO
     */
    addressName?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingParamDTO
     */
    memo?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingParamDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingParamDTO
     */
    orderNo?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingParamDTO
     */
    postcode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShippingParamDTO
     */
    selected?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShippingParamDTO
     */
    tel1?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingParamDTO
     */
    tel2?: string;
}
/**
 * 
 * @export
 * @interface Timestamp
 */
export interface Timestamp {
    /**
     * 
     * @type {number}
     * @memberof Timestamp
     */
    date?: number;
    /**
     * 
     * @type {number}
     * @memberof Timestamp
     */
    hours?: number;
    /**
     * 
     * @type {number}
     * @memberof Timestamp
     */
    minutes?: number;
    /**
     * 
     * @type {number}
     * @memberof Timestamp
     */
    month?: number;
    /**
     * 
     * @type {number}
     * @memberof Timestamp
     */
    nanos?: number;
    /**
     * 
     * @type {number}
     * @memberof Timestamp
     */
    seconds?: number;
    /**
     * 
     * @type {number}
     * @memberof Timestamp
     */
    time?: number;
    /**
     * 
     * @type {number}
     * @memberof Timestamp
     */
    year?: number;
}
/**
 * 
 * @export
 * @interface TrackingCodeParamDTO
 */
export interface TrackingCodeParamDTO {
    /**
     * 
     * @type {number}
     * @memberof TrackingCodeParamDTO
     */
    orderId?: number;
    /**
     * 
     * @type {number}
     * @memberof TrackingCodeParamDTO
     */
    shippingTypeId?: number;
    /**
     * 
     * @type {string}
     * @memberof TrackingCodeParamDTO
     */
    trackingCode?: string;
}
/**
 * 
 * @export
 * @interface View
 */
export interface View {
    /**
     * 
     * @type {string}
     * @memberof View
     */
    contentType?: string;
}

/**
 * AdminAuthApi - axios parameter creator
 * @export
 */
export const AdminAuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 로그인
         * @summary login
         * @param {AuthParamDTO} authParamDTO authParamDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUsingPOST: async (authParamDTO: AuthParamDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authParamDTO' is not null or undefined
            assertParamExists('loginUsingPOST', 'authParamDTO', authParamDTO)
            const localVarPath = `/admin-api/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authParamDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 로그아웃
         * @summary logout
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutUsingGET: async (creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin-api/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (creationTime !== undefined) {
                localVarQueryParameter['creationTime'] = creationTime;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (lastAccessedTime !== undefined) {
                localVarQueryParameter['lastAccessedTime'] = lastAccessedTime;
            }

            if (maxInactiveInterval !== undefined) {
                localVarQueryParameter['maxInactiveInterval'] = maxInactiveInterval;
            }

            if (_new !== undefined) {
                localVarQueryParameter['new'] = _new;
            }

            if (valueNames) {
                localVarQueryParameter['valueNames'] = valueNames;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminAuthApi - functional programming interface
 * @export
 */
export const AdminAuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminAuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 로그인
         * @summary login
         * @param {AuthParamDTO} authParamDTO authParamDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginUsingPOST(authParamDTO: AuthParamDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginUsingPOST(authParamDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 로그아웃
         * @summary logout
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutUsingGET(creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logoutUsingGET(creationTime, id, lastAccessedTime, maxInactiveInterval, _new, valueNames, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminAuthApi - factory interface
 * @export
 */
export const AdminAuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminAuthApiFp(configuration)
    return {
        /**
         * 로그인
         * @summary login
         * @param {AuthParamDTO} authParamDTO authParamDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUsingPOST(authParamDTO: AuthParamDTO, options?: any): AxiosPromise<object> {
            return localVarFp.loginUsingPOST(authParamDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 로그아웃
         * @summary logout
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutUsingGET(creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options?: any): AxiosPromise<string> {
            return localVarFp.logoutUsingGET(creationTime, id, lastAccessedTime, maxInactiveInterval, _new, valueNames, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for loginUsingPOST operation in AdminAuthApi.
 * @export
 * @interface AdminAuthApiLoginUsingPOSTRequest
 */
export interface AdminAuthApiLoginUsingPOSTRequest {
    /**
     * authParamDTO
     * @type {AuthParamDTO}
     * @memberof AdminAuthApiLoginUsingPOST
     */
    readonly authParamDTO: AuthParamDTO
}

/**
 * Request parameters for logoutUsingGET operation in AdminAuthApi.
 * @export
 * @interface AdminAuthApiLogoutUsingGETRequest
 */
export interface AdminAuthApiLogoutUsingGETRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminAuthApiLogoutUsingGET
     */
    readonly creationTime?: number

    /**
     * 
     * @type {string}
     * @memberof AdminAuthApiLogoutUsingGET
     */
    readonly id?: string

    /**
     * 
     * @type {number}
     * @memberof AdminAuthApiLogoutUsingGET
     */
    readonly lastAccessedTime?: number

    /**
     * 
     * @type {number}
     * @memberof AdminAuthApiLogoutUsingGET
     */
    readonly maxInactiveInterval?: number

    /**
     * 
     * @type {boolean}
     * @memberof AdminAuthApiLogoutUsingGET
     */
    readonly _new?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof AdminAuthApiLogoutUsingGET
     */
    readonly valueNames?: Array<string>
}

/**
 * AdminAuthApi - object-oriented interface
 * @export
 * @class AdminAuthApi
 * @extends {BaseAPI}
 */
export class AdminAuthApi extends BaseAPI {
    /**
     * 로그인
     * @summary login
     * @param {AdminAuthApiLoginUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAuthApi
     */
    public loginUsingPOST(requestParameters: AdminAuthApiLoginUsingPOSTRequest, options?: any) {
        return AdminAuthApiFp(this.configuration).loginUsingPOST(requestParameters.authParamDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 로그아웃
     * @summary logout
     * @param {AdminAuthApiLogoutUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminAuthApi
     */
    public logoutUsingGET(requestParameters: AdminAuthApiLogoutUsingGETRequest = {}, options?: any) {
        return AdminAuthApiFp(this.configuration).logoutUsingGET(requestParameters.creationTime, requestParameters.id, requestParameters.lastAccessedTime, requestParameters.maxInactiveInterval, requestParameters._new, requestParameters.valueNames, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminBannerApi - axios parameter creator
 * @export
 */
export const AdminBannerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 배너생성or수정
         * @summary adminBannerCreateUpdate
         * @param {number} [bannerId] 
         * @param {number} [createdAtDate] 
         * @param {number} [createdAtDay] 
         * @param {number} [createdAtHours] 
         * @param {number} [createdAtMinutes] 
         * @param {number} [createdAtMonth] 
         * @param {number} [createdAtNanos] 
         * @param {number} [createdAtSeconds] 
         * @param {number} [createdAtTime] 
         * @param {number} [createdAtTimezoneOffset] 
         * @param {number} [createdAtYear] 
         * @param {string} [imageUrl] 
         * @param {number} [order] 
         * @param {string} [targetUrl] 
         * @param {'main' | 'mobile' | 'sub'} [type] 
         * @param {number} [updatedAtDate] 
         * @param {number} [updatedAtDay] 
         * @param {number} [updatedAtHours] 
         * @param {number} [updatedAtMinutes] 
         * @param {number} [updatedAtMonth] 
         * @param {number} [updatedAtNanos] 
         * @param {number} [updatedAtSeconds] 
         * @param {number} [updatedAtTime] 
         * @param {number} [updatedAtTimezoneOffset] 
         * @param {number} [updatedAtYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannerCreateUpdate: async (bannerId?: number, createdAtDate?: number, createdAtDay?: number, createdAtHours?: number, createdAtMinutes?: number, createdAtMonth?: number, createdAtNanos?: number, createdAtSeconds?: number, createdAtTime?: number, createdAtTimezoneOffset?: number, createdAtYear?: number, imageUrl?: string, order?: number, targetUrl?: string, type?: 'main' | 'mobile' | 'sub', updatedAtDate?: number, updatedAtDay?: number, updatedAtHours?: number, updatedAtMinutes?: number, updatedAtMonth?: number, updatedAtNanos?: number, updatedAtSeconds?: number, updatedAtTime?: number, updatedAtTimezoneOffset?: number, updatedAtYear?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin-api/banner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (bannerId !== undefined) {
                localVarQueryParameter['bannerId'] = bannerId;
            }

            if (createdAtDate !== undefined) {
                localVarQueryParameter['createdAt.date'] = createdAtDate;
            }

            if (createdAtDay !== undefined) {
                localVarQueryParameter['createdAt.day'] = createdAtDay;
            }

            if (createdAtHours !== undefined) {
                localVarQueryParameter['createdAt.hours'] = createdAtHours;
            }

            if (createdAtMinutes !== undefined) {
                localVarQueryParameter['createdAt.minutes'] = createdAtMinutes;
            }

            if (createdAtMonth !== undefined) {
                localVarQueryParameter['createdAt.month'] = createdAtMonth;
            }

            if (createdAtNanos !== undefined) {
                localVarQueryParameter['createdAt.nanos'] = createdAtNanos;
            }

            if (createdAtSeconds !== undefined) {
                localVarQueryParameter['createdAt.seconds'] = createdAtSeconds;
            }

            if (createdAtTime !== undefined) {
                localVarQueryParameter['createdAt.time'] = createdAtTime;
            }

            if (createdAtTimezoneOffset !== undefined) {
                localVarQueryParameter['createdAt.timezoneOffset'] = createdAtTimezoneOffset;
            }

            if (createdAtYear !== undefined) {
                localVarQueryParameter['createdAt.year'] = createdAtYear;
            }

            if (imageUrl !== undefined) {
                localVarQueryParameter['imageUrl'] = imageUrl;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (targetUrl !== undefined) {
                localVarQueryParameter['targetUrl'] = targetUrl;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (updatedAtDate !== undefined) {
                localVarQueryParameter['updatedAt.date'] = updatedAtDate;
            }

            if (updatedAtDay !== undefined) {
                localVarQueryParameter['updatedAt.day'] = updatedAtDay;
            }

            if (updatedAtHours !== undefined) {
                localVarQueryParameter['updatedAt.hours'] = updatedAtHours;
            }

            if (updatedAtMinutes !== undefined) {
                localVarQueryParameter['updatedAt.minutes'] = updatedAtMinutes;
            }

            if (updatedAtMonth !== undefined) {
                localVarQueryParameter['updatedAt.month'] = updatedAtMonth;
            }

            if (updatedAtNanos !== undefined) {
                localVarQueryParameter['updatedAt.nanos'] = updatedAtNanos;
            }

            if (updatedAtSeconds !== undefined) {
                localVarQueryParameter['updatedAt.seconds'] = updatedAtSeconds;
            }

            if (updatedAtTime !== undefined) {
                localVarQueryParameter['updatedAt.time'] = updatedAtTime;
            }

            if (updatedAtTimezoneOffset !== undefined) {
                localVarQueryParameter['updatedAt.timezoneOffset'] = updatedAtTimezoneOffset;
            }

            if (updatedAtYear !== undefined) {
                localVarQueryParameter['updatedAt.year'] = updatedAtYear;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 배너삭제
         * @summary adminBannerDelete
         * @param {number} bannerId bannerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannerDelete: async (bannerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bannerId' is not null or undefined
            assertParamExists('adminBannerDelete', 'bannerId', bannerId)
            const localVarPath = `/admin-api/banner/{bannerId}`
                .replace(`{${"bannerId"}}`, encodeURIComponent(String(bannerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 배너리스트
         * @summary adminBannerList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannerList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin-api/banner/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminBannerApi - functional programming interface
 * @export
 */
export const AdminBannerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminBannerApiAxiosParamCreator(configuration)
    return {
        /**
         * 배너생성or수정
         * @summary adminBannerCreateUpdate
         * @param {number} [bannerId] 
         * @param {number} [createdAtDate] 
         * @param {number} [createdAtDay] 
         * @param {number} [createdAtHours] 
         * @param {number} [createdAtMinutes] 
         * @param {number} [createdAtMonth] 
         * @param {number} [createdAtNanos] 
         * @param {number} [createdAtSeconds] 
         * @param {number} [createdAtTime] 
         * @param {number} [createdAtTimezoneOffset] 
         * @param {number} [createdAtYear] 
         * @param {string} [imageUrl] 
         * @param {number} [order] 
         * @param {string} [targetUrl] 
         * @param {'main' | 'mobile' | 'sub'} [type] 
         * @param {number} [updatedAtDate] 
         * @param {number} [updatedAtDay] 
         * @param {number} [updatedAtHours] 
         * @param {number} [updatedAtMinutes] 
         * @param {number} [updatedAtMonth] 
         * @param {number} [updatedAtNanos] 
         * @param {number} [updatedAtSeconds] 
         * @param {number} [updatedAtTime] 
         * @param {number} [updatedAtTimezoneOffset] 
         * @param {number} [updatedAtYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBannerCreateUpdate(bannerId?: number, createdAtDate?: number, createdAtDay?: number, createdAtHours?: number, createdAtMinutes?: number, createdAtMonth?: number, createdAtNanos?: number, createdAtSeconds?: number, createdAtTime?: number, createdAtTimezoneOffset?: number, createdAtYear?: number, imageUrl?: string, order?: number, targetUrl?: string, type?: 'main' | 'mobile' | 'sub', updatedAtDate?: number, updatedAtDay?: number, updatedAtHours?: number, updatedAtMinutes?: number, updatedAtMonth?: number, updatedAtNanos?: number, updatedAtSeconds?: number, updatedAtTime?: number, updatedAtTimezoneOffset?: number, updatedAtYear?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBannerCreateUpdate(bannerId, createdAtDate, createdAtDay, createdAtHours, createdAtMinutes, createdAtMonth, createdAtNanos, createdAtSeconds, createdAtTime, createdAtTimezoneOffset, createdAtYear, imageUrl, order, targetUrl, type, updatedAtDate, updatedAtDay, updatedAtHours, updatedAtMinutes, updatedAtMonth, updatedAtNanos, updatedAtSeconds, updatedAtTime, updatedAtTimezoneOffset, updatedAtYear, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 배너삭제
         * @summary adminBannerDelete
         * @param {number} bannerId bannerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBannerDelete(bannerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBannerDelete(bannerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 배너리스트
         * @summary adminBannerList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBannerList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBannerList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminBannerApi - factory interface
 * @export
 */
export const AdminBannerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminBannerApiFp(configuration)
    return {
        /**
         * 배너생성or수정
         * @summary adminBannerCreateUpdate
         * @param {number} [bannerId] 
         * @param {number} [createdAtDate] 
         * @param {number} [createdAtDay] 
         * @param {number} [createdAtHours] 
         * @param {number} [createdAtMinutes] 
         * @param {number} [createdAtMonth] 
         * @param {number} [createdAtNanos] 
         * @param {number} [createdAtSeconds] 
         * @param {number} [createdAtTime] 
         * @param {number} [createdAtTimezoneOffset] 
         * @param {number} [createdAtYear] 
         * @param {string} [imageUrl] 
         * @param {number} [order] 
         * @param {string} [targetUrl] 
         * @param {'main' | 'mobile' | 'sub'} [type] 
         * @param {number} [updatedAtDate] 
         * @param {number} [updatedAtDay] 
         * @param {number} [updatedAtHours] 
         * @param {number} [updatedAtMinutes] 
         * @param {number} [updatedAtMonth] 
         * @param {number} [updatedAtNanos] 
         * @param {number} [updatedAtSeconds] 
         * @param {number} [updatedAtTime] 
         * @param {number} [updatedAtTimezoneOffset] 
         * @param {number} [updatedAtYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannerCreateUpdate(bannerId?: number, createdAtDate?: number, createdAtDay?: number, createdAtHours?: number, createdAtMinutes?: number, createdAtMonth?: number, createdAtNanos?: number, createdAtSeconds?: number, createdAtTime?: number, createdAtTimezoneOffset?: number, createdAtYear?: number, imageUrl?: string, order?: number, targetUrl?: string, type?: 'main' | 'mobile' | 'sub', updatedAtDate?: number, updatedAtDay?: number, updatedAtHours?: number, updatedAtMinutes?: number, updatedAtMonth?: number, updatedAtNanos?: number, updatedAtSeconds?: number, updatedAtTime?: number, updatedAtTimezoneOffset?: number, updatedAtYear?: number, options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminBannerCreateUpdate(bannerId, createdAtDate, createdAtDay, createdAtHours, createdAtMinutes, createdAtMonth, createdAtNanos, createdAtSeconds, createdAtTime, createdAtTimezoneOffset, createdAtYear, imageUrl, order, targetUrl, type, updatedAtDate, updatedAtDay, updatedAtHours, updatedAtMinutes, updatedAtMonth, updatedAtNanos, updatedAtSeconds, updatedAtTime, updatedAtTimezoneOffset, updatedAtYear, options).then((request) => request(axios, basePath));
        },
        /**
         * 배너삭제
         * @summary adminBannerDelete
         * @param {number} bannerId bannerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannerDelete(bannerId: number, options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminBannerDelete(bannerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 배너리스트
         * @summary adminBannerList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBannerList(options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminBannerList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminBannerCreateUpdate operation in AdminBannerApi.
 * @export
 * @interface AdminBannerApiAdminBannerCreateUpdateRequest
 */
export interface AdminBannerApiAdminBannerCreateUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminBannerApiAdminBannerCreateUpdate
     */
    readonly bannerId?: number

    /**
     * 
     * @type {number}
     * @memberof AdminBannerApiAdminBannerCreateUpdate
     */
    readonly createdAtDate?: number

    /**
     * 
     * @type {number}
     * @memberof AdminBannerApiAdminBannerCreateUpdate
     */
    readonly createdAtDay?: number

    /**
     * 
     * @type {number}
     * @memberof AdminBannerApiAdminBannerCreateUpdate
     */
    readonly createdAtHours?: number

    /**
     * 
     * @type {number}
     * @memberof AdminBannerApiAdminBannerCreateUpdate
     */
    readonly createdAtMinutes?: number

    /**
     * 
     * @type {number}
     * @memberof AdminBannerApiAdminBannerCreateUpdate
     */
    readonly createdAtMonth?: number

    /**
     * 
     * @type {number}
     * @memberof AdminBannerApiAdminBannerCreateUpdate
     */
    readonly createdAtNanos?: number

    /**
     * 
     * @type {number}
     * @memberof AdminBannerApiAdminBannerCreateUpdate
     */
    readonly createdAtSeconds?: number

    /**
     * 
     * @type {number}
     * @memberof AdminBannerApiAdminBannerCreateUpdate
     */
    readonly createdAtTime?: number

    /**
     * 
     * @type {number}
     * @memberof AdminBannerApiAdminBannerCreateUpdate
     */
    readonly createdAtTimezoneOffset?: number

    /**
     * 
     * @type {number}
     * @memberof AdminBannerApiAdminBannerCreateUpdate
     */
    readonly createdAtYear?: number

    /**
     * 
     * @type {string}
     * @memberof AdminBannerApiAdminBannerCreateUpdate
     */
    readonly imageUrl?: string

    /**
     * 
     * @type {number}
     * @memberof AdminBannerApiAdminBannerCreateUpdate
     */
    readonly order?: number

    /**
     * 
     * @type {string}
     * @memberof AdminBannerApiAdminBannerCreateUpdate
     */
    readonly targetUrl?: string

    /**
     * 
     * @type {'main' | 'mobile' | 'sub'}
     * @memberof AdminBannerApiAdminBannerCreateUpdate
     */
    readonly type?: 'main' | 'mobile' | 'sub'

    /**
     * 
     * @type {number}
     * @memberof AdminBannerApiAdminBannerCreateUpdate
     */
    readonly updatedAtDate?: number

    /**
     * 
     * @type {number}
     * @memberof AdminBannerApiAdminBannerCreateUpdate
     */
    readonly updatedAtDay?: number

    /**
     * 
     * @type {number}
     * @memberof AdminBannerApiAdminBannerCreateUpdate
     */
    readonly updatedAtHours?: number

    /**
     * 
     * @type {number}
     * @memberof AdminBannerApiAdminBannerCreateUpdate
     */
    readonly updatedAtMinutes?: number

    /**
     * 
     * @type {number}
     * @memberof AdminBannerApiAdminBannerCreateUpdate
     */
    readonly updatedAtMonth?: number

    /**
     * 
     * @type {number}
     * @memberof AdminBannerApiAdminBannerCreateUpdate
     */
    readonly updatedAtNanos?: number

    /**
     * 
     * @type {number}
     * @memberof AdminBannerApiAdminBannerCreateUpdate
     */
    readonly updatedAtSeconds?: number

    /**
     * 
     * @type {number}
     * @memberof AdminBannerApiAdminBannerCreateUpdate
     */
    readonly updatedAtTime?: number

    /**
     * 
     * @type {number}
     * @memberof AdminBannerApiAdminBannerCreateUpdate
     */
    readonly updatedAtTimezoneOffset?: number

    /**
     * 
     * @type {number}
     * @memberof AdminBannerApiAdminBannerCreateUpdate
     */
    readonly updatedAtYear?: number
}

/**
 * Request parameters for adminBannerDelete operation in AdminBannerApi.
 * @export
 * @interface AdminBannerApiAdminBannerDeleteRequest
 */
export interface AdminBannerApiAdminBannerDeleteRequest {
    /**
     * bannerId
     * @type {number}
     * @memberof AdminBannerApiAdminBannerDelete
     */
    readonly bannerId: number
}

/**
 * AdminBannerApi - object-oriented interface
 * @export
 * @class AdminBannerApi
 * @extends {BaseAPI}
 */
export class AdminBannerApi extends BaseAPI {
    /**
     * 배너생성or수정
     * @summary adminBannerCreateUpdate
     * @param {AdminBannerApiAdminBannerCreateUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBannerApi
     */
    public adminBannerCreateUpdate(requestParameters: AdminBannerApiAdminBannerCreateUpdateRequest = {}, options?: any) {
        return AdminBannerApiFp(this.configuration).adminBannerCreateUpdate(requestParameters.bannerId, requestParameters.createdAtDate, requestParameters.createdAtDay, requestParameters.createdAtHours, requestParameters.createdAtMinutes, requestParameters.createdAtMonth, requestParameters.createdAtNanos, requestParameters.createdAtSeconds, requestParameters.createdAtTime, requestParameters.createdAtTimezoneOffset, requestParameters.createdAtYear, requestParameters.imageUrl, requestParameters.order, requestParameters.targetUrl, requestParameters.type, requestParameters.updatedAtDate, requestParameters.updatedAtDay, requestParameters.updatedAtHours, requestParameters.updatedAtMinutes, requestParameters.updatedAtMonth, requestParameters.updatedAtNanos, requestParameters.updatedAtSeconds, requestParameters.updatedAtTime, requestParameters.updatedAtTimezoneOffset, requestParameters.updatedAtYear, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 배너삭제
     * @summary adminBannerDelete
     * @param {AdminBannerApiAdminBannerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBannerApi
     */
    public adminBannerDelete(requestParameters: AdminBannerApiAdminBannerDeleteRequest, options?: any) {
        return AdminBannerApiFp(this.configuration).adminBannerDelete(requestParameters.bannerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 배너리스트
     * @summary adminBannerList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBannerApi
     */
    public adminBannerList(options?: any) {
        return AdminBannerApiFp(this.configuration).adminBannerList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminDashboardApi - axios parameter creator
 * @export
 */
export const AdminDashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 대시보드 조회
         * @summary adminDashboard
         * @param {'all' | 'last_month' | 'this_month' | 'two_month_ago'} [dashboardTerm] dashboardTerm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDashboard: async (dashboardTerm?: 'all' | 'last_month' | 'this_month' | 'two_month_ago', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin-api/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dashboardTerm !== undefined) {
                localVarQueryParameter['dashboardTerm'] = dashboardTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminDashboardApi - functional programming interface
 * @export
 */
export const AdminDashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminDashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 대시보드 조회
         * @summary adminDashboard
         * @param {'all' | 'last_month' | 'this_month' | 'two_month_ago'} [dashboardTerm] dashboardTerm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDashboard(dashboardTerm?: 'all' | 'last_month' | 'this_month' | 'two_month_ago', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDashboard(dashboardTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminDashboardApi - factory interface
 * @export
 */
export const AdminDashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminDashboardApiFp(configuration)
    return {
        /**
         * 대시보드 조회
         * @summary adminDashboard
         * @param {'all' | 'last_month' | 'this_month' | 'two_month_ago'} [dashboardTerm] dashboardTerm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDashboard(dashboardTerm?: 'all' | 'last_month' | 'this_month' | 'two_month_ago', options?: any): AxiosPromise<object> {
            return localVarFp.adminDashboard(dashboardTerm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminDashboard operation in AdminDashboardApi.
 * @export
 * @interface AdminDashboardApiAdminDashboardRequest
 */
export interface AdminDashboardApiAdminDashboardRequest {
    /**
     * dashboardTerm
     * @type {'all' | 'last_month' | 'this_month' | 'two_month_ago'}
     * @memberof AdminDashboardApiAdminDashboard
     */
    readonly dashboardTerm?: 'all' | 'last_month' | 'this_month' | 'two_month_ago'
}

/**
 * AdminDashboardApi - object-oriented interface
 * @export
 * @class AdminDashboardApi
 * @extends {BaseAPI}
 */
export class AdminDashboardApi extends BaseAPI {
    /**
     * 대시보드 조회
     * @summary adminDashboard
     * @param {AdminDashboardApiAdminDashboardRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDashboardApi
     */
    public adminDashboard(requestParameters: AdminDashboardApiAdminDashboardRequest = {}, options?: any) {
        return AdminDashboardApiFp(this.configuration).adminDashboard(requestParameters.dashboardTerm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminEventApi - axios parameter creator
 * @export
 */
export const AdminEventApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 관리자 이벤트 등록
         * @summary adminEventCreate
         * @param {AdminEventDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEventCreate: async (param: AdminEventDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('adminEventCreate', 'param', param)
            const localVarPath = `/admin-api/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 관리자 이벤트 삭제
         * @summary adminEventDelete
         * @param {Array<string>} eventIdArray eventIdArray
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEventDelete: async (eventIdArray: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventIdArray' is not null or undefined
            assertParamExists('adminEventDelete', 'eventIdArray', eventIdArray)
            const localVarPath = `/admin-api/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventIdArray, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 관리자 이벤트 리스트
         * @summary adminEventList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {string} [category] category
         * @param {string} [end] end
         * @param {string} [keyword] keyword
         * @param {boolean} [showDeleted] showDeleted
         * @param {string} [sortBy] sortBy
         * @param {string} [start] start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEventList: async (page: number, pageSize: number, category?: string, end?: string, keyword?: string, showDeleted?: boolean, sortBy?: string, start?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('adminEventList', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminEventList', 'pageSize', pageSize)
            const localVarPath = `/admin-api/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (showDeleted !== undefined) {
                localVarQueryParameter['showDeleted'] = showDeleted;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 관리자 이벤트 상품 제외
         * @summary adminEventProductExcept
         * @param {Array<string>} productIdArray productIdArray
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEventProductExcept: async (productIdArray: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productIdArray' is not null or undefined
            assertParamExists('adminEventProductExcept', 'productIdArray', productIdArray)
            const localVarPath = `/admin-api/event/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productIdArray, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 관리자 이벤트 상품 리스트
         * @summary adminEventProductList
         * @param {string} eventCode eventCode
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {string} [eventCategory] eventCategory
         * @param {number} [partnerId] partnerId
         * @param {string} [sortBy] sortBy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEventProductList: async (eventCode: string, page: number, pageSize: number, eventCategory?: string, partnerId?: number, sortBy?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventCode' is not null or undefined
            assertParamExists('adminEventProductList', 'eventCode', eventCode)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('adminEventProductList', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminEventProductList', 'pageSize', pageSize)
            const localVarPath = `/admin-api/event/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (eventCategory !== undefined) {
                localVarQueryParameter['eventCategory'] = eventCategory;
            }

            if (eventCode !== undefined) {
                localVarQueryParameter['eventCode'] = eventCode;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partnerId'] = partnerId;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 관리자 이벤트 수정
         * @summary adminEventProductUpdate
         * @param {string} eventCategory eventCategory
         * @param {Array<string>} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEventProductUpdate: async (eventCategory: string, productId: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventCategory' is not null or undefined
            assertParamExists('adminEventProductUpdate', 'eventCategory', eventCategory)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('adminEventProductUpdate', 'productId', productId)
            const localVarPath = `/admin-api/event/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (eventCategory !== undefined) {
                localVarQueryParameter['eventCategory'] = eventCategory;
            }

            if (productId) {
                localVarQueryParameter['productId'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 관리자 이벤트 조회
         * @summary adminEventSingle
         * @param {number} eventId eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEventSingle: async (eventId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('adminEventSingle', 'eventId', eventId)
            const localVarPath = `/admin-api/event/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 관리자 이벤트 수정
         * @summary adminEventUpdate
         * @param {AdminEventDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEventUpdate: async (param: AdminEventDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('adminEventUpdate', 'param', param)
            const localVarPath = `/admin-api/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminEventApi - functional programming interface
 * @export
 */
export const AdminEventApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminEventApiAxiosParamCreator(configuration)
    return {
        /**
         * 관리자 이벤트 등록
         * @summary adminEventCreate
         * @param {AdminEventDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEventCreate(param: AdminEventDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEventCreate(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 관리자 이벤트 삭제
         * @summary adminEventDelete
         * @param {Array<string>} eventIdArray eventIdArray
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEventDelete(eventIdArray: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEventDelete(eventIdArray, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 관리자 이벤트 리스트
         * @summary adminEventList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {string} [category] category
         * @param {string} [end] end
         * @param {string} [keyword] keyword
         * @param {boolean} [showDeleted] showDeleted
         * @param {string} [sortBy] sortBy
         * @param {string} [start] start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEventList(page: number, pageSize: number, category?: string, end?: string, keyword?: string, showDeleted?: boolean, sortBy?: string, start?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEventList(page, pageSize, category, end, keyword, showDeleted, sortBy, start, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 관리자 이벤트 상품 제외
         * @summary adminEventProductExcept
         * @param {Array<string>} productIdArray productIdArray
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEventProductExcept(productIdArray: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEventProductExcept(productIdArray, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 관리자 이벤트 상품 리스트
         * @summary adminEventProductList
         * @param {string} eventCode eventCode
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {string} [eventCategory] eventCategory
         * @param {number} [partnerId] partnerId
         * @param {string} [sortBy] sortBy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEventProductList(eventCode: string, page: number, pageSize: number, eventCategory?: string, partnerId?: number, sortBy?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEventProductList(eventCode, page, pageSize, eventCategory, partnerId, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 관리자 이벤트 수정
         * @summary adminEventProductUpdate
         * @param {string} eventCategory eventCategory
         * @param {Array<string>} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEventProductUpdate(eventCategory: string, productId: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEventProductUpdate(eventCategory, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 관리자 이벤트 조회
         * @summary adminEventSingle
         * @param {number} eventId eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEventSingle(eventId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEventSingle(eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 관리자 이벤트 수정
         * @summary adminEventUpdate
         * @param {AdminEventDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminEventUpdate(param: AdminEventDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminEventUpdate(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminEventApi - factory interface
 * @export
 */
export const AdminEventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminEventApiFp(configuration)
    return {
        /**
         * 관리자 이벤트 등록
         * @summary adminEventCreate
         * @param {AdminEventDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEventCreate(param: AdminEventDTO, options?: any): AxiosPromise<object> {
            return localVarFp.adminEventCreate(param, options).then((request) => request(axios, basePath));
        },
        /**
         * 관리자 이벤트 삭제
         * @summary adminEventDelete
         * @param {Array<string>} eventIdArray eventIdArray
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEventDelete(eventIdArray: Array<string>, options?: any): AxiosPromise<object> {
            return localVarFp.adminEventDelete(eventIdArray, options).then((request) => request(axios, basePath));
        },
        /**
         * 관리자 이벤트 리스트
         * @summary adminEventList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {string} [category] category
         * @param {string} [end] end
         * @param {string} [keyword] keyword
         * @param {boolean} [showDeleted] showDeleted
         * @param {string} [sortBy] sortBy
         * @param {string} [start] start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEventList(page: number, pageSize: number, category?: string, end?: string, keyword?: string, showDeleted?: boolean, sortBy?: string, start?: string, options?: any): AxiosPromise<object> {
            return localVarFp.adminEventList(page, pageSize, category, end, keyword, showDeleted, sortBy, start, options).then((request) => request(axios, basePath));
        },
        /**
         * 관리자 이벤트 상품 제외
         * @summary adminEventProductExcept
         * @param {Array<string>} productIdArray productIdArray
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEventProductExcept(productIdArray: Array<string>, options?: any): AxiosPromise<object> {
            return localVarFp.adminEventProductExcept(productIdArray, options).then((request) => request(axios, basePath));
        },
        /**
         * 관리자 이벤트 상품 리스트
         * @summary adminEventProductList
         * @param {string} eventCode eventCode
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {string} [eventCategory] eventCategory
         * @param {number} [partnerId] partnerId
         * @param {string} [sortBy] sortBy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEventProductList(eventCode: string, page: number, pageSize: number, eventCategory?: string, partnerId?: number, sortBy?: string, options?: any): AxiosPromise<object> {
            return localVarFp.adminEventProductList(eventCode, page, pageSize, eventCategory, partnerId, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 관리자 이벤트 수정
         * @summary adminEventProductUpdate
         * @param {string} eventCategory eventCategory
         * @param {Array<string>} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEventProductUpdate(eventCategory: string, productId: Array<string>, options?: any): AxiosPromise<object> {
            return localVarFp.adminEventProductUpdate(eventCategory, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 관리자 이벤트 조회
         * @summary adminEventSingle
         * @param {number} eventId eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEventSingle(eventId: number, options?: any): AxiosPromise<object> {
            return localVarFp.adminEventSingle(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 관리자 이벤트 수정
         * @summary adminEventUpdate
         * @param {AdminEventDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEventUpdate(param: AdminEventDTO, options?: any): AxiosPromise<object> {
            return localVarFp.adminEventUpdate(param, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminEventCreate operation in AdminEventApi.
 * @export
 * @interface AdminEventApiAdminEventCreateRequest
 */
export interface AdminEventApiAdminEventCreateRequest {
    /**
     * param
     * @type {AdminEventDTO}
     * @memberof AdminEventApiAdminEventCreate
     */
    readonly param: AdminEventDTO
}

/**
 * Request parameters for adminEventDelete operation in AdminEventApi.
 * @export
 * @interface AdminEventApiAdminEventDeleteRequest
 */
export interface AdminEventApiAdminEventDeleteRequest {
    /**
     * eventIdArray
     * @type {Array<string>}
     * @memberof AdminEventApiAdminEventDelete
     */
    readonly eventIdArray: Array<string>
}

/**
 * Request parameters for adminEventList operation in AdminEventApi.
 * @export
 * @interface AdminEventApiAdminEventListRequest
 */
export interface AdminEventApiAdminEventListRequest {
    /**
     * page
     * @type {number}
     * @memberof AdminEventApiAdminEventList
     */
    readonly page: number

    /**
     * pageSize
     * @type {number}
     * @memberof AdminEventApiAdminEventList
     */
    readonly pageSize: number

    /**
     * category
     * @type {string}
     * @memberof AdminEventApiAdminEventList
     */
    readonly category?: string

    /**
     * end
     * @type {string}
     * @memberof AdminEventApiAdminEventList
     */
    readonly end?: string

    /**
     * keyword
     * @type {string}
     * @memberof AdminEventApiAdminEventList
     */
    readonly keyword?: string

    /**
     * showDeleted
     * @type {boolean}
     * @memberof AdminEventApiAdminEventList
     */
    readonly showDeleted?: boolean

    /**
     * sortBy
     * @type {string}
     * @memberof AdminEventApiAdminEventList
     */
    readonly sortBy?: string

    /**
     * start
     * @type {string}
     * @memberof AdminEventApiAdminEventList
     */
    readonly start?: string
}

/**
 * Request parameters for adminEventProductExcept operation in AdminEventApi.
 * @export
 * @interface AdminEventApiAdminEventProductExceptRequest
 */
export interface AdminEventApiAdminEventProductExceptRequest {
    /**
     * productIdArray
     * @type {Array<string>}
     * @memberof AdminEventApiAdminEventProductExcept
     */
    readonly productIdArray: Array<string>
}

/**
 * Request parameters for adminEventProductList operation in AdminEventApi.
 * @export
 * @interface AdminEventApiAdminEventProductListRequest
 */
export interface AdminEventApiAdminEventProductListRequest {
    /**
     * eventCode
     * @type {string}
     * @memberof AdminEventApiAdminEventProductList
     */
    readonly eventCode: string

    /**
     * page
     * @type {number}
     * @memberof AdminEventApiAdminEventProductList
     */
    readonly page: number

    /**
     * pageSize
     * @type {number}
     * @memberof AdminEventApiAdminEventProductList
     */
    readonly pageSize: number

    /**
     * eventCategory
     * @type {string}
     * @memberof AdminEventApiAdminEventProductList
     */
    readonly eventCategory?: string

    /**
     * partnerId
     * @type {number}
     * @memberof AdminEventApiAdminEventProductList
     */
    readonly partnerId?: number

    /**
     * sortBy
     * @type {string}
     * @memberof AdminEventApiAdminEventProductList
     */
    readonly sortBy?: string
}

/**
 * Request parameters for adminEventProductUpdate operation in AdminEventApi.
 * @export
 * @interface AdminEventApiAdminEventProductUpdateRequest
 */
export interface AdminEventApiAdminEventProductUpdateRequest {
    /**
     * eventCategory
     * @type {string}
     * @memberof AdminEventApiAdminEventProductUpdate
     */
    readonly eventCategory: string

    /**
     * productId
     * @type {Array<string>}
     * @memberof AdminEventApiAdminEventProductUpdate
     */
    readonly productId: Array<string>
}

/**
 * Request parameters for adminEventSingle operation in AdminEventApi.
 * @export
 * @interface AdminEventApiAdminEventSingleRequest
 */
export interface AdminEventApiAdminEventSingleRequest {
    /**
     * eventId
     * @type {number}
     * @memberof AdminEventApiAdminEventSingle
     */
    readonly eventId: number
}

/**
 * Request parameters for adminEventUpdate operation in AdminEventApi.
 * @export
 * @interface AdminEventApiAdminEventUpdateRequest
 */
export interface AdminEventApiAdminEventUpdateRequest {
    /**
     * param
     * @type {AdminEventDTO}
     * @memberof AdminEventApiAdminEventUpdate
     */
    readonly param: AdminEventDTO
}

/**
 * AdminEventApi - object-oriented interface
 * @export
 * @class AdminEventApi
 * @extends {BaseAPI}
 */
export class AdminEventApi extends BaseAPI {
    /**
     * 관리자 이벤트 등록
     * @summary adminEventCreate
     * @param {AdminEventApiAdminEventCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEventApi
     */
    public adminEventCreate(requestParameters: AdminEventApiAdminEventCreateRequest, options?: any) {
        return AdminEventApiFp(this.configuration).adminEventCreate(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 관리자 이벤트 삭제
     * @summary adminEventDelete
     * @param {AdminEventApiAdminEventDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEventApi
     */
    public adminEventDelete(requestParameters: AdminEventApiAdminEventDeleteRequest, options?: any) {
        return AdminEventApiFp(this.configuration).adminEventDelete(requestParameters.eventIdArray, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 관리자 이벤트 리스트
     * @summary adminEventList
     * @param {AdminEventApiAdminEventListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEventApi
     */
    public adminEventList(requestParameters: AdminEventApiAdminEventListRequest, options?: any) {
        return AdminEventApiFp(this.configuration).adminEventList(requestParameters.page, requestParameters.pageSize, requestParameters.category, requestParameters.end, requestParameters.keyword, requestParameters.showDeleted, requestParameters.sortBy, requestParameters.start, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 관리자 이벤트 상품 제외
     * @summary adminEventProductExcept
     * @param {AdminEventApiAdminEventProductExceptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEventApi
     */
    public adminEventProductExcept(requestParameters: AdminEventApiAdminEventProductExceptRequest, options?: any) {
        return AdminEventApiFp(this.configuration).adminEventProductExcept(requestParameters.productIdArray, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 관리자 이벤트 상품 리스트
     * @summary adminEventProductList
     * @param {AdminEventApiAdminEventProductListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEventApi
     */
    public adminEventProductList(requestParameters: AdminEventApiAdminEventProductListRequest, options?: any) {
        return AdminEventApiFp(this.configuration).adminEventProductList(requestParameters.eventCode, requestParameters.page, requestParameters.pageSize, requestParameters.eventCategory, requestParameters.partnerId, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 관리자 이벤트 수정
     * @summary adminEventProductUpdate
     * @param {AdminEventApiAdminEventProductUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEventApi
     */
    public adminEventProductUpdate(requestParameters: AdminEventApiAdminEventProductUpdateRequest, options?: any) {
        return AdminEventApiFp(this.configuration).adminEventProductUpdate(requestParameters.eventCategory, requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 관리자 이벤트 조회
     * @summary adminEventSingle
     * @param {AdminEventApiAdminEventSingleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEventApi
     */
    public adminEventSingle(requestParameters: AdminEventApiAdminEventSingleRequest, options?: any) {
        return AdminEventApiFp(this.configuration).adminEventSingle(requestParameters.eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 관리자 이벤트 수정
     * @summary adminEventUpdate
     * @param {AdminEventApiAdminEventUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminEventApi
     */
    public adminEventUpdate(requestParameters: AdminEventApiAdminEventUpdateRequest, options?: any) {
        return AdminEventApiFp(this.configuration).adminEventUpdate(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminFeaturedSellerApi - axios parameter creator
 * @export
 */
export const AdminFeaturedSellerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * all피쳐드셀러
         * @summary adminFeaturedSellerAll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFeaturedSellerAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin-api/featured-seller/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 피쳐드셀러파트너리스트
         * @summary adminFeaturedSellerPartnerList
         * @param {string} [keyword] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFeaturedSellerPartnerList: async (keyword?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin-api/featured-seller/partner/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 피쳐드셀러수정
         * @summary adminFeaturedSellerUpdate
         * @param {number} featuredSellerId featuredSellerId
         * @param {number} partnerId partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFeaturedSellerUpdate: async (featuredSellerId: number, partnerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'featuredSellerId' is not null or undefined
            assertParamExists('adminFeaturedSellerUpdate', 'featuredSellerId', featuredSellerId)
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('adminFeaturedSellerUpdate', 'partnerId', partnerId)
            const localVarPath = `/admin-api/featured-seller/{featuredSellerId}/partner/{partnerId}`
                .replace(`{${"featuredSellerId"}}`, encodeURIComponent(String(featuredSellerId)))
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminFeaturedSellerApi - functional programming interface
 * @export
 */
export const AdminFeaturedSellerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminFeaturedSellerApiAxiosParamCreator(configuration)
    return {
        /**
         * all피쳐드셀러
         * @summary adminFeaturedSellerAll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFeaturedSellerAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFeaturedSellerAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 피쳐드셀러파트너리스트
         * @summary adminFeaturedSellerPartnerList
         * @param {string} [keyword] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFeaturedSellerPartnerList(keyword?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFeaturedSellerPartnerList(keyword, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 피쳐드셀러수정
         * @summary adminFeaturedSellerUpdate
         * @param {number} featuredSellerId featuredSellerId
         * @param {number} partnerId partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFeaturedSellerUpdate(featuredSellerId: number, partnerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFeaturedSellerUpdate(featuredSellerId, partnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminFeaturedSellerApi - factory interface
 * @export
 */
export const AdminFeaturedSellerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminFeaturedSellerApiFp(configuration)
    return {
        /**
         * all피쳐드셀러
         * @summary adminFeaturedSellerAll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFeaturedSellerAll(options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminFeaturedSellerAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 피쳐드셀러파트너리스트
         * @summary adminFeaturedSellerPartnerList
         * @param {string} [keyword] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFeaturedSellerPartnerList(keyword?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminFeaturedSellerPartnerList(keyword, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 피쳐드셀러수정
         * @summary adminFeaturedSellerUpdate
         * @param {number} featuredSellerId featuredSellerId
         * @param {number} partnerId partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFeaturedSellerUpdate(featuredSellerId: number, partnerId: number, options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminFeaturedSellerUpdate(featuredSellerId, partnerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminFeaturedSellerPartnerList operation in AdminFeaturedSellerApi.
 * @export
 * @interface AdminFeaturedSellerApiAdminFeaturedSellerPartnerListRequest
 */
export interface AdminFeaturedSellerApiAdminFeaturedSellerPartnerListRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminFeaturedSellerApiAdminFeaturedSellerPartnerList
     */
    readonly keyword?: string

    /**
     * 
     * @type {number}
     * @memberof AdminFeaturedSellerApiAdminFeaturedSellerPartnerList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminFeaturedSellerApiAdminFeaturedSellerPartnerList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for adminFeaturedSellerUpdate operation in AdminFeaturedSellerApi.
 * @export
 * @interface AdminFeaturedSellerApiAdminFeaturedSellerUpdateRequest
 */
export interface AdminFeaturedSellerApiAdminFeaturedSellerUpdateRequest {
    /**
     * featuredSellerId
     * @type {number}
     * @memberof AdminFeaturedSellerApiAdminFeaturedSellerUpdate
     */
    readonly featuredSellerId: number

    /**
     * partnerId
     * @type {number}
     * @memberof AdminFeaturedSellerApiAdminFeaturedSellerUpdate
     */
    readonly partnerId: number
}

/**
 * AdminFeaturedSellerApi - object-oriented interface
 * @export
 * @class AdminFeaturedSellerApi
 * @extends {BaseAPI}
 */
export class AdminFeaturedSellerApi extends BaseAPI {
    /**
     * all피쳐드셀러
     * @summary adminFeaturedSellerAll
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFeaturedSellerApi
     */
    public adminFeaturedSellerAll(options?: any) {
        return AdminFeaturedSellerApiFp(this.configuration).adminFeaturedSellerAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 피쳐드셀러파트너리스트
     * @summary adminFeaturedSellerPartnerList
     * @param {AdminFeaturedSellerApiAdminFeaturedSellerPartnerListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFeaturedSellerApi
     */
    public adminFeaturedSellerPartnerList(requestParameters: AdminFeaturedSellerApiAdminFeaturedSellerPartnerListRequest = {}, options?: any) {
        return AdminFeaturedSellerApiFp(this.configuration).adminFeaturedSellerPartnerList(requestParameters.keyword, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 피쳐드셀러수정
     * @summary adminFeaturedSellerUpdate
     * @param {AdminFeaturedSellerApiAdminFeaturedSellerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFeaturedSellerApi
     */
    public adminFeaturedSellerUpdate(requestParameters: AdminFeaturedSellerApiAdminFeaturedSellerUpdateRequest, options?: any) {
        return AdminFeaturedSellerApiFp(this.configuration).adminFeaturedSellerUpdate(requestParameters.featuredSellerId, requestParameters.partnerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminFileApi - axios parameter creator
 * @export
 */
export const AdminFileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 어드민파일업로드
         * @summary adminFileUpload
         * @param {'banner' | 'event' | 'lotinside'} folderPath folderPath
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFileUpload: async (folderPath: 'banner' | 'event' | 'lotinside', file?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'folderPath' is not null or undefined
            assertParamExists('adminFileUpload', 'folderPath', folderPath)
            const localVarPath = `/admin-api/file/upload/{folderPath}`
                .replace(`{${"folderPath"}}`, encodeURIComponent(String(folderPath)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminFileApi - functional programming interface
 * @export
 */
export const AdminFileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminFileApiAxiosParamCreator(configuration)
    return {
        /**
         * 어드민파일업로드
         * @summary adminFileUpload
         * @param {'banner' | 'event' | 'lotinside'} folderPath folderPath
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFileUpload(folderPath: 'banner' | 'event' | 'lotinside', file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFileUpload(folderPath, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminFileApi - factory interface
 * @export
 */
export const AdminFileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminFileApiFp(configuration)
    return {
        /**
         * 어드민파일업로드
         * @summary adminFileUpload
         * @param {'banner' | 'event' | 'lotinside'} folderPath folderPath
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFileUpload(folderPath: 'banner' | 'event' | 'lotinside', file?: any, options?: any): AxiosPromise<string> {
            return localVarFp.adminFileUpload(folderPath, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminFileUpload operation in AdminFileApi.
 * @export
 * @interface AdminFileApiAdminFileUploadRequest
 */
export interface AdminFileApiAdminFileUploadRequest {
    /**
     * folderPath
     * @type {'banner' | 'event' | 'lotinside'}
     * @memberof AdminFileApiAdminFileUpload
     */
    readonly folderPath: 'banner' | 'event' | 'lotinside'

    /**
     * 
     * @type {any}
     * @memberof AdminFileApiAdminFileUpload
     */
    readonly file?: any
}

/**
 * AdminFileApi - object-oriented interface
 * @export
 * @class AdminFileApi
 * @extends {BaseAPI}
 */
export class AdminFileApi extends BaseAPI {
    /**
     * 어드민파일업로드
     * @summary adminFileUpload
     * @param {AdminFileApiAdminFileUploadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminFileApi
     */
    public adminFileUpload(requestParameters: AdminFileApiAdminFileUploadRequest, options?: any) {
        return AdminFileApiFp(this.configuration).adminFileUpload(requestParameters.folderPath, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminLotInsideApi - axios parameter creator
 * @export
 */
export const AdminLotInsideApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 랏인사이드조회
         * @summary adminLotInside
         * @param {number} lotInsideId lotInsideId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLotInside: async (lotInsideId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'lotInsideId' is not null or undefined
            assertParamExists('adminLotInside', 'lotInsideId', lotInsideId)
            const localVarPath = `/admin-api/lot-inside/{lotInsideId}`
                .replace(`{${"lotInsideId"}}`, encodeURIComponent(String(lotInsideId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 랏인사이드등록
         * @summary adminLotInsideCreate
         * @param {AdminLotInsideCreateDTO} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLotInsideCreate: async (body: AdminLotInsideCreateDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminLotInsideCreate', 'body', body)
            const localVarPath = `/admin-api/lot-inside`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 랏인사이드삭제
         * @summary adminLotInsideDelete
         * @param {number} lotInsideId lotInsideId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLotInsideDelete: async (lotInsideId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'lotInsideId' is not null or undefined
            assertParamExists('adminLotInsideDelete', 'lotInsideId', lotInsideId)
            const localVarPath = `/admin-api/lot-inside/{lotInsideId}`
                .replace(`{${"lotInsideId"}}`, encodeURIComponent(String(lotInsideId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 랏인사이드리스트
         * @summary adminLotInsideList
         * @param {string} [keyword] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLotInsideList: async (keyword?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin-api/lot-inside/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 랏인사이드수정
         * @summary adminLotInsideUpdate
         * @param {number} lotInsideId lotInsideId
         * @param {AdminLotInsideCreateDTO} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLotInsideUpdate: async (lotInsideId: number, body: AdminLotInsideCreateDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'lotInsideId' is not null or undefined
            assertParamExists('adminLotInsideUpdate', 'lotInsideId', lotInsideId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminLotInsideUpdate', 'body', body)
            const localVarPath = `/admin-api/lot-inside/{lotInsideId}`
                .replace(`{${"lotInsideId"}}`, encodeURIComponent(String(lotInsideId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminLotInsideApi - functional programming interface
 * @export
 */
export const AdminLotInsideApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminLotInsideApiAxiosParamCreator(configuration)
    return {
        /**
         * 랏인사이드조회
         * @summary adminLotInside
         * @param {number} lotInsideId lotInsideId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLotInside(lotInsideId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLotInside(lotInsideId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 랏인사이드등록
         * @summary adminLotInsideCreate
         * @param {AdminLotInsideCreateDTO} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLotInsideCreate(body: AdminLotInsideCreateDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLotInsideCreate(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 랏인사이드삭제
         * @summary adminLotInsideDelete
         * @param {number} lotInsideId lotInsideId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLotInsideDelete(lotInsideId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLotInsideDelete(lotInsideId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 랏인사이드리스트
         * @summary adminLotInsideList
         * @param {string} [keyword] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLotInsideList(keyword?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLotInsideList(keyword, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 랏인사이드수정
         * @summary adminLotInsideUpdate
         * @param {number} lotInsideId lotInsideId
         * @param {AdminLotInsideCreateDTO} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminLotInsideUpdate(lotInsideId: number, body: AdminLotInsideCreateDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminLotInsideUpdate(lotInsideId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminLotInsideApi - factory interface
 * @export
 */
export const AdminLotInsideApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminLotInsideApiFp(configuration)
    return {
        /**
         * 랏인사이드조회
         * @summary adminLotInside
         * @param {number} lotInsideId lotInsideId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLotInside(lotInsideId: number, options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminLotInside(lotInsideId, options).then((request) => request(axios, basePath));
        },
        /**
         * 랏인사이드등록
         * @summary adminLotInsideCreate
         * @param {AdminLotInsideCreateDTO} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLotInsideCreate(body: AdminLotInsideCreateDTO, options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminLotInsideCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 랏인사이드삭제
         * @summary adminLotInsideDelete
         * @param {number} lotInsideId lotInsideId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLotInsideDelete(lotInsideId: number, options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminLotInsideDelete(lotInsideId, options).then((request) => request(axios, basePath));
        },
        /**
         * 랏인사이드리스트
         * @summary adminLotInsideList
         * @param {string} [keyword] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLotInsideList(keyword?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminLotInsideList(keyword, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 랏인사이드수정
         * @summary adminLotInsideUpdate
         * @param {number} lotInsideId lotInsideId
         * @param {AdminLotInsideCreateDTO} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLotInsideUpdate(lotInsideId: number, body: AdminLotInsideCreateDTO, options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminLotInsideUpdate(lotInsideId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminLotInside operation in AdminLotInsideApi.
 * @export
 * @interface AdminLotInsideApiAdminLotInsideRequest
 */
export interface AdminLotInsideApiAdminLotInsideRequest {
    /**
     * lotInsideId
     * @type {number}
     * @memberof AdminLotInsideApiAdminLotInside
     */
    readonly lotInsideId: number
}

/**
 * Request parameters for adminLotInsideCreate operation in AdminLotInsideApi.
 * @export
 * @interface AdminLotInsideApiAdminLotInsideCreateRequest
 */
export interface AdminLotInsideApiAdminLotInsideCreateRequest {
    /**
     * body
     * @type {AdminLotInsideCreateDTO}
     * @memberof AdminLotInsideApiAdminLotInsideCreate
     */
    readonly body: AdminLotInsideCreateDTO
}

/**
 * Request parameters for adminLotInsideDelete operation in AdminLotInsideApi.
 * @export
 * @interface AdminLotInsideApiAdminLotInsideDeleteRequest
 */
export interface AdminLotInsideApiAdminLotInsideDeleteRequest {
    /**
     * lotInsideId
     * @type {number}
     * @memberof AdminLotInsideApiAdminLotInsideDelete
     */
    readonly lotInsideId: number
}

/**
 * Request parameters for adminLotInsideList operation in AdminLotInsideApi.
 * @export
 * @interface AdminLotInsideApiAdminLotInsideListRequest
 */
export interface AdminLotInsideApiAdminLotInsideListRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminLotInsideApiAdminLotInsideList
     */
    readonly keyword?: string

    /**
     * 
     * @type {number}
     * @memberof AdminLotInsideApiAdminLotInsideList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminLotInsideApiAdminLotInsideList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for adminLotInsideUpdate operation in AdminLotInsideApi.
 * @export
 * @interface AdminLotInsideApiAdminLotInsideUpdateRequest
 */
export interface AdminLotInsideApiAdminLotInsideUpdateRequest {
    /**
     * lotInsideId
     * @type {number}
     * @memberof AdminLotInsideApiAdminLotInsideUpdate
     */
    readonly lotInsideId: number

    /**
     * body
     * @type {AdminLotInsideCreateDTO}
     * @memberof AdminLotInsideApiAdminLotInsideUpdate
     */
    readonly body: AdminLotInsideCreateDTO
}

/**
 * AdminLotInsideApi - object-oriented interface
 * @export
 * @class AdminLotInsideApi
 * @extends {BaseAPI}
 */
export class AdminLotInsideApi extends BaseAPI {
    /**
     * 랏인사이드조회
     * @summary adminLotInside
     * @param {AdminLotInsideApiAdminLotInsideRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLotInsideApi
     */
    public adminLotInside(requestParameters: AdminLotInsideApiAdminLotInsideRequest, options?: any) {
        return AdminLotInsideApiFp(this.configuration).adminLotInside(requestParameters.lotInsideId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 랏인사이드등록
     * @summary adminLotInsideCreate
     * @param {AdminLotInsideApiAdminLotInsideCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLotInsideApi
     */
    public adminLotInsideCreate(requestParameters: AdminLotInsideApiAdminLotInsideCreateRequest, options?: any) {
        return AdminLotInsideApiFp(this.configuration).adminLotInsideCreate(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 랏인사이드삭제
     * @summary adminLotInsideDelete
     * @param {AdminLotInsideApiAdminLotInsideDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLotInsideApi
     */
    public adminLotInsideDelete(requestParameters: AdminLotInsideApiAdminLotInsideDeleteRequest, options?: any) {
        return AdminLotInsideApiFp(this.configuration).adminLotInsideDelete(requestParameters.lotInsideId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 랏인사이드리스트
     * @summary adminLotInsideList
     * @param {AdminLotInsideApiAdminLotInsideListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLotInsideApi
     */
    public adminLotInsideList(requestParameters: AdminLotInsideApiAdminLotInsideListRequest = {}, options?: any) {
        return AdminLotInsideApiFp(this.configuration).adminLotInsideList(requestParameters.keyword, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 랏인사이드수정
     * @summary adminLotInsideUpdate
     * @param {AdminLotInsideApiAdminLotInsideUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLotInsideApi
     */
    public adminLotInsideUpdate(requestParameters: AdminLotInsideApiAdminLotInsideUpdateRequest, options?: any) {
        return AdminLotInsideApiFp(this.configuration).adminLotInsideUpdate(requestParameters.lotInsideId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminMemberApi - axios parameter creator
 * @export
 */
export const AdminMemberApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 회원조회
         * @summary adminMember
         * @param {number} memberId memberId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMember: async (memberId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('adminMember', 'memberId', memberId)
            const localVarPath = `/admin-api/member/{memberId}`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 회원리스트-엑셀용
         * @summary adminMemberExcelList
         * @param {boolean} [approved] 
         * @param {string} [end] 
         * @param {string} [keyword] 
         * @param {'MEMBER' | 'PARTNER'} [memberType] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [start] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMemberExcelList: async (approved?: boolean, end?: string, keyword?: string, memberType?: 'MEMBER' | 'PARTNER', page?: number, pageSize?: number, start?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin-api/member/excel-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (approved !== undefined) {
                localVarQueryParameter['approved'] = approved;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (memberType !== undefined) {
                localVarQueryParameter['memberType'] = memberType;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 회원리스트
         * @summary adminMemberList
         * @param {boolean} [approved] 
         * @param {string} [end] 
         * @param {string} [keyword] 
         * @param {'MEMBER' | 'PARTNER'} [memberType] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [start] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMemberList: async (approved?: boolean, end?: string, keyword?: string, memberType?: 'MEMBER' | 'PARTNER', page?: number, pageSize?: number, start?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin-api/member/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (approved !== undefined) {
                localVarQueryParameter['approved'] = approved;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (memberType !== undefined) {
                localVarQueryParameter['memberType'] = memberType;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 파트너회원조회
         * @summary adminMemberPartner
         * @param {number} partnerId partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMemberPartner: async (partnerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('adminMemberPartner', 'partnerId', partnerId)
            const localVarPath = `/admin-api/member/partner/{partnerId}`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 회원파트너 가입승인
         * @summary adminMemberPartnerApprove
         * @param {number} memberId memberId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMemberPartnerApprove: async (memberId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('adminMemberPartnerApprove', 'memberId', memberId)
            const localVarPath = `/admin-api/member/{memberId}/approve`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 회원비밀번호재발급 (어드민에 의해서 직접 변경)
         * @summary adminMemberPatchPassword
         * @param {number} memberId memberId
         * @param {AdminMemberPatchPasswordDTO} adminMemberPatchPasswordDTO adminMemberPatchPasswordDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMemberPatchPassword: async (memberId: number, adminMemberPatchPasswordDTO: AdminMemberPatchPasswordDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('adminMemberPatchPassword', 'memberId', memberId)
            // verify required parameter 'adminMemberPatchPasswordDTO' is not null or undefined
            assertParamExists('adminMemberPatchPassword', 'adminMemberPatchPasswordDTO', adminMemberPatchPasswordDTO)
            const localVarPath = `/admin-api/member/{memberId}/patch-password`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminMemberPatchPasswordDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 회원비밀번호재발급 (이메일 링크 발송)
         * @summary adminMemberResetPassword
         * @param {number} memberId memberId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMemberResetPassword: async (memberId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('adminMemberResetPassword', 'memberId', memberId)
            const localVarPath = `/admin-api/member/{memberId}/reset-password`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 회원정보 수정
         * @summary adminMemberUpdate
         * @param {number} memberId memberId
         * @param {AdminMemberDTO} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMemberUpdate: async (memberId: number, body: AdminMemberDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('adminMemberUpdate', 'memberId', memberId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminMemberUpdate', 'body', body)
            const localVarPath = `/admin-api/member/{memberId}`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminMemberApi - functional programming interface
 * @export
 */
export const AdminMemberApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminMemberApiAxiosParamCreator(configuration)
    return {
        /**
         * 회원조회
         * @summary adminMember
         * @param {number} memberId memberId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMember(memberId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMember(memberId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 회원리스트-엑셀용
         * @summary adminMemberExcelList
         * @param {boolean} [approved] 
         * @param {string} [end] 
         * @param {string} [keyword] 
         * @param {'MEMBER' | 'PARTNER'} [memberType] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [start] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMemberExcelList(approved?: boolean, end?: string, keyword?: string, memberType?: 'MEMBER' | 'PARTNER', page?: number, pageSize?: number, start?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMemberExcelList(approved, end, keyword, memberType, page, pageSize, start, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 회원리스트
         * @summary adminMemberList
         * @param {boolean} [approved] 
         * @param {string} [end] 
         * @param {string} [keyword] 
         * @param {'MEMBER' | 'PARTNER'} [memberType] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [start] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMemberList(approved?: boolean, end?: string, keyword?: string, memberType?: 'MEMBER' | 'PARTNER', page?: number, pageSize?: number, start?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMemberList(approved, end, keyword, memberType, page, pageSize, start, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 파트너회원조회
         * @summary adminMemberPartner
         * @param {number} partnerId partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMemberPartner(partnerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMemberPartner(partnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 회원파트너 가입승인
         * @summary adminMemberPartnerApprove
         * @param {number} memberId memberId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMemberPartnerApprove(memberId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMemberPartnerApprove(memberId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 회원비밀번호재발급 (어드민에 의해서 직접 변경)
         * @summary adminMemberPatchPassword
         * @param {number} memberId memberId
         * @param {AdminMemberPatchPasswordDTO} adminMemberPatchPasswordDTO adminMemberPatchPasswordDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMemberPatchPassword(memberId: number, adminMemberPatchPasswordDTO: AdminMemberPatchPasswordDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMemberPatchPassword(memberId, adminMemberPatchPasswordDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 회원비밀번호재발급 (이메일 링크 발송)
         * @summary adminMemberResetPassword
         * @param {number} memberId memberId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMemberResetPassword(memberId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMemberResetPassword(memberId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 회원정보 수정
         * @summary adminMemberUpdate
         * @param {number} memberId memberId
         * @param {AdminMemberDTO} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminMemberUpdate(memberId: number, body: AdminMemberDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminMemberUpdate(memberId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminMemberApi - factory interface
 * @export
 */
export const AdminMemberApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminMemberApiFp(configuration)
    return {
        /**
         * 회원조회
         * @summary adminMember
         * @param {number} memberId memberId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMember(memberId: number, options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminMember(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 회원리스트-엑셀용
         * @summary adminMemberExcelList
         * @param {boolean} [approved] 
         * @param {string} [end] 
         * @param {string} [keyword] 
         * @param {'MEMBER' | 'PARTNER'} [memberType] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [start] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMemberExcelList(approved?: boolean, end?: string, keyword?: string, memberType?: 'MEMBER' | 'PARTNER', page?: number, pageSize?: number, start?: string, options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminMemberExcelList(approved, end, keyword, memberType, page, pageSize, start, options).then((request) => request(axios, basePath));
        },
        /**
         * 회원리스트
         * @summary adminMemberList
         * @param {boolean} [approved] 
         * @param {string} [end] 
         * @param {string} [keyword] 
         * @param {'MEMBER' | 'PARTNER'} [memberType] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [start] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMemberList(approved?: boolean, end?: string, keyword?: string, memberType?: 'MEMBER' | 'PARTNER', page?: number, pageSize?: number, start?: string, options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminMemberList(approved, end, keyword, memberType, page, pageSize, start, options).then((request) => request(axios, basePath));
        },
        /**
         * 파트너회원조회
         * @summary adminMemberPartner
         * @param {number} partnerId partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMemberPartner(partnerId: number, options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminMemberPartner(partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 회원파트너 가입승인
         * @summary adminMemberPartnerApprove
         * @param {number} memberId memberId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMemberPartnerApprove(memberId: number, options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminMemberPartnerApprove(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 회원비밀번호재발급 (어드민에 의해서 직접 변경)
         * @summary adminMemberPatchPassword
         * @param {number} memberId memberId
         * @param {AdminMemberPatchPasswordDTO} adminMemberPatchPasswordDTO adminMemberPatchPasswordDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMemberPatchPassword(memberId: number, adminMemberPatchPasswordDTO: AdminMemberPatchPasswordDTO, options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminMemberPatchPassword(memberId, adminMemberPatchPasswordDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 회원비밀번호재발급 (이메일 링크 발송)
         * @summary adminMemberResetPassword
         * @param {number} memberId memberId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMemberResetPassword(memberId: number, options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminMemberResetPassword(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 회원정보 수정
         * @summary adminMemberUpdate
         * @param {number} memberId memberId
         * @param {AdminMemberDTO} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminMemberUpdate(memberId: number, body: AdminMemberDTO, options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminMemberUpdate(memberId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminMember operation in AdminMemberApi.
 * @export
 * @interface AdminMemberApiAdminMemberRequest
 */
export interface AdminMemberApiAdminMemberRequest {
    /**
     * memberId
     * @type {number}
     * @memberof AdminMemberApiAdminMember
     */
    readonly memberId: number
}

/**
 * Request parameters for adminMemberExcelList operation in AdminMemberApi.
 * @export
 * @interface AdminMemberApiAdminMemberExcelListRequest
 */
export interface AdminMemberApiAdminMemberExcelListRequest {
    /**
     * 
     * @type {boolean}
     * @memberof AdminMemberApiAdminMemberExcelList
     */
    readonly approved?: boolean

    /**
     * 
     * @type {string}
     * @memberof AdminMemberApiAdminMemberExcelList
     */
    readonly end?: string

    /**
     * 
     * @type {string}
     * @memberof AdminMemberApiAdminMemberExcelList
     */
    readonly keyword?: string

    /**
     * 
     * @type {'MEMBER' | 'PARTNER'}
     * @memberof AdminMemberApiAdminMemberExcelList
     */
    readonly memberType?: 'MEMBER' | 'PARTNER'

    /**
     * 
     * @type {number}
     * @memberof AdminMemberApiAdminMemberExcelList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminMemberApiAdminMemberExcelList
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof AdminMemberApiAdminMemberExcelList
     */
    readonly start?: string
}

/**
 * Request parameters for adminMemberList operation in AdminMemberApi.
 * @export
 * @interface AdminMemberApiAdminMemberListRequest
 */
export interface AdminMemberApiAdminMemberListRequest {
    /**
     * 
     * @type {boolean}
     * @memberof AdminMemberApiAdminMemberList
     */
    readonly approved?: boolean

    /**
     * 
     * @type {string}
     * @memberof AdminMemberApiAdminMemberList
     */
    readonly end?: string

    /**
     * 
     * @type {string}
     * @memberof AdminMemberApiAdminMemberList
     */
    readonly keyword?: string

    /**
     * 
     * @type {'MEMBER' | 'PARTNER'}
     * @memberof AdminMemberApiAdminMemberList
     */
    readonly memberType?: 'MEMBER' | 'PARTNER'

    /**
     * 
     * @type {number}
     * @memberof AdminMemberApiAdminMemberList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminMemberApiAdminMemberList
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof AdminMemberApiAdminMemberList
     */
    readonly start?: string
}

/**
 * Request parameters for adminMemberPartner operation in AdminMemberApi.
 * @export
 * @interface AdminMemberApiAdminMemberPartnerRequest
 */
export interface AdminMemberApiAdminMemberPartnerRequest {
    /**
     * partnerId
     * @type {number}
     * @memberof AdminMemberApiAdminMemberPartner
     */
    readonly partnerId: number
}

/**
 * Request parameters for adminMemberPartnerApprove operation in AdminMemberApi.
 * @export
 * @interface AdminMemberApiAdminMemberPartnerApproveRequest
 */
export interface AdminMemberApiAdminMemberPartnerApproveRequest {
    /**
     * memberId
     * @type {number}
     * @memberof AdminMemberApiAdminMemberPartnerApprove
     */
    readonly memberId: number
}

/**
 * Request parameters for adminMemberPatchPassword operation in AdminMemberApi.
 * @export
 * @interface AdminMemberApiAdminMemberPatchPasswordRequest
 */
export interface AdminMemberApiAdminMemberPatchPasswordRequest {
    /**
     * memberId
     * @type {number}
     * @memberof AdminMemberApiAdminMemberPatchPassword
     */
    readonly memberId: number

    /**
     * adminMemberPatchPasswordDTO
     * @type {AdminMemberPatchPasswordDTO}
     * @memberof AdminMemberApiAdminMemberPatchPassword
     */
    readonly adminMemberPatchPasswordDTO: AdminMemberPatchPasswordDTO
}

/**
 * Request parameters for adminMemberResetPassword operation in AdminMemberApi.
 * @export
 * @interface AdminMemberApiAdminMemberResetPasswordRequest
 */
export interface AdminMemberApiAdminMemberResetPasswordRequest {
    /**
     * memberId
     * @type {number}
     * @memberof AdminMemberApiAdminMemberResetPassword
     */
    readonly memberId: number
}

/**
 * Request parameters for adminMemberUpdate operation in AdminMemberApi.
 * @export
 * @interface AdminMemberApiAdminMemberUpdateRequest
 */
export interface AdminMemberApiAdminMemberUpdateRequest {
    /**
     * memberId
     * @type {number}
     * @memberof AdminMemberApiAdminMemberUpdate
     */
    readonly memberId: number

    /**
     * body
     * @type {AdminMemberDTO}
     * @memberof AdminMemberApiAdminMemberUpdate
     */
    readonly body: AdminMemberDTO
}

/**
 * AdminMemberApi - object-oriented interface
 * @export
 * @class AdminMemberApi
 * @extends {BaseAPI}
 */
export class AdminMemberApi extends BaseAPI {
    /**
     * 회원조회
     * @summary adminMember
     * @param {AdminMemberApiAdminMemberRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMemberApi
     */
    public adminMember(requestParameters: AdminMemberApiAdminMemberRequest, options?: any) {
        return AdminMemberApiFp(this.configuration).adminMember(requestParameters.memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 회원리스트-엑셀용
     * @summary adminMemberExcelList
     * @param {AdminMemberApiAdminMemberExcelListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMemberApi
     */
    public adminMemberExcelList(requestParameters: AdminMemberApiAdminMemberExcelListRequest = {}, options?: any) {
        return AdminMemberApiFp(this.configuration).adminMemberExcelList(requestParameters.approved, requestParameters.end, requestParameters.keyword, requestParameters.memberType, requestParameters.page, requestParameters.pageSize, requestParameters.start, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 회원리스트
     * @summary adminMemberList
     * @param {AdminMemberApiAdminMemberListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMemberApi
     */
    public adminMemberList(requestParameters: AdminMemberApiAdminMemberListRequest = {}, options?: any) {
        return AdminMemberApiFp(this.configuration).adminMemberList(requestParameters.approved, requestParameters.end, requestParameters.keyword, requestParameters.memberType, requestParameters.page, requestParameters.pageSize, requestParameters.start, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 파트너회원조회
     * @summary adminMemberPartner
     * @param {AdminMemberApiAdminMemberPartnerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMemberApi
     */
    public adminMemberPartner(requestParameters: AdminMemberApiAdminMemberPartnerRequest, options?: any) {
        return AdminMemberApiFp(this.configuration).adminMemberPartner(requestParameters.partnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 회원파트너 가입승인
     * @summary adminMemberPartnerApprove
     * @param {AdminMemberApiAdminMemberPartnerApproveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMemberApi
     */
    public adminMemberPartnerApprove(requestParameters: AdminMemberApiAdminMemberPartnerApproveRequest, options?: any) {
        return AdminMemberApiFp(this.configuration).adminMemberPartnerApprove(requestParameters.memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 회원비밀번호재발급 (어드민에 의해서 직접 변경)
     * @summary adminMemberPatchPassword
     * @param {AdminMemberApiAdminMemberPatchPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMemberApi
     */
    public adminMemberPatchPassword(requestParameters: AdminMemberApiAdminMemberPatchPasswordRequest, options?: any) {
        return AdminMemberApiFp(this.configuration).adminMemberPatchPassword(requestParameters.memberId, requestParameters.adminMemberPatchPasswordDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 회원비밀번호재발급 (이메일 링크 발송)
     * @summary adminMemberResetPassword
     * @param {AdminMemberApiAdminMemberResetPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMemberApi
     */
    public adminMemberResetPassword(requestParameters: AdminMemberApiAdminMemberResetPasswordRequest, options?: any) {
        return AdminMemberApiFp(this.configuration).adminMemberResetPassword(requestParameters.memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 회원정보 수정
     * @summary adminMemberUpdate
     * @param {AdminMemberApiAdminMemberUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMemberApi
     */
    public adminMemberUpdate(requestParameters: AdminMemberApiAdminMemberUpdateRequest, options?: any) {
        return AdminMemberApiFp(this.configuration).adminMemberUpdate(requestParameters.memberId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminOrderApi - axios parameter creator
 * @export
 */
export const AdminOrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 인보이스
         * @summary adminInvoice
         * @param {number} orderId orderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInvoice: async (orderId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('adminInvoice', 'orderId', orderId)
            const localVarPath = `/admin-api/invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 정산 보류
         * @summary adminOrderHold
         * @param {AdminSettlementHoldParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderHold: async (param: AdminSettlementHoldParamDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('adminOrderHold', 'param', param)
            const localVarPath = `/admin-api/settlement-hold`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 정산 상세
         * @summary adminOrderSalesInfo
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderSettlementInfo: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('adminOrderSettlementInfo', 'productId', productId)
            const localVarPath = `/admin-api/settlements/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 정산 리스트
         * @summary adminOrderSettlementList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {string} [end] end
         * @param {string} [keyword] keyword
         * @param {'bid' | 'buynow'} [orderType] orderType
         * @param {number} [partnerId] partnerId
         * @param {'complete' | 'hold' | 'purchase_complete' | 'waiting'} [settlementStatus] settlementStatus
         * @param {boolean} [showSeoulAuction] showSeoulAuction
         * @param {string} [sortBy] sortBy
         * @param {string} [start] start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderSettlementList: async (page: number, pageSize: number, categoryDepth1Id?: number, enableBid?: boolean, enableBuyNow?: boolean, end?: string, keyword?: string, orderType?: 'bid' | 'buynow', partnerId?: number, settlementStatus?: 'complete' | 'hold' | 'purchase_complete' | 'waiting', showSeoulAuction?: boolean, sortBy?: string, start?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('adminOrderSettlementList', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminOrderSettlementList', 'pageSize', pageSize)
            const localVarPath = `/admin-api/settlements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (categoryDepth1Id !== undefined) {
                localVarQueryParameter['categoryDepth1Id'] = categoryDepth1Id;
            }

            if (enableBid !== undefined) {
                localVarQueryParameter['enableBid'] = enableBid;
            }

            if (enableBuyNow !== undefined) {
                localVarQueryParameter['enableBuyNow'] = enableBuyNow;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (orderType !== undefined) {
                localVarQueryParameter['orderType'] = orderType;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partnerId'] = partnerId;
            }

            if (settlementStatus !== undefined) {
                localVarQueryParameter['settlementStatus'] = settlementStatus;
            }

            if (showSeoulAuction !== undefined) {
                localVarQueryParameter['showSeoulAuction'] = showSeoulAuction;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 정산 요약
         * @summary adminOrderSettlementSummary
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {string} [end] end
         * @param {string} [keyword] keyword
         * @param {'bid' | 'buynow'} [orderType] orderType
         * @param {number} [partnerId] partnerId
         * @param {'complete' | 'hold' | 'purchase_complete' | 'waiting'} [settlementStatus] settlementStatus
         * @param {boolean} [showSeoulAuction] showSeoulAuction
         * @param {string} [start] start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderSettlementSummary: async (categoryDepth1Id?: number, enableBid?: boolean, enableBuyNow?: boolean, end?: string, keyword?: string, orderType?: 'bid' | 'buynow', partnerId?: number, settlementStatus?: 'complete' | 'hold' | 'purchase_complete' | 'waiting', showSeoulAuction?: boolean, start?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin-api/settlement-summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (categoryDepth1Id !== undefined) {
                localVarQueryParameter['categoryDepth1Id'] = categoryDepth1Id;
            }

            if (enableBid !== undefined) {
                localVarQueryParameter['enableBid'] = enableBid;
            }

            if (enableBuyNow !== undefined) {
                localVarQueryParameter['enableBuyNow'] = enableBuyNow;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (orderType !== undefined) {
                localVarQueryParameter['orderType'] = orderType;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partnerId'] = partnerId;
            }

            if (settlementStatus !== undefined) {
                localVarQueryParameter['settlementStatus'] = settlementStatus;
            }

            if (showSeoulAuction !== undefined) {
                localVarQueryParameter['showSeoulAuction'] = showSeoulAuction;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 세금계산서 조회
         * @summary adminTaxBillInfo
         * @param {number} orderId orderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTaxBillInfo: async (orderId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('adminTaxBillInfo', 'orderId', orderId)
            const localVarPath = `/admin-api/taxbill/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminOrderApi - functional programming interface
 * @export
 */
export const AdminOrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminOrderApiAxiosParamCreator(configuration)
    return {
        /**
         * 인보이스
         * @summary adminInvoice
         * @param {number} orderId orderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminInvoice(orderId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminInvoice(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 정산 보류
         * @summary adminOrderHold
         * @param {AdminSettlementHoldParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderHold(param: AdminSettlementHoldParamDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderHold(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 정산 상세
         * @summary adminOrderSalesInfo
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderSettlementInfo(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderSettlementInfo(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 정산 리스트
         * @summary adminOrderSettlementList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {string} [end] end
         * @param {string} [keyword] keyword
         * @param {'bid' | 'buynow'} [orderType] orderType
         * @param {number} [partnerId] partnerId
         * @param {'complete' | 'hold' | 'purchase_complete' | 'waiting'} [settlementStatus] settlementStatus
         * @param {boolean} [showSeoulAuction] showSeoulAuction
         * @param {string} [sortBy] sortBy
         * @param {string} [start] start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderSettlementList(page: number, pageSize: number, categoryDepth1Id?: number, enableBid?: boolean, enableBuyNow?: boolean, end?: string, keyword?: string, orderType?: 'bid' | 'buynow', partnerId?: number, settlementStatus?: 'complete' | 'hold' | 'purchase_complete' | 'waiting', showSeoulAuction?: boolean, sortBy?: string, start?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderSettlementList(page, pageSize, categoryDepth1Id, enableBid, enableBuyNow, end, keyword, orderType, partnerId, settlementStatus, showSeoulAuction, sortBy, start, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 정산 요약
         * @summary adminOrderSettlementSummary
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {string} [end] end
         * @param {string} [keyword] keyword
         * @param {'bid' | 'buynow'} [orderType] orderType
         * @param {number} [partnerId] partnerId
         * @param {'complete' | 'hold' | 'purchase_complete' | 'waiting'} [settlementStatus] settlementStatus
         * @param {boolean} [showSeoulAuction] showSeoulAuction
         * @param {string} [start] start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderSettlementSummary(categoryDepth1Id?: number, enableBid?: boolean, enableBuyNow?: boolean, end?: string, keyword?: string, orderType?: 'bid' | 'buynow', partnerId?: number, settlementStatus?: 'complete' | 'hold' | 'purchase_complete' | 'waiting', showSeoulAuction?: boolean, start?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderSettlementSummary(categoryDepth1Id, enableBid, enableBuyNow, end, keyword, orderType, partnerId, settlementStatus, showSeoulAuction, start, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 세금계산서 조회
         * @summary adminTaxBillInfo
         * @param {number} orderId orderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTaxBillInfo(orderId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTaxBillInfo(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminOrderApi - factory interface
 * @export
 */
export const AdminOrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminOrderApiFp(configuration)
    return {
        /**
         * 인보이스
         * @summary adminInvoice
         * @param {number} orderId orderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInvoice(orderId: number, options?: any): AxiosPromise<object> {
            return localVarFp.adminInvoice(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 정산 보류
         * @summary adminOrderHold
         * @param {AdminSettlementHoldParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderHold(param: AdminSettlementHoldParamDTO, options?: any): AxiosPromise<object> {
            return localVarFp.adminOrderHold(param, options).then((request) => request(axios, basePath));
        },
        /**
         * 정산 상세
         * @summary adminOrderSalesInfo
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderSettlementInfo(productId: number, options?: any): AxiosPromise<object> {
            return localVarFp.adminOrderSettlementInfo(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 정산 리스트
         * @summary adminOrderSettlementList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {string} [end] end
         * @param {string} [keyword] keyword
         * @param {'bid' | 'buynow'} [orderType] orderType
         * @param {number} [partnerId] partnerId
         * @param {'complete' | 'hold' | 'purchase_complete' | 'waiting'} [settlementStatus] settlementStatus
         * @param {boolean} [showSeoulAuction] showSeoulAuction
         * @param {string} [sortBy] sortBy
         * @param {string} [start] start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderSettlementList(page: number, pageSize: number, categoryDepth1Id?: number, enableBid?: boolean, enableBuyNow?: boolean, end?: string, keyword?: string, orderType?: 'bid' | 'buynow', partnerId?: number, settlementStatus?: 'complete' | 'hold' | 'purchase_complete' | 'waiting', showSeoulAuction?: boolean, sortBy?: string, start?: string, options?: any): AxiosPromise<object> {
            return localVarFp.adminOrderSettlementList(page, pageSize, categoryDepth1Id, enableBid, enableBuyNow, end, keyword, orderType, partnerId, settlementStatus, showSeoulAuction, sortBy, start, options).then((request) => request(axios, basePath));
        },
        /**
         * 정산 요약
         * @summary adminOrderSettlementSummary
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {string} [end] end
         * @param {string} [keyword] keyword
         * @param {'bid' | 'buynow'} [orderType] orderType
         * @param {number} [partnerId] partnerId
         * @param {'complete' | 'hold' | 'purchase_complete' | 'waiting'} [settlementStatus] settlementStatus
         * @param {boolean} [showSeoulAuction] showSeoulAuction
         * @param {string} [start] start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderSettlementSummary(categoryDepth1Id?: number, enableBid?: boolean, enableBuyNow?: boolean, end?: string, keyword?: string, orderType?: 'bid' | 'buynow', partnerId?: number, settlementStatus?: 'complete' | 'hold' | 'purchase_complete' | 'waiting', showSeoulAuction?: boolean, start?: string, options?: any): AxiosPromise<object> {
            return localVarFp.adminOrderSettlementSummary(categoryDepth1Id, enableBid, enableBuyNow, end, keyword, orderType, partnerId, settlementStatus, showSeoulAuction, start, options).then((request) => request(axios, basePath));
        },
        /**
         * 세금계산서 조회
         * @summary adminTaxBillInfo
         * @param {number} orderId orderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTaxBillInfo(orderId: number, options?: any): AxiosPromise<object> {
            return localVarFp.adminTaxBillInfo(orderId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminInvoice operation in AdminOrderApi.
 * @export
 * @interface AdminOrderApiAdminInvoiceRequest
 */
export interface AdminOrderApiAdminInvoiceRequest {
    /**
     * orderId
     * @type {number}
     * @memberof AdminOrderApiAdminInvoice
     */
    readonly orderId: number
}

/**
 * Request parameters for adminOrderHold operation in AdminOrderApi.
 * @export
 * @interface AdminOrderApiAdminOrderHoldRequest
 */
export interface AdminOrderApiAdminOrderHoldRequest {
    /**
     * param
     * @type {AdminSettlementHoldParamDTO}
     * @memberof AdminOrderApiAdminOrderHold
     */
    readonly param: AdminSettlementHoldParamDTO
}

/**
 * Request parameters for adminOrderSettlementInfo operation in AdminOrderApi.
 * @export
 * @interface AdminOrderApiAdminOrderSettlementInfoRequest
 */
export interface AdminOrderApiAdminOrderSettlementInfoRequest {
    /**
     * productId
     * @type {number}
     * @memberof AdminOrderApiAdminOrderSettlementInfo
     */
    readonly productId: number
}

/**
 * Request parameters for adminOrderSettlementList operation in AdminOrderApi.
 * @export
 * @interface AdminOrderApiAdminOrderSettlementListRequest
 */
export interface AdminOrderApiAdminOrderSettlementListRequest {
    /**
     * page
     * @type {number}
     * @memberof AdminOrderApiAdminOrderSettlementList
     */
    readonly page: number

    /**
     * pageSize
     * @type {number}
     * @memberof AdminOrderApiAdminOrderSettlementList
     */
    readonly pageSize: number

    /**
     * categoryDepth1Id
     * @type {number}
     * @memberof AdminOrderApiAdminOrderSettlementList
     */
    readonly categoryDepth1Id?: number

    /**
     * enableBid
     * @type {boolean}
     * @memberof AdminOrderApiAdminOrderSettlementList
     */
    readonly enableBid?: boolean

    /**
     * enableBuyNow
     * @type {boolean}
     * @memberof AdminOrderApiAdminOrderSettlementList
     */
    readonly enableBuyNow?: boolean

    /**
     * end
     * @type {string}
     * @memberof AdminOrderApiAdminOrderSettlementList
     */
    readonly end?: string

    /**
     * keyword
     * @type {string}
     * @memberof AdminOrderApiAdminOrderSettlementList
     */
    readonly keyword?: string

    /**
     * orderType
     * @type {'bid' | 'buynow'}
     * @memberof AdminOrderApiAdminOrderSettlementList
     */
    readonly orderType?: 'bid' | 'buynow'

    /**
     * partnerId
     * @type {number}
     * @memberof AdminOrderApiAdminOrderSettlementList
     */
    readonly partnerId?: number

    /**
     * settlementStatus
     * @type {'complete' | 'hold' | 'purchase_complete' | 'waiting'}
     * @memberof AdminOrderApiAdminOrderSettlementList
     */
    readonly settlementStatus?: 'complete' | 'hold' | 'purchase_complete' | 'waiting'

    /**
     * showSeoulAuction
     * @type {boolean}
     * @memberof AdminOrderApiAdminOrderSettlementList
     */
    readonly showSeoulAuction?: boolean

    /**
     * sortBy
     * @type {string}
     * @memberof AdminOrderApiAdminOrderSettlementList
     */
    readonly sortBy?: string

    /**
     * start
     * @type {string}
     * @memberof AdminOrderApiAdminOrderSettlementList
     */
    readonly start?: string
}

/**
 * Request parameters for adminOrderSettlementSummary operation in AdminOrderApi.
 * @export
 * @interface AdminOrderApiAdminOrderSettlementSummaryRequest
 */
export interface AdminOrderApiAdminOrderSettlementSummaryRequest {
    /**
     * categoryDepth1Id
     * @type {number}
     * @memberof AdminOrderApiAdminOrderSettlementSummary
     */
    readonly categoryDepth1Id?: number

    /**
     * enableBid
     * @type {boolean}
     * @memberof AdminOrderApiAdminOrderSettlementSummary
     */
    readonly enableBid?: boolean

    /**
     * enableBuyNow
     * @type {boolean}
     * @memberof AdminOrderApiAdminOrderSettlementSummary
     */
    readonly enableBuyNow?: boolean

    /**
     * end
     * @type {string}
     * @memberof AdminOrderApiAdminOrderSettlementSummary
     */
    readonly end?: string

    /**
     * keyword
     * @type {string}
     * @memberof AdminOrderApiAdminOrderSettlementSummary
     */
    readonly keyword?: string

    /**
     * orderType
     * @type {'bid' | 'buynow'}
     * @memberof AdminOrderApiAdminOrderSettlementSummary
     */
    readonly orderType?: 'bid' | 'buynow'

    /**
     * partnerId
     * @type {number}
     * @memberof AdminOrderApiAdminOrderSettlementSummary
     */
    readonly partnerId?: number

    /**
     * settlementStatus
     * @type {'complete' | 'hold' | 'purchase_complete' | 'waiting'}
     * @memberof AdminOrderApiAdminOrderSettlementSummary
     */
    readonly settlementStatus?: 'complete' | 'hold' | 'purchase_complete' | 'waiting'

    /**
     * showSeoulAuction
     * @type {boolean}
     * @memberof AdminOrderApiAdminOrderSettlementSummary
     */
    readonly showSeoulAuction?: boolean

    /**
     * start
     * @type {string}
     * @memberof AdminOrderApiAdminOrderSettlementSummary
     */
    readonly start?: string
}

/**
 * Request parameters for adminTaxBillInfo operation in AdminOrderApi.
 * @export
 * @interface AdminOrderApiAdminTaxBillInfoRequest
 */
export interface AdminOrderApiAdminTaxBillInfoRequest {
    /**
     * orderId
     * @type {number}
     * @memberof AdminOrderApiAdminTaxBillInfo
     */
    readonly orderId: number
}

/**
 * AdminOrderApi - object-oriented interface
 * @export
 * @class AdminOrderApi
 * @extends {BaseAPI}
 */
export class AdminOrderApi extends BaseAPI {
    /**
     * 인보이스
     * @summary adminInvoice
     * @param {AdminOrderApiAdminInvoiceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderApi
     */
    public adminInvoice(requestParameters: AdminOrderApiAdminInvoiceRequest, options?: any) {
        return AdminOrderApiFp(this.configuration).adminInvoice(requestParameters.orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 정산 보류
     * @summary adminOrderHold
     * @param {AdminOrderApiAdminOrderHoldRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderApi
     */
    public adminOrderHold(requestParameters: AdminOrderApiAdminOrderHoldRequest, options?: any) {
        return AdminOrderApiFp(this.configuration).adminOrderHold(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 정산 상세
     * @summary adminOrderSalesInfo
     * @param {AdminOrderApiAdminOrderSettlementInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderApi
     */
    public adminOrderSettlementInfo(requestParameters: AdminOrderApiAdminOrderSettlementInfoRequest, options?: any) {
        return AdminOrderApiFp(this.configuration).adminOrderSettlementInfo(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 정산 리스트
     * @summary adminOrderSettlementList
     * @param {AdminOrderApiAdminOrderSettlementListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderApi
     */
    public adminOrderSettlementList(requestParameters: AdminOrderApiAdminOrderSettlementListRequest, options?: any) {
        return AdminOrderApiFp(this.configuration).adminOrderSettlementList(requestParameters.page, requestParameters.pageSize, requestParameters.categoryDepth1Id, requestParameters.enableBid, requestParameters.enableBuyNow, requestParameters.end, requestParameters.keyword, requestParameters.orderType, requestParameters.partnerId, requestParameters.settlementStatus, requestParameters.showSeoulAuction, requestParameters.sortBy, requestParameters.start, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 정산 요약
     * @summary adminOrderSettlementSummary
     * @param {AdminOrderApiAdminOrderSettlementSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderApi
     */
    public adminOrderSettlementSummary(requestParameters: AdminOrderApiAdminOrderSettlementSummaryRequest = {}, options?: any) {
        return AdminOrderApiFp(this.configuration).adminOrderSettlementSummary(requestParameters.categoryDepth1Id, requestParameters.enableBid, requestParameters.enableBuyNow, requestParameters.end, requestParameters.keyword, requestParameters.orderType, requestParameters.partnerId, requestParameters.settlementStatus, requestParameters.showSeoulAuction, requestParameters.start, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 세금계산서 조회
     * @summary adminTaxBillInfo
     * @param {AdminOrderApiAdminTaxBillInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminOrderApi
     */
    public adminTaxBillInfo(requestParameters: AdminOrderApiAdminTaxBillInfoRequest, options?: any) {
        return AdminOrderApiFp(this.configuration).adminTaxBillInfo(requestParameters.orderId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminProductApi - axios parameter creator
 * @export
 */
export const AdminProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 관리자 카테고리 리스트
         * @summary adminCategoryList
         * @param {number} depth depth
         * @param {number} [parentCategoryId] parentCategoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCategoryList: async (depth: number, parentCategoryId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'depth' is not null or undefined
            assertParamExists('adminCategoryList', 'depth', depth)
            const localVarPath = `/admin-api/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (depth !== undefined) {
                localVarQueryParameter['depth'] = depth;
            }

            if (parentCategoryId !== undefined) {
                localVarQueryParameter['parentCategoryId '] = parentCategoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 관리자 옵션 생성 ( type : closingUrl 등 )
         * @summary adminCreateOption
         * @param {AdminOptionDTO} adminOptionDTO adminOptionDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateOption: async (adminOptionDTO: AdminOptionDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminOptionDTO' is not null or undefined
            assertParamExists('adminCreateOption', 'adminOptionDTO', adminOptionDTO)
            const localVarPath = `/admin-api/option`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminOptionDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 관리자 옵션 삭제 ( type : closingUrl 등 )
         * @summary adminDeleteOption
         * @param {number} optionId optionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteOption: async (optionId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'optionId' is not null or undefined
            assertParamExists('adminDeleteOption', 'optionId', optionId)
            const localVarPath = `/admin-api/option/{optionId}`
                .replace(`{${"optionId"}}`, encodeURIComponent(String(optionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 파일업로드
         * @summary fileUpload
         * @param {number} [maxSize] maxSize
         * @param {boolean} [resize] resize
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFileupload: async (maxSize?: number, resize?: boolean, file?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin-api/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (maxSize !== undefined) {
                localVarQueryParameter['maxSize'] = maxSize;
            }

            if (resize !== undefined) {
                localVarQueryParameter['resize'] = resize;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 관리자 옵션 조회 ( id 기반 : optionId )
         * @summary adminGetOption
         * @param {number} optionId optionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetOption: async (optionId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'optionId' is not null or undefined
            assertParamExists('adminGetOption', 'optionId', optionId)
            const localVarPath = `/admin-api/option/{optionId}`
                .replace(`{${"optionId"}}`, encodeURIComponent(String(optionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 관리자 옵션 조회 ( type 기반 : closingUrl 등 )
         * @summary adminGetOptionByType
         * @param {string} type type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetOptionByType: async (type: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('adminGetOptionByType', 'type', type)
            const localVarPath = `/admin-api/option`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 관리자 옵션 리스트 ( type : period , status , country , artist , medium )
         * @summary adminOptionList
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOptionList: async (type?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin-api/options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 관리자 옵션 패치 ( id 기반 : optionId )
         * @summary adminPatchOption
         * @param {number} optionId optionId
         * @param {AdminOptionDTO} adminOptionDTO adminOptionDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPatchOption: async (optionId: number, adminOptionDTO: AdminOptionDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'optionId' is not null or undefined
            assertParamExists('adminPatchOption', 'optionId', optionId)
            // verify required parameter 'adminOptionDTO' is not null or undefined
            assertParamExists('adminPatchOption', 'adminOptionDTO', adminOptionDTO)
            const localVarPath = `/admin-api/option/{optionId}`
                .replace(`{${"optionId"}}`, encodeURIComponent(String(optionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminOptionDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 관리자 옵션 패치 ( type 기반 : closingUrl )
         * @summary adminPatchOptionByType
         * @param {string} type type
         * @param {AdminOptionTypeDTO} adminOptionTypeDTO adminOptionTypeDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPatchOptionByType: async (type: string, adminOptionTypeDTO: AdminOptionTypeDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('adminPatchOptionByType', 'type', type)
            // verify required parameter 'adminOptionTypeDTO' is not null or undefined
            assertParamExists('adminPatchOptionByType', 'adminOptionTypeDTO', adminOptionTypeDTO)
            const localVarPath = `/admin-api/option`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminOptionTypeDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 판매정보 등록
         * @summary productAddStep2
         * @param {SalesInfoParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductAddStep2: async (param: SalesInfoParamDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('adminProductAddStep2', 'param', param)
            const localVarPath = `/admin-api/product/step2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 상품삭제
         * @summary adminProductDelete
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductDelete: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('adminProductDelete', 'productId', productId)
            const localVarPath = `/admin-api/product/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 상품숨김
         * @summary adminProductHide
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductHide: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('adminProductHide', 'productId', productId)
            const localVarPath = `/admin-api/visibility/product/hide/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 관리자 상품리스트
         * @summary adminProductList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {number} [categoryDepth2Id] categoryDepth2Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {string} [end] end
         * @param {string} [eventCategory] eventCategory
         * @param {string} [eventCode] eventCode
         * @param {string} [keyword] keyword
         * @param {number} [partnerId] partnerId
         * @param {'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out'} [salesStatus] salesStatus
         * @param {boolean} [showDeleted] showDeleted
         * @param {boolean} [showInvisibled] showInvisibled
         * @param {string} [sortBy] sortBy
         * @param {string} [start] start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductList: async (page: number, pageSize: number, categoryDepth1Id?: number, categoryDepth2Id?: number, enableBid?: boolean, enableBuyNow?: boolean, end?: string, eventCategory?: string, eventCode?: string, keyword?: string, partnerId?: number, salesStatus?: 'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out', showDeleted?: boolean, showInvisibled?: boolean, sortBy?: string, start?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('adminProductList', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminProductList', 'pageSize', pageSize)
            const localVarPath = `/admin-api/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (categoryDepth1Id !== undefined) {
                localVarQueryParameter['categoryDepth1Id'] = categoryDepth1Id;
            }

            if (categoryDepth2Id !== undefined) {
                localVarQueryParameter['categoryDepth2Id'] = categoryDepth2Id;
            }

            if (enableBid !== undefined) {
                localVarQueryParameter['enableBid'] = enableBid;
            }

            if (enableBuyNow !== undefined) {
                localVarQueryParameter['enableBuyNow'] = enableBuyNow;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (eventCategory !== undefined) {
                localVarQueryParameter['eventCategory'] = eventCategory;
            }

            if (eventCode !== undefined) {
                localVarQueryParameter['eventCode'] = eventCode;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partnerId'] = partnerId;
            }

            if (salesStatus !== undefined) {
                localVarQueryParameter['salesStatus'] = salesStatus;
            }

            if (showDeleted !== undefined) {
                localVarQueryParameter['showDeleted'] = showDeleted;
            }

            if (showInvisibled !== undefined) {
                localVarQueryParameter['showInvisibled'] = showInvisibled;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 상품게시
         * @summary adminProductShow
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductShow: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('adminProductShow', 'productId', productId)
            const localVarPath = `/admin-api/visibility/product/show/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 관리자 상품조회
         * @summary adminProductSingle
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductSingle: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('adminProductSingle', 'productId', productId)
            const localVarPath = `/admin-api/product/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 상품등록
         * @summary productStatusUpdate
         * @param {number} productId productId
         * @param {'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out'} salesStatus salesStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductStatusUpdate: async (productId: number, salesStatus: 'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('adminProductStatusUpdate', 'productId', productId)
            // verify required parameter 'salesStatus' is not null or undefined
            assertParamExists('adminProductStatusUpdate', 'salesStatus', salesStatus)
            const localVarPath = `/admin-api/product/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (salesStatus !== undefined) {
                localVarQueryParameter['salesStatus'] = salesStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 상품수정
         * @summary productUpdate
         * @param {ProductUpdateParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductUpdate: async (param: ProductUpdateParamDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('adminProductUpdate', 'param', param)
            const localVarPath = `/admin-api/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 관리자 배송타입 리스트
         * @summary adminShippingTypeList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminShippingTypeList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin-api/shippingTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminProductApi - functional programming interface
 * @export
 */
export const AdminProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 관리자 카테고리 리스트
         * @summary adminCategoryList
         * @param {number} depth depth
         * @param {number} [parentCategoryId] parentCategoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCategoryList(depth: number, parentCategoryId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCategoryList(depth, parentCategoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 관리자 옵션 생성 ( type : closingUrl 등 )
         * @summary adminCreateOption
         * @param {AdminOptionDTO} adminOptionDTO adminOptionDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateOption(adminOptionDTO: AdminOptionDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateOption(adminOptionDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 관리자 옵션 삭제 ( type : closingUrl 등 )
         * @summary adminDeleteOption
         * @param {number} optionId optionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteOption(optionId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteOption(optionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 파일업로드
         * @summary fileUpload
         * @param {number} [maxSize] maxSize
         * @param {boolean} [resize] resize
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminFileupload(maxSize?: number, resize?: boolean, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminFileupload(maxSize, resize, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 관리자 옵션 조회 ( id 기반 : optionId )
         * @summary adminGetOption
         * @param {number} optionId optionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetOption(optionId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetOption(optionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 관리자 옵션 조회 ( type 기반 : closingUrl 등 )
         * @summary adminGetOptionByType
         * @param {string} type type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetOptionByType(type: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetOptionByType(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 관리자 옵션 리스트 ( type : period , status , country , artist , medium )
         * @summary adminOptionList
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOptionList(type?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOptionList(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 관리자 옵션 패치 ( id 기반 : optionId )
         * @summary adminPatchOption
         * @param {number} optionId optionId
         * @param {AdminOptionDTO} adminOptionDTO adminOptionDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPatchOption(optionId: number, adminOptionDTO: AdminOptionDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPatchOption(optionId, adminOptionDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 관리자 옵션 패치 ( type 기반 : closingUrl )
         * @summary adminPatchOptionByType
         * @param {string} type type
         * @param {AdminOptionTypeDTO} adminOptionTypeDTO adminOptionTypeDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPatchOptionByType(type: string, adminOptionTypeDTO: AdminOptionTypeDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPatchOptionByType(type, adminOptionTypeDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 판매정보 등록
         * @summary productAddStep2
         * @param {SalesInfoParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductAddStep2(param: SalesInfoParamDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductAddStep2(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 상품삭제
         * @summary adminProductDelete
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductDelete(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductDelete(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 상품숨김
         * @summary adminProductHide
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductHide(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductHide(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 관리자 상품리스트
         * @summary adminProductList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {number} [categoryDepth2Id] categoryDepth2Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {string} [end] end
         * @param {string} [eventCategory] eventCategory
         * @param {string} [eventCode] eventCode
         * @param {string} [keyword] keyword
         * @param {number} [partnerId] partnerId
         * @param {'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out'} [salesStatus] salesStatus
         * @param {boolean} [showDeleted] showDeleted
         * @param {boolean} [showInvisibled] showInvisibled
         * @param {string} [sortBy] sortBy
         * @param {string} [start] start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductList(page: number, pageSize: number, categoryDepth1Id?: number, categoryDepth2Id?: number, enableBid?: boolean, enableBuyNow?: boolean, end?: string, eventCategory?: string, eventCode?: string, keyword?: string, partnerId?: number, salesStatus?: 'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out', showDeleted?: boolean, showInvisibled?: boolean, sortBy?: string, start?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductList(page, pageSize, categoryDepth1Id, categoryDepth2Id, enableBid, enableBuyNow, end, eventCategory, eventCode, keyword, partnerId, salesStatus, showDeleted, showInvisibled, sortBy, start, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 상품게시
         * @summary adminProductShow
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductShow(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductShow(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 관리자 상품조회
         * @summary adminProductSingle
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductSingle(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductSingle(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 상품등록
         * @summary productStatusUpdate
         * @param {number} productId productId
         * @param {'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out'} salesStatus salesStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductStatusUpdate(productId: number, salesStatus: 'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductStatusUpdate(productId, salesStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 상품수정
         * @summary productUpdate
         * @param {ProductUpdateParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminProductUpdate(param: ProductUpdateParamDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminProductUpdate(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 관리자 배송타입 리스트
         * @summary adminShippingTypeList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminShippingTypeList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminShippingTypeList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminProductApi - factory interface
 * @export
 */
export const AdminProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminProductApiFp(configuration)
    return {
        /**
         * 관리자 카테고리 리스트
         * @summary adminCategoryList
         * @param {number} depth depth
         * @param {number} [parentCategoryId] parentCategoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCategoryList(depth: number, parentCategoryId?: number, options?: any): AxiosPromise<object> {
            return localVarFp.adminCategoryList(depth, parentCategoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 관리자 옵션 생성 ( type : closingUrl 등 )
         * @summary adminCreateOption
         * @param {AdminOptionDTO} adminOptionDTO adminOptionDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateOption(adminOptionDTO: AdminOptionDTO, options?: any): AxiosPromise<object> {
            return localVarFp.adminCreateOption(adminOptionDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 관리자 옵션 삭제 ( type : closingUrl 등 )
         * @summary adminDeleteOption
         * @param {number} optionId optionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteOption(optionId: number, options?: any): AxiosPromise<object> {
            return localVarFp.adminDeleteOption(optionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 파일업로드
         * @summary fileUpload
         * @param {number} [maxSize] maxSize
         * @param {boolean} [resize] resize
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminFileupload(maxSize?: number, resize?: boolean, file?: any, options?: any): AxiosPromise<string> {
            return localVarFp.adminFileupload(maxSize, resize, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 관리자 옵션 조회 ( id 기반 : optionId )
         * @summary adminGetOption
         * @param {number} optionId optionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetOption(optionId: number, options?: any): AxiosPromise<object> {
            return localVarFp.adminGetOption(optionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 관리자 옵션 조회 ( type 기반 : closingUrl 등 )
         * @summary adminGetOptionByType
         * @param {string} type type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetOptionByType(type: string, options?: any): AxiosPromise<object> {
            return localVarFp.adminGetOptionByType(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 관리자 옵션 리스트 ( type : period , status , country , artist , medium )
         * @summary adminOptionList
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOptionList(type?: string, options?: any): AxiosPromise<object> {
            return localVarFp.adminOptionList(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 관리자 옵션 패치 ( id 기반 : optionId )
         * @summary adminPatchOption
         * @param {number} optionId optionId
         * @param {AdminOptionDTO} adminOptionDTO adminOptionDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPatchOption(optionId: number, adminOptionDTO: AdminOptionDTO, options?: any): AxiosPromise<object> {
            return localVarFp.adminPatchOption(optionId, adminOptionDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 관리자 옵션 패치 ( type 기반 : closingUrl )
         * @summary adminPatchOptionByType
         * @param {string} type type
         * @param {AdminOptionTypeDTO} adminOptionTypeDTO adminOptionTypeDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPatchOptionByType(type: string, adminOptionTypeDTO: AdminOptionTypeDTO, options?: any): AxiosPromise<object> {
            return localVarFp.adminPatchOptionByType(type, adminOptionTypeDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 판매정보 등록
         * @summary productAddStep2
         * @param {SalesInfoParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductAddStep2(param: SalesInfoParamDTO, options?: any): AxiosPromise<object> {
            return localVarFp.adminProductAddStep2(param, options).then((request) => request(axios, basePath));
        },
        /**
         * 상품삭제
         * @summary adminProductDelete
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductDelete(productId: number, options?: any): AxiosPromise<object> {
            return localVarFp.adminProductDelete(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 상품숨김
         * @summary adminProductHide
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductHide(productId: number, options?: any): AxiosPromise<object> {
            return localVarFp.adminProductHide(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 관리자 상품리스트
         * @summary adminProductList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {number} [categoryDepth2Id] categoryDepth2Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {string} [end] end
         * @param {string} [eventCategory] eventCategory
         * @param {string} [eventCode] eventCode
         * @param {string} [keyword] keyword
         * @param {number} [partnerId] partnerId
         * @param {'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out'} [salesStatus] salesStatus
         * @param {boolean} [showDeleted] showDeleted
         * @param {boolean} [showInvisibled] showInvisibled
         * @param {string} [sortBy] sortBy
         * @param {string} [start] start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductList(page: number, pageSize: number, categoryDepth1Id?: number, categoryDepth2Id?: number, enableBid?: boolean, enableBuyNow?: boolean, end?: string, eventCategory?: string, eventCode?: string, keyword?: string, partnerId?: number, salesStatus?: 'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out', showDeleted?: boolean, showInvisibled?: boolean, sortBy?: string, start?: string, options?: any): AxiosPromise<object> {
            return localVarFp.adminProductList(page, pageSize, categoryDepth1Id, categoryDepth2Id, enableBid, enableBuyNow, end, eventCategory, eventCode, keyword, partnerId, salesStatus, showDeleted, showInvisibled, sortBy, start, options).then((request) => request(axios, basePath));
        },
        /**
         * 상품게시
         * @summary adminProductShow
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductShow(productId: number, options?: any): AxiosPromise<object> {
            return localVarFp.adminProductShow(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 관리자 상품조회
         * @summary adminProductSingle
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductSingle(productId: number, options?: any): AxiosPromise<object> {
            return localVarFp.adminProductSingle(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 상품등록
         * @summary productStatusUpdate
         * @param {number} productId productId
         * @param {'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out'} salesStatus salesStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductStatusUpdate(productId: number, salesStatus: 'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out', options?: any): AxiosPromise<object> {
            return localVarFp.adminProductStatusUpdate(productId, salesStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 상품수정
         * @summary productUpdate
         * @param {ProductUpdateParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminProductUpdate(param: ProductUpdateParamDTO, options?: any): AxiosPromise<object> {
            return localVarFp.adminProductUpdate(param, options).then((request) => request(axios, basePath));
        },
        /**
         * 관리자 배송타입 리스트
         * @summary adminShippingTypeList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminShippingTypeList(options?: any): AxiosPromise<object> {
            return localVarFp.adminShippingTypeList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminCategoryList operation in AdminProductApi.
 * @export
 * @interface AdminProductApiAdminCategoryListRequest
 */
export interface AdminProductApiAdminCategoryListRequest {
    /**
     * depth
     * @type {number}
     * @memberof AdminProductApiAdminCategoryList
     */
    readonly depth: number

    /**
     * parentCategoryId 
     * @type {number}
     * @memberof AdminProductApiAdminCategoryList
     */
    readonly parentCategoryId?: number
}

/**
 * Request parameters for adminCreateOption operation in AdminProductApi.
 * @export
 * @interface AdminProductApiAdminCreateOptionRequest
 */
export interface AdminProductApiAdminCreateOptionRequest {
    /**
     * adminOptionDTO
     * @type {AdminOptionDTO}
     * @memberof AdminProductApiAdminCreateOption
     */
    readonly adminOptionDTO: AdminOptionDTO
}

/**
 * Request parameters for adminDeleteOption operation in AdminProductApi.
 * @export
 * @interface AdminProductApiAdminDeleteOptionRequest
 */
export interface AdminProductApiAdminDeleteOptionRequest {
    /**
     * optionId
     * @type {number}
     * @memberof AdminProductApiAdminDeleteOption
     */
    readonly optionId: number
}

/**
 * Request parameters for adminFileupload operation in AdminProductApi.
 * @export
 * @interface AdminProductApiAdminFileuploadRequest
 */
export interface AdminProductApiAdminFileuploadRequest {
    /**
     * maxSize
     * @type {number}
     * @memberof AdminProductApiAdminFileupload
     */
    readonly maxSize?: number

    /**
     * resize
     * @type {boolean}
     * @memberof AdminProductApiAdminFileupload
     */
    readonly resize?: boolean

    /**
     * 
     * @type {any}
     * @memberof AdminProductApiAdminFileupload
     */
    readonly file?: any
}

/**
 * Request parameters for adminGetOption operation in AdminProductApi.
 * @export
 * @interface AdminProductApiAdminGetOptionRequest
 */
export interface AdminProductApiAdminGetOptionRequest {
    /**
     * optionId
     * @type {number}
     * @memberof AdminProductApiAdminGetOption
     */
    readonly optionId: number
}

/**
 * Request parameters for adminGetOptionByType operation in AdminProductApi.
 * @export
 * @interface AdminProductApiAdminGetOptionByTypeRequest
 */
export interface AdminProductApiAdminGetOptionByTypeRequest {
    /**
     * type
     * @type {string}
     * @memberof AdminProductApiAdminGetOptionByType
     */
    readonly type: string
}

/**
 * Request parameters for adminOptionList operation in AdminProductApi.
 * @export
 * @interface AdminProductApiAdminOptionListRequest
 */
export interface AdminProductApiAdminOptionListRequest {
    /**
     * type
     * @type {string}
     * @memberof AdminProductApiAdminOptionList
     */
    readonly type?: string
}

/**
 * Request parameters for adminPatchOption operation in AdminProductApi.
 * @export
 * @interface AdminProductApiAdminPatchOptionRequest
 */
export interface AdminProductApiAdminPatchOptionRequest {
    /**
     * optionId
     * @type {number}
     * @memberof AdminProductApiAdminPatchOption
     */
    readonly optionId: number

    /**
     * adminOptionDTO
     * @type {AdminOptionDTO}
     * @memberof AdminProductApiAdminPatchOption
     */
    readonly adminOptionDTO: AdminOptionDTO
}

/**
 * Request parameters for adminPatchOptionByType operation in AdminProductApi.
 * @export
 * @interface AdminProductApiAdminPatchOptionByTypeRequest
 */
export interface AdminProductApiAdminPatchOptionByTypeRequest {
    /**
     * type
     * @type {string}
     * @memberof AdminProductApiAdminPatchOptionByType
     */
    readonly type: string

    /**
     * adminOptionTypeDTO
     * @type {AdminOptionTypeDTO}
     * @memberof AdminProductApiAdminPatchOptionByType
     */
    readonly adminOptionTypeDTO: AdminOptionTypeDTO
}

/**
 * Request parameters for adminProductAddStep2 operation in AdminProductApi.
 * @export
 * @interface AdminProductApiAdminProductAddStep2Request
 */
export interface AdminProductApiAdminProductAddStep2Request {
    /**
     * param
     * @type {SalesInfoParamDTO}
     * @memberof AdminProductApiAdminProductAddStep2
     */
    readonly param: SalesInfoParamDTO
}

/**
 * Request parameters for adminProductDelete operation in AdminProductApi.
 * @export
 * @interface AdminProductApiAdminProductDeleteRequest
 */
export interface AdminProductApiAdminProductDeleteRequest {
    /**
     * productId
     * @type {number}
     * @memberof AdminProductApiAdminProductDelete
     */
    readonly productId: number
}

/**
 * Request parameters for adminProductHide operation in AdminProductApi.
 * @export
 * @interface AdminProductApiAdminProductHideRequest
 */
export interface AdminProductApiAdminProductHideRequest {
    /**
     * productId
     * @type {number}
     * @memberof AdminProductApiAdminProductHide
     */
    readonly productId: number
}

/**
 * Request parameters for adminProductList operation in AdminProductApi.
 * @export
 * @interface AdminProductApiAdminProductListRequest
 */
export interface AdminProductApiAdminProductListRequest {
    /**
     * page
     * @type {number}
     * @memberof AdminProductApiAdminProductList
     */
    readonly page: number

    /**
     * pageSize
     * @type {number}
     * @memberof AdminProductApiAdminProductList
     */
    readonly pageSize: number

    /**
     * categoryDepth1Id
     * @type {number}
     * @memberof AdminProductApiAdminProductList
     */
    readonly categoryDepth1Id?: number

    /**
     * categoryDepth2Id
     * @type {number}
     * @memberof AdminProductApiAdminProductList
     */
    readonly categoryDepth2Id?: number

    /**
     * enableBid
     * @type {boolean}
     * @memberof AdminProductApiAdminProductList
     */
    readonly enableBid?: boolean

    /**
     * enableBuyNow
     * @type {boolean}
     * @memberof AdminProductApiAdminProductList
     */
    readonly enableBuyNow?: boolean

    /**
     * end
     * @type {string}
     * @memberof AdminProductApiAdminProductList
     */
    readonly end?: string

    /**
     * eventCategory
     * @type {string}
     * @memberof AdminProductApiAdminProductList
     */
    readonly eventCategory?: string

    /**
     * eventCode
     * @type {string}
     * @memberof AdminProductApiAdminProductList
     */
    readonly eventCode?: string

    /**
     * keyword
     * @type {string}
     * @memberof AdminProductApiAdminProductList
     */
    readonly keyword?: string

    /**
     * partnerId
     * @type {number}
     * @memberof AdminProductApiAdminProductList
     */
    readonly partnerId?: number

    /**
     * salesStatus
     * @type {'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out'}
     * @memberof AdminProductApiAdminProductList
     */
    readonly salesStatus?: 'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out'

    /**
     * showDeleted
     * @type {boolean}
     * @memberof AdminProductApiAdminProductList
     */
    readonly showDeleted?: boolean

    /**
     * showInvisibled
     * @type {boolean}
     * @memberof AdminProductApiAdminProductList
     */
    readonly showInvisibled?: boolean

    /**
     * sortBy
     * @type {string}
     * @memberof AdminProductApiAdminProductList
     */
    readonly sortBy?: string

    /**
     * start
     * @type {string}
     * @memberof AdminProductApiAdminProductList
     */
    readonly start?: string
}

/**
 * Request parameters for adminProductShow operation in AdminProductApi.
 * @export
 * @interface AdminProductApiAdminProductShowRequest
 */
export interface AdminProductApiAdminProductShowRequest {
    /**
     * productId
     * @type {number}
     * @memberof AdminProductApiAdminProductShow
     */
    readonly productId: number
}

/**
 * Request parameters for adminProductSingle operation in AdminProductApi.
 * @export
 * @interface AdminProductApiAdminProductSingleRequest
 */
export interface AdminProductApiAdminProductSingleRequest {
    /**
     * productId
     * @type {number}
     * @memberof AdminProductApiAdminProductSingle
     */
    readonly productId: number
}

/**
 * Request parameters for adminProductStatusUpdate operation in AdminProductApi.
 * @export
 * @interface AdminProductApiAdminProductStatusUpdateRequest
 */
export interface AdminProductApiAdminProductStatusUpdateRequest {
    /**
     * productId
     * @type {number}
     * @memberof AdminProductApiAdminProductStatusUpdate
     */
    readonly productId: number

    /**
     * salesStatus
     * @type {'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out'}
     * @memberof AdminProductApiAdminProductStatusUpdate
     */
    readonly salesStatus: 'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out'
}

/**
 * Request parameters for adminProductUpdate operation in AdminProductApi.
 * @export
 * @interface AdminProductApiAdminProductUpdateRequest
 */
export interface AdminProductApiAdminProductUpdateRequest {
    /**
     * param
     * @type {ProductUpdateParamDTO}
     * @memberof AdminProductApiAdminProductUpdate
     */
    readonly param: ProductUpdateParamDTO
}

/**
 * AdminProductApi - object-oriented interface
 * @export
 * @class AdminProductApi
 * @extends {BaseAPI}
 */
export class AdminProductApi extends BaseAPI {
    /**
     * 관리자 카테고리 리스트
     * @summary adminCategoryList
     * @param {AdminProductApiAdminCategoryListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductApi
     */
    public adminCategoryList(requestParameters: AdminProductApiAdminCategoryListRequest, options?: any) {
        return AdminProductApiFp(this.configuration).adminCategoryList(requestParameters.depth, requestParameters.parentCategoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 관리자 옵션 생성 ( type : closingUrl 등 )
     * @summary adminCreateOption
     * @param {AdminProductApiAdminCreateOptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductApi
     */
    public adminCreateOption(requestParameters: AdminProductApiAdminCreateOptionRequest, options?: any) {
        return AdminProductApiFp(this.configuration).adminCreateOption(requestParameters.adminOptionDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 관리자 옵션 삭제 ( type : closingUrl 등 )
     * @summary adminDeleteOption
     * @param {AdminProductApiAdminDeleteOptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductApi
     */
    public adminDeleteOption(requestParameters: AdminProductApiAdminDeleteOptionRequest, options?: any) {
        return AdminProductApiFp(this.configuration).adminDeleteOption(requestParameters.optionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 파일업로드
     * @summary fileUpload
     * @param {AdminProductApiAdminFileuploadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductApi
     */
    public adminFileupload(requestParameters: AdminProductApiAdminFileuploadRequest = {}, options?: any) {
        return AdminProductApiFp(this.configuration).adminFileupload(requestParameters.maxSize, requestParameters.resize, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 관리자 옵션 조회 ( id 기반 : optionId )
     * @summary adminGetOption
     * @param {AdminProductApiAdminGetOptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductApi
     */
    public adminGetOption(requestParameters: AdminProductApiAdminGetOptionRequest, options?: any) {
        return AdminProductApiFp(this.configuration).adminGetOption(requestParameters.optionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 관리자 옵션 조회 ( type 기반 : closingUrl 등 )
     * @summary adminGetOptionByType
     * @param {AdminProductApiAdminGetOptionByTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductApi
     */
    public adminGetOptionByType(requestParameters: AdminProductApiAdminGetOptionByTypeRequest, options?: any) {
        return AdminProductApiFp(this.configuration).adminGetOptionByType(requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 관리자 옵션 리스트 ( type : period , status , country , artist , medium )
     * @summary adminOptionList
     * @param {AdminProductApiAdminOptionListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductApi
     */
    public adminOptionList(requestParameters: AdminProductApiAdminOptionListRequest = {}, options?: any) {
        return AdminProductApiFp(this.configuration).adminOptionList(requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 관리자 옵션 패치 ( id 기반 : optionId )
     * @summary adminPatchOption
     * @param {AdminProductApiAdminPatchOptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductApi
     */
    public adminPatchOption(requestParameters: AdminProductApiAdminPatchOptionRequest, options?: any) {
        return AdminProductApiFp(this.configuration).adminPatchOption(requestParameters.optionId, requestParameters.adminOptionDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 관리자 옵션 패치 ( type 기반 : closingUrl )
     * @summary adminPatchOptionByType
     * @param {AdminProductApiAdminPatchOptionByTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductApi
     */
    public adminPatchOptionByType(requestParameters: AdminProductApiAdminPatchOptionByTypeRequest, options?: any) {
        return AdminProductApiFp(this.configuration).adminPatchOptionByType(requestParameters.type, requestParameters.adminOptionTypeDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 판매정보 등록
     * @summary productAddStep2
     * @param {AdminProductApiAdminProductAddStep2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductApi
     */
    public adminProductAddStep2(requestParameters: AdminProductApiAdminProductAddStep2Request, options?: any) {
        return AdminProductApiFp(this.configuration).adminProductAddStep2(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 상품삭제
     * @summary adminProductDelete
     * @param {AdminProductApiAdminProductDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductApi
     */
    public adminProductDelete(requestParameters: AdminProductApiAdminProductDeleteRequest, options?: any) {
        return AdminProductApiFp(this.configuration).adminProductDelete(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 상품숨김
     * @summary adminProductHide
     * @param {AdminProductApiAdminProductHideRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductApi
     */
    public adminProductHide(requestParameters: AdminProductApiAdminProductHideRequest, options?: any) {
        return AdminProductApiFp(this.configuration).adminProductHide(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 관리자 상품리스트
     * @summary adminProductList
     * @param {AdminProductApiAdminProductListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductApi
     */
    public adminProductList(requestParameters: AdminProductApiAdminProductListRequest, options?: any) {
        return AdminProductApiFp(this.configuration).adminProductList(requestParameters.page, requestParameters.pageSize, requestParameters.categoryDepth1Id, requestParameters.categoryDepth2Id, requestParameters.enableBid, requestParameters.enableBuyNow, requestParameters.end, requestParameters.eventCategory, requestParameters.eventCode, requestParameters.keyword, requestParameters.partnerId, requestParameters.salesStatus, requestParameters.showDeleted, requestParameters.showInvisibled, requestParameters.sortBy, requestParameters.start, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 상품게시
     * @summary adminProductShow
     * @param {AdminProductApiAdminProductShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductApi
     */
    public adminProductShow(requestParameters: AdminProductApiAdminProductShowRequest, options?: any) {
        return AdminProductApiFp(this.configuration).adminProductShow(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 관리자 상품조회
     * @summary adminProductSingle
     * @param {AdminProductApiAdminProductSingleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductApi
     */
    public adminProductSingle(requestParameters: AdminProductApiAdminProductSingleRequest, options?: any) {
        return AdminProductApiFp(this.configuration).adminProductSingle(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 상품등록
     * @summary productStatusUpdate
     * @param {AdminProductApiAdminProductStatusUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductApi
     */
    public adminProductStatusUpdate(requestParameters: AdminProductApiAdminProductStatusUpdateRequest, options?: any) {
        return AdminProductApiFp(this.configuration).adminProductStatusUpdate(requestParameters.productId, requestParameters.salesStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 상품수정
     * @summary productUpdate
     * @param {AdminProductApiAdminProductUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductApi
     */
    public adminProductUpdate(requestParameters: AdminProductApiAdminProductUpdateRequest, options?: any) {
        return AdminProductApiFp(this.configuration).adminProductUpdate(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 관리자 배송타입 리스트
     * @summary adminShippingTypeList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProductApi
     */
    public adminShippingTypeList(options?: any) {
        return AdminProductApiFp(this.configuration).adminShippingTypeList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminQnaApi - axios parameter creator
 * @export
 */
export const AdminQnaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * qna상세
         * @summary adminQnaDetail
         * @param {number} memberId memberId
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminQnaDetail: async (memberId: number, productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('adminQnaDetail', 'memberId', memberId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('adminQnaDetail', 'productId', productId)
            const localVarPath = `/admin-api/qna`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * qna리스트
         * @summary adminQnaList
         * @param {string} [keyword] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminQnaList: async (keyword?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin-api/qna/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminQnaApi - functional programming interface
 * @export
 */
export const AdminQnaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminQnaApiAxiosParamCreator(configuration)
    return {
        /**
         * qna상세
         * @summary adminQnaDetail
         * @param {number} memberId memberId
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminQnaDetail(memberId: number, productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminQnaDetail(memberId, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * qna리스트
         * @summary adminQnaList
         * @param {string} [keyword] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminQnaList(keyword?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminQnaList(keyword, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminQnaApi - factory interface
 * @export
 */
export const AdminQnaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminQnaApiFp(configuration)
    return {
        /**
         * qna상세
         * @summary adminQnaDetail
         * @param {number} memberId memberId
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminQnaDetail(memberId: number, productId: number, options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminQnaDetail(memberId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * qna리스트
         * @summary adminQnaList
         * @param {string} [keyword] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminQnaList(keyword?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminQnaList(keyword, page, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminQnaDetail operation in AdminQnaApi.
 * @export
 * @interface AdminQnaApiAdminQnaDetailRequest
 */
export interface AdminQnaApiAdminQnaDetailRequest {
    /**
     * memberId
     * @type {number}
     * @memberof AdminQnaApiAdminQnaDetail
     */
    readonly memberId: number

    /**
     * productId
     * @type {number}
     * @memberof AdminQnaApiAdminQnaDetail
     */
    readonly productId: number
}

/**
 * Request parameters for adminQnaList operation in AdminQnaApi.
 * @export
 * @interface AdminQnaApiAdminQnaListRequest
 */
export interface AdminQnaApiAdminQnaListRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminQnaApiAdminQnaList
     */
    readonly keyword?: string

    /**
     * 
     * @type {number}
     * @memberof AdminQnaApiAdminQnaList
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminQnaApiAdminQnaList
     */
    readonly pageSize?: number
}

/**
 * AdminQnaApi - object-oriented interface
 * @export
 * @class AdminQnaApi
 * @extends {BaseAPI}
 */
export class AdminQnaApi extends BaseAPI {
    /**
     * qna상세
     * @summary adminQnaDetail
     * @param {AdminQnaApiAdminQnaDetailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminQnaApi
     */
    public adminQnaDetail(requestParameters: AdminQnaApiAdminQnaDetailRequest, options?: any) {
        return AdminQnaApiFp(this.configuration).adminQnaDetail(requestParameters.memberId, requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * qna리스트
     * @summary adminQnaList
     * @param {AdminQnaApiAdminQnaListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminQnaApi
     */
    public adminQnaList(requestParameters: AdminQnaApiAdminQnaListRequest = {}, options?: any) {
        return AdminQnaApiFp(this.configuration).adminQnaList(requestParameters.keyword, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminRegisterTokenApi - axios parameter creator
 * @export
 */
export const AdminRegisterTokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * adminRegisterTokenCreate
         * @summary adminRegisterTokenCreate
         * @param {Array<AdminRegisterTokenCreateDTO>} adminRegisterTokenCreateDTOList adminRegisterTokenCreateDTOList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRegisterTokenCreate: async (adminRegisterTokenCreateDTOList: Array<AdminRegisterTokenCreateDTO>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminRegisterTokenCreateDTOList' is not null or undefined
            assertParamExists('adminRegisterTokenCreate', 'adminRegisterTokenCreateDTOList', adminRegisterTokenCreateDTOList)
            const localVarPath = `/admin-api/register-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminRegisterTokenCreateDTOList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * adminRegisterTokenDelete
         * @summary adminRegisterTokenDelete
         * @param {Array<number>} registerTokenIds registerTokenIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRegisterTokenDelete: async (registerTokenIds: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerTokenIds' is not null or undefined
            assertParamExists('adminRegisterTokenDelete', 'registerTokenIds', registerTokenIds)
            const localVarPath = `/admin-api/register-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (registerTokenIds) {
                localVarQueryParameter['registerTokenIds'] = registerTokenIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * adminRegisterTokenFind
         * @summary adminRegisterTokenFind
         * @param {string} [artistNameKr] artistNameKr
         * @param {string} [email] email
         * @param {string} [eventCategory] eventCategory
         * @param {string} [eventCode] eventCode
         * @param {number} [memberId] memberId
         * @param {string} [name] name
         * @param {number} [page] page
         * @param {number} [pageSize] pageSize
         * @param {number} [productId] productId
         * @param {string} [titleKr] titleKr
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRegisterTokenFind: async (artistNameKr?: string, email?: string, eventCategory?: string, eventCode?: string, memberId?: number, name?: string, page?: number, pageSize?: number, productId?: number, titleKr?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin-api/register-token/detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (artistNameKr !== undefined) {
                localVarQueryParameter['artistNameKr'] = artistNameKr;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (eventCategory !== undefined) {
                localVarQueryParameter['eventCategory'] = eventCategory;
            }

            if (eventCode !== undefined) {
                localVarQueryParameter['eventCode'] = eventCode;
            }

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (titleKr !== undefined) {
                localVarQueryParameter['titleKr'] = titleKr;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * adminRegisterTokenFind
         * @summary adminRegisterTokenFind
         * @param {number} memberId memberId
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRegisterTokenFind1: async (memberId: number, productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('adminRegisterTokenFind1', 'memberId', memberId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('adminRegisterTokenFind1', 'productId', productId)
            const localVarPath = `/admin-api/register-token/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * adminRegisterTokenGet
         * @summary adminRegisterTokenGet
         * @param {Array<number>} registerTokenIds registerTokenIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRegisterTokenGet: async (registerTokenIds: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerTokenIds' is not null or undefined
            assertParamExists('adminRegisterTokenGet', 'registerTokenIds', registerTokenIds)
            const localVarPath = `/admin-api/register-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (registerTokenIds) {
                localVarQueryParameter['registerTokenIds'] = registerTokenIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * adminRegisterTokenList
         * @summary adminRegisterTokenList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRegisterTokenList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin-api/register-token/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminRegisterTokenApi - functional programming interface
 * @export
 */
export const AdminRegisterTokenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminRegisterTokenApiAxiosParamCreator(configuration)
    return {
        /**
         * adminRegisterTokenCreate
         * @summary adminRegisterTokenCreate
         * @param {Array<AdminRegisterTokenCreateDTO>} adminRegisterTokenCreateDTOList adminRegisterTokenCreateDTOList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminRegisterTokenCreate(adminRegisterTokenCreateDTOList: Array<AdminRegisterTokenCreateDTO>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminRegisterTokenCreate(adminRegisterTokenCreateDTOList, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * adminRegisterTokenDelete
         * @summary adminRegisterTokenDelete
         * @param {Array<number>} registerTokenIds registerTokenIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminRegisterTokenDelete(registerTokenIds: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminRegisterTokenDelete(registerTokenIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * adminRegisterTokenFind
         * @summary adminRegisterTokenFind
         * @param {string} [artistNameKr] artistNameKr
         * @param {string} [email] email
         * @param {string} [eventCategory] eventCategory
         * @param {string} [eventCode] eventCode
         * @param {number} [memberId] memberId
         * @param {string} [name] name
         * @param {number} [page] page
         * @param {number} [pageSize] pageSize
         * @param {number} [productId] productId
         * @param {string} [titleKr] titleKr
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminRegisterTokenFind(artistNameKr?: string, email?: string, eventCategory?: string, eventCode?: string, memberId?: number, name?: string, page?: number, pageSize?: number, productId?: number, titleKr?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminRegisterTokenFind(artistNameKr, email, eventCategory, eventCode, memberId, name, page, pageSize, productId, titleKr, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * adminRegisterTokenFind
         * @summary adminRegisterTokenFind
         * @param {number} memberId memberId
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminRegisterTokenFind1(memberId: number, productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminRegisterTokenFind1(memberId, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * adminRegisterTokenGet
         * @summary adminRegisterTokenGet
         * @param {Array<number>} registerTokenIds registerTokenIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminRegisterTokenGet(registerTokenIds: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminRegisterTokenGet(registerTokenIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * adminRegisterTokenList
         * @summary adminRegisterTokenList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminRegisterTokenList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminRegisterTokenList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminRegisterTokenApi - factory interface
 * @export
 */
export const AdminRegisterTokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminRegisterTokenApiFp(configuration)
    return {
        /**
         * adminRegisterTokenCreate
         * @summary adminRegisterTokenCreate
         * @param {Array<AdminRegisterTokenCreateDTO>} adminRegisterTokenCreateDTOList adminRegisterTokenCreateDTOList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRegisterTokenCreate(adminRegisterTokenCreateDTOList: Array<AdminRegisterTokenCreateDTO>, options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminRegisterTokenCreate(adminRegisterTokenCreateDTOList, options).then((request) => request(axios, basePath));
        },
        /**
         * adminRegisterTokenDelete
         * @summary adminRegisterTokenDelete
         * @param {Array<number>} registerTokenIds registerTokenIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRegisterTokenDelete(registerTokenIds: Array<number>, options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminRegisterTokenDelete(registerTokenIds, options).then((request) => request(axios, basePath));
        },
        /**
         * adminRegisterTokenFind
         * @summary adminRegisterTokenFind
         * @param {string} [artistNameKr] artistNameKr
         * @param {string} [email] email
         * @param {string} [eventCategory] eventCategory
         * @param {string} [eventCode] eventCode
         * @param {number} [memberId] memberId
         * @param {string} [name] name
         * @param {number} [page] page
         * @param {number} [pageSize] pageSize
         * @param {number} [productId] productId
         * @param {string} [titleKr] titleKr
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRegisterTokenFind(artistNameKr?: string, email?: string, eventCategory?: string, eventCode?: string, memberId?: number, name?: string, page?: number, pageSize?: number, productId?: number, titleKr?: string, options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminRegisterTokenFind(artistNameKr, email, eventCategory, eventCode, memberId, name, page, pageSize, productId, titleKr, options).then((request) => request(axios, basePath));
        },
        /**
         * adminRegisterTokenFind
         * @summary adminRegisterTokenFind
         * @param {number} memberId memberId
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRegisterTokenFind1(memberId: number, productId: number, options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminRegisterTokenFind1(memberId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * adminRegisterTokenGet
         * @summary adminRegisterTokenGet
         * @param {Array<number>} registerTokenIds registerTokenIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRegisterTokenGet(registerTokenIds: Array<number>, options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminRegisterTokenGet(registerTokenIds, options).then((request) => request(axios, basePath));
        },
        /**
         * adminRegisterTokenList
         * @summary adminRegisterTokenList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminRegisterTokenList(options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminRegisterTokenList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminRegisterTokenCreate operation in AdminRegisterTokenApi.
 * @export
 * @interface AdminRegisterTokenApiAdminRegisterTokenCreateRequest
 */
export interface AdminRegisterTokenApiAdminRegisterTokenCreateRequest {
    /**
     * adminRegisterTokenCreateDTOList
     * @type {Array<AdminRegisterTokenCreateDTO>}
     * @memberof AdminRegisterTokenApiAdminRegisterTokenCreate
     */
    readonly adminRegisterTokenCreateDTOList: Array<AdminRegisterTokenCreateDTO>
}

/**
 * Request parameters for adminRegisterTokenDelete operation in AdminRegisterTokenApi.
 * @export
 * @interface AdminRegisterTokenApiAdminRegisterTokenDeleteRequest
 */
export interface AdminRegisterTokenApiAdminRegisterTokenDeleteRequest {
    /**
     * registerTokenIds
     * @type {Array<number>}
     * @memberof AdminRegisterTokenApiAdminRegisterTokenDelete
     */
    readonly registerTokenIds: Array<number>
}

/**
 * Request parameters for adminRegisterTokenFind operation in AdminRegisterTokenApi.
 * @export
 * @interface AdminRegisterTokenApiAdminRegisterTokenFindRequest
 */
export interface AdminRegisterTokenApiAdminRegisterTokenFindRequest {
    /**
     * artistNameKr
     * @type {string}
     * @memberof AdminRegisterTokenApiAdminRegisterTokenFind
     */
    readonly artistNameKr?: string

    /**
     * email
     * @type {string}
     * @memberof AdminRegisterTokenApiAdminRegisterTokenFind
     */
    readonly email?: string

    /**
     * eventCategory
     * @type {string}
     * @memberof AdminRegisterTokenApiAdminRegisterTokenFind
     */
    readonly eventCategory?: string

    /**
     * eventCode
     * @type {string}
     * @memberof AdminRegisterTokenApiAdminRegisterTokenFind
     */
    readonly eventCode?: string

    /**
     * memberId
     * @type {number}
     * @memberof AdminRegisterTokenApiAdminRegisterTokenFind
     */
    readonly memberId?: number

    /**
     * name
     * @type {string}
     * @memberof AdminRegisterTokenApiAdminRegisterTokenFind
     */
    readonly name?: string

    /**
     * page
     * @type {number}
     * @memberof AdminRegisterTokenApiAdminRegisterTokenFind
     */
    readonly page?: number

    /**
     * pageSize
     * @type {number}
     * @memberof AdminRegisterTokenApiAdminRegisterTokenFind
     */
    readonly pageSize?: number

    /**
     * productId
     * @type {number}
     * @memberof AdminRegisterTokenApiAdminRegisterTokenFind
     */
    readonly productId?: number

    /**
     * titleKr
     * @type {string}
     * @memberof AdminRegisterTokenApiAdminRegisterTokenFind
     */
    readonly titleKr?: string
}

/**
 * Request parameters for adminRegisterTokenFind1 operation in AdminRegisterTokenApi.
 * @export
 * @interface AdminRegisterTokenApiAdminRegisterTokenFind1Request
 */
export interface AdminRegisterTokenApiAdminRegisterTokenFind1Request {
    /**
     * memberId
     * @type {number}
     * @memberof AdminRegisterTokenApiAdminRegisterTokenFind1
     */
    readonly memberId: number

    /**
     * productId
     * @type {number}
     * @memberof AdminRegisterTokenApiAdminRegisterTokenFind1
     */
    readonly productId: number
}

/**
 * Request parameters for adminRegisterTokenGet operation in AdminRegisterTokenApi.
 * @export
 * @interface AdminRegisterTokenApiAdminRegisterTokenGetRequest
 */
export interface AdminRegisterTokenApiAdminRegisterTokenGetRequest {
    /**
     * registerTokenIds
     * @type {Array<number>}
     * @memberof AdminRegisterTokenApiAdminRegisterTokenGet
     */
    readonly registerTokenIds: Array<number>
}

/**
 * AdminRegisterTokenApi - object-oriented interface
 * @export
 * @class AdminRegisterTokenApi
 * @extends {BaseAPI}
 */
export class AdminRegisterTokenApi extends BaseAPI {
    /**
     * adminRegisterTokenCreate
     * @summary adminRegisterTokenCreate
     * @param {AdminRegisterTokenApiAdminRegisterTokenCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRegisterTokenApi
     */
    public adminRegisterTokenCreate(requestParameters: AdminRegisterTokenApiAdminRegisterTokenCreateRequest, options?: any) {
        return AdminRegisterTokenApiFp(this.configuration).adminRegisterTokenCreate(requestParameters.adminRegisterTokenCreateDTOList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * adminRegisterTokenDelete
     * @summary adminRegisterTokenDelete
     * @param {AdminRegisterTokenApiAdminRegisterTokenDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRegisterTokenApi
     */
    public adminRegisterTokenDelete(requestParameters: AdminRegisterTokenApiAdminRegisterTokenDeleteRequest, options?: any) {
        return AdminRegisterTokenApiFp(this.configuration).adminRegisterTokenDelete(requestParameters.registerTokenIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * adminRegisterTokenFind
     * @summary adminRegisterTokenFind
     * @param {AdminRegisterTokenApiAdminRegisterTokenFindRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRegisterTokenApi
     */
    public adminRegisterTokenFind(requestParameters: AdminRegisterTokenApiAdminRegisterTokenFindRequest = {}, options?: any) {
        return AdminRegisterTokenApiFp(this.configuration).adminRegisterTokenFind(requestParameters.artistNameKr, requestParameters.email, requestParameters.eventCategory, requestParameters.eventCode, requestParameters.memberId, requestParameters.name, requestParameters.page, requestParameters.pageSize, requestParameters.productId, requestParameters.titleKr, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * adminRegisterTokenFind
     * @summary adminRegisterTokenFind
     * @param {AdminRegisterTokenApiAdminRegisterTokenFind1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRegisterTokenApi
     */
    public adminRegisterTokenFind1(requestParameters: AdminRegisterTokenApiAdminRegisterTokenFind1Request, options?: any) {
        return AdminRegisterTokenApiFp(this.configuration).adminRegisterTokenFind1(requestParameters.memberId, requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * adminRegisterTokenGet
     * @summary adminRegisterTokenGet
     * @param {AdminRegisterTokenApiAdminRegisterTokenGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRegisterTokenApi
     */
    public adminRegisterTokenGet(requestParameters: AdminRegisterTokenApiAdminRegisterTokenGetRequest, options?: any) {
        return AdminRegisterTokenApiFp(this.configuration).adminRegisterTokenGet(requestParameters.registerTokenIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * adminRegisterTokenList
     * @summary adminRegisterTokenList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRegisterTokenApi
     */
    public adminRegisterTokenList(options?: any) {
        return AdminRegisterTokenApiFp(this.configuration).adminRegisterTokenList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminSalesApi - axios parameter creator
 * @export
 */
export const AdminSalesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 관리자상품주문상태값수정
         * @summary adminOrderStatusUpdate
         * @param {OrderStatusParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderStatusUpdate: async (param: OrderStatusParamDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('adminOrderStatusUpdate', 'param', param)
            const localVarPath = `/admin-api/order-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 관리자반품완료
         * @summary adminReturnMemoUpdate
         * @param {ReturnMemoParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReturnMemoUpdate: async (param: ReturnMemoParamDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('adminReturnMemoUpdate', 'param', param)
            const localVarPath = `/admin-api/order-return-memo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 관리자판매상세
         * @summary adminSalesInfo
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesInfo: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('adminSalesInfo', 'productId', productId)
            const localVarPath = `/admin-api/sales/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 관리자판매리스트
         * @summary adminSalesList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {boolean} [biddingExist] biddingExist
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {number} [categoryDepth2Id] categoryDepth2Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {string} [end] end
         * @param {string} [keyword] keyword
         * @param {'complete' | 'delivered' | 'deposit_wait' | 'none' | 'payment_complete' | 'payment_time_out' | 'payment_wait' | 'proccessed' | 'purchase_cancel' | 'return_complete' | 'return_request' | 'shipped'} [orderStatus] orderStatus
         * @param {number} [partnerId] partnerId
         * @param {'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out'} [salesStatus] salesStatus
         * @param {string} [sortBy] sortBy
         * @param {string} [start] start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesList: async (page: number, pageSize: number, biddingExist?: boolean, categoryDepth1Id?: number, categoryDepth2Id?: number, enableBid?: boolean, enableBuyNow?: boolean, end?: string, keyword?: string, orderStatus?: 'complete' | 'delivered' | 'deposit_wait' | 'none' | 'payment_complete' | 'payment_time_out' | 'payment_wait' | 'proccessed' | 'purchase_cancel' | 'return_complete' | 'return_request' | 'shipped', partnerId?: number, salesStatus?: 'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out', sortBy?: string, start?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('adminSalesList', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('adminSalesList', 'pageSize', pageSize)
            const localVarPath = `/admin-api/sales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (biddingExist !== undefined) {
                localVarQueryParameter['biddingExist'] = biddingExist;
            }

            if (categoryDepth1Id !== undefined) {
                localVarQueryParameter['categoryDepth1Id'] = categoryDepth1Id;
            }

            if (categoryDepth2Id !== undefined) {
                localVarQueryParameter['categoryDepth2Id'] = categoryDepth2Id;
            }

            if (enableBid !== undefined) {
                localVarQueryParameter['enableBid'] = enableBid;
            }

            if (enableBuyNow !== undefined) {
                localVarQueryParameter['enableBuyNow'] = enableBuyNow;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (orderStatus !== undefined) {
                localVarQueryParameter['orderStatus'] = orderStatus;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partnerId'] = partnerId;
            }

            if (salesStatus !== undefined) {
                localVarQueryParameter['salesStatus'] = salesStatus;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 관리자배송조회
         * @summary adminShippingTracking
         * @param {string} ordernum ordernum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminShippingTracking: async (ordernum: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ordernum' is not null or undefined
            assertParamExists('adminShippingTracking', 'ordernum', ordernum)
            const localVarPath = `/admin-api/shipping-tracking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ordernum !== undefined) {
                localVarQueryParameter['ordernum'] = ordernum;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 관리자트래킹코드입력
         * @summary adminTrackingCodeUpdate
         * @param {TrackingCodeParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackingCodeUpdate: async (param: TrackingCodeParamDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('adminTrackingCodeUpdate', 'param', param)
            const localVarPath = `/admin-api/update-tracking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminSalesApi - functional programming interface
 * @export
 */
export const AdminSalesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminSalesApiAxiosParamCreator(configuration)
    return {
        /**
         * 관리자상품주문상태값수정
         * @summary adminOrderStatusUpdate
         * @param {OrderStatusParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminOrderStatusUpdate(param: OrderStatusParamDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminOrderStatusUpdate(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 관리자반품완료
         * @summary adminReturnMemoUpdate
         * @param {ReturnMemoParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminReturnMemoUpdate(param: ReturnMemoParamDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminReturnMemoUpdate(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 관리자판매상세
         * @summary adminSalesInfo
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSalesInfo(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSalesInfo(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 관리자판매리스트
         * @summary adminSalesList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {boolean} [biddingExist] biddingExist
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {number} [categoryDepth2Id] categoryDepth2Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {string} [end] end
         * @param {string} [keyword] keyword
         * @param {'complete' | 'delivered' | 'deposit_wait' | 'none' | 'payment_complete' | 'payment_time_out' | 'payment_wait' | 'proccessed' | 'purchase_cancel' | 'return_complete' | 'return_request' | 'shipped'} [orderStatus] orderStatus
         * @param {number} [partnerId] partnerId
         * @param {'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out'} [salesStatus] salesStatus
         * @param {string} [sortBy] sortBy
         * @param {string} [start] start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminSalesList(page: number, pageSize: number, biddingExist?: boolean, categoryDepth1Id?: number, categoryDepth2Id?: number, enableBid?: boolean, enableBuyNow?: boolean, end?: string, keyword?: string, orderStatus?: 'complete' | 'delivered' | 'deposit_wait' | 'none' | 'payment_complete' | 'payment_time_out' | 'payment_wait' | 'proccessed' | 'purchase_cancel' | 'return_complete' | 'return_request' | 'shipped', partnerId?: number, salesStatus?: 'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out', sortBy?: string, start?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminSalesList(page, pageSize, biddingExist, categoryDepth1Id, categoryDepth2Id, enableBid, enableBuyNow, end, keyword, orderStatus, partnerId, salesStatus, sortBy, start, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 관리자배송조회
         * @summary adminShippingTracking
         * @param {string} ordernum ordernum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminShippingTracking(ordernum: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminShippingTracking(ordernum, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 관리자트래킹코드입력
         * @summary adminTrackingCodeUpdate
         * @param {TrackingCodeParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTrackingCodeUpdate(param: TrackingCodeParamDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTrackingCodeUpdate(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminSalesApi - factory interface
 * @export
 */
export const AdminSalesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminSalesApiFp(configuration)
    return {
        /**
         * 관리자상품주문상태값수정
         * @summary adminOrderStatusUpdate
         * @param {OrderStatusParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrderStatusUpdate(param: OrderStatusParamDTO, options?: any): AxiosPromise<object> {
            return localVarFp.adminOrderStatusUpdate(param, options).then((request) => request(axios, basePath));
        },
        /**
         * 관리자반품완료
         * @summary adminReturnMemoUpdate
         * @param {ReturnMemoParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminReturnMemoUpdate(param: ReturnMemoParamDTO, options?: any): AxiosPromise<object> {
            return localVarFp.adminReturnMemoUpdate(param, options).then((request) => request(axios, basePath));
        },
        /**
         * 관리자판매상세
         * @summary adminSalesInfo
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesInfo(productId: number, options?: any): AxiosPromise<object> {
            return localVarFp.adminSalesInfo(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 관리자판매리스트
         * @summary adminSalesList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {boolean} [biddingExist] biddingExist
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {number} [categoryDepth2Id] categoryDepth2Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {string} [end] end
         * @param {string} [keyword] keyword
         * @param {'complete' | 'delivered' | 'deposit_wait' | 'none' | 'payment_complete' | 'payment_time_out' | 'payment_wait' | 'proccessed' | 'purchase_cancel' | 'return_complete' | 'return_request' | 'shipped'} [orderStatus] orderStatus
         * @param {number} [partnerId] partnerId
         * @param {'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out'} [salesStatus] salesStatus
         * @param {string} [sortBy] sortBy
         * @param {string} [start] start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSalesList(page: number, pageSize: number, biddingExist?: boolean, categoryDepth1Id?: number, categoryDepth2Id?: number, enableBid?: boolean, enableBuyNow?: boolean, end?: string, keyword?: string, orderStatus?: 'complete' | 'delivered' | 'deposit_wait' | 'none' | 'payment_complete' | 'payment_time_out' | 'payment_wait' | 'proccessed' | 'purchase_cancel' | 'return_complete' | 'return_request' | 'shipped', partnerId?: number, salesStatus?: 'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out', sortBy?: string, start?: string, options?: any): AxiosPromise<object> {
            return localVarFp.adminSalesList(page, pageSize, biddingExist, categoryDepth1Id, categoryDepth2Id, enableBid, enableBuyNow, end, keyword, orderStatus, partnerId, salesStatus, sortBy, start, options).then((request) => request(axios, basePath));
        },
        /**
         * 관리자배송조회
         * @summary adminShippingTracking
         * @param {string} ordernum ordernum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminShippingTracking(ordernum: string, options?: any): AxiosPromise<object> {
            return localVarFp.adminShippingTracking(ordernum, options).then((request) => request(axios, basePath));
        },
        /**
         * 관리자트래킹코드입력
         * @summary adminTrackingCodeUpdate
         * @param {TrackingCodeParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTrackingCodeUpdate(param: TrackingCodeParamDTO, options?: any): AxiosPromise<object> {
            return localVarFp.adminTrackingCodeUpdate(param, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminOrderStatusUpdate operation in AdminSalesApi.
 * @export
 * @interface AdminSalesApiAdminOrderStatusUpdateRequest
 */
export interface AdminSalesApiAdminOrderStatusUpdateRequest {
    /**
     * param
     * @type {OrderStatusParamDTO}
     * @memberof AdminSalesApiAdminOrderStatusUpdate
     */
    readonly param: OrderStatusParamDTO
}

/**
 * Request parameters for adminReturnMemoUpdate operation in AdminSalesApi.
 * @export
 * @interface AdminSalesApiAdminReturnMemoUpdateRequest
 */
export interface AdminSalesApiAdminReturnMemoUpdateRequest {
    /**
     * param
     * @type {ReturnMemoParamDTO}
     * @memberof AdminSalesApiAdminReturnMemoUpdate
     */
    readonly param: ReturnMemoParamDTO
}

/**
 * Request parameters for adminSalesInfo operation in AdminSalesApi.
 * @export
 * @interface AdminSalesApiAdminSalesInfoRequest
 */
export interface AdminSalesApiAdminSalesInfoRequest {
    /**
     * productId
     * @type {number}
     * @memberof AdminSalesApiAdminSalesInfo
     */
    readonly productId: number
}

/**
 * Request parameters for adminSalesList operation in AdminSalesApi.
 * @export
 * @interface AdminSalesApiAdminSalesListRequest
 */
export interface AdminSalesApiAdminSalesListRequest {
    /**
     * page
     * @type {number}
     * @memberof AdminSalesApiAdminSalesList
     */
    readonly page: number

    /**
     * pageSize
     * @type {number}
     * @memberof AdminSalesApiAdminSalesList
     */
    readonly pageSize: number

    /**
     * biddingExist
     * @type {boolean}
     * @memberof AdminSalesApiAdminSalesList
     */
    readonly biddingExist?: boolean

    /**
     * categoryDepth1Id
     * @type {number}
     * @memberof AdminSalesApiAdminSalesList
     */
    readonly categoryDepth1Id?: number

    /**
     * categoryDepth2Id
     * @type {number}
     * @memberof AdminSalesApiAdminSalesList
     */
    readonly categoryDepth2Id?: number

    /**
     * enableBid
     * @type {boolean}
     * @memberof AdminSalesApiAdminSalesList
     */
    readonly enableBid?: boolean

    /**
     * enableBuyNow
     * @type {boolean}
     * @memberof AdminSalesApiAdminSalesList
     */
    readonly enableBuyNow?: boolean

    /**
     * end
     * @type {string}
     * @memberof AdminSalesApiAdminSalesList
     */
    readonly end?: string

    /**
     * keyword
     * @type {string}
     * @memberof AdminSalesApiAdminSalesList
     */
    readonly keyword?: string

    /**
     * orderStatus
     * @type {'complete' | 'delivered' | 'deposit_wait' | 'none' | 'payment_complete' | 'payment_time_out' | 'payment_wait' | 'proccessed' | 'purchase_cancel' | 'return_complete' | 'return_request' | 'shipped'}
     * @memberof AdminSalesApiAdminSalesList
     */
    readonly orderStatus?: 'complete' | 'delivered' | 'deposit_wait' | 'none' | 'payment_complete' | 'payment_time_out' | 'payment_wait' | 'proccessed' | 'purchase_cancel' | 'return_complete' | 'return_request' | 'shipped'

    /**
     * partnerId
     * @type {number}
     * @memberof AdminSalesApiAdminSalesList
     */
    readonly partnerId?: number

    /**
     * salesStatus
     * @type {'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out'}
     * @memberof AdminSalesApiAdminSalesList
     */
    readonly salesStatus?: 'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out'

    /**
     * sortBy
     * @type {string}
     * @memberof AdminSalesApiAdminSalesList
     */
    readonly sortBy?: string

    /**
     * start
     * @type {string}
     * @memberof AdminSalesApiAdminSalesList
     */
    readonly start?: string
}

/**
 * Request parameters for adminShippingTracking operation in AdminSalesApi.
 * @export
 * @interface AdminSalesApiAdminShippingTrackingRequest
 */
export interface AdminSalesApiAdminShippingTrackingRequest {
    /**
     * ordernum
     * @type {string}
     * @memberof AdminSalesApiAdminShippingTracking
     */
    readonly ordernum: string
}

/**
 * Request parameters for adminTrackingCodeUpdate operation in AdminSalesApi.
 * @export
 * @interface AdminSalesApiAdminTrackingCodeUpdateRequest
 */
export interface AdminSalesApiAdminTrackingCodeUpdateRequest {
    /**
     * param
     * @type {TrackingCodeParamDTO}
     * @memberof AdminSalesApiAdminTrackingCodeUpdate
     */
    readonly param: TrackingCodeParamDTO
}

/**
 * AdminSalesApi - object-oriented interface
 * @export
 * @class AdminSalesApi
 * @extends {BaseAPI}
 */
export class AdminSalesApi extends BaseAPI {
    /**
     * 관리자상품주문상태값수정
     * @summary adminOrderStatusUpdate
     * @param {AdminSalesApiAdminOrderStatusUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSalesApi
     */
    public adminOrderStatusUpdate(requestParameters: AdminSalesApiAdminOrderStatusUpdateRequest, options?: any) {
        return AdminSalesApiFp(this.configuration).adminOrderStatusUpdate(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 관리자반품완료
     * @summary adminReturnMemoUpdate
     * @param {AdminSalesApiAdminReturnMemoUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSalesApi
     */
    public adminReturnMemoUpdate(requestParameters: AdminSalesApiAdminReturnMemoUpdateRequest, options?: any) {
        return AdminSalesApiFp(this.configuration).adminReturnMemoUpdate(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 관리자판매상세
     * @summary adminSalesInfo
     * @param {AdminSalesApiAdminSalesInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSalesApi
     */
    public adminSalesInfo(requestParameters: AdminSalesApiAdminSalesInfoRequest, options?: any) {
        return AdminSalesApiFp(this.configuration).adminSalesInfo(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 관리자판매리스트
     * @summary adminSalesList
     * @param {AdminSalesApiAdminSalesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSalesApi
     */
    public adminSalesList(requestParameters: AdminSalesApiAdminSalesListRequest, options?: any) {
        return AdminSalesApiFp(this.configuration).adminSalesList(requestParameters.page, requestParameters.pageSize, requestParameters.biddingExist, requestParameters.categoryDepth1Id, requestParameters.categoryDepth2Id, requestParameters.enableBid, requestParameters.enableBuyNow, requestParameters.end, requestParameters.keyword, requestParameters.orderStatus, requestParameters.partnerId, requestParameters.salesStatus, requestParameters.sortBy, requestParameters.start, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 관리자배송조회
     * @summary adminShippingTracking
     * @param {AdminSalesApiAdminShippingTrackingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSalesApi
     */
    public adminShippingTracking(requestParameters: AdminSalesApiAdminShippingTrackingRequest, options?: any) {
        return AdminSalesApiFp(this.configuration).adminShippingTracking(requestParameters.ordernum, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 관리자트래킹코드입력
     * @summary adminTrackingCodeUpdate
     * @param {AdminSalesApiAdminTrackingCodeUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSalesApi
     */
    public adminTrackingCodeUpdate(requestParameters: AdminSalesApiAdminTrackingCodeUpdateRequest, options?: any) {
        return AdminSalesApiFp(this.configuration).adminTrackingCodeUpdate(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminSettingsApi - axios parameter creator
 * @export
 */
export const AdminSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 어드민 셋팅 생성
         * @summary adminCreateSettings
         * @param {AdminCreateSettingsDTO} adminCreateSettingsDTO adminCreateSettingsDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateSettings: async (adminCreateSettingsDTO: AdminCreateSettingsDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminCreateSettingsDTO' is not null or undefined
            assertParamExists('adminCreateSettings', 'adminCreateSettingsDTO', adminCreateSettingsDTO)
            const localVarPath = `/admin-api/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateSettingsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 어드민 셋팅 삭제
         * @summary adminDeleteSettings
         * @param {string} name name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteSettings: async (name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('adminDeleteSettings', 'name', name)
            const localVarPath = `/admin-api/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 어드민 셋팅 조회
         * @summary adminGetSettings
         * @param {string} name name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSettings: async (name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('adminGetSettings', 'name', name)
            const localVarPath = `/admin-api/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 어드민 셋팅 리스트
         * @summary adminListSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminListSettings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin-api/settings/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 어드민 셋팅 수정
         * @summary adminPatchSettings
         * @param {string} name name
         * @param {AdminPatchSettingsDTO} adminPatchSettingsDTO adminPatchSettingsDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPatchSettings: async (name: string, adminPatchSettingsDTO: AdminPatchSettingsDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('adminPatchSettings', 'name', name)
            // verify required parameter 'adminPatchSettingsDTO' is not null or undefined
            assertParamExists('adminPatchSettings', 'adminPatchSettingsDTO', adminPatchSettingsDTO)
            const localVarPath = `/admin-api/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminPatchSettingsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminSettingsApi - functional programming interface
 * @export
 */
export const AdminSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 어드민 셋팅 생성
         * @summary adminCreateSettings
         * @param {AdminCreateSettingsDTO} adminCreateSettingsDTO adminCreateSettingsDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCreateSettings(adminCreateSettingsDTO: AdminCreateSettingsDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCreateSettings(adminCreateSettingsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 어드민 셋팅 삭제
         * @summary adminDeleteSettings
         * @param {string} name name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDeleteSettings(name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDeleteSettings(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 어드민 셋팅 조회
         * @summary adminGetSettings
         * @param {string} name name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminGetSettings(name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminGetSettings(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 어드민 셋팅 리스트
         * @summary adminListSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminListSettings(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminListSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 어드민 셋팅 수정
         * @summary adminPatchSettings
         * @param {string} name name
         * @param {AdminPatchSettingsDTO} adminPatchSettingsDTO adminPatchSettingsDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPatchSettings(name: string, adminPatchSettingsDTO: AdminPatchSettingsDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPatchSettings(name, adminPatchSettingsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminSettingsApi - factory interface
 * @export
 */
export const AdminSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminSettingsApiFp(configuration)
    return {
        /**
         * 어드민 셋팅 생성
         * @summary adminCreateSettings
         * @param {AdminCreateSettingsDTO} adminCreateSettingsDTO adminCreateSettingsDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCreateSettings(adminCreateSettingsDTO: AdminCreateSettingsDTO, options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminCreateSettings(adminCreateSettingsDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 어드민 셋팅 삭제
         * @summary adminDeleteSettings
         * @param {string} name name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDeleteSettings(name: string, options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminDeleteSettings(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 어드민 셋팅 조회
         * @summary adminGetSettings
         * @param {string} name name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminGetSettings(name: string, options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminGetSettings(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 어드민 셋팅 리스트
         * @summary adminListSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminListSettings(options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminListSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 어드민 셋팅 수정
         * @summary adminPatchSettings
         * @param {string} name name
         * @param {AdminPatchSettingsDTO} adminPatchSettingsDTO adminPatchSettingsDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPatchSettings(name: string, adminPatchSettingsDTO: AdminPatchSettingsDTO, options?: any): AxiosPromise<RestDTO> {
            return localVarFp.adminPatchSettings(name, adminPatchSettingsDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminCreateSettings operation in AdminSettingsApi.
 * @export
 * @interface AdminSettingsApiAdminCreateSettingsRequest
 */
export interface AdminSettingsApiAdminCreateSettingsRequest {
    /**
     * adminCreateSettingsDTO
     * @type {AdminCreateSettingsDTO}
     * @memberof AdminSettingsApiAdminCreateSettings
     */
    readonly adminCreateSettingsDTO: AdminCreateSettingsDTO
}

/**
 * Request parameters for adminDeleteSettings operation in AdminSettingsApi.
 * @export
 * @interface AdminSettingsApiAdminDeleteSettingsRequest
 */
export interface AdminSettingsApiAdminDeleteSettingsRequest {
    /**
     * name
     * @type {string}
     * @memberof AdminSettingsApiAdminDeleteSettings
     */
    readonly name: string
}

/**
 * Request parameters for adminGetSettings operation in AdminSettingsApi.
 * @export
 * @interface AdminSettingsApiAdminGetSettingsRequest
 */
export interface AdminSettingsApiAdminGetSettingsRequest {
    /**
     * name
     * @type {string}
     * @memberof AdminSettingsApiAdminGetSettings
     */
    readonly name: string
}

/**
 * Request parameters for adminPatchSettings operation in AdminSettingsApi.
 * @export
 * @interface AdminSettingsApiAdminPatchSettingsRequest
 */
export interface AdminSettingsApiAdminPatchSettingsRequest {
    /**
     * name
     * @type {string}
     * @memberof AdminSettingsApiAdminPatchSettings
     */
    readonly name: string

    /**
     * adminPatchSettingsDTO
     * @type {AdminPatchSettingsDTO}
     * @memberof AdminSettingsApiAdminPatchSettings
     */
    readonly adminPatchSettingsDTO: AdminPatchSettingsDTO
}

/**
 * AdminSettingsApi - object-oriented interface
 * @export
 * @class AdminSettingsApi
 * @extends {BaseAPI}
 */
export class AdminSettingsApi extends BaseAPI {
    /**
     * 어드민 셋팅 생성
     * @summary adminCreateSettings
     * @param {AdminSettingsApiAdminCreateSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSettingsApi
     */
    public adminCreateSettings(requestParameters: AdminSettingsApiAdminCreateSettingsRequest, options?: any) {
        return AdminSettingsApiFp(this.configuration).adminCreateSettings(requestParameters.adminCreateSettingsDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 어드민 셋팅 삭제
     * @summary adminDeleteSettings
     * @param {AdminSettingsApiAdminDeleteSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSettingsApi
     */
    public adminDeleteSettings(requestParameters: AdminSettingsApiAdminDeleteSettingsRequest, options?: any) {
        return AdminSettingsApiFp(this.configuration).adminDeleteSettings(requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 어드민 셋팅 조회
     * @summary adminGetSettings
     * @param {AdminSettingsApiAdminGetSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSettingsApi
     */
    public adminGetSettings(requestParameters: AdminSettingsApiAdminGetSettingsRequest, options?: any) {
        return AdminSettingsApiFp(this.configuration).adminGetSettings(requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 어드민 셋팅 리스트
     * @summary adminListSettings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSettingsApi
     */
    public adminListSettings(options?: any) {
        return AdminSettingsApiFp(this.configuration).adminListSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 어드민 셋팅 수정
     * @summary adminPatchSettings
     * @param {AdminSettingsApiAdminPatchSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSettingsApi
     */
    public adminPatchSettings(requestParameters: AdminSettingsApiAdminPatchSettingsRequest, options?: any) {
        return AdminSettingsApiFp(this.configuration).adminPatchSettings(requestParameters.name, requestParameters.adminPatchSettingsDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AgreementControllerApi - axios parameter creator
 * @export
 */
export const AgreementControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getProductAgreementByMemberId
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductAgreementByMemberIdUsingGET: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductAgreementByMemberIdUsingGET', 'productId', productId)
            const localVarPath = `/api/agreement/product/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveAgreementLog
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAgreementLogUsingPOST: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('saveAgreementLogUsingPOST', 'productId', productId)
            const localVarPath = `/api/agreement/product/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgreementControllerApi - functional programming interface
 * @export
 */
export const AgreementControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgreementControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary getProductAgreementByMemberId
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductAgreementByMemberIdUsingGET(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductAgreementByMemberIdUsingGET(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary saveAgreementLog
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveAgreementLogUsingPOST(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveAgreementLogUsingPOST(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AgreementControllerApi - factory interface
 * @export
 */
export const AgreementControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgreementControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary getProductAgreementByMemberId
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductAgreementByMemberIdUsingGET(productId: number, options?: any): AxiosPromise<object> {
            return localVarFp.getProductAgreementByMemberIdUsingGET(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary saveAgreementLog
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAgreementLogUsingPOST(productId: number, options?: any): AxiosPromise<object> {
            return localVarFp.saveAgreementLogUsingPOST(productId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getProductAgreementByMemberIdUsingGET operation in AgreementControllerApi.
 * @export
 * @interface AgreementControllerApiGetProductAgreementByMemberIdUsingGETRequest
 */
export interface AgreementControllerApiGetProductAgreementByMemberIdUsingGETRequest {
    /**
     * productId
     * @type {number}
     * @memberof AgreementControllerApiGetProductAgreementByMemberIdUsingGET
     */
    readonly productId: number
}

/**
 * Request parameters for saveAgreementLogUsingPOST operation in AgreementControllerApi.
 * @export
 * @interface AgreementControllerApiSaveAgreementLogUsingPOSTRequest
 */
export interface AgreementControllerApiSaveAgreementLogUsingPOSTRequest {
    /**
     * productId
     * @type {number}
     * @memberof AgreementControllerApiSaveAgreementLogUsingPOST
     */
    readonly productId: number
}

/**
 * AgreementControllerApi - object-oriented interface
 * @export
 * @class AgreementControllerApi
 * @extends {BaseAPI}
 */
export class AgreementControllerApi extends BaseAPI {
    /**
     * 
     * @summary getProductAgreementByMemberId
     * @param {AgreementControllerApiGetProductAgreementByMemberIdUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementControllerApi
     */
    public getProductAgreementByMemberIdUsingGET(requestParameters: AgreementControllerApiGetProductAgreementByMemberIdUsingGETRequest, options?: any) {
        return AgreementControllerApiFp(this.configuration).getProductAgreementByMemberIdUsingGET(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary saveAgreementLog
     * @param {AgreementControllerApiSaveAgreementLogUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementControllerApi
     */
    public saveAgreementLogUsingPOST(requestParameters: AgreementControllerApiSaveAgreementLogUsingPOSTRequest, options?: any) {
        return AgreementControllerApiFp(this.configuration).saveAgreementLogUsingPOST(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommonEventControllerApi - axios parameter creator
 * @export
 */
export const CommonEventControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 이벤트정보
         * @summary eventInfo
         * @param {string} eventCode eventCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventInfoUsingGET: async (eventCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventCode' is not null or undefined
            assertParamExists('eventInfoUsingGET', 'eventCode', eventCode)
            const localVarPath = `/api/common/event/{eventCode}`
                .replace(`{${"eventCode"}}`, encodeURIComponent(String(eventCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 이벤트 리스트
         * @summary eventList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {string} [category] category
         * @param {string} [keyword] keyword
         * @param {boolean} [showDeleted] showDeleted
         * @param {'event_id_desc' | 'seller_count_desc'} [sortBy] sortBy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventList: async (page: number, pageSize: number, category?: string, keyword?: string, showDeleted?: boolean, sortBy?: 'event_id_desc' | 'seller_count_desc', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('eventList', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('eventList', 'pageSize', pageSize)
            const localVarPath = `/api/common/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (showDeleted !== undefined) {
                localVarQueryParameter['showDeleted'] = showDeleted;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 아티스트리스트
         * @summary eventArtistList
         * @param {string} eventCode eventCode
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {string} [eventCategory] eventCategory
         * @param {number} [partnerId] partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEventArtistUsingGET: async (eventCode: string, page: number, pageSize: number, eventCategory?: string, partnerId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventCode' is not null or undefined
            assertParamExists('listEventArtistUsingGET', 'eventCode', eventCode)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('listEventArtistUsingGET', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('listEventArtistUsingGET', 'pageSize', pageSize)
            const localVarPath = `/api/common/event/{eventCode}/artists`
                .replace(`{${"eventCode"}}`, encodeURIComponent(String(eventCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (eventCategory !== undefined) {
                localVarQueryParameter['eventCategory'] = eventCategory;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partnerId'] = partnerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 셀러리스트
         * @summary eventPartnerList
         * @param {string} eventCode eventCode
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {boolean} [buynowOnly] buynowOnly
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {string} [eventCategory] eventCategory
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEventPartnerUsingGET: async (eventCode: string, page: number, pageSize: number, buynowOnly?: boolean, enableBid?: boolean, enableBuyNow?: boolean, eventCategory?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventCode' is not null or undefined
            assertParamExists('listEventPartnerUsingGET', 'eventCode', eventCode)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('listEventPartnerUsingGET', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('listEventPartnerUsingGET', 'pageSize', pageSize)
            const localVarPath = `/api/common/event/{eventCode}/partners`
                .replace(`{${"eventCode"}}`, encodeURIComponent(String(eventCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (buynowOnly !== undefined) {
                localVarQueryParameter['buynowOnly'] = buynowOnly;
            }

            if (enableBid !== undefined) {
                localVarQueryParameter['enableBid'] = enableBid;
            }

            if (enableBuyNow !== undefined) {
                localVarQueryParameter['enableBuyNow'] = enableBuyNow;
            }

            if (eventCategory !== undefined) {
                localVarQueryParameter['eventCategory'] = eventCategory;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 최근이벤트
         * @summary topEventInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        topEventInfoUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/common/event/top`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommonEventControllerApi - functional programming interface
 * @export
 */
export const CommonEventControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommonEventControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 이벤트정보
         * @summary eventInfo
         * @param {string} eventCode eventCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventInfoUsingGET(eventCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventInfoUsingGET(eventCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 이벤트 리스트
         * @summary eventList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {string} [category] category
         * @param {string} [keyword] keyword
         * @param {boolean} [showDeleted] showDeleted
         * @param {'event_id_desc' | 'seller_count_desc'} [sortBy] sortBy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventList(page: number, pageSize: number, category?: string, keyword?: string, showDeleted?: boolean, sortBy?: 'event_id_desc' | 'seller_count_desc', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventList(page, pageSize, category, keyword, showDeleted, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 아티스트리스트
         * @summary eventArtistList
         * @param {string} eventCode eventCode
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {string} [eventCategory] eventCategory
         * @param {number} [partnerId] partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEventArtistUsingGET(eventCode: string, page: number, pageSize: number, eventCategory?: string, partnerId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEventArtistUsingGET(eventCode, page, pageSize, eventCategory, partnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 셀러리스트
         * @summary eventPartnerList
         * @param {string} eventCode eventCode
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {boolean} [buynowOnly] buynowOnly
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {string} [eventCategory] eventCategory
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEventPartnerUsingGET(eventCode: string, page: number, pageSize: number, buynowOnly?: boolean, enableBid?: boolean, enableBuyNow?: boolean, eventCategory?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEventPartnerUsingGET(eventCode, page, pageSize, buynowOnly, enableBid, enableBuyNow, eventCategory, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 최근이벤트
         * @summary topEventInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async topEventInfoUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.topEventInfoUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommonEventControllerApi - factory interface
 * @export
 */
export const CommonEventControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommonEventControllerApiFp(configuration)
    return {
        /**
         * 이벤트정보
         * @summary eventInfo
         * @param {string} eventCode eventCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventInfoUsingGET(eventCode: string, options?: any): AxiosPromise<object> {
            return localVarFp.eventInfoUsingGET(eventCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 이벤트 리스트
         * @summary eventList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {string} [category] category
         * @param {string} [keyword] keyword
         * @param {boolean} [showDeleted] showDeleted
         * @param {'event_id_desc' | 'seller_count_desc'} [sortBy] sortBy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventList(page: number, pageSize: number, category?: string, keyword?: string, showDeleted?: boolean, sortBy?: 'event_id_desc' | 'seller_count_desc', options?: any): AxiosPromise<object> {
            return localVarFp.eventList(page, pageSize, category, keyword, showDeleted, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 아티스트리스트
         * @summary eventArtistList
         * @param {string} eventCode eventCode
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {string} [eventCategory] eventCategory
         * @param {number} [partnerId] partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEventArtistUsingGET(eventCode: string, page: number, pageSize: number, eventCategory?: string, partnerId?: number, options?: any): AxiosPromise<object> {
            return localVarFp.listEventArtistUsingGET(eventCode, page, pageSize, eventCategory, partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 셀러리스트
         * @summary eventPartnerList
         * @param {string} eventCode eventCode
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {boolean} [buynowOnly] buynowOnly
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {string} [eventCategory] eventCategory
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEventPartnerUsingGET(eventCode: string, page: number, pageSize: number, buynowOnly?: boolean, enableBid?: boolean, enableBuyNow?: boolean, eventCategory?: string, options?: any): AxiosPromise<object> {
            return localVarFp.listEventPartnerUsingGET(eventCode, page, pageSize, buynowOnly, enableBid, enableBuyNow, eventCategory, options).then((request) => request(axios, basePath));
        },
        /**
         * 최근이벤트
         * @summary topEventInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        topEventInfoUsingGET(options?: any): AxiosPromise<object> {
            return localVarFp.topEventInfoUsingGET(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for eventInfoUsingGET operation in CommonEventControllerApi.
 * @export
 * @interface CommonEventControllerApiEventInfoUsingGETRequest
 */
export interface CommonEventControllerApiEventInfoUsingGETRequest {
    /**
     * eventCode
     * @type {string}
     * @memberof CommonEventControllerApiEventInfoUsingGET
     */
    readonly eventCode: string
}

/**
 * Request parameters for eventList operation in CommonEventControllerApi.
 * @export
 * @interface CommonEventControllerApiEventListRequest
 */
export interface CommonEventControllerApiEventListRequest {
    /**
     * page
     * @type {number}
     * @memberof CommonEventControllerApiEventList
     */
    readonly page: number

    /**
     * pageSize
     * @type {number}
     * @memberof CommonEventControllerApiEventList
     */
    readonly pageSize: number

    /**
     * category
     * @type {string}
     * @memberof CommonEventControllerApiEventList
     */
    readonly category?: string

    /**
     * keyword
     * @type {string}
     * @memberof CommonEventControllerApiEventList
     */
    readonly keyword?: string

    /**
     * showDeleted
     * @type {boolean}
     * @memberof CommonEventControllerApiEventList
     */
    readonly showDeleted?: boolean

    /**
     * sortBy
     * @type {'event_id_desc' | 'seller_count_desc'}
     * @memberof CommonEventControllerApiEventList
     */
    readonly sortBy?: 'event_id_desc' | 'seller_count_desc'
}

/**
 * Request parameters for listEventArtistUsingGET operation in CommonEventControllerApi.
 * @export
 * @interface CommonEventControllerApiListEventArtistUsingGETRequest
 */
export interface CommonEventControllerApiListEventArtistUsingGETRequest {
    /**
     * eventCode
     * @type {string}
     * @memberof CommonEventControllerApiListEventArtistUsingGET
     */
    readonly eventCode: string

    /**
     * page
     * @type {number}
     * @memberof CommonEventControllerApiListEventArtistUsingGET
     */
    readonly page: number

    /**
     * pageSize
     * @type {number}
     * @memberof CommonEventControllerApiListEventArtistUsingGET
     */
    readonly pageSize: number

    /**
     * eventCategory
     * @type {string}
     * @memberof CommonEventControllerApiListEventArtistUsingGET
     */
    readonly eventCategory?: string

    /**
     * partnerId
     * @type {number}
     * @memberof CommonEventControllerApiListEventArtistUsingGET
     */
    readonly partnerId?: number
}

/**
 * Request parameters for listEventPartnerUsingGET operation in CommonEventControllerApi.
 * @export
 * @interface CommonEventControllerApiListEventPartnerUsingGETRequest
 */
export interface CommonEventControllerApiListEventPartnerUsingGETRequest {
    /**
     * eventCode
     * @type {string}
     * @memberof CommonEventControllerApiListEventPartnerUsingGET
     */
    readonly eventCode: string

    /**
     * page
     * @type {number}
     * @memberof CommonEventControllerApiListEventPartnerUsingGET
     */
    readonly page: number

    /**
     * pageSize
     * @type {number}
     * @memberof CommonEventControllerApiListEventPartnerUsingGET
     */
    readonly pageSize: number

    /**
     * buynowOnly
     * @type {boolean}
     * @memberof CommonEventControllerApiListEventPartnerUsingGET
     */
    readonly buynowOnly?: boolean

    /**
     * enableBid
     * @type {boolean}
     * @memberof CommonEventControllerApiListEventPartnerUsingGET
     */
    readonly enableBid?: boolean

    /**
     * enableBuyNow
     * @type {boolean}
     * @memberof CommonEventControllerApiListEventPartnerUsingGET
     */
    readonly enableBuyNow?: boolean

    /**
     * eventCategory
     * @type {string}
     * @memberof CommonEventControllerApiListEventPartnerUsingGET
     */
    readonly eventCategory?: string
}

/**
 * CommonEventControllerApi - object-oriented interface
 * @export
 * @class CommonEventControllerApi
 * @extends {BaseAPI}
 */
export class CommonEventControllerApi extends BaseAPI {
    /**
     * 이벤트정보
     * @summary eventInfo
     * @param {CommonEventControllerApiEventInfoUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonEventControllerApi
     */
    public eventInfoUsingGET(requestParameters: CommonEventControllerApiEventInfoUsingGETRequest, options?: any) {
        return CommonEventControllerApiFp(this.configuration).eventInfoUsingGET(requestParameters.eventCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 이벤트 리스트
     * @summary eventList
     * @param {CommonEventControllerApiEventListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonEventControllerApi
     */
    public eventList(requestParameters: CommonEventControllerApiEventListRequest, options?: any) {
        return CommonEventControllerApiFp(this.configuration).eventList(requestParameters.page, requestParameters.pageSize, requestParameters.category, requestParameters.keyword, requestParameters.showDeleted, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 아티스트리스트
     * @summary eventArtistList
     * @param {CommonEventControllerApiListEventArtistUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonEventControllerApi
     */
    public listEventArtistUsingGET(requestParameters: CommonEventControllerApiListEventArtistUsingGETRequest, options?: any) {
        return CommonEventControllerApiFp(this.configuration).listEventArtistUsingGET(requestParameters.eventCode, requestParameters.page, requestParameters.pageSize, requestParameters.eventCategory, requestParameters.partnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 셀러리스트
     * @summary eventPartnerList
     * @param {CommonEventControllerApiListEventPartnerUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonEventControllerApi
     */
    public listEventPartnerUsingGET(requestParameters: CommonEventControllerApiListEventPartnerUsingGETRequest, options?: any) {
        return CommonEventControllerApiFp(this.configuration).listEventPartnerUsingGET(requestParameters.eventCode, requestParameters.page, requestParameters.pageSize, requestParameters.buynowOnly, requestParameters.enableBid, requestParameters.enableBuyNow, requestParameters.eventCategory, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 최근이벤트
     * @summary topEventInfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonEventControllerApi
     */
    public topEventInfoUsingGET(options?: any) {
        return CommonEventControllerApiFp(this.configuration).topEventInfoUsingGET(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommonLotControllerApi - axios parameter creator
 * @export
 */
export const CommonLotControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 입찰자 리스트
         * @summary biddingMember
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bidderListUsingGET: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('bidderListUsingGET', 'productId', productId)
            const localVarPath = `/api/common/lot/{productId}/bidders`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 카테고리 리스트
         * @summary categoryList
         * @param {number} depth depth
         * @param {number} [parentCategoryId] parentCategoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryListUsingGET: async (depth: number, parentCategoryId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'depth' is not null or undefined
            assertParamExists('categoryListUsingGET', 'depth', depth)
            const localVarPath = `/api/common/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (depth !== undefined) {
                localVarQueryParameter['depth'] = depth;
            }

            if (parentCategoryId !== undefined) {
                localVarQueryParameter['parentCategoryId '] = parentCategoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 종료예정경매리스트
         * @summary closingSoonLotList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {boolean} [buynowOnly] buynowOnly
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {number} [categoryDepth2Id] categoryDepth2Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {string} [keyword] keyword
         * @param {boolean} [makeOffer] makeOffer
         * @param {number} [partnerId] partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closingListUsingGET: async (page: number, pageSize: number, buynowOnly?: boolean, categoryDepth1Id?: number, categoryDepth2Id?: number, enableBid?: boolean, enableBuyNow?: boolean, keyword?: string, makeOffer?: boolean, partnerId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('closingListUsingGET', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('closingListUsingGET', 'pageSize', pageSize)
            const localVarPath = `/api/common/closing/lots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (buynowOnly !== undefined) {
                localVarQueryParameter['buynowOnly'] = buynowOnly;
            }

            if (categoryDepth1Id !== undefined) {
                localVarQueryParameter['categoryDepth1Id'] = categoryDepth1Id;
            }

            if (categoryDepth2Id !== undefined) {
                localVarQueryParameter['categoryDepth2Id'] = categoryDepth2Id;
            }

            if (enableBid !== undefined) {
                localVarQueryParameter['enableBid'] = enableBid;
            }

            if (enableBuyNow !== undefined) {
                localVarQueryParameter['enableBuyNow'] = enableBuyNow;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (makeOffer !== undefined) {
                localVarQueryParameter['makeOffer'] = makeOffer;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partnerId'] = partnerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 인기셀러리스트
         * @summary featuredSellers
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        featuredSellerListUsingGET: async (page: number, pageSize: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('featuredSellerListUsingGET', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('featuredSellerListUsingGET', 'pageSize', pageSize)
            const localVarPath = `/api/common/featured/sellers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 다음경매조회
         * @summary lotSingle
         * @param {number} productId productId
         * @param {number} [categoryDepth1id] categoryDepth1id
         * @param {number} [categoryDepth2id] categoryDepth2id
         * @param {number} [categoryDepth3id] categoryDepth3id
         * @param {string} [eventCode] eventCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextLotUsingGET: async (productId: number, categoryDepth1id?: number, categoryDepth2id?: number, categoryDepth3id?: number, eventCode?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getNextLotUsingGET', 'productId', productId)
            const localVarPath = `/api/common/lot/next`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (categoryDepth1id !== undefined) {
                localVarQueryParameter['categoryDepth1id'] = categoryDepth1id;
            }

            if (categoryDepth2id !== undefined) {
                localVarQueryParameter['categoryDepth2id'] = categoryDepth2id;
            }

            if (categoryDepth3id !== undefined) {
                localVarQueryParameter['categoryDepth3id'] = categoryDepth3id;
            }

            if (eventCode !== undefined) {
                localVarQueryParameter['eventCode'] = eventCode;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 셀러조회
         * @summary partner
         * @param {number} partnerId partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerUsingGET: async (partnerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('getPartnerUsingGET', 'partnerId', partnerId)
            const localVarPath = `/api/common/seller/{partnerId}`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 이전경매조회
         * @summary lotSingle
         * @param {number} productId productId
         * @param {number} [categoryDepth1id] categoryDepth1id
         * @param {number} [categoryDepth2id] categoryDepth2id
         * @param {number} [categoryDepth3id] categoryDepth3id
         * @param {string} [eventCode] eventCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrevLotUsingGET: async (productId: number, categoryDepth1id?: number, categoryDepth2id?: number, categoryDepth3id?: number, eventCode?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getPrevLotUsingGET', 'productId', productId)
            const localVarPath = `/api/common/lot/prev`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (categoryDepth1id !== undefined) {
                localVarQueryParameter['categoryDepth1id'] = categoryDepth1id;
            }

            if (categoryDepth2id !== undefined) {
                localVarQueryParameter['categoryDepth2id'] = categoryDepth2id;
            }

            if (categoryDepth3id !== undefined) {
                localVarQueryParameter['categoryDepth3id'] = categoryDepth3id;
            }

            if (eventCode !== undefined) {
                localVarQueryParameter['eventCode'] = eventCode;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 경매조회
         * @summary lotSingle
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingGET1: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getUsingGET1', 'productId', productId)
            const localVarPath = `/api/common/lot/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 경매조회
         * @summary lotSingle
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingGET6: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getUsingGET6', 'productId', productId)
            const localVarPath = `/api/common/seller/{partnerId}/lot/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 셀러리스트 모바일
         * @summary partnerListMobile
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPartnerMobileUsingGET: async (page: number, pageSize: number, categoryDepth1Id?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('listPartnerMobileUsingGET', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('listPartnerMobileUsingGET', 'pageSize', pageSize)
            const localVarPath = `/api/common/sellers/v2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (categoryDepth1Id !== undefined) {
                localVarQueryParameter['categoryDepth1Id'] = categoryDepth1Id;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 셀러리스트
         * @summary partnerList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPartnerUsingGET: async (page: number, pageSize: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('listPartnerUsingGET', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('listPartnerUsingGET', 'pageSize', pageSize)
            const localVarPath = `/api/common/sellers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 경매리스트
         * @summary lotList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {string} [artist] artist
         * @param {boolean} [buynowOnly] buynowOnly
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {number} [categoryDepth2Id] categoryDepth2Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {boolean} [excludingEnd] excludingEnd
         * @param {string} [keyword] keyword
         * @param {boolean} [makeOffer] makeOffer
         * @param {'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc'} [orderby] orderby
         * @param {number} [partnerId] partnerId
         * @param {string} [period] period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingGET: async (page: number, pageSize: number, artist?: string, buynowOnly?: boolean, categoryDepth1Id?: number, categoryDepth2Id?: number, enableBid?: boolean, enableBuyNow?: boolean, excludingEnd?: boolean, keyword?: string, makeOffer?: boolean, orderby?: 'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc', partnerId?: number, period?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('listUsingGET', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('listUsingGET', 'pageSize', pageSize)
            const localVarPath = `/api/common/lots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (buynowOnly !== undefined) {
                localVarQueryParameter['buynowOnly'] = buynowOnly;
            }

            if (categoryDepth1Id !== undefined) {
                localVarQueryParameter['categoryDepth1Id'] = categoryDepth1Id;
            }

            if (categoryDepth2Id !== undefined) {
                localVarQueryParameter['categoryDepth2Id'] = categoryDepth2Id;
            }

            if (enableBid !== undefined) {
                localVarQueryParameter['enableBid'] = enableBid;
            }

            if (enableBuyNow !== undefined) {
                localVarQueryParameter['enableBuyNow'] = enableBuyNow;
            }

            if (excludingEnd !== undefined) {
                localVarQueryParameter['excludingEnd'] = excludingEnd;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (makeOffer !== undefined) {
                localVarQueryParameter['makeOffer'] = makeOffer;
            }

            if (orderby !== undefined) {
                localVarQueryParameter['orderby'] = orderby;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partnerId'] = partnerId;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 경매리스트
         * @summary lotList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {number} partnerId partnerId
         * @param {string} [artist] artist
         * @param {boolean} [buynowOnly] buynowOnly
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {number} [categoryDepth2Id] categoryDepth2Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {boolean} [excludingEnd] excludingEnd
         * @param {string} [keyword] keyword
         * @param {boolean} [makeOffer] makeOffer
         * @param {'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc'} [orderby] orderby
         * @param {string} [period] period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingGET5: async (page: number, pageSize: number, partnerId: number, artist?: string, buynowOnly?: boolean, categoryDepth1Id?: number, categoryDepth2Id?: number, enableBid?: boolean, enableBuyNow?: boolean, excludingEnd?: boolean, keyword?: string, makeOffer?: boolean, orderby?: 'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc', period?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('listUsingGET5', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('listUsingGET5', 'pageSize', pageSize)
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('listUsingGET5', 'partnerId', partnerId)
            const localVarPath = `/api/common/seller/{partnerId}/lots`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (buynowOnly !== undefined) {
                localVarQueryParameter['buynowOnly'] = buynowOnly;
            }

            if (categoryDepth1Id !== undefined) {
                localVarQueryParameter['categoryDepth1Id'] = categoryDepth1Id;
            }

            if (categoryDepth2Id !== undefined) {
                localVarQueryParameter['categoryDepth2Id'] = categoryDepth2Id;
            }

            if (enableBid !== undefined) {
                localVarQueryParameter['enableBid'] = enableBid;
            }

            if (enableBuyNow !== undefined) {
                localVarQueryParameter['enableBuyNow'] = enableBuyNow;
            }

            if (excludingEnd !== undefined) {
                localVarQueryParameter['excludingEnd'] = excludingEnd;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (makeOffer !== undefined) {
                localVarQueryParameter['makeOffer'] = makeOffer;
            }

            if (orderby !== undefined) {
                localVarQueryParameter['orderby'] = orderby;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 즉시가/현재가/마감시간/응찰횟수 리스트
         * @summary lotInfoList
         * @param {Array<number>} lotIds lotIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lotInfoList: async (lotIds: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'lotIds' is not null or undefined
            assertParamExists('lotInfoList', 'lotIds', lotIds)
            const localVarPath = `/api/common/lot/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (lotIds) {
                localVarQueryParameter['lotIds'] = lotIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 모바일경매리스트
         * @summary mobileLotList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {number} partnerId partnerId
         * @param {string} [artist] artist
         * @param {boolean} [bid] bid
         * @param {boolean} [bidAndBuynow] bidAndBuynow
         * @param {boolean} [buynowOnly] buynowOnly
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {number} [categoryDepth2Id] categoryDepth2Id
         * @param {string} [eventCategory] eventCategory
         * @param {string} [eventCode] eventCode
         * @param {boolean} [excludingEnd] excludingEnd
         * @param {string} [keyword] keyword
         * @param {boolean} [makeOffer] makeOffer
         * @param {'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc'} [orderby] orderby
         * @param {string} [period] period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileLotList: async (page: number, pageSize: number, partnerId: number, artist?: string, bid?: boolean, bidAndBuynow?: boolean, buynowOnly?: boolean, categoryDepth1Id?: number, categoryDepth2Id?: number, eventCategory?: string, eventCode?: string, excludingEnd?: boolean, keyword?: string, makeOffer?: boolean, orderby?: 'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc', period?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('mobileLotList', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('mobileLotList', 'pageSize', pageSize)
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('mobileLotList', 'partnerId', partnerId)
            const localVarPath = `/api/common/seller/{partnerId}/lots/v2`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (bid !== undefined) {
                localVarQueryParameter['bid'] = bid;
            }

            if (bidAndBuynow !== undefined) {
                localVarQueryParameter['bidAndBuynow'] = bidAndBuynow;
            }

            if (buynowOnly !== undefined) {
                localVarQueryParameter['buynowOnly'] = buynowOnly;
            }

            if (categoryDepth1Id !== undefined) {
                localVarQueryParameter['categoryDepth1Id'] = categoryDepth1Id;
            }

            if (categoryDepth2Id !== undefined) {
                localVarQueryParameter['categoryDepth2Id'] = categoryDepth2Id;
            }

            if (eventCategory !== undefined) {
                localVarQueryParameter['eventCategory'] = eventCategory;
            }

            if (eventCode !== undefined) {
                localVarQueryParameter['eventCode'] = eventCode;
            }

            if (excludingEnd !== undefined) {
                localVarQueryParameter['excludingEnd'] = excludingEnd;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (makeOffer !== undefined) {
                localVarQueryParameter['makeOffer'] = makeOffer;
            }

            if (orderby !== undefined) {
                localVarQueryParameter['orderby'] = orderby;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 옵션 리스트 ( type : period , status , country , artist , medium )
         * @summary optionList
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionListUsingGET: async (type?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/common/options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 경매검색리스트
         * @summary lotSearchList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {string} [artist] artist
         * @param {boolean} [bid] bid
         * @param {boolean} [bidAndBuynow] bidAndBuynow
         * @param {boolean} [buynowOnly] buynowOnly
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {number} [categoryDepth2Id] categoryDepth2Id
         * @param {string} [eventCategory] eventCategory
         * @param {string} [eventCode] eventCode
         * @param {boolean} [excludingEnd] excludingEnd
         * @param {string} [keyword] keyword
         * @param {boolean} [makeOffer] makeOffer
         * @param {'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc'} [orderby] orderby
         * @param {number} [partnerId] partnerId
         * @param {string} [period] period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsingGET: async (page: number, pageSize: number, artist?: string, bid?: boolean, bidAndBuynow?: boolean, buynowOnly?: boolean, categoryDepth1Id?: number, categoryDepth2Id?: number, eventCategory?: string, eventCode?: string, excludingEnd?: boolean, keyword?: string, makeOffer?: boolean, orderby?: 'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc', partnerId?: number, period?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('searchUsingGET', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('searchUsingGET', 'pageSize', pageSize)
            const localVarPath = `/api/common/lots/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (artist !== undefined) {
                localVarQueryParameter['artist'] = artist;
            }

            if (bid !== undefined) {
                localVarQueryParameter['bid'] = bid;
            }

            if (bidAndBuynow !== undefined) {
                localVarQueryParameter['bidAndBuynow'] = bidAndBuynow;
            }

            if (buynowOnly !== undefined) {
                localVarQueryParameter['buynowOnly'] = buynowOnly;
            }

            if (categoryDepth1Id !== undefined) {
                localVarQueryParameter['categoryDepth1Id'] = categoryDepth1Id;
            }

            if (categoryDepth2Id !== undefined) {
                localVarQueryParameter['categoryDepth2Id'] = categoryDepth2Id;
            }

            if (eventCategory !== undefined) {
                localVarQueryParameter['eventCategory'] = eventCategory;
            }

            if (eventCode !== undefined) {
                localVarQueryParameter['eventCode'] = eventCode;
            }

            if (excludingEnd !== undefined) {
                localVarQueryParameter['excludingEnd'] = excludingEnd;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (makeOffer !== undefined) {
                localVarQueryParameter['makeOffer'] = makeOffer;
            }

            if (orderby !== undefined) {
                localVarQueryParameter['orderby'] = orderby;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partnerId'] = partnerId;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 임시 등록상품 미리보기
         * @summary tempProductPreview
         * @param {number} productTempId productTempId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tempProductPreviewUsingGET: async (productTempId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productTempId' is not null or undefined
            assertParamExists('tempProductPreviewUsingGET', 'productTempId', productTempId)
            const localVarPath = `/api/common/lot/preview/{productTempId}`
                .replace(`{${"productTempId"}}`, encodeURIComponent(String(productTempId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary testBatch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testBatchUsingPOST: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/common/test-batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommonLotControllerApi - functional programming interface
 * @export
 */
export const CommonLotControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommonLotControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 입찰자 리스트
         * @summary biddingMember
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bidderListUsingGET(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bidderListUsingGET(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 카테고리 리스트
         * @summary categoryList
         * @param {number} depth depth
         * @param {number} [parentCategoryId] parentCategoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryListUsingGET(depth: number, parentCategoryId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoryListUsingGET(depth, parentCategoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 종료예정경매리스트
         * @summary closingSoonLotList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {boolean} [buynowOnly] buynowOnly
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {number} [categoryDepth2Id] categoryDepth2Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {string} [keyword] keyword
         * @param {boolean} [makeOffer] makeOffer
         * @param {number} [partnerId] partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async closingListUsingGET(page: number, pageSize: number, buynowOnly?: boolean, categoryDepth1Id?: number, categoryDepth2Id?: number, enableBid?: boolean, enableBuyNow?: boolean, keyword?: string, makeOffer?: boolean, partnerId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.closingListUsingGET(page, pageSize, buynowOnly, categoryDepth1Id, categoryDepth2Id, enableBid, enableBuyNow, keyword, makeOffer, partnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 인기셀러리스트
         * @summary featuredSellers
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async featuredSellerListUsingGET(page: number, pageSize: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.featuredSellerListUsingGET(page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 다음경매조회
         * @summary lotSingle
         * @param {number} productId productId
         * @param {number} [categoryDepth1id] categoryDepth1id
         * @param {number} [categoryDepth2id] categoryDepth2id
         * @param {number} [categoryDepth3id] categoryDepth3id
         * @param {string} [eventCode] eventCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNextLotUsingGET(productId: number, categoryDepth1id?: number, categoryDepth2id?: number, categoryDepth3id?: number, eventCode?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNextLotUsingGET(productId, categoryDepth1id, categoryDepth2id, categoryDepth3id, eventCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 셀러조회
         * @summary partner
         * @param {number} partnerId partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPartnerUsingGET(partnerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPartnerUsingGET(partnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 이전경매조회
         * @summary lotSingle
         * @param {number} productId productId
         * @param {number} [categoryDepth1id] categoryDepth1id
         * @param {number} [categoryDepth2id] categoryDepth2id
         * @param {number} [categoryDepth3id] categoryDepth3id
         * @param {string} [eventCode] eventCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrevLotUsingGET(productId: number, categoryDepth1id?: number, categoryDepth2id?: number, categoryDepth3id?: number, eventCode?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrevLotUsingGET(productId, categoryDepth1id, categoryDepth2id, categoryDepth3id, eventCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 경매조회
         * @summary lotSingle
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsingGET1(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsingGET1(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 경매조회
         * @summary lotSingle
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsingGET6(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsingGET6(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 셀러리스트 모바일
         * @summary partnerListMobile
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPartnerMobileUsingGET(page: number, pageSize: number, categoryDepth1Id?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPartnerMobileUsingGET(page, pageSize, categoryDepth1Id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 셀러리스트
         * @summary partnerList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPartnerUsingGET(page: number, pageSize: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPartnerUsingGET(page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 경매리스트
         * @summary lotList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {string} [artist] artist
         * @param {boolean} [buynowOnly] buynowOnly
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {number} [categoryDepth2Id] categoryDepth2Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {boolean} [excludingEnd] excludingEnd
         * @param {string} [keyword] keyword
         * @param {boolean} [makeOffer] makeOffer
         * @param {'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc'} [orderby] orderby
         * @param {number} [partnerId] partnerId
         * @param {string} [period] period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsingGET(page: number, pageSize: number, artist?: string, buynowOnly?: boolean, categoryDepth1Id?: number, categoryDepth2Id?: number, enableBid?: boolean, enableBuyNow?: boolean, excludingEnd?: boolean, keyword?: string, makeOffer?: boolean, orderby?: 'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc', partnerId?: number, period?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsingGET(page, pageSize, artist, buynowOnly, categoryDepth1Id, categoryDepth2Id, enableBid, enableBuyNow, excludingEnd, keyword, makeOffer, orderby, partnerId, period, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 경매리스트
         * @summary lotList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {number} partnerId partnerId
         * @param {string} [artist] artist
         * @param {boolean} [buynowOnly] buynowOnly
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {number} [categoryDepth2Id] categoryDepth2Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {boolean} [excludingEnd] excludingEnd
         * @param {string} [keyword] keyword
         * @param {boolean} [makeOffer] makeOffer
         * @param {'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc'} [orderby] orderby
         * @param {string} [period] period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsingGET5(page: number, pageSize: number, partnerId: number, artist?: string, buynowOnly?: boolean, categoryDepth1Id?: number, categoryDepth2Id?: number, enableBid?: boolean, enableBuyNow?: boolean, excludingEnd?: boolean, keyword?: string, makeOffer?: boolean, orderby?: 'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc', period?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsingGET5(page, pageSize, partnerId, artist, buynowOnly, categoryDepth1Id, categoryDepth2Id, enableBid, enableBuyNow, excludingEnd, keyword, makeOffer, orderby, period, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 즉시가/현재가/마감시간/응찰횟수 리스트
         * @summary lotInfoList
         * @param {Array<number>} lotIds lotIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lotInfoList(lotIds: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lotInfoList(lotIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 모바일경매리스트
         * @summary mobileLotList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {number} partnerId partnerId
         * @param {string} [artist] artist
         * @param {boolean} [bid] bid
         * @param {boolean} [bidAndBuynow] bidAndBuynow
         * @param {boolean} [buynowOnly] buynowOnly
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {number} [categoryDepth2Id] categoryDepth2Id
         * @param {string} [eventCategory] eventCategory
         * @param {string} [eventCode] eventCode
         * @param {boolean} [excludingEnd] excludingEnd
         * @param {string} [keyword] keyword
         * @param {boolean} [makeOffer] makeOffer
         * @param {'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc'} [orderby] orderby
         * @param {string} [period] period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileLotList(page: number, pageSize: number, partnerId: number, artist?: string, bid?: boolean, bidAndBuynow?: boolean, buynowOnly?: boolean, categoryDepth1Id?: number, categoryDepth2Id?: number, eventCategory?: string, eventCode?: string, excludingEnd?: boolean, keyword?: string, makeOffer?: boolean, orderby?: 'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc', period?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileLotList(page, pageSize, partnerId, artist, bid, bidAndBuynow, buynowOnly, categoryDepth1Id, categoryDepth2Id, eventCategory, eventCode, excludingEnd, keyword, makeOffer, orderby, period, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 옵션 리스트 ( type : period , status , country , artist , medium )
         * @summary optionList
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionListUsingGET(type?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionListUsingGET(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 경매검색리스트
         * @summary lotSearchList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {string} [artist] artist
         * @param {boolean} [bid] bid
         * @param {boolean} [bidAndBuynow] bidAndBuynow
         * @param {boolean} [buynowOnly] buynowOnly
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {number} [categoryDepth2Id] categoryDepth2Id
         * @param {string} [eventCategory] eventCategory
         * @param {string} [eventCode] eventCode
         * @param {boolean} [excludingEnd] excludingEnd
         * @param {string} [keyword] keyword
         * @param {boolean} [makeOffer] makeOffer
         * @param {'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc'} [orderby] orderby
         * @param {number} [partnerId] partnerId
         * @param {string} [period] period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchUsingGET(page: number, pageSize: number, artist?: string, bid?: boolean, bidAndBuynow?: boolean, buynowOnly?: boolean, categoryDepth1Id?: number, categoryDepth2Id?: number, eventCategory?: string, eventCode?: string, excludingEnd?: boolean, keyword?: string, makeOffer?: boolean, orderby?: 'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc', partnerId?: number, period?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchUsingGET(page, pageSize, artist, bid, bidAndBuynow, buynowOnly, categoryDepth1Id, categoryDepth2Id, eventCategory, eventCode, excludingEnd, keyword, makeOffer, orderby, partnerId, period, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 임시 등록상품 미리보기
         * @summary tempProductPreview
         * @param {number} productTempId productTempId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tempProductPreviewUsingGET(productTempId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tempProductPreviewUsingGET(productTempId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary testBatch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testBatchUsingPOST(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testBatchUsingPOST(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommonLotControllerApi - factory interface
 * @export
 */
export const CommonLotControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommonLotControllerApiFp(configuration)
    return {
        /**
         * 입찰자 리스트
         * @summary biddingMember
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bidderListUsingGET(productId: number, options?: any): AxiosPromise<object> {
            return localVarFp.bidderListUsingGET(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 카테고리 리스트
         * @summary categoryList
         * @param {number} depth depth
         * @param {number} [parentCategoryId] parentCategoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryListUsingGET(depth: number, parentCategoryId?: number, options?: any): AxiosPromise<object> {
            return localVarFp.categoryListUsingGET(depth, parentCategoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 종료예정경매리스트
         * @summary closingSoonLotList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {boolean} [buynowOnly] buynowOnly
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {number} [categoryDepth2Id] categoryDepth2Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {string} [keyword] keyword
         * @param {boolean} [makeOffer] makeOffer
         * @param {number} [partnerId] partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closingListUsingGET(page: number, pageSize: number, buynowOnly?: boolean, categoryDepth1Id?: number, categoryDepth2Id?: number, enableBid?: boolean, enableBuyNow?: boolean, keyword?: string, makeOffer?: boolean, partnerId?: number, options?: any): AxiosPromise<object> {
            return localVarFp.closingListUsingGET(page, pageSize, buynowOnly, categoryDepth1Id, categoryDepth2Id, enableBid, enableBuyNow, keyword, makeOffer, partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 인기셀러리스트
         * @summary featuredSellers
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        featuredSellerListUsingGET(page: number, pageSize: number, options?: any): AxiosPromise<object> {
            return localVarFp.featuredSellerListUsingGET(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 다음경매조회
         * @summary lotSingle
         * @param {number} productId productId
         * @param {number} [categoryDepth1id] categoryDepth1id
         * @param {number} [categoryDepth2id] categoryDepth2id
         * @param {number} [categoryDepth3id] categoryDepth3id
         * @param {string} [eventCode] eventCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextLotUsingGET(productId: number, categoryDepth1id?: number, categoryDepth2id?: number, categoryDepth3id?: number, eventCode?: string, options?: any): AxiosPromise<object> {
            return localVarFp.getNextLotUsingGET(productId, categoryDepth1id, categoryDepth2id, categoryDepth3id, eventCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 셀러조회
         * @summary partner
         * @param {number} partnerId partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerUsingGET(partnerId: number, options?: any): AxiosPromise<object> {
            return localVarFp.getPartnerUsingGET(partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 이전경매조회
         * @summary lotSingle
         * @param {number} productId productId
         * @param {number} [categoryDepth1id] categoryDepth1id
         * @param {number} [categoryDepth2id] categoryDepth2id
         * @param {number} [categoryDepth3id] categoryDepth3id
         * @param {string} [eventCode] eventCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrevLotUsingGET(productId: number, categoryDepth1id?: number, categoryDepth2id?: number, categoryDepth3id?: number, eventCode?: string, options?: any): AxiosPromise<object> {
            return localVarFp.getPrevLotUsingGET(productId, categoryDepth1id, categoryDepth2id, categoryDepth3id, eventCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 경매조회
         * @summary lotSingle
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingGET1(productId: number, options?: any): AxiosPromise<object> {
            return localVarFp.getUsingGET1(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 경매조회
         * @summary lotSingle
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingGET6(productId: number, options?: any): AxiosPromise<object> {
            return localVarFp.getUsingGET6(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 셀러리스트 모바일
         * @summary partnerListMobile
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPartnerMobileUsingGET(page: number, pageSize: number, categoryDepth1Id?: number, options?: any): AxiosPromise<object> {
            return localVarFp.listPartnerMobileUsingGET(page, pageSize, categoryDepth1Id, options).then((request) => request(axios, basePath));
        },
        /**
         * 셀러리스트
         * @summary partnerList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPartnerUsingGET(page: number, pageSize: number, options?: any): AxiosPromise<object> {
            return localVarFp.listPartnerUsingGET(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 경매리스트
         * @summary lotList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {string} [artist] artist
         * @param {boolean} [buynowOnly] buynowOnly
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {number} [categoryDepth2Id] categoryDepth2Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {boolean} [excludingEnd] excludingEnd
         * @param {string} [keyword] keyword
         * @param {boolean} [makeOffer] makeOffer
         * @param {'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc'} [orderby] orderby
         * @param {number} [partnerId] partnerId
         * @param {string} [period] period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingGET(page: number, pageSize: number, artist?: string, buynowOnly?: boolean, categoryDepth1Id?: number, categoryDepth2Id?: number, enableBid?: boolean, enableBuyNow?: boolean, excludingEnd?: boolean, keyword?: string, makeOffer?: boolean, orderby?: 'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc', partnerId?: number, period?: string, options?: any): AxiosPromise<object> {
            return localVarFp.listUsingGET(page, pageSize, artist, buynowOnly, categoryDepth1Id, categoryDepth2Id, enableBid, enableBuyNow, excludingEnd, keyword, makeOffer, orderby, partnerId, period, options).then((request) => request(axios, basePath));
        },
        /**
         * 경매리스트
         * @summary lotList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {number} partnerId partnerId
         * @param {string} [artist] artist
         * @param {boolean} [buynowOnly] buynowOnly
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {number} [categoryDepth2Id] categoryDepth2Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {boolean} [excludingEnd] excludingEnd
         * @param {string} [keyword] keyword
         * @param {boolean} [makeOffer] makeOffer
         * @param {'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc'} [orderby] orderby
         * @param {string} [period] period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingGET5(page: number, pageSize: number, partnerId: number, artist?: string, buynowOnly?: boolean, categoryDepth1Id?: number, categoryDepth2Id?: number, enableBid?: boolean, enableBuyNow?: boolean, excludingEnd?: boolean, keyword?: string, makeOffer?: boolean, orderby?: 'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc', period?: string, options?: any): AxiosPromise<object> {
            return localVarFp.listUsingGET5(page, pageSize, partnerId, artist, buynowOnly, categoryDepth1Id, categoryDepth2Id, enableBid, enableBuyNow, excludingEnd, keyword, makeOffer, orderby, period, options).then((request) => request(axios, basePath));
        },
        /**
         * 즉시가/현재가/마감시간/응찰횟수 리스트
         * @summary lotInfoList
         * @param {Array<number>} lotIds lotIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lotInfoList(lotIds: Array<number>, options?: any): AxiosPromise<object> {
            return localVarFp.lotInfoList(lotIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 모바일경매리스트
         * @summary mobileLotList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {number} partnerId partnerId
         * @param {string} [artist] artist
         * @param {boolean} [bid] bid
         * @param {boolean} [bidAndBuynow] bidAndBuynow
         * @param {boolean} [buynowOnly] buynowOnly
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {number} [categoryDepth2Id] categoryDepth2Id
         * @param {string} [eventCategory] eventCategory
         * @param {string} [eventCode] eventCode
         * @param {boolean} [excludingEnd] excludingEnd
         * @param {string} [keyword] keyword
         * @param {boolean} [makeOffer] makeOffer
         * @param {'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc'} [orderby] orderby
         * @param {string} [period] period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileLotList(page: number, pageSize: number, partnerId: number, artist?: string, bid?: boolean, bidAndBuynow?: boolean, buynowOnly?: boolean, categoryDepth1Id?: number, categoryDepth2Id?: number, eventCategory?: string, eventCode?: string, excludingEnd?: boolean, keyword?: string, makeOffer?: boolean, orderby?: 'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc', period?: string, options?: any): AxiosPromise<object> {
            return localVarFp.mobileLotList(page, pageSize, partnerId, artist, bid, bidAndBuynow, buynowOnly, categoryDepth1Id, categoryDepth2Id, eventCategory, eventCode, excludingEnd, keyword, makeOffer, orderby, period, options).then((request) => request(axios, basePath));
        },
        /**
         * 옵션 리스트 ( type : period , status , country , artist , medium )
         * @summary optionList
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionListUsingGET(type?: string, options?: any): AxiosPromise<object> {
            return localVarFp.optionListUsingGET(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 경매검색리스트
         * @summary lotSearchList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {string} [artist] artist
         * @param {boolean} [bid] bid
         * @param {boolean} [bidAndBuynow] bidAndBuynow
         * @param {boolean} [buynowOnly] buynowOnly
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {number} [categoryDepth2Id] categoryDepth2Id
         * @param {string} [eventCategory] eventCategory
         * @param {string} [eventCode] eventCode
         * @param {boolean} [excludingEnd] excludingEnd
         * @param {string} [keyword] keyword
         * @param {boolean} [makeOffer] makeOffer
         * @param {'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc'} [orderby] orderby
         * @param {number} [partnerId] partnerId
         * @param {string} [period] period
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsingGET(page: number, pageSize: number, artist?: string, bid?: boolean, bidAndBuynow?: boolean, buynowOnly?: boolean, categoryDepth1Id?: number, categoryDepth2Id?: number, eventCategory?: string, eventCode?: string, excludingEnd?: boolean, keyword?: string, makeOffer?: boolean, orderby?: 'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc', partnerId?: number, period?: string, options?: any): AxiosPromise<object> {
            return localVarFp.searchUsingGET(page, pageSize, artist, bid, bidAndBuynow, buynowOnly, categoryDepth1Id, categoryDepth2Id, eventCategory, eventCode, excludingEnd, keyword, makeOffer, orderby, partnerId, period, options).then((request) => request(axios, basePath));
        },
        /**
         * 임시 등록상품 미리보기
         * @summary tempProductPreview
         * @param {number} productTempId productTempId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tempProductPreviewUsingGET(productTempId: number, options?: any): AxiosPromise<object> {
            return localVarFp.tempProductPreviewUsingGET(productTempId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary testBatch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testBatchUsingPOST(options?: any): AxiosPromise<void> {
            return localVarFp.testBatchUsingPOST(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for bidderListUsingGET operation in CommonLotControllerApi.
 * @export
 * @interface CommonLotControllerApiBidderListUsingGETRequest
 */
export interface CommonLotControllerApiBidderListUsingGETRequest {
    /**
     * productId
     * @type {number}
     * @memberof CommonLotControllerApiBidderListUsingGET
     */
    readonly productId: number
}

/**
 * Request parameters for categoryListUsingGET operation in CommonLotControllerApi.
 * @export
 * @interface CommonLotControllerApiCategoryListUsingGETRequest
 */
export interface CommonLotControllerApiCategoryListUsingGETRequest {
    /**
     * depth
     * @type {number}
     * @memberof CommonLotControllerApiCategoryListUsingGET
     */
    readonly depth: number

    /**
     * parentCategoryId 
     * @type {number}
     * @memberof CommonLotControllerApiCategoryListUsingGET
     */
    readonly parentCategoryId?: number
}

/**
 * Request parameters for closingListUsingGET operation in CommonLotControllerApi.
 * @export
 * @interface CommonLotControllerApiClosingListUsingGETRequest
 */
export interface CommonLotControllerApiClosingListUsingGETRequest {
    /**
     * page
     * @type {number}
     * @memberof CommonLotControllerApiClosingListUsingGET
     */
    readonly page: number

    /**
     * pageSize
     * @type {number}
     * @memberof CommonLotControllerApiClosingListUsingGET
     */
    readonly pageSize: number

    /**
     * buynowOnly
     * @type {boolean}
     * @memberof CommonLotControllerApiClosingListUsingGET
     */
    readonly buynowOnly?: boolean

    /**
     * categoryDepth1Id
     * @type {number}
     * @memberof CommonLotControllerApiClosingListUsingGET
     */
    readonly categoryDepth1Id?: number

    /**
     * categoryDepth2Id
     * @type {number}
     * @memberof CommonLotControllerApiClosingListUsingGET
     */
    readonly categoryDepth2Id?: number

    /**
     * enableBid
     * @type {boolean}
     * @memberof CommonLotControllerApiClosingListUsingGET
     */
    readonly enableBid?: boolean

    /**
     * enableBuyNow
     * @type {boolean}
     * @memberof CommonLotControllerApiClosingListUsingGET
     */
    readonly enableBuyNow?: boolean

    /**
     * keyword
     * @type {string}
     * @memberof CommonLotControllerApiClosingListUsingGET
     */
    readonly keyword?: string

    /**
     * makeOffer
     * @type {boolean}
     * @memberof CommonLotControllerApiClosingListUsingGET
     */
    readonly makeOffer?: boolean

    /**
     * partnerId
     * @type {number}
     * @memberof CommonLotControllerApiClosingListUsingGET
     */
    readonly partnerId?: number
}

/**
 * Request parameters for featuredSellerListUsingGET operation in CommonLotControllerApi.
 * @export
 * @interface CommonLotControllerApiFeaturedSellerListUsingGETRequest
 */
export interface CommonLotControllerApiFeaturedSellerListUsingGETRequest {
    /**
     * page
     * @type {number}
     * @memberof CommonLotControllerApiFeaturedSellerListUsingGET
     */
    readonly page: number

    /**
     * pageSize
     * @type {number}
     * @memberof CommonLotControllerApiFeaturedSellerListUsingGET
     */
    readonly pageSize: number
}

/**
 * Request parameters for getNextLotUsingGET operation in CommonLotControllerApi.
 * @export
 * @interface CommonLotControllerApiGetNextLotUsingGETRequest
 */
export interface CommonLotControllerApiGetNextLotUsingGETRequest {
    /**
     * productId
     * @type {number}
     * @memberof CommonLotControllerApiGetNextLotUsingGET
     */
    readonly productId: number

    /**
     * categoryDepth1id
     * @type {number}
     * @memberof CommonLotControllerApiGetNextLotUsingGET
     */
    readonly categoryDepth1id?: number

    /**
     * categoryDepth2id
     * @type {number}
     * @memberof CommonLotControllerApiGetNextLotUsingGET
     */
    readonly categoryDepth2id?: number

    /**
     * categoryDepth3id
     * @type {number}
     * @memberof CommonLotControllerApiGetNextLotUsingGET
     */
    readonly categoryDepth3id?: number

    /**
     * eventCode
     * @type {string}
     * @memberof CommonLotControllerApiGetNextLotUsingGET
     */
    readonly eventCode?: string
}

/**
 * Request parameters for getPartnerUsingGET operation in CommonLotControllerApi.
 * @export
 * @interface CommonLotControllerApiGetPartnerUsingGETRequest
 */
export interface CommonLotControllerApiGetPartnerUsingGETRequest {
    /**
     * partnerId
     * @type {number}
     * @memberof CommonLotControllerApiGetPartnerUsingGET
     */
    readonly partnerId: number
}

/**
 * Request parameters for getPrevLotUsingGET operation in CommonLotControllerApi.
 * @export
 * @interface CommonLotControllerApiGetPrevLotUsingGETRequest
 */
export interface CommonLotControllerApiGetPrevLotUsingGETRequest {
    /**
     * productId
     * @type {number}
     * @memberof CommonLotControllerApiGetPrevLotUsingGET
     */
    readonly productId: number

    /**
     * categoryDepth1id
     * @type {number}
     * @memberof CommonLotControllerApiGetPrevLotUsingGET
     */
    readonly categoryDepth1id?: number

    /**
     * categoryDepth2id
     * @type {number}
     * @memberof CommonLotControllerApiGetPrevLotUsingGET
     */
    readonly categoryDepth2id?: number

    /**
     * categoryDepth3id
     * @type {number}
     * @memberof CommonLotControllerApiGetPrevLotUsingGET
     */
    readonly categoryDepth3id?: number

    /**
     * eventCode
     * @type {string}
     * @memberof CommonLotControllerApiGetPrevLotUsingGET
     */
    readonly eventCode?: string
}

/**
 * Request parameters for getUsingGET1 operation in CommonLotControllerApi.
 * @export
 * @interface CommonLotControllerApiGetUsingGET1Request
 */
export interface CommonLotControllerApiGetUsingGET1Request {
    /**
     * productId
     * @type {number}
     * @memberof CommonLotControllerApiGetUsingGET1
     */
    readonly productId: number
}

/**
 * Request parameters for getUsingGET6 operation in CommonLotControllerApi.
 * @export
 * @interface CommonLotControllerApiGetUsingGET6Request
 */
export interface CommonLotControllerApiGetUsingGET6Request {
    /**
     * productId
     * @type {number}
     * @memberof CommonLotControllerApiGetUsingGET6
     */
    readonly productId: number
}

/**
 * Request parameters for listPartnerMobileUsingGET operation in CommonLotControllerApi.
 * @export
 * @interface CommonLotControllerApiListPartnerMobileUsingGETRequest
 */
export interface CommonLotControllerApiListPartnerMobileUsingGETRequest {
    /**
     * page
     * @type {number}
     * @memberof CommonLotControllerApiListPartnerMobileUsingGET
     */
    readonly page: number

    /**
     * pageSize
     * @type {number}
     * @memberof CommonLotControllerApiListPartnerMobileUsingGET
     */
    readonly pageSize: number

    /**
     * categoryDepth1Id
     * @type {number}
     * @memberof CommonLotControllerApiListPartnerMobileUsingGET
     */
    readonly categoryDepth1Id?: number
}

/**
 * Request parameters for listPartnerUsingGET operation in CommonLotControllerApi.
 * @export
 * @interface CommonLotControllerApiListPartnerUsingGETRequest
 */
export interface CommonLotControllerApiListPartnerUsingGETRequest {
    /**
     * page
     * @type {number}
     * @memberof CommonLotControllerApiListPartnerUsingGET
     */
    readonly page: number

    /**
     * pageSize
     * @type {number}
     * @memberof CommonLotControllerApiListPartnerUsingGET
     */
    readonly pageSize: number
}

/**
 * Request parameters for listUsingGET operation in CommonLotControllerApi.
 * @export
 * @interface CommonLotControllerApiListUsingGETRequest
 */
export interface CommonLotControllerApiListUsingGETRequest {
    /**
     * page
     * @type {number}
     * @memberof CommonLotControllerApiListUsingGET
     */
    readonly page: number

    /**
     * pageSize
     * @type {number}
     * @memberof CommonLotControllerApiListUsingGET
     */
    readonly pageSize: number

    /**
     * artist
     * @type {string}
     * @memberof CommonLotControllerApiListUsingGET
     */
    readonly artist?: string

    /**
     * buynowOnly
     * @type {boolean}
     * @memberof CommonLotControllerApiListUsingGET
     */
    readonly buynowOnly?: boolean

    /**
     * categoryDepth1Id
     * @type {number}
     * @memberof CommonLotControllerApiListUsingGET
     */
    readonly categoryDepth1Id?: number

    /**
     * categoryDepth2Id
     * @type {number}
     * @memberof CommonLotControllerApiListUsingGET
     */
    readonly categoryDepth2Id?: number

    /**
     * enableBid
     * @type {boolean}
     * @memberof CommonLotControllerApiListUsingGET
     */
    readonly enableBid?: boolean

    /**
     * enableBuyNow
     * @type {boolean}
     * @memberof CommonLotControllerApiListUsingGET
     */
    readonly enableBuyNow?: boolean

    /**
     * excludingEnd
     * @type {boolean}
     * @memberof CommonLotControllerApiListUsingGET
     */
    readonly excludingEnd?: boolean

    /**
     * keyword
     * @type {string}
     * @memberof CommonLotControllerApiListUsingGET
     */
    readonly keyword?: string

    /**
     * makeOffer
     * @type {boolean}
     * @memberof CommonLotControllerApiListUsingGET
     */
    readonly makeOffer?: boolean

    /**
     * orderby
     * @type {'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc'}
     * @memberof CommonLotControllerApiListUsingGET
     */
    readonly orderby?: 'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc'

    /**
     * partnerId
     * @type {number}
     * @memberof CommonLotControllerApiListUsingGET
     */
    readonly partnerId?: number

    /**
     * period
     * @type {string}
     * @memberof CommonLotControllerApiListUsingGET
     */
    readonly period?: string
}

/**
 * Request parameters for listUsingGET5 operation in CommonLotControllerApi.
 * @export
 * @interface CommonLotControllerApiListUsingGET5Request
 */
export interface CommonLotControllerApiListUsingGET5Request {
    /**
     * page
     * @type {number}
     * @memberof CommonLotControllerApiListUsingGET5
     */
    readonly page: number

    /**
     * pageSize
     * @type {number}
     * @memberof CommonLotControllerApiListUsingGET5
     */
    readonly pageSize: number

    /**
     * partnerId
     * @type {number}
     * @memberof CommonLotControllerApiListUsingGET5
     */
    readonly partnerId: number

    /**
     * artist
     * @type {string}
     * @memberof CommonLotControllerApiListUsingGET5
     */
    readonly artist?: string

    /**
     * buynowOnly
     * @type {boolean}
     * @memberof CommonLotControllerApiListUsingGET5
     */
    readonly buynowOnly?: boolean

    /**
     * categoryDepth1Id
     * @type {number}
     * @memberof CommonLotControllerApiListUsingGET5
     */
    readonly categoryDepth1Id?: number

    /**
     * categoryDepth2Id
     * @type {number}
     * @memberof CommonLotControllerApiListUsingGET5
     */
    readonly categoryDepth2Id?: number

    /**
     * enableBid
     * @type {boolean}
     * @memberof CommonLotControllerApiListUsingGET5
     */
    readonly enableBid?: boolean

    /**
     * enableBuyNow
     * @type {boolean}
     * @memberof CommonLotControllerApiListUsingGET5
     */
    readonly enableBuyNow?: boolean

    /**
     * excludingEnd
     * @type {boolean}
     * @memberof CommonLotControllerApiListUsingGET5
     */
    readonly excludingEnd?: boolean

    /**
     * keyword
     * @type {string}
     * @memberof CommonLotControllerApiListUsingGET5
     */
    readonly keyword?: string

    /**
     * makeOffer
     * @type {boolean}
     * @memberof CommonLotControllerApiListUsingGET5
     */
    readonly makeOffer?: boolean

    /**
     * orderby
     * @type {'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc'}
     * @memberof CommonLotControllerApiListUsingGET5
     */
    readonly orderby?: 'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc'

    /**
     * period
     * @type {string}
     * @memberof CommonLotControllerApiListUsingGET5
     */
    readonly period?: string
}

/**
 * Request parameters for lotInfoList operation in CommonLotControllerApi.
 * @export
 * @interface CommonLotControllerApiLotInfoListRequest
 */
export interface CommonLotControllerApiLotInfoListRequest {
    /**
     * lotIds
     * @type {Array<number>}
     * @memberof CommonLotControllerApiLotInfoList
     */
    readonly lotIds: Array<number>
}

/**
 * Request parameters for mobileLotList operation in CommonLotControllerApi.
 * @export
 * @interface CommonLotControllerApiMobileLotListRequest
 */
export interface CommonLotControllerApiMobileLotListRequest {
    /**
     * page
     * @type {number}
     * @memberof CommonLotControllerApiMobileLotList
     */
    readonly page: number

    /**
     * pageSize
     * @type {number}
     * @memberof CommonLotControllerApiMobileLotList
     */
    readonly pageSize: number

    /**
     * partnerId
     * @type {number}
     * @memberof CommonLotControllerApiMobileLotList
     */
    readonly partnerId: number

    /**
     * artist
     * @type {string}
     * @memberof CommonLotControllerApiMobileLotList
     */
    readonly artist?: string

    /**
     * bid
     * @type {boolean}
     * @memberof CommonLotControllerApiMobileLotList
     */
    readonly bid?: boolean

    /**
     * bidAndBuynow
     * @type {boolean}
     * @memberof CommonLotControllerApiMobileLotList
     */
    readonly bidAndBuynow?: boolean

    /**
     * buynowOnly
     * @type {boolean}
     * @memberof CommonLotControllerApiMobileLotList
     */
    readonly buynowOnly?: boolean

    /**
     * categoryDepth1Id
     * @type {number}
     * @memberof CommonLotControllerApiMobileLotList
     */
    readonly categoryDepth1Id?: number

    /**
     * categoryDepth2Id
     * @type {number}
     * @memberof CommonLotControllerApiMobileLotList
     */
    readonly categoryDepth2Id?: number

    /**
     * eventCategory
     * @type {string}
     * @memberof CommonLotControllerApiMobileLotList
     */
    readonly eventCategory?: string

    /**
     * eventCode
     * @type {string}
     * @memberof CommonLotControllerApiMobileLotList
     */
    readonly eventCode?: string

    /**
     * excludingEnd
     * @type {boolean}
     * @memberof CommonLotControllerApiMobileLotList
     */
    readonly excludingEnd?: boolean

    /**
     * keyword
     * @type {string}
     * @memberof CommonLotControllerApiMobileLotList
     */
    readonly keyword?: string

    /**
     * makeOffer
     * @type {boolean}
     * @memberof CommonLotControllerApiMobileLotList
     */
    readonly makeOffer?: boolean

    /**
     * orderby
     * @type {'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc'}
     * @memberof CommonLotControllerApiMobileLotList
     */
    readonly orderby?: 'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc'

    /**
     * period
     * @type {string}
     * @memberof CommonLotControllerApiMobileLotList
     */
    readonly period?: string
}

/**
 * Request parameters for optionListUsingGET operation in CommonLotControllerApi.
 * @export
 * @interface CommonLotControllerApiOptionListUsingGETRequest
 */
export interface CommonLotControllerApiOptionListUsingGETRequest {
    /**
     * type
     * @type {string}
     * @memberof CommonLotControllerApiOptionListUsingGET
     */
    readonly type?: string
}

/**
 * Request parameters for searchUsingGET operation in CommonLotControllerApi.
 * @export
 * @interface CommonLotControllerApiSearchUsingGETRequest
 */
export interface CommonLotControllerApiSearchUsingGETRequest {
    /**
     * page
     * @type {number}
     * @memberof CommonLotControllerApiSearchUsingGET
     */
    readonly page: number

    /**
     * pageSize
     * @type {number}
     * @memberof CommonLotControllerApiSearchUsingGET
     */
    readonly pageSize: number

    /**
     * artist
     * @type {string}
     * @memberof CommonLotControllerApiSearchUsingGET
     */
    readonly artist?: string

    /**
     * bid
     * @type {boolean}
     * @memberof CommonLotControllerApiSearchUsingGET
     */
    readonly bid?: boolean

    /**
     * bidAndBuynow
     * @type {boolean}
     * @memberof CommonLotControllerApiSearchUsingGET
     */
    readonly bidAndBuynow?: boolean

    /**
     * buynowOnly
     * @type {boolean}
     * @memberof CommonLotControllerApiSearchUsingGET
     */
    readonly buynowOnly?: boolean

    /**
     * categoryDepth1Id
     * @type {number}
     * @memberof CommonLotControllerApiSearchUsingGET
     */
    readonly categoryDepth1Id?: number

    /**
     * categoryDepth2Id
     * @type {number}
     * @memberof CommonLotControllerApiSearchUsingGET
     */
    readonly categoryDepth2Id?: number

    /**
     * eventCategory
     * @type {string}
     * @memberof CommonLotControllerApiSearchUsingGET
     */
    readonly eventCategory?: string

    /**
     * eventCode
     * @type {string}
     * @memberof CommonLotControllerApiSearchUsingGET
     */
    readonly eventCode?: string

    /**
     * excludingEnd
     * @type {boolean}
     * @memberof CommonLotControllerApiSearchUsingGET
     */
    readonly excludingEnd?: boolean

    /**
     * keyword
     * @type {string}
     * @memberof CommonLotControllerApiSearchUsingGET
     */
    readonly keyword?: string

    /**
     * makeOffer
     * @type {boolean}
     * @memberof CommonLotControllerApiSearchUsingGET
     */
    readonly makeOffer?: boolean

    /**
     * orderby
     * @type {'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc'}
     * @memberof CommonLotControllerApiSearchUsingGET
     */
    readonly orderby?: 'buynow_price_asc' | 'buynow_price_desc' | 'imminent' | 'latest' | 'now_price_asc' | 'now_price_desc' | 'oldest' | 'partner_id_asc' | 'partner_id_desc' | 'recommend' | 'start_price_asc' | 'start_price_desc'

    /**
     * partnerId
     * @type {number}
     * @memberof CommonLotControllerApiSearchUsingGET
     */
    readonly partnerId?: number

    /**
     * period
     * @type {string}
     * @memberof CommonLotControllerApiSearchUsingGET
     */
    readonly period?: string
}

/**
 * Request parameters for tempProductPreviewUsingGET operation in CommonLotControllerApi.
 * @export
 * @interface CommonLotControllerApiTempProductPreviewUsingGETRequest
 */
export interface CommonLotControllerApiTempProductPreviewUsingGETRequest {
    /**
     * productTempId
     * @type {number}
     * @memberof CommonLotControllerApiTempProductPreviewUsingGET
     */
    readonly productTempId: number
}

/**
 * CommonLotControllerApi - object-oriented interface
 * @export
 * @class CommonLotControllerApi
 * @extends {BaseAPI}
 */
export class CommonLotControllerApi extends BaseAPI {
    /**
     * 입찰자 리스트
     * @summary biddingMember
     * @param {CommonLotControllerApiBidderListUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonLotControllerApi
     */
    public bidderListUsingGET(requestParameters: CommonLotControllerApiBidderListUsingGETRequest, options?: any) {
        return CommonLotControllerApiFp(this.configuration).bidderListUsingGET(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 카테고리 리스트
     * @summary categoryList
     * @param {CommonLotControllerApiCategoryListUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonLotControllerApi
     */
    public categoryListUsingGET(requestParameters: CommonLotControllerApiCategoryListUsingGETRequest, options?: any) {
        return CommonLotControllerApiFp(this.configuration).categoryListUsingGET(requestParameters.depth, requestParameters.parentCategoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 종료예정경매리스트
     * @summary closingSoonLotList
     * @param {CommonLotControllerApiClosingListUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonLotControllerApi
     */
    public closingListUsingGET(requestParameters: CommonLotControllerApiClosingListUsingGETRequest, options?: any) {
        return CommonLotControllerApiFp(this.configuration).closingListUsingGET(requestParameters.page, requestParameters.pageSize, requestParameters.buynowOnly, requestParameters.categoryDepth1Id, requestParameters.categoryDepth2Id, requestParameters.enableBid, requestParameters.enableBuyNow, requestParameters.keyword, requestParameters.makeOffer, requestParameters.partnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 인기셀러리스트
     * @summary featuredSellers
     * @param {CommonLotControllerApiFeaturedSellerListUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonLotControllerApi
     */
    public featuredSellerListUsingGET(requestParameters: CommonLotControllerApiFeaturedSellerListUsingGETRequest, options?: any) {
        return CommonLotControllerApiFp(this.configuration).featuredSellerListUsingGET(requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 다음경매조회
     * @summary lotSingle
     * @param {CommonLotControllerApiGetNextLotUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonLotControllerApi
     */
    public getNextLotUsingGET(requestParameters: CommonLotControllerApiGetNextLotUsingGETRequest, options?: any) {
        return CommonLotControllerApiFp(this.configuration).getNextLotUsingGET(requestParameters.productId, requestParameters.categoryDepth1id, requestParameters.categoryDepth2id, requestParameters.categoryDepth3id, requestParameters.eventCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 셀러조회
     * @summary partner
     * @param {CommonLotControllerApiGetPartnerUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonLotControllerApi
     */
    public getPartnerUsingGET(requestParameters: CommonLotControllerApiGetPartnerUsingGETRequest, options?: any) {
        return CommonLotControllerApiFp(this.configuration).getPartnerUsingGET(requestParameters.partnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 이전경매조회
     * @summary lotSingle
     * @param {CommonLotControllerApiGetPrevLotUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonLotControllerApi
     */
    public getPrevLotUsingGET(requestParameters: CommonLotControllerApiGetPrevLotUsingGETRequest, options?: any) {
        return CommonLotControllerApiFp(this.configuration).getPrevLotUsingGET(requestParameters.productId, requestParameters.categoryDepth1id, requestParameters.categoryDepth2id, requestParameters.categoryDepth3id, requestParameters.eventCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 경매조회
     * @summary lotSingle
     * @param {CommonLotControllerApiGetUsingGET1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonLotControllerApi
     */
    public getUsingGET1(requestParameters: CommonLotControllerApiGetUsingGET1Request, options?: any) {
        return CommonLotControllerApiFp(this.configuration).getUsingGET1(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 경매조회
     * @summary lotSingle
     * @param {CommonLotControllerApiGetUsingGET6Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonLotControllerApi
     */
    public getUsingGET6(requestParameters: CommonLotControllerApiGetUsingGET6Request, options?: any) {
        return CommonLotControllerApiFp(this.configuration).getUsingGET6(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 셀러리스트 모바일
     * @summary partnerListMobile
     * @param {CommonLotControllerApiListPartnerMobileUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonLotControllerApi
     */
    public listPartnerMobileUsingGET(requestParameters: CommonLotControllerApiListPartnerMobileUsingGETRequest, options?: any) {
        return CommonLotControllerApiFp(this.configuration).listPartnerMobileUsingGET(requestParameters.page, requestParameters.pageSize, requestParameters.categoryDepth1Id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 셀러리스트
     * @summary partnerList
     * @param {CommonLotControllerApiListPartnerUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonLotControllerApi
     */
    public listPartnerUsingGET(requestParameters: CommonLotControllerApiListPartnerUsingGETRequest, options?: any) {
        return CommonLotControllerApiFp(this.configuration).listPartnerUsingGET(requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 경매리스트
     * @summary lotList
     * @param {CommonLotControllerApiListUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonLotControllerApi
     */
    public listUsingGET(requestParameters: CommonLotControllerApiListUsingGETRequest, options?: any) {
        return CommonLotControllerApiFp(this.configuration).listUsingGET(requestParameters.page, requestParameters.pageSize, requestParameters.artist, requestParameters.buynowOnly, requestParameters.categoryDepth1Id, requestParameters.categoryDepth2Id, requestParameters.enableBid, requestParameters.enableBuyNow, requestParameters.excludingEnd, requestParameters.keyword, requestParameters.makeOffer, requestParameters.orderby, requestParameters.partnerId, requestParameters.period, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 경매리스트
     * @summary lotList
     * @param {CommonLotControllerApiListUsingGET5Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonLotControllerApi
     */
    public listUsingGET5(requestParameters: CommonLotControllerApiListUsingGET5Request, options?: any) {
        return CommonLotControllerApiFp(this.configuration).listUsingGET5(requestParameters.page, requestParameters.pageSize, requestParameters.partnerId, requestParameters.artist, requestParameters.buynowOnly, requestParameters.categoryDepth1Id, requestParameters.categoryDepth2Id, requestParameters.enableBid, requestParameters.enableBuyNow, requestParameters.excludingEnd, requestParameters.keyword, requestParameters.makeOffer, requestParameters.orderby, requestParameters.period, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 즉시가/현재가/마감시간/응찰횟수 리스트
     * @summary lotInfoList
     * @param {CommonLotControllerApiLotInfoListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonLotControllerApi
     */
    public lotInfoList(requestParameters: CommonLotControllerApiLotInfoListRequest, options?: any) {
        return CommonLotControllerApiFp(this.configuration).lotInfoList(requestParameters.lotIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 모바일경매리스트
     * @summary mobileLotList
     * @param {CommonLotControllerApiMobileLotListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonLotControllerApi
     */
    public mobileLotList(requestParameters: CommonLotControllerApiMobileLotListRequest, options?: any) {
        return CommonLotControllerApiFp(this.configuration).mobileLotList(requestParameters.page, requestParameters.pageSize, requestParameters.partnerId, requestParameters.artist, requestParameters.bid, requestParameters.bidAndBuynow, requestParameters.buynowOnly, requestParameters.categoryDepth1Id, requestParameters.categoryDepth2Id, requestParameters.eventCategory, requestParameters.eventCode, requestParameters.excludingEnd, requestParameters.keyword, requestParameters.makeOffer, requestParameters.orderby, requestParameters.period, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 옵션 리스트 ( type : period , status , country , artist , medium )
     * @summary optionList
     * @param {CommonLotControllerApiOptionListUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonLotControllerApi
     */
    public optionListUsingGET(requestParameters: CommonLotControllerApiOptionListUsingGETRequest = {}, options?: any) {
        return CommonLotControllerApiFp(this.configuration).optionListUsingGET(requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 경매검색리스트
     * @summary lotSearchList
     * @param {CommonLotControllerApiSearchUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonLotControllerApi
     */
    public searchUsingGET(requestParameters: CommonLotControllerApiSearchUsingGETRequest, options?: any) {
        return CommonLotControllerApiFp(this.configuration).searchUsingGET(requestParameters.page, requestParameters.pageSize, requestParameters.artist, requestParameters.bid, requestParameters.bidAndBuynow, requestParameters.buynowOnly, requestParameters.categoryDepth1Id, requestParameters.categoryDepth2Id, requestParameters.eventCategory, requestParameters.eventCode, requestParameters.excludingEnd, requestParameters.keyword, requestParameters.makeOffer, requestParameters.orderby, requestParameters.partnerId, requestParameters.period, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 임시 등록상품 미리보기
     * @summary tempProductPreview
     * @param {CommonLotControllerApiTempProductPreviewUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonLotControllerApi
     */
    public tempProductPreviewUsingGET(requestParameters: CommonLotControllerApiTempProductPreviewUsingGETRequest, options?: any) {
        return CommonLotControllerApiFp(this.configuration).tempProductPreviewUsingGET(requestParameters.productTempId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary testBatch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonLotControllerApi
     */
    public testBatchUsingPOST(options?: any) {
        return CommonLotControllerApiFp(this.configuration).testBatchUsingPOST(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommonLotInsideControllerApi - axios parameter creator
 * @export
 */
export const CommonLotInsideControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Lot인사이드조회
         * @summary lotInsideSingle
         * @param {number} lotinsideId lotinsideId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingGET2: async (lotinsideId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'lotinsideId' is not null or undefined
            assertParamExists('getUsingGET2', 'lotinsideId', lotinsideId)
            const localVarPath = `/api/common/lotinside/{lotinsideId}`
                .replace(`{${"lotinsideId"}}`, encodeURIComponent(String(lotinsideId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lot인사이드리스트
         * @summary lotInsideList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lotInsideListUsingGET: async (page: number, pageSize: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('lotInsideListUsingGET', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('lotInsideListUsingGET', 'pageSize', pageSize)
            const localVarPath = `/api/common/lotinsides`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommonLotInsideControllerApi - functional programming interface
 * @export
 */
export const CommonLotInsideControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommonLotInsideControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Lot인사이드조회
         * @summary lotInsideSingle
         * @param {number} lotinsideId lotinsideId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsingGET2(lotinsideId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsingGET2(lotinsideId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lot인사이드리스트
         * @summary lotInsideList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lotInsideListUsingGET(page: number, pageSize: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lotInsideListUsingGET(page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommonLotInsideControllerApi - factory interface
 * @export
 */
export const CommonLotInsideControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommonLotInsideControllerApiFp(configuration)
    return {
        /**
         * Lot인사이드조회
         * @summary lotInsideSingle
         * @param {number} lotinsideId lotinsideId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingGET2(lotinsideId: number, options?: any): AxiosPromise<object> {
            return localVarFp.getUsingGET2(lotinsideId, options).then((request) => request(axios, basePath));
        },
        /**
         * Lot인사이드리스트
         * @summary lotInsideList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lotInsideListUsingGET(page: number, pageSize: number, options?: any): AxiosPromise<object> {
            return localVarFp.lotInsideListUsingGET(page, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getUsingGET2 operation in CommonLotInsideControllerApi.
 * @export
 * @interface CommonLotInsideControllerApiGetUsingGET2Request
 */
export interface CommonLotInsideControllerApiGetUsingGET2Request {
    /**
     * lotinsideId
     * @type {number}
     * @memberof CommonLotInsideControllerApiGetUsingGET2
     */
    readonly lotinsideId: number
}

/**
 * Request parameters for lotInsideListUsingGET operation in CommonLotInsideControllerApi.
 * @export
 * @interface CommonLotInsideControllerApiLotInsideListUsingGETRequest
 */
export interface CommonLotInsideControllerApiLotInsideListUsingGETRequest {
    /**
     * page
     * @type {number}
     * @memberof CommonLotInsideControllerApiLotInsideListUsingGET
     */
    readonly page: number

    /**
     * pageSize
     * @type {number}
     * @memberof CommonLotInsideControllerApiLotInsideListUsingGET
     */
    readonly pageSize: number
}

/**
 * CommonLotInsideControllerApi - object-oriented interface
 * @export
 * @class CommonLotInsideControllerApi
 * @extends {BaseAPI}
 */
export class CommonLotInsideControllerApi extends BaseAPI {
    /**
     * Lot인사이드조회
     * @summary lotInsideSingle
     * @param {CommonLotInsideControllerApiGetUsingGET2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonLotInsideControllerApi
     */
    public getUsingGET2(requestParameters: CommonLotInsideControllerApiGetUsingGET2Request, options?: any) {
        return CommonLotInsideControllerApiFp(this.configuration).getUsingGET2(requestParameters.lotinsideId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lot인사이드리스트
     * @summary lotInsideList
     * @param {CommonLotInsideControllerApiLotInsideListUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonLotInsideControllerApi
     */
    public lotInsideListUsingGET(requestParameters: CommonLotInsideControllerApiLotInsideListUsingGETRequest, options?: any) {
        return CommonLotInsideControllerApiFp(this.configuration).lotInsideListUsingGET(requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommonMainApi - axios parameter creator
 * @export
 */
export const CommonMainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 메인화면
         * @summary main
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mainUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/common/main`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 메뉴 리스트
         * @summary menuList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        menuList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/common/menus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommonMainApi - functional programming interface
 * @export
 */
export const CommonMainApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommonMainApiAxiosParamCreator(configuration)
    return {
        /**
         * 메인화면
         * @summary main
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mainUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mainUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 메뉴 리스트
         * @summary menuList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async menuList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.menuList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommonMainApi - factory interface
 * @export
 */
export const CommonMainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommonMainApiFp(configuration)
    return {
        /**
         * 메인화면
         * @summary main
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mainUsingGET(options?: any): AxiosPromise<object> {
            return localVarFp.mainUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 메뉴 리스트
         * @summary menuList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        menuList(options?: any): AxiosPromise<object> {
            return localVarFp.menuList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommonMainApi - object-oriented interface
 * @export
 * @class CommonMainApi
 * @extends {BaseAPI}
 */
export class CommonMainApi extends BaseAPI {
    /**
     * 메인화면
     * @summary main
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonMainApi
     */
    public mainUsingGET(options?: any) {
        return CommonMainApiFp(this.configuration).mainUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 메뉴 리스트
     * @summary menuList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonMainApi
     */
    public menuList(options?: any) {
        return CommonMainApiFp(this.configuration).menuList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LikeControllerApi - axios parameter creator
 * @export
 */
export const LikeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 파트너 좋아요 조회
         * @summary partnerLikeCount
         * @param {number} partnerId partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerLikeUsingGET: async (partnerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('getPartnerLikeUsingGET', 'partnerId', partnerId)
            const localVarPath = `/api/product/seller/like/{partnerId}`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 상품 좋아요 조회
         * @summary productLikeCount
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductLikeUsingGET: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductLikeUsingGET', 'productId', productId)
            const localVarPath = `/api/product/lot/like/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 파트너 좋아요 설정
         * @summary partnerLike
         * @param {number} partnerId partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPartnerLikeUsingPUT: async (partnerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('setPartnerLikeUsingPUT', 'partnerId', partnerId)
            const localVarPath = `/api/product/seller/like`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (partnerId !== undefined) {
                localVarQueryParameter['partnerId'] = partnerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 상품 좋아요 설정
         * @summary productLike
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProductLikeUsingPUT: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('setProductLikeUsingPUT', 'productId', productId)
            const localVarPath = `/api/product/lot/like`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LikeControllerApi - functional programming interface
 * @export
 */
export const LikeControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LikeControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 파트너 좋아요 조회
         * @summary partnerLikeCount
         * @param {number} partnerId partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPartnerLikeUsingGET(partnerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPartnerLikeUsingGET(partnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 상품 좋아요 조회
         * @summary productLikeCount
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductLikeUsingGET(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductLikeUsingGET(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 파트너 좋아요 설정
         * @summary partnerLike
         * @param {number} partnerId partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPartnerLikeUsingPUT(partnerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPartnerLikeUsingPUT(partnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 상품 좋아요 설정
         * @summary productLike
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setProductLikeUsingPUT(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setProductLikeUsingPUT(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LikeControllerApi - factory interface
 * @export
 */
export const LikeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LikeControllerApiFp(configuration)
    return {
        /**
         * 파트너 좋아요 조회
         * @summary partnerLikeCount
         * @param {number} partnerId partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerLikeUsingGET(partnerId: number, options?: any): AxiosPromise<object> {
            return localVarFp.getPartnerLikeUsingGET(partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 상품 좋아요 조회
         * @summary productLikeCount
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductLikeUsingGET(productId: number, options?: any): AxiosPromise<object> {
            return localVarFp.getProductLikeUsingGET(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 파트너 좋아요 설정
         * @summary partnerLike
         * @param {number} partnerId partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPartnerLikeUsingPUT(partnerId: number, options?: any): AxiosPromise<object> {
            return localVarFp.setPartnerLikeUsingPUT(partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 상품 좋아요 설정
         * @summary productLike
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProductLikeUsingPUT(productId: number, options?: any): AxiosPromise<object> {
            return localVarFp.setProductLikeUsingPUT(productId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getPartnerLikeUsingGET operation in LikeControllerApi.
 * @export
 * @interface LikeControllerApiGetPartnerLikeUsingGETRequest
 */
export interface LikeControllerApiGetPartnerLikeUsingGETRequest {
    /**
     * partnerId
     * @type {number}
     * @memberof LikeControllerApiGetPartnerLikeUsingGET
     */
    readonly partnerId: number
}

/**
 * Request parameters for getProductLikeUsingGET operation in LikeControllerApi.
 * @export
 * @interface LikeControllerApiGetProductLikeUsingGETRequest
 */
export interface LikeControllerApiGetProductLikeUsingGETRequest {
    /**
     * productId
     * @type {number}
     * @memberof LikeControllerApiGetProductLikeUsingGET
     */
    readonly productId: number
}

/**
 * Request parameters for setPartnerLikeUsingPUT operation in LikeControllerApi.
 * @export
 * @interface LikeControllerApiSetPartnerLikeUsingPUTRequest
 */
export interface LikeControllerApiSetPartnerLikeUsingPUTRequest {
    /**
     * partnerId
     * @type {number}
     * @memberof LikeControllerApiSetPartnerLikeUsingPUT
     */
    readonly partnerId: number
}

/**
 * Request parameters for setProductLikeUsingPUT operation in LikeControllerApi.
 * @export
 * @interface LikeControllerApiSetProductLikeUsingPUTRequest
 */
export interface LikeControllerApiSetProductLikeUsingPUTRequest {
    /**
     * productId
     * @type {number}
     * @memberof LikeControllerApiSetProductLikeUsingPUT
     */
    readonly productId: number
}

/**
 * LikeControllerApi - object-oriented interface
 * @export
 * @class LikeControllerApi
 * @extends {BaseAPI}
 */
export class LikeControllerApi extends BaseAPI {
    /**
     * 파트너 좋아요 조회
     * @summary partnerLikeCount
     * @param {LikeControllerApiGetPartnerLikeUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LikeControllerApi
     */
    public getPartnerLikeUsingGET(requestParameters: LikeControllerApiGetPartnerLikeUsingGETRequest, options?: any) {
        return LikeControllerApiFp(this.configuration).getPartnerLikeUsingGET(requestParameters.partnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 상품 좋아요 조회
     * @summary productLikeCount
     * @param {LikeControllerApiGetProductLikeUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LikeControllerApi
     */
    public getProductLikeUsingGET(requestParameters: LikeControllerApiGetProductLikeUsingGETRequest, options?: any) {
        return LikeControllerApiFp(this.configuration).getProductLikeUsingGET(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 파트너 좋아요 설정
     * @summary partnerLike
     * @param {LikeControllerApiSetPartnerLikeUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LikeControllerApi
     */
    public setPartnerLikeUsingPUT(requestParameters: LikeControllerApiSetPartnerLikeUsingPUTRequest, options?: any) {
        return LikeControllerApiFp(this.configuration).setPartnerLikeUsingPUT(requestParameters.partnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 상품 좋아요 설정
     * @summary productLike
     * @param {LikeControllerApiSetProductLikeUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LikeControllerApi
     */
    public setProductLikeUsingPUT(requestParameters: LikeControllerApiSetProductLikeUsingPUTRequest, options?: any) {
        return LikeControllerApiFp(this.configuration).setProductLikeUsingPUT(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MakeOfferControllerApi - axios parameter creator
 * @export
 */
export const MakeOfferControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 오퍼상세
         * @summary makeOfferInfo
         * @param {number} [productId] productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeOfferDetailUsingGET: async (productId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/makeoffer/detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 메이크 오퍼 설정
         * @summary makeOffer
         * @param {MakeOfferParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeOfferUsingPOST: async (param: MakeOfferParamDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('makeOfferUsingPOST', 'param', param)
            const localVarPath = `/api/makeoffer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MakeOfferControllerApi - functional programming interface
 * @export
 */
export const MakeOfferControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MakeOfferControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 오퍼상세
         * @summary makeOfferInfo
         * @param {number} [productId] productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makeOfferDetailUsingGET(productId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makeOfferDetailUsingGET(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 메이크 오퍼 설정
         * @summary makeOffer
         * @param {MakeOfferParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makeOfferUsingPOST(param: MakeOfferParamDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makeOfferUsingPOST(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MakeOfferControllerApi - factory interface
 * @export
 */
export const MakeOfferControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MakeOfferControllerApiFp(configuration)
    return {
        /**
         * 오퍼상세
         * @summary makeOfferInfo
         * @param {number} [productId] productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeOfferDetailUsingGET(productId?: number, options?: any): AxiosPromise<object> {
            return localVarFp.makeOfferDetailUsingGET(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 메이크 오퍼 설정
         * @summary makeOffer
         * @param {MakeOfferParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeOfferUsingPOST(param: MakeOfferParamDTO, options?: any): AxiosPromise<object> {
            return localVarFp.makeOfferUsingPOST(param, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for makeOfferDetailUsingGET operation in MakeOfferControllerApi.
 * @export
 * @interface MakeOfferControllerApiMakeOfferDetailUsingGETRequest
 */
export interface MakeOfferControllerApiMakeOfferDetailUsingGETRequest {
    /**
     * productId
     * @type {number}
     * @memberof MakeOfferControllerApiMakeOfferDetailUsingGET
     */
    readonly productId?: number
}

/**
 * Request parameters for makeOfferUsingPOST operation in MakeOfferControllerApi.
 * @export
 * @interface MakeOfferControllerApiMakeOfferUsingPOSTRequest
 */
export interface MakeOfferControllerApiMakeOfferUsingPOSTRequest {
    /**
     * param
     * @type {MakeOfferParamDTO}
     * @memberof MakeOfferControllerApiMakeOfferUsingPOST
     */
    readonly param: MakeOfferParamDTO
}

/**
 * MakeOfferControllerApi - object-oriented interface
 * @export
 * @class MakeOfferControllerApi
 * @extends {BaseAPI}
 */
export class MakeOfferControllerApi extends BaseAPI {
    /**
     * 오퍼상세
     * @summary makeOfferInfo
     * @param {MakeOfferControllerApiMakeOfferDetailUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MakeOfferControllerApi
     */
    public makeOfferDetailUsingGET(requestParameters: MakeOfferControllerApiMakeOfferDetailUsingGETRequest = {}, options?: any) {
        return MakeOfferControllerApiFp(this.configuration).makeOfferDetailUsingGET(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 메이크 오퍼 설정
     * @summary makeOffer
     * @param {MakeOfferControllerApiMakeOfferUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MakeOfferControllerApi
     */
    public makeOfferUsingPOST(requestParameters: MakeOfferControllerApiMakeOfferUsingPOSTRequest, options?: any) {
        return MakeOfferControllerApiFp(this.configuration).makeOfferUsingPOST(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MemberAuthApi - axios parameter creator
 * @export
 */
export const MemberAuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 이메일 중복 체크
         * @summary checkEmail
         * @param {string} email email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkEmailUsingPOST: async (email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('checkEmailUsingPOST', 'email', email)
            const localVarPath = `/api/check-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 휴대폰 중복 체크
         * @summary checkMobile
         * @param {string} mobile mobile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkMobileUsingPOST: async (mobile: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mobile' is not null or undefined
            assertParamExists('checkMobileUsingPOST', 'mobile', mobile)
            const localVarPath = `/api/check-mobile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (mobile !== undefined) {
                localVarQueryParameter['mobile'] = mobile;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 실명인증 유/무
         * @summary dupInfo
         * @param {string} email email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dupInfoUsingGET: async (email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('dupInfoUsingGET', 'email', email)
            const localVarPath = `/api/dupinfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 이메일찾기
         * @summary findEmail
         * @param {FindMemberEmailParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEmailUsingPOST: async (param: FindMemberEmailParamDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('findEmailUsingPOST', 'param', param)
            const localVarPath = `/api/find-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 비밀번호찾기
         * @summary findPassword
         * @param {FindMemberPasswordParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEmailUsingPOST1: async (param: FindMemberPasswordParamDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('findEmailUsingPOST1', 'param', param)
            const localVarPath = `/api/find-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary identificationFailJoin
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identificationFailJoinUsingGET: async (creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/identification-fail-join`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (creationTime !== undefined) {
                localVarQueryParameter['creationTime'] = creationTime;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (lastAccessedTime !== undefined) {
                localVarQueryParameter['lastAccessedTime'] = lastAccessedTime;
            }

            if (maxInactiveInterval !== undefined) {
                localVarQueryParameter['maxInactiveInterval'] = maxInactiveInterval;
            }

            if (_new !== undefined) {
                localVarQueryParameter['new'] = _new;
            }

            if (valueNames) {
                localVarQueryParameter['valueNames'] = valueNames;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary identificationFailJoin
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identificationFailJoinUsingPOST: async (creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/identification-fail-join`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (creationTime !== undefined) {
                localVarQueryParameter['creationTime'] = creationTime;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (lastAccessedTime !== undefined) {
                localVarQueryParameter['lastAccessedTime'] = lastAccessedTime;
            }

            if (maxInactiveInterval !== undefined) {
                localVarQueryParameter['maxInactiveInterval'] = maxInactiveInterval;
            }

            if (_new !== undefined) {
                localVarQueryParameter['new'] = _new;
            }

            if (valueNames) {
                localVarQueryParameter['valueNames'] = valueNames;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary identificationFailMypage
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identificationFailMypageUsingGET: async (creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/identification-fail-mypage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (creationTime !== undefined) {
                localVarQueryParameter['creationTime'] = creationTime;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (lastAccessedTime !== undefined) {
                localVarQueryParameter['lastAccessedTime'] = lastAccessedTime;
            }

            if (maxInactiveInterval !== undefined) {
                localVarQueryParameter['maxInactiveInterval'] = maxInactiveInterval;
            }

            if (_new !== undefined) {
                localVarQueryParameter['new'] = _new;
            }

            if (valueNames) {
                localVarQueryParameter['valueNames'] = valueNames;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary identificationFailMypage
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identificationFailMypageUsingPOST: async (creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/identification-fail-mypage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (creationTime !== undefined) {
                localVarQueryParameter['creationTime'] = creationTime;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (lastAccessedTime !== undefined) {
                localVarQueryParameter['lastAccessedTime'] = lastAccessedTime;
            }

            if (maxInactiveInterval !== undefined) {
                localVarQueryParameter['maxInactiveInterval'] = maxInactiveInterval;
            }

            if (_new !== undefined) {
                localVarQueryParameter['new'] = _new;
            }

            if (valueNames) {
                localVarQueryParameter['valueNames'] = valueNames;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary identificationFail
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identificationFailUsingGET: async (creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/identification-fail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (creationTime !== undefined) {
                localVarQueryParameter['creationTime'] = creationTime;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (lastAccessedTime !== undefined) {
                localVarQueryParameter['lastAccessedTime'] = lastAccessedTime;
            }

            if (maxInactiveInterval !== undefined) {
                localVarQueryParameter['maxInactiveInterval'] = maxInactiveInterval;
            }

            if (_new !== undefined) {
                localVarQueryParameter['new'] = _new;
            }

            if (valueNames) {
                localVarQueryParameter['valueNames'] = valueNames;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary identificationFail
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identificationFailUsingPOST: async (creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/identification-fail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (creationTime !== undefined) {
                localVarQueryParameter['creationTime'] = creationTime;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (lastAccessedTime !== undefined) {
                localVarQueryParameter['lastAccessedTime'] = lastAccessedTime;
            }

            if (maxInactiveInterval !== undefined) {
                localVarQueryParameter['maxInactiveInterval'] = maxInactiveInterval;
            }

            if (_new !== undefined) {
                localVarQueryParameter['new'] = _new;
            }

            if (valueNames) {
                localVarQueryParameter['valueNames'] = valueNames;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 실명인증 정보
         * @summary identification-info
         * @param {'mobile_join' | 'mobile_mypage' | 'pc'} [redirectType] redirectType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identificationInfoUsingGET: async (redirectType?: 'mobile_join' | 'mobile_mypage' | 'pc', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/identification-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (redirectType !== undefined) {
                localVarQueryParameter['redirectType'] = redirectType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 인증 정보 가져오기
         * @summary identification-result
         * @param {string} [identifyKey] identify-key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identificationResultUsingGET: async (identifyKey?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/identification-result`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (identifyKey !== undefined) {
                localVarQueryParameter['identify-key'] = identifyKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary identificationSuccessMobileJoin
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identificationSuccessMobileJoinUsingGET: async (creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/identification-success-join`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (creationTime !== undefined) {
                localVarQueryParameter['creationTime'] = creationTime;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (lastAccessedTime !== undefined) {
                localVarQueryParameter['lastAccessedTime'] = lastAccessedTime;
            }

            if (maxInactiveInterval !== undefined) {
                localVarQueryParameter['maxInactiveInterval'] = maxInactiveInterval;
            }

            if (_new !== undefined) {
                localVarQueryParameter['new'] = _new;
            }

            if (valueNames) {
                localVarQueryParameter['valueNames'] = valueNames;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary identificationSuccessMobileJoin
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identificationSuccessMobileJoinUsingPOST: async (creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/identification-success-join`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (creationTime !== undefined) {
                localVarQueryParameter['creationTime'] = creationTime;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (lastAccessedTime !== undefined) {
                localVarQueryParameter['lastAccessedTime'] = lastAccessedTime;
            }

            if (maxInactiveInterval !== undefined) {
                localVarQueryParameter['maxInactiveInterval'] = maxInactiveInterval;
            }

            if (_new !== undefined) {
                localVarQueryParameter['new'] = _new;
            }

            if (valueNames) {
                localVarQueryParameter['valueNames'] = valueNames;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary identificationSuccessMobileMypage
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identificationSuccessMobileMypageUsingGET: async (creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/identification-success-mypage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (creationTime !== undefined) {
                localVarQueryParameter['creationTime'] = creationTime;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (lastAccessedTime !== undefined) {
                localVarQueryParameter['lastAccessedTime'] = lastAccessedTime;
            }

            if (maxInactiveInterval !== undefined) {
                localVarQueryParameter['maxInactiveInterval'] = maxInactiveInterval;
            }

            if (_new !== undefined) {
                localVarQueryParameter['new'] = _new;
            }

            if (valueNames) {
                localVarQueryParameter['valueNames'] = valueNames;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary identificationSuccessMobileMypage
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identificationSuccessMobileMypageUsingPOST: async (creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/identification-success-mypage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (creationTime !== undefined) {
                localVarQueryParameter['creationTime'] = creationTime;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (lastAccessedTime !== undefined) {
                localVarQueryParameter['lastAccessedTime'] = lastAccessedTime;
            }

            if (maxInactiveInterval !== undefined) {
                localVarQueryParameter['maxInactiveInterval'] = maxInactiveInterval;
            }

            if (_new !== undefined) {
                localVarQueryParameter['new'] = _new;
            }

            if (valueNames) {
                localVarQueryParameter['valueNames'] = valueNames;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary identificationSuccess
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identificationSuccessUsingGET: async (creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/identification-success`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (creationTime !== undefined) {
                localVarQueryParameter['creationTime'] = creationTime;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (lastAccessedTime !== undefined) {
                localVarQueryParameter['lastAccessedTime'] = lastAccessedTime;
            }

            if (maxInactiveInterval !== undefined) {
                localVarQueryParameter['maxInactiveInterval'] = maxInactiveInterval;
            }

            if (_new !== undefined) {
                localVarQueryParameter['new'] = _new;
            }

            if (valueNames) {
                localVarQueryParameter['valueNames'] = valueNames;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary identificationSuccess
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identificationSuccessUsingPOST: async (creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/identification-success`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (creationTime !== undefined) {
                localVarQueryParameter['creationTime'] = creationTime;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (lastAccessedTime !== undefined) {
                localVarQueryParameter['lastAccessedTime'] = lastAccessedTime;
            }

            if (maxInactiveInterval !== undefined) {
                localVarQueryParameter['maxInactiveInterval'] = maxInactiveInterval;
            }

            if (_new !== undefined) {
                localVarQueryParameter['new'] = _new;
            }

            if (valueNames) {
                localVarQueryParameter['valueNames'] = valueNames;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 회원 가입
         * @summary join
         * @param {MemberJoinDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinPartnerUsingPOST: async (param: MemberJoinDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('joinPartnerUsingPOST', 'param', param)
            const localVarPath = `/api/join`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 로그인
         * @summary login
         * @param {AuthParamDTO} authParamDTO authParamDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUsingPOST1: async (authParamDTO: AuthParamDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authParamDTO' is not null or undefined
            assertParamExists('loginUsingPOST1', 'authParamDTO', authParamDTO)
            const localVarPath = `/api/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authParamDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 로그아웃
         * @summary logout
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutUsingGET1: async (creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (creationTime !== undefined) {
                localVarQueryParameter['creationTime'] = creationTime;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (lastAccessedTime !== undefined) {
                localVarQueryParameter['lastAccessedTime'] = lastAccessedTime;
            }

            if (maxInactiveInterval !== undefined) {
                localVarQueryParameter['maxInactiveInterval'] = maxInactiveInterval;
            }

            if (_new !== undefined) {
                localVarQueryParameter['new'] = _new;
            }

            if (valueNames) {
                localVarQueryParameter['valueNames'] = valueNames;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 회원 탈퇴
         * @summary signout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberSignout: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/signout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 비번번호 재설정
         * @summary resetPassword
         * @param {ResetPasswordParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordUsingPOST: async (param: ResetPasswordParamDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('resetPasswordUsingPOST', 'param', param)
            const localVarPath = `/api/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MemberAuthApi - functional programming interface
 * @export
 */
export const MemberAuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MemberAuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 이메일 중복 체크
         * @summary checkEmail
         * @param {string} email email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkEmailUsingPOST(email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkEmailUsingPOST(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 휴대폰 중복 체크
         * @summary checkMobile
         * @param {string} mobile mobile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkMobileUsingPOST(mobile: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkMobileUsingPOST(mobile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 실명인증 유/무
         * @summary dupInfo
         * @param {string} email email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dupInfoUsingGET(email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dupInfoUsingGET(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 이메일찾기
         * @summary findEmail
         * @param {FindMemberEmailParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findEmailUsingPOST(param: FindMemberEmailParamDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findEmailUsingPOST(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 비밀번호찾기
         * @summary findPassword
         * @param {FindMemberPasswordParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findEmailUsingPOST1(param: FindMemberPasswordParamDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findEmailUsingPOST1(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary identificationFailJoin
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identificationFailJoinUsingGET(creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identificationFailJoinUsingGET(creationTime, id, lastAccessedTime, maxInactiveInterval, _new, valueNames, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary identificationFailJoin
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identificationFailJoinUsingPOST(creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identificationFailJoinUsingPOST(creationTime, id, lastAccessedTime, maxInactiveInterval, _new, valueNames, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary identificationFailMypage
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identificationFailMypageUsingGET(creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identificationFailMypageUsingGET(creationTime, id, lastAccessedTime, maxInactiveInterval, _new, valueNames, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary identificationFailMypage
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identificationFailMypageUsingPOST(creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identificationFailMypageUsingPOST(creationTime, id, lastAccessedTime, maxInactiveInterval, _new, valueNames, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary identificationFail
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identificationFailUsingGET(creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identificationFailUsingGET(creationTime, id, lastAccessedTime, maxInactiveInterval, _new, valueNames, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary identificationFail
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identificationFailUsingPOST(creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identificationFailUsingPOST(creationTime, id, lastAccessedTime, maxInactiveInterval, _new, valueNames, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 실명인증 정보
         * @summary identification-info
         * @param {'mobile_join' | 'mobile_mypage' | 'pc'} [redirectType] redirectType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identificationInfoUsingGET(redirectType?: 'mobile_join' | 'mobile_mypage' | 'pc', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identificationInfoUsingGET(redirectType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 인증 정보 가져오기
         * @summary identification-result
         * @param {string} [identifyKey] identify-key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identificationResultUsingGET(identifyKey?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identificationResultUsingGET(identifyKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary identificationSuccessMobileJoin
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identificationSuccessMobileJoinUsingGET(creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAndView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identificationSuccessMobileJoinUsingGET(creationTime, id, lastAccessedTime, maxInactiveInterval, _new, valueNames, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary identificationSuccessMobileJoin
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identificationSuccessMobileJoinUsingPOST(creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAndView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identificationSuccessMobileJoinUsingPOST(creationTime, id, lastAccessedTime, maxInactiveInterval, _new, valueNames, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary identificationSuccessMobileMypage
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identificationSuccessMobileMypageUsingGET(creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAndView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identificationSuccessMobileMypageUsingGET(creationTime, id, lastAccessedTime, maxInactiveInterval, _new, valueNames, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary identificationSuccessMobileMypage
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identificationSuccessMobileMypageUsingPOST(creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAndView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identificationSuccessMobileMypageUsingPOST(creationTime, id, lastAccessedTime, maxInactiveInterval, _new, valueNames, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary identificationSuccess
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identificationSuccessUsingGET(creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identificationSuccessUsingGET(creationTime, id, lastAccessedTime, maxInactiveInterval, _new, valueNames, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary identificationSuccess
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identificationSuccessUsingPOST(creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identificationSuccessUsingPOST(creationTime, id, lastAccessedTime, maxInactiveInterval, _new, valueNames, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 회원 가입
         * @summary join
         * @param {MemberJoinDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async joinPartnerUsingPOST(param: MemberJoinDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.joinPartnerUsingPOST(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 로그인
         * @summary login
         * @param {AuthParamDTO} authParamDTO authParamDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginUsingPOST1(authParamDTO: AuthParamDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginUsingPOST1(authParamDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 로그아웃
         * @summary logout
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutUsingGET1(creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logoutUsingGET1(creationTime, id, lastAccessedTime, maxInactiveInterval, _new, valueNames, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 회원 탈퇴
         * @summary signout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberSignout(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberSignout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 비번번호 재설정
         * @summary resetPassword
         * @param {ResetPasswordParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPasswordUsingPOST(param: ResetPasswordParamDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPasswordUsingPOST(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MemberAuthApi - factory interface
 * @export
 */
export const MemberAuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MemberAuthApiFp(configuration)
    return {
        /**
         * 이메일 중복 체크
         * @summary checkEmail
         * @param {string} email email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkEmailUsingPOST(email: string, options?: any): AxiosPromise<object> {
            return localVarFp.checkEmailUsingPOST(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 휴대폰 중복 체크
         * @summary checkMobile
         * @param {string} mobile mobile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkMobileUsingPOST(mobile: string, options?: any): AxiosPromise<object> {
            return localVarFp.checkMobileUsingPOST(mobile, options).then((request) => request(axios, basePath));
        },
        /**
         * 실명인증 유/무
         * @summary dupInfo
         * @param {string} email email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dupInfoUsingGET(email: string, options?: any): AxiosPromise<object> {
            return localVarFp.dupInfoUsingGET(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 이메일찾기
         * @summary findEmail
         * @param {FindMemberEmailParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEmailUsingPOST(param: FindMemberEmailParamDTO, options?: any): AxiosPromise<object> {
            return localVarFp.findEmailUsingPOST(param, options).then((request) => request(axios, basePath));
        },
        /**
         * 비밀번호찾기
         * @summary findPassword
         * @param {FindMemberPasswordParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findEmailUsingPOST1(param: FindMemberPasswordParamDTO, options?: any): AxiosPromise<object> {
            return localVarFp.findEmailUsingPOST1(param, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary identificationFailJoin
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identificationFailJoinUsingGET(creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options?: any): AxiosPromise<object> {
            return localVarFp.identificationFailJoinUsingGET(creationTime, id, lastAccessedTime, maxInactiveInterval, _new, valueNames, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary identificationFailJoin
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identificationFailJoinUsingPOST(creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options?: any): AxiosPromise<object> {
            return localVarFp.identificationFailJoinUsingPOST(creationTime, id, lastAccessedTime, maxInactiveInterval, _new, valueNames, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary identificationFailMypage
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identificationFailMypageUsingGET(creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options?: any): AxiosPromise<object> {
            return localVarFp.identificationFailMypageUsingGET(creationTime, id, lastAccessedTime, maxInactiveInterval, _new, valueNames, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary identificationFailMypage
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identificationFailMypageUsingPOST(creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options?: any): AxiosPromise<object> {
            return localVarFp.identificationFailMypageUsingPOST(creationTime, id, lastAccessedTime, maxInactiveInterval, _new, valueNames, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary identificationFail
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identificationFailUsingGET(creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options?: any): AxiosPromise<object> {
            return localVarFp.identificationFailUsingGET(creationTime, id, lastAccessedTime, maxInactiveInterval, _new, valueNames, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary identificationFail
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identificationFailUsingPOST(creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options?: any): AxiosPromise<object> {
            return localVarFp.identificationFailUsingPOST(creationTime, id, lastAccessedTime, maxInactiveInterval, _new, valueNames, options).then((request) => request(axios, basePath));
        },
        /**
         * 실명인증 정보
         * @summary identification-info
         * @param {'mobile_join' | 'mobile_mypage' | 'pc'} [redirectType] redirectType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identificationInfoUsingGET(redirectType?: 'mobile_join' | 'mobile_mypage' | 'pc', options?: any): AxiosPromise<object> {
            return localVarFp.identificationInfoUsingGET(redirectType, options).then((request) => request(axios, basePath));
        },
        /**
         * 인증 정보 가져오기
         * @summary identification-result
         * @param {string} [identifyKey] identify-key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identificationResultUsingGET(identifyKey?: string, options?: any): AxiosPromise<object> {
            return localVarFp.identificationResultUsingGET(identifyKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary identificationSuccessMobileJoin
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identificationSuccessMobileJoinUsingGET(creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options?: any): AxiosPromise<ModelAndView> {
            return localVarFp.identificationSuccessMobileJoinUsingGET(creationTime, id, lastAccessedTime, maxInactiveInterval, _new, valueNames, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary identificationSuccessMobileJoin
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identificationSuccessMobileJoinUsingPOST(creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options?: any): AxiosPromise<ModelAndView> {
            return localVarFp.identificationSuccessMobileJoinUsingPOST(creationTime, id, lastAccessedTime, maxInactiveInterval, _new, valueNames, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary identificationSuccessMobileMypage
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identificationSuccessMobileMypageUsingGET(creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options?: any): AxiosPromise<ModelAndView> {
            return localVarFp.identificationSuccessMobileMypageUsingGET(creationTime, id, lastAccessedTime, maxInactiveInterval, _new, valueNames, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary identificationSuccessMobileMypage
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identificationSuccessMobileMypageUsingPOST(creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options?: any): AxiosPromise<ModelAndView> {
            return localVarFp.identificationSuccessMobileMypageUsingPOST(creationTime, id, lastAccessedTime, maxInactiveInterval, _new, valueNames, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary identificationSuccess
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identificationSuccessUsingGET(creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options?: any): AxiosPromise<string> {
            return localVarFp.identificationSuccessUsingGET(creationTime, id, lastAccessedTime, maxInactiveInterval, _new, valueNames, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary identificationSuccess
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identificationSuccessUsingPOST(creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options?: any): AxiosPromise<string> {
            return localVarFp.identificationSuccessUsingPOST(creationTime, id, lastAccessedTime, maxInactiveInterval, _new, valueNames, options).then((request) => request(axios, basePath));
        },
        /**
         * 회원 가입
         * @summary join
         * @param {MemberJoinDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinPartnerUsingPOST(param: MemberJoinDTO, options?: any): AxiosPromise<object> {
            return localVarFp.joinPartnerUsingPOST(param, options).then((request) => request(axios, basePath));
        },
        /**
         * 로그인
         * @summary login
         * @param {AuthParamDTO} authParamDTO authParamDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUsingPOST1(authParamDTO: AuthParamDTO, options?: any): AxiosPromise<object> {
            return localVarFp.loginUsingPOST1(authParamDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 로그아웃
         * @summary logout
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutUsingGET1(creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options?: any): AxiosPromise<string> {
            return localVarFp.logoutUsingGET1(creationTime, id, lastAccessedTime, maxInactiveInterval, _new, valueNames, options).then((request) => request(axios, basePath));
        },
        /**
         * 회원 탈퇴
         * @summary signout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberSignout(options?: any): AxiosPromise<object> {
            return localVarFp.memberSignout(options).then((request) => request(axios, basePath));
        },
        /**
         * 비번번호 재설정
         * @summary resetPassword
         * @param {ResetPasswordParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordUsingPOST(param: ResetPasswordParamDTO, options?: any): AxiosPromise<object> {
            return localVarFp.resetPasswordUsingPOST(param, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for checkEmailUsingPOST operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiCheckEmailUsingPOSTRequest
 */
export interface MemberAuthApiCheckEmailUsingPOSTRequest {
    /**
     * email
     * @type {string}
     * @memberof MemberAuthApiCheckEmailUsingPOST
     */
    readonly email: string
}

/**
 * Request parameters for checkMobileUsingPOST operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiCheckMobileUsingPOSTRequest
 */
export interface MemberAuthApiCheckMobileUsingPOSTRequest {
    /**
     * mobile
     * @type {string}
     * @memberof MemberAuthApiCheckMobileUsingPOST
     */
    readonly mobile: string
}

/**
 * Request parameters for dupInfoUsingGET operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiDupInfoUsingGETRequest
 */
export interface MemberAuthApiDupInfoUsingGETRequest {
    /**
     * email
     * @type {string}
     * @memberof MemberAuthApiDupInfoUsingGET
     */
    readonly email: string
}

/**
 * Request parameters for findEmailUsingPOST operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiFindEmailUsingPOSTRequest
 */
export interface MemberAuthApiFindEmailUsingPOSTRequest {
    /**
     * param
     * @type {FindMemberEmailParamDTO}
     * @memberof MemberAuthApiFindEmailUsingPOST
     */
    readonly param: FindMemberEmailParamDTO
}

/**
 * Request parameters for findEmailUsingPOST1 operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiFindEmailUsingPOST1Request
 */
export interface MemberAuthApiFindEmailUsingPOST1Request {
    /**
     * param
     * @type {FindMemberPasswordParamDTO}
     * @memberof MemberAuthApiFindEmailUsingPOST1
     */
    readonly param: FindMemberPasswordParamDTO
}

/**
 * Request parameters for identificationFailJoinUsingGET operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiIdentificationFailJoinUsingGETRequest
 */
export interface MemberAuthApiIdentificationFailJoinUsingGETRequest {
    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationFailJoinUsingGET
     */
    readonly creationTime?: number

    /**
     * 
     * @type {string}
     * @memberof MemberAuthApiIdentificationFailJoinUsingGET
     */
    readonly id?: string

    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationFailJoinUsingGET
     */
    readonly lastAccessedTime?: number

    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationFailJoinUsingGET
     */
    readonly maxInactiveInterval?: number

    /**
     * 
     * @type {boolean}
     * @memberof MemberAuthApiIdentificationFailJoinUsingGET
     */
    readonly _new?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof MemberAuthApiIdentificationFailJoinUsingGET
     */
    readonly valueNames?: Array<string>
}

/**
 * Request parameters for identificationFailJoinUsingPOST operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiIdentificationFailJoinUsingPOSTRequest
 */
export interface MemberAuthApiIdentificationFailJoinUsingPOSTRequest {
    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationFailJoinUsingPOST
     */
    readonly creationTime?: number

    /**
     * 
     * @type {string}
     * @memberof MemberAuthApiIdentificationFailJoinUsingPOST
     */
    readonly id?: string

    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationFailJoinUsingPOST
     */
    readonly lastAccessedTime?: number

    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationFailJoinUsingPOST
     */
    readonly maxInactiveInterval?: number

    /**
     * 
     * @type {boolean}
     * @memberof MemberAuthApiIdentificationFailJoinUsingPOST
     */
    readonly _new?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof MemberAuthApiIdentificationFailJoinUsingPOST
     */
    readonly valueNames?: Array<string>
}

/**
 * Request parameters for identificationFailMypageUsingGET operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiIdentificationFailMypageUsingGETRequest
 */
export interface MemberAuthApiIdentificationFailMypageUsingGETRequest {
    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationFailMypageUsingGET
     */
    readonly creationTime?: number

    /**
     * 
     * @type {string}
     * @memberof MemberAuthApiIdentificationFailMypageUsingGET
     */
    readonly id?: string

    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationFailMypageUsingGET
     */
    readonly lastAccessedTime?: number

    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationFailMypageUsingGET
     */
    readonly maxInactiveInterval?: number

    /**
     * 
     * @type {boolean}
     * @memberof MemberAuthApiIdentificationFailMypageUsingGET
     */
    readonly _new?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof MemberAuthApiIdentificationFailMypageUsingGET
     */
    readonly valueNames?: Array<string>
}

/**
 * Request parameters for identificationFailMypageUsingPOST operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiIdentificationFailMypageUsingPOSTRequest
 */
export interface MemberAuthApiIdentificationFailMypageUsingPOSTRequest {
    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationFailMypageUsingPOST
     */
    readonly creationTime?: number

    /**
     * 
     * @type {string}
     * @memberof MemberAuthApiIdentificationFailMypageUsingPOST
     */
    readonly id?: string

    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationFailMypageUsingPOST
     */
    readonly lastAccessedTime?: number

    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationFailMypageUsingPOST
     */
    readonly maxInactiveInterval?: number

    /**
     * 
     * @type {boolean}
     * @memberof MemberAuthApiIdentificationFailMypageUsingPOST
     */
    readonly _new?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof MemberAuthApiIdentificationFailMypageUsingPOST
     */
    readonly valueNames?: Array<string>
}

/**
 * Request parameters for identificationFailUsingGET operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiIdentificationFailUsingGETRequest
 */
export interface MemberAuthApiIdentificationFailUsingGETRequest {
    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationFailUsingGET
     */
    readonly creationTime?: number

    /**
     * 
     * @type {string}
     * @memberof MemberAuthApiIdentificationFailUsingGET
     */
    readonly id?: string

    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationFailUsingGET
     */
    readonly lastAccessedTime?: number

    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationFailUsingGET
     */
    readonly maxInactiveInterval?: number

    /**
     * 
     * @type {boolean}
     * @memberof MemberAuthApiIdentificationFailUsingGET
     */
    readonly _new?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof MemberAuthApiIdentificationFailUsingGET
     */
    readonly valueNames?: Array<string>
}

/**
 * Request parameters for identificationFailUsingPOST operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiIdentificationFailUsingPOSTRequest
 */
export interface MemberAuthApiIdentificationFailUsingPOSTRequest {
    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationFailUsingPOST
     */
    readonly creationTime?: number

    /**
     * 
     * @type {string}
     * @memberof MemberAuthApiIdentificationFailUsingPOST
     */
    readonly id?: string

    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationFailUsingPOST
     */
    readonly lastAccessedTime?: number

    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationFailUsingPOST
     */
    readonly maxInactiveInterval?: number

    /**
     * 
     * @type {boolean}
     * @memberof MemberAuthApiIdentificationFailUsingPOST
     */
    readonly _new?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof MemberAuthApiIdentificationFailUsingPOST
     */
    readonly valueNames?: Array<string>
}

/**
 * Request parameters for identificationInfoUsingGET operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiIdentificationInfoUsingGETRequest
 */
export interface MemberAuthApiIdentificationInfoUsingGETRequest {
    /**
     * redirectType
     * @type {'mobile_join' | 'mobile_mypage' | 'pc'}
     * @memberof MemberAuthApiIdentificationInfoUsingGET
     */
    readonly redirectType?: 'mobile_join' | 'mobile_mypage' | 'pc'
}

/**
 * Request parameters for identificationResultUsingGET operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiIdentificationResultUsingGETRequest
 */
export interface MemberAuthApiIdentificationResultUsingGETRequest {
    /**
     * identify-key
     * @type {string}
     * @memberof MemberAuthApiIdentificationResultUsingGET
     */
    readonly identifyKey?: string
}

/**
 * Request parameters for identificationSuccessMobileJoinUsingGET operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiIdentificationSuccessMobileJoinUsingGETRequest
 */
export interface MemberAuthApiIdentificationSuccessMobileJoinUsingGETRequest {
    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationSuccessMobileJoinUsingGET
     */
    readonly creationTime?: number

    /**
     * 
     * @type {string}
     * @memberof MemberAuthApiIdentificationSuccessMobileJoinUsingGET
     */
    readonly id?: string

    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationSuccessMobileJoinUsingGET
     */
    readonly lastAccessedTime?: number

    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationSuccessMobileJoinUsingGET
     */
    readonly maxInactiveInterval?: number

    /**
     * 
     * @type {boolean}
     * @memberof MemberAuthApiIdentificationSuccessMobileJoinUsingGET
     */
    readonly _new?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof MemberAuthApiIdentificationSuccessMobileJoinUsingGET
     */
    readonly valueNames?: Array<string>
}

/**
 * Request parameters for identificationSuccessMobileJoinUsingPOST operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiIdentificationSuccessMobileJoinUsingPOSTRequest
 */
export interface MemberAuthApiIdentificationSuccessMobileJoinUsingPOSTRequest {
    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationSuccessMobileJoinUsingPOST
     */
    readonly creationTime?: number

    /**
     * 
     * @type {string}
     * @memberof MemberAuthApiIdentificationSuccessMobileJoinUsingPOST
     */
    readonly id?: string

    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationSuccessMobileJoinUsingPOST
     */
    readonly lastAccessedTime?: number

    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationSuccessMobileJoinUsingPOST
     */
    readonly maxInactiveInterval?: number

    /**
     * 
     * @type {boolean}
     * @memberof MemberAuthApiIdentificationSuccessMobileJoinUsingPOST
     */
    readonly _new?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof MemberAuthApiIdentificationSuccessMobileJoinUsingPOST
     */
    readonly valueNames?: Array<string>
}

/**
 * Request parameters for identificationSuccessMobileMypageUsingGET operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiIdentificationSuccessMobileMypageUsingGETRequest
 */
export interface MemberAuthApiIdentificationSuccessMobileMypageUsingGETRequest {
    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationSuccessMobileMypageUsingGET
     */
    readonly creationTime?: number

    /**
     * 
     * @type {string}
     * @memberof MemberAuthApiIdentificationSuccessMobileMypageUsingGET
     */
    readonly id?: string

    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationSuccessMobileMypageUsingGET
     */
    readonly lastAccessedTime?: number

    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationSuccessMobileMypageUsingGET
     */
    readonly maxInactiveInterval?: number

    /**
     * 
     * @type {boolean}
     * @memberof MemberAuthApiIdentificationSuccessMobileMypageUsingGET
     */
    readonly _new?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof MemberAuthApiIdentificationSuccessMobileMypageUsingGET
     */
    readonly valueNames?: Array<string>
}

/**
 * Request parameters for identificationSuccessMobileMypageUsingPOST operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiIdentificationSuccessMobileMypageUsingPOSTRequest
 */
export interface MemberAuthApiIdentificationSuccessMobileMypageUsingPOSTRequest {
    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationSuccessMobileMypageUsingPOST
     */
    readonly creationTime?: number

    /**
     * 
     * @type {string}
     * @memberof MemberAuthApiIdentificationSuccessMobileMypageUsingPOST
     */
    readonly id?: string

    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationSuccessMobileMypageUsingPOST
     */
    readonly lastAccessedTime?: number

    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationSuccessMobileMypageUsingPOST
     */
    readonly maxInactiveInterval?: number

    /**
     * 
     * @type {boolean}
     * @memberof MemberAuthApiIdentificationSuccessMobileMypageUsingPOST
     */
    readonly _new?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof MemberAuthApiIdentificationSuccessMobileMypageUsingPOST
     */
    readonly valueNames?: Array<string>
}

/**
 * Request parameters for identificationSuccessUsingGET operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiIdentificationSuccessUsingGETRequest
 */
export interface MemberAuthApiIdentificationSuccessUsingGETRequest {
    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationSuccessUsingGET
     */
    readonly creationTime?: number

    /**
     * 
     * @type {string}
     * @memberof MemberAuthApiIdentificationSuccessUsingGET
     */
    readonly id?: string

    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationSuccessUsingGET
     */
    readonly lastAccessedTime?: number

    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationSuccessUsingGET
     */
    readonly maxInactiveInterval?: number

    /**
     * 
     * @type {boolean}
     * @memberof MemberAuthApiIdentificationSuccessUsingGET
     */
    readonly _new?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof MemberAuthApiIdentificationSuccessUsingGET
     */
    readonly valueNames?: Array<string>
}

/**
 * Request parameters for identificationSuccessUsingPOST operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiIdentificationSuccessUsingPOSTRequest
 */
export interface MemberAuthApiIdentificationSuccessUsingPOSTRequest {
    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationSuccessUsingPOST
     */
    readonly creationTime?: number

    /**
     * 
     * @type {string}
     * @memberof MemberAuthApiIdentificationSuccessUsingPOST
     */
    readonly id?: string

    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationSuccessUsingPOST
     */
    readonly lastAccessedTime?: number

    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiIdentificationSuccessUsingPOST
     */
    readonly maxInactiveInterval?: number

    /**
     * 
     * @type {boolean}
     * @memberof MemberAuthApiIdentificationSuccessUsingPOST
     */
    readonly _new?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof MemberAuthApiIdentificationSuccessUsingPOST
     */
    readonly valueNames?: Array<string>
}

/**
 * Request parameters for joinPartnerUsingPOST operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiJoinPartnerUsingPOSTRequest
 */
export interface MemberAuthApiJoinPartnerUsingPOSTRequest {
    /**
     * param
     * @type {MemberJoinDTO}
     * @memberof MemberAuthApiJoinPartnerUsingPOST
     */
    readonly param: MemberJoinDTO
}

/**
 * Request parameters for loginUsingPOST1 operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiLoginUsingPOST1Request
 */
export interface MemberAuthApiLoginUsingPOST1Request {
    /**
     * authParamDTO
     * @type {AuthParamDTO}
     * @memberof MemberAuthApiLoginUsingPOST1
     */
    readonly authParamDTO: AuthParamDTO
}

/**
 * Request parameters for logoutUsingGET1 operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiLogoutUsingGET1Request
 */
export interface MemberAuthApiLogoutUsingGET1Request {
    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiLogoutUsingGET1
     */
    readonly creationTime?: number

    /**
     * 
     * @type {string}
     * @memberof MemberAuthApiLogoutUsingGET1
     */
    readonly id?: string

    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiLogoutUsingGET1
     */
    readonly lastAccessedTime?: number

    /**
     * 
     * @type {number}
     * @memberof MemberAuthApiLogoutUsingGET1
     */
    readonly maxInactiveInterval?: number

    /**
     * 
     * @type {boolean}
     * @memberof MemberAuthApiLogoutUsingGET1
     */
    readonly _new?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof MemberAuthApiLogoutUsingGET1
     */
    readonly valueNames?: Array<string>
}

/**
 * Request parameters for resetPasswordUsingPOST operation in MemberAuthApi.
 * @export
 * @interface MemberAuthApiResetPasswordUsingPOSTRequest
 */
export interface MemberAuthApiResetPasswordUsingPOSTRequest {
    /**
     * param
     * @type {ResetPasswordParamDTO}
     * @memberof MemberAuthApiResetPasswordUsingPOST
     */
    readonly param: ResetPasswordParamDTO
}

/**
 * MemberAuthApi - object-oriented interface
 * @export
 * @class MemberAuthApi
 * @extends {BaseAPI}
 */
export class MemberAuthApi extends BaseAPI {
    /**
     * 이메일 중복 체크
     * @summary checkEmail
     * @param {MemberAuthApiCheckEmailUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public checkEmailUsingPOST(requestParameters: MemberAuthApiCheckEmailUsingPOSTRequest, options?: any) {
        return MemberAuthApiFp(this.configuration).checkEmailUsingPOST(requestParameters.email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 휴대폰 중복 체크
     * @summary checkMobile
     * @param {MemberAuthApiCheckMobileUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public checkMobileUsingPOST(requestParameters: MemberAuthApiCheckMobileUsingPOSTRequest, options?: any) {
        return MemberAuthApiFp(this.configuration).checkMobileUsingPOST(requestParameters.mobile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 실명인증 유/무
     * @summary dupInfo
     * @param {MemberAuthApiDupInfoUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public dupInfoUsingGET(requestParameters: MemberAuthApiDupInfoUsingGETRequest, options?: any) {
        return MemberAuthApiFp(this.configuration).dupInfoUsingGET(requestParameters.email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 이메일찾기
     * @summary findEmail
     * @param {MemberAuthApiFindEmailUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public findEmailUsingPOST(requestParameters: MemberAuthApiFindEmailUsingPOSTRequest, options?: any) {
        return MemberAuthApiFp(this.configuration).findEmailUsingPOST(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 비밀번호찾기
     * @summary findPassword
     * @param {MemberAuthApiFindEmailUsingPOST1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public findEmailUsingPOST1(requestParameters: MemberAuthApiFindEmailUsingPOST1Request, options?: any) {
        return MemberAuthApiFp(this.configuration).findEmailUsingPOST1(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary identificationFailJoin
     * @param {MemberAuthApiIdentificationFailJoinUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public identificationFailJoinUsingGET(requestParameters: MemberAuthApiIdentificationFailJoinUsingGETRequest = {}, options?: any) {
        return MemberAuthApiFp(this.configuration).identificationFailJoinUsingGET(requestParameters.creationTime, requestParameters.id, requestParameters.lastAccessedTime, requestParameters.maxInactiveInterval, requestParameters._new, requestParameters.valueNames, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary identificationFailJoin
     * @param {MemberAuthApiIdentificationFailJoinUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public identificationFailJoinUsingPOST(requestParameters: MemberAuthApiIdentificationFailJoinUsingPOSTRequest = {}, options?: any) {
        return MemberAuthApiFp(this.configuration).identificationFailJoinUsingPOST(requestParameters.creationTime, requestParameters.id, requestParameters.lastAccessedTime, requestParameters.maxInactiveInterval, requestParameters._new, requestParameters.valueNames, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary identificationFailMypage
     * @param {MemberAuthApiIdentificationFailMypageUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public identificationFailMypageUsingGET(requestParameters: MemberAuthApiIdentificationFailMypageUsingGETRequest = {}, options?: any) {
        return MemberAuthApiFp(this.configuration).identificationFailMypageUsingGET(requestParameters.creationTime, requestParameters.id, requestParameters.lastAccessedTime, requestParameters.maxInactiveInterval, requestParameters._new, requestParameters.valueNames, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary identificationFailMypage
     * @param {MemberAuthApiIdentificationFailMypageUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public identificationFailMypageUsingPOST(requestParameters: MemberAuthApiIdentificationFailMypageUsingPOSTRequest = {}, options?: any) {
        return MemberAuthApiFp(this.configuration).identificationFailMypageUsingPOST(requestParameters.creationTime, requestParameters.id, requestParameters.lastAccessedTime, requestParameters.maxInactiveInterval, requestParameters._new, requestParameters.valueNames, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary identificationFail
     * @param {MemberAuthApiIdentificationFailUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public identificationFailUsingGET(requestParameters: MemberAuthApiIdentificationFailUsingGETRequest = {}, options?: any) {
        return MemberAuthApiFp(this.configuration).identificationFailUsingGET(requestParameters.creationTime, requestParameters.id, requestParameters.lastAccessedTime, requestParameters.maxInactiveInterval, requestParameters._new, requestParameters.valueNames, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary identificationFail
     * @param {MemberAuthApiIdentificationFailUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public identificationFailUsingPOST(requestParameters: MemberAuthApiIdentificationFailUsingPOSTRequest = {}, options?: any) {
        return MemberAuthApiFp(this.configuration).identificationFailUsingPOST(requestParameters.creationTime, requestParameters.id, requestParameters.lastAccessedTime, requestParameters.maxInactiveInterval, requestParameters._new, requestParameters.valueNames, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 실명인증 정보
     * @summary identification-info
     * @param {MemberAuthApiIdentificationInfoUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public identificationInfoUsingGET(requestParameters: MemberAuthApiIdentificationInfoUsingGETRequest = {}, options?: any) {
        return MemberAuthApiFp(this.configuration).identificationInfoUsingGET(requestParameters.redirectType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 인증 정보 가져오기
     * @summary identification-result
     * @param {MemberAuthApiIdentificationResultUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public identificationResultUsingGET(requestParameters: MemberAuthApiIdentificationResultUsingGETRequest = {}, options?: any) {
        return MemberAuthApiFp(this.configuration).identificationResultUsingGET(requestParameters.identifyKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary identificationSuccessMobileJoin
     * @param {MemberAuthApiIdentificationSuccessMobileJoinUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public identificationSuccessMobileJoinUsingGET(requestParameters: MemberAuthApiIdentificationSuccessMobileJoinUsingGETRequest = {}, options?: any) {
        return MemberAuthApiFp(this.configuration).identificationSuccessMobileJoinUsingGET(requestParameters.creationTime, requestParameters.id, requestParameters.lastAccessedTime, requestParameters.maxInactiveInterval, requestParameters._new, requestParameters.valueNames, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary identificationSuccessMobileJoin
     * @param {MemberAuthApiIdentificationSuccessMobileJoinUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public identificationSuccessMobileJoinUsingPOST(requestParameters: MemberAuthApiIdentificationSuccessMobileJoinUsingPOSTRequest = {}, options?: any) {
        return MemberAuthApiFp(this.configuration).identificationSuccessMobileJoinUsingPOST(requestParameters.creationTime, requestParameters.id, requestParameters.lastAccessedTime, requestParameters.maxInactiveInterval, requestParameters._new, requestParameters.valueNames, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary identificationSuccessMobileMypage
     * @param {MemberAuthApiIdentificationSuccessMobileMypageUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public identificationSuccessMobileMypageUsingGET(requestParameters: MemberAuthApiIdentificationSuccessMobileMypageUsingGETRequest = {}, options?: any) {
        return MemberAuthApiFp(this.configuration).identificationSuccessMobileMypageUsingGET(requestParameters.creationTime, requestParameters.id, requestParameters.lastAccessedTime, requestParameters.maxInactiveInterval, requestParameters._new, requestParameters.valueNames, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary identificationSuccessMobileMypage
     * @param {MemberAuthApiIdentificationSuccessMobileMypageUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public identificationSuccessMobileMypageUsingPOST(requestParameters: MemberAuthApiIdentificationSuccessMobileMypageUsingPOSTRequest = {}, options?: any) {
        return MemberAuthApiFp(this.configuration).identificationSuccessMobileMypageUsingPOST(requestParameters.creationTime, requestParameters.id, requestParameters.lastAccessedTime, requestParameters.maxInactiveInterval, requestParameters._new, requestParameters.valueNames, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary identificationSuccess
     * @param {MemberAuthApiIdentificationSuccessUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public identificationSuccessUsingGET(requestParameters: MemberAuthApiIdentificationSuccessUsingGETRequest = {}, options?: any) {
        return MemberAuthApiFp(this.configuration).identificationSuccessUsingGET(requestParameters.creationTime, requestParameters.id, requestParameters.lastAccessedTime, requestParameters.maxInactiveInterval, requestParameters._new, requestParameters.valueNames, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary identificationSuccess
     * @param {MemberAuthApiIdentificationSuccessUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public identificationSuccessUsingPOST(requestParameters: MemberAuthApiIdentificationSuccessUsingPOSTRequest = {}, options?: any) {
        return MemberAuthApiFp(this.configuration).identificationSuccessUsingPOST(requestParameters.creationTime, requestParameters.id, requestParameters.lastAccessedTime, requestParameters.maxInactiveInterval, requestParameters._new, requestParameters.valueNames, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 회원 가입
     * @summary join
     * @param {MemberAuthApiJoinPartnerUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public joinPartnerUsingPOST(requestParameters: MemberAuthApiJoinPartnerUsingPOSTRequest, options?: any) {
        return MemberAuthApiFp(this.configuration).joinPartnerUsingPOST(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 로그인
     * @summary login
     * @param {MemberAuthApiLoginUsingPOST1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public loginUsingPOST1(requestParameters: MemberAuthApiLoginUsingPOST1Request, options?: any) {
        return MemberAuthApiFp(this.configuration).loginUsingPOST1(requestParameters.authParamDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 로그아웃
     * @summary logout
     * @param {MemberAuthApiLogoutUsingGET1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public logoutUsingGET1(requestParameters: MemberAuthApiLogoutUsingGET1Request = {}, options?: any) {
        return MemberAuthApiFp(this.configuration).logoutUsingGET1(requestParameters.creationTime, requestParameters.id, requestParameters.lastAccessedTime, requestParameters.maxInactiveInterval, requestParameters._new, requestParameters.valueNames, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 회원 탈퇴
     * @summary signout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public memberSignout(options?: any) {
        return MemberAuthApiFp(this.configuration).memberSignout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 비번번호 재설정
     * @summary resetPassword
     * @param {MemberAuthApiResetPasswordUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAuthApi
     */
    public resetPasswordUsingPOST(requestParameters: MemberAuthApiResetPasswordUsingPOSTRequest, options?: any) {
        return MemberAuthApiFp(this.configuration).resetPasswordUsingPOST(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MyPageControllerApi - axios parameter creator
 * @export
 */
export const MyPageControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 배송지 추가
         * @summary shppingAddressChange
         * @param {ShippingAddress} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAddressUsingPOST: async (param: ShippingAddress, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('addAddressUsingPOST', 'param', param)
            const localVarPath = `/api/mypage/addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Qna 등록
         * @summary myQnaAdd
         * @param {ProductQnaDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addQnaUsingPOST: async (param: ProductQnaDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('addQnaUsingPOST', 'param', param)
            const localVarPath = `/api/mypage/qna/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 구매확정
         * @summary orderCompleteConfirm
         * @param {OrderCompleteConfirmDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmOrderCompleteUsingPOST: async (param: OrderCompleteConfirmDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('confirmOrderCompleteUsingPOST', 'param', param)
            const localVarPath = `/api/mypage/order-confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 배송지 삭제
         * @summary shppingAddressChange
         * @param {number} shippingAddressId shippingAddressId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAddressUsingDELETE: async (shippingAddressId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'shippingAddressId' is not null or undefined
            assertParamExists('deleteAddressUsingDELETE', 'shippingAddressId', shippingAddressId)
            const localVarPath = `/api/mypage/addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (shippingAddressId !== undefined) {
                localVarQueryParameter['shippingAddressId'] = shippingAddressId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 배송조회
         * @summary shippingTracking
         * @param {string} ordernum ordernum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShippingTrackingUsingGET: async (ordernum: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ordernum' is not null or undefined
            assertParamExists('getShippingTrackingUsingGET', 'ordernum', ordernum)
            const localVarPath = `/api/mypage/shipping-tracking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ordernum !== undefined) {
                localVarQueryParameter['ordernum'] = ordernum;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 회원 프로필 파일업로드
         * @summary signupFileUpload
         * @param {number} [maxSize] maxSize
         * @param {boolean} [resize] resize
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFileUploadUsingPOST1: async (maxSize?: number, resize?: boolean, file?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mypage/signup-upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (maxSize !== undefined) {
                localVarQueryParameter['maxSize'] = maxSize;
            }

            if (resize !== undefined) {
                localVarQueryParameter['resize'] = resize;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 회원 정보 수정
         * @summary memberUpdate
         * @param {MemberUpdateDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinPartnerUsingPUT: async (param: MemberUpdateDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('joinPartnerUsingPUT', 'param', param)
            const localVarPath = `/api/mypage/member`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 내경매리스트
         * @summary myBidList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyBidUsingGET: async (page: number, pageSize: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('listMyBidUsingGET', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('listMyBidUsingGET', 'pageSize', pageSize)
            const localVarPath = `/api/mypage/bids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 회원정보 조회
         * @summary myinfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyBidUsingGET1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mypage/myinfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 내낙찰/주문리스트
         * @summary myOrderList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyOrderUsingGET: async (page: number, pageSize: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('listMyOrderUsingGET', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('listMyOrderUsingGET', 'pageSize', pageSize)
            const localVarPath = `/api/mypage/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 인보이스
         * @summary invoice
         * @param {string} ordernum ordernum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyOrderUsingGET1: async (ordernum: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ordernum' is not null or undefined
            assertParamExists('listMyOrderUsingGET1', 'ordernum', ordernum)
            const localVarPath = `/api/mypage/invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ordernum !== undefined) {
                localVarQueryParameter['ordernum'] = ordernum;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 좋아요 리스트
         * @summary myLikeList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myLikeListUsingGET: async (page: number, pageSize: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('myLikeListUsingGET', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('myLikeListUsingGET', 'pageSize', pageSize)
            const localVarPath = `/api/mypage/likes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 파트너좋아요 리스트
         * @summary myLikePartnerList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myLikePartnerListUsingGET: async (page: number, pageSize: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('myLikePartnerListUsingGET', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('myLikePartnerListUsingGET', 'pageSize', pageSize)
            const localVarPath = `/api/mypage/partner-likes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * qna 리스트
         * @summary myQnaList
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myQnaListUsingGET: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('myQnaListUsingGET', 'productId', productId)
            const localVarPath = `/api/mypage/qna-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * qna 상품  리스트
         * @summary myQnaProductList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myQnaProductListUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mypage/qna-products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * qna 추가 상품
         * @summary myQnaProductInfo
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        qnaProductInfoUsingGET: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('qnaProductInfoUsingGET', 'productId', productId)
            const localVarPath = `/api/mypage/qna-product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 반품요청
         * @summary requestReturn
         * @param {OrderCancelParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestReturnUsingPUT: async (param: OrderCancelParamDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('requestReturnUsingPUT', 'param', param)
            const localVarPath = `/api/mypage/request-return`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 배송지 리스트
         * @summary shippingAddressList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippingLitUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mypage/addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 배송지 수정
         * @summary shppingAddressChange
         * @param {ShippingAddress} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAddressUsingPUT: async (param: ShippingAddress, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('updateAddressUsingPUT', 'param', param)
            const localVarPath = `/api/mypage/addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 배송지 수정
         * @summary orderShippingAddressUpdate
         * @param {ShippingParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderAddressUsingPUT: async (param: ShippingParamDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('updateOrderAddressUsingPUT', 'param', param)
            const localVarPath = `/api/mypage/order-address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 주문취소
         * @summary orderCancel
         * @param {OrderCancelParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderCancelUsingPUT: async (param: OrderCancelParamDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('updateOrderCancelUsingPUT', 'param', param)
            const localVarPath = `/api/mypage/order-cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MyPageControllerApi - functional programming interface
 * @export
 */
export const MyPageControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MyPageControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 배송지 추가
         * @summary shppingAddressChange
         * @param {ShippingAddress} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAddressUsingPOST(param: ShippingAddress, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAddressUsingPOST(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Qna 등록
         * @summary myQnaAdd
         * @param {ProductQnaDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addQnaUsingPOST(param: ProductQnaDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addQnaUsingPOST(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 구매확정
         * @summary orderCompleteConfirm
         * @param {OrderCompleteConfirmDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmOrderCompleteUsingPOST(param: OrderCompleteConfirmDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmOrderCompleteUsingPOST(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 배송지 삭제
         * @summary shppingAddressChange
         * @param {number} shippingAddressId shippingAddressId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAddressUsingDELETE(shippingAddressId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAddressUsingDELETE(shippingAddressId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 배송조회
         * @summary shippingTracking
         * @param {string} ordernum ordernum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShippingTrackingUsingGET(ordernum: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShippingTrackingUsingGET(ordernum, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 회원 프로필 파일업로드
         * @summary signupFileUpload
         * @param {number} [maxSize] maxSize
         * @param {boolean} [resize] resize
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleFileUploadUsingPOST1(maxSize?: number, resize?: boolean, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleFileUploadUsingPOST1(maxSize, resize, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 회원 정보 수정
         * @summary memberUpdate
         * @param {MemberUpdateDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async joinPartnerUsingPUT(param: MemberUpdateDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.joinPartnerUsingPUT(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 내경매리스트
         * @summary myBidList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMyBidUsingGET(page: number, pageSize: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMyBidUsingGET(page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 회원정보 조회
         * @summary myinfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMyBidUsingGET1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMyBidUsingGET1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 내낙찰/주문리스트
         * @summary myOrderList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMyOrderUsingGET(page: number, pageSize: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMyOrderUsingGET(page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 인보이스
         * @summary invoice
         * @param {string} ordernum ordernum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMyOrderUsingGET1(ordernum: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMyOrderUsingGET1(ordernum, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 좋아요 리스트
         * @summary myLikeList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myLikeListUsingGET(page: number, pageSize: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.myLikeListUsingGET(page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 파트너좋아요 리스트
         * @summary myLikePartnerList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myLikePartnerListUsingGET(page: number, pageSize: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.myLikePartnerListUsingGET(page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * qna 리스트
         * @summary myQnaList
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myQnaListUsingGET(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.myQnaListUsingGET(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * qna 상품  리스트
         * @summary myQnaProductList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myQnaProductListUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.myQnaProductListUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * qna 추가 상품
         * @summary myQnaProductInfo
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async qnaProductInfoUsingGET(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.qnaProductInfoUsingGET(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 반품요청
         * @summary requestReturn
         * @param {OrderCancelParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestReturnUsingPUT(param: OrderCancelParamDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestReturnUsingPUT(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 배송지 리스트
         * @summary shippingAddressList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shippingLitUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shippingLitUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 배송지 수정
         * @summary shppingAddressChange
         * @param {ShippingAddress} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAddressUsingPUT(param: ShippingAddress, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAddressUsingPUT(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 배송지 수정
         * @summary orderShippingAddressUpdate
         * @param {ShippingParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrderAddressUsingPUT(param: ShippingParamDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrderAddressUsingPUT(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 주문취소
         * @summary orderCancel
         * @param {OrderCancelParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrderCancelUsingPUT(param: OrderCancelParamDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrderCancelUsingPUT(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MyPageControllerApi - factory interface
 * @export
 */
export const MyPageControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MyPageControllerApiFp(configuration)
    return {
        /**
         * 배송지 추가
         * @summary shppingAddressChange
         * @param {ShippingAddress} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAddressUsingPOST(param: ShippingAddress, options?: any): AxiosPromise<object> {
            return localVarFp.addAddressUsingPOST(param, options).then((request) => request(axios, basePath));
        },
        /**
         * Qna 등록
         * @summary myQnaAdd
         * @param {ProductQnaDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addQnaUsingPOST(param: ProductQnaDTO, options?: any): AxiosPromise<object> {
            return localVarFp.addQnaUsingPOST(param, options).then((request) => request(axios, basePath));
        },
        /**
         * 구매확정
         * @summary orderCompleteConfirm
         * @param {OrderCompleteConfirmDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmOrderCompleteUsingPOST(param: OrderCompleteConfirmDTO, options?: any): AxiosPromise<object> {
            return localVarFp.confirmOrderCompleteUsingPOST(param, options).then((request) => request(axios, basePath));
        },
        /**
         * 배송지 삭제
         * @summary shppingAddressChange
         * @param {number} shippingAddressId shippingAddressId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAddressUsingDELETE(shippingAddressId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteAddressUsingDELETE(shippingAddressId, options).then((request) => request(axios, basePath));
        },
        /**
         * 배송조회
         * @summary shippingTracking
         * @param {string} ordernum ordernum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShippingTrackingUsingGET(ordernum: string, options?: any): AxiosPromise<object> {
            return localVarFp.getShippingTrackingUsingGET(ordernum, options).then((request) => request(axios, basePath));
        },
        /**
         * 회원 프로필 파일업로드
         * @summary signupFileUpload
         * @param {number} [maxSize] maxSize
         * @param {boolean} [resize] resize
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFileUploadUsingPOST1(maxSize?: number, resize?: boolean, file?: any, options?: any): AxiosPromise<string> {
            return localVarFp.handleFileUploadUsingPOST1(maxSize, resize, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 회원 정보 수정
         * @summary memberUpdate
         * @param {MemberUpdateDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinPartnerUsingPUT(param: MemberUpdateDTO, options?: any): AxiosPromise<object> {
            return localVarFp.joinPartnerUsingPUT(param, options).then((request) => request(axios, basePath));
        },
        /**
         * 내경매리스트
         * @summary myBidList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyBidUsingGET(page: number, pageSize: number, options?: any): AxiosPromise<object> {
            return localVarFp.listMyBidUsingGET(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 회원정보 조회
         * @summary myinfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyBidUsingGET1(options?: any): AxiosPromise<object> {
            return localVarFp.listMyBidUsingGET1(options).then((request) => request(axios, basePath));
        },
        /**
         * 내낙찰/주문리스트
         * @summary myOrderList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyOrderUsingGET(page: number, pageSize: number, options?: any): AxiosPromise<object> {
            return localVarFp.listMyOrderUsingGET(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 인보이스
         * @summary invoice
         * @param {string} ordernum ordernum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyOrderUsingGET1(ordernum: string, options?: any): AxiosPromise<object> {
            return localVarFp.listMyOrderUsingGET1(ordernum, options).then((request) => request(axios, basePath));
        },
        /**
         * 좋아요 리스트
         * @summary myLikeList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myLikeListUsingGET(page: number, pageSize: number, options?: any): AxiosPromise<object> {
            return localVarFp.myLikeListUsingGET(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 파트너좋아요 리스트
         * @summary myLikePartnerList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myLikePartnerListUsingGET(page: number, pageSize: number, options?: any): AxiosPromise<object> {
            return localVarFp.myLikePartnerListUsingGET(page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * qna 리스트
         * @summary myQnaList
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myQnaListUsingGET(productId: number, options?: any): AxiosPromise<object> {
            return localVarFp.myQnaListUsingGET(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * qna 상품  리스트
         * @summary myQnaProductList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myQnaProductListUsingGET(options?: any): AxiosPromise<object> {
            return localVarFp.myQnaProductListUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * qna 추가 상품
         * @summary myQnaProductInfo
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        qnaProductInfoUsingGET(productId: number, options?: any): AxiosPromise<object> {
            return localVarFp.qnaProductInfoUsingGET(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 반품요청
         * @summary requestReturn
         * @param {OrderCancelParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestReturnUsingPUT(param: OrderCancelParamDTO, options?: any): AxiosPromise<object> {
            return localVarFp.requestReturnUsingPUT(param, options).then((request) => request(axios, basePath));
        },
        /**
         * 배송지 리스트
         * @summary shippingAddressList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippingLitUsingGET(options?: any): AxiosPromise<object> {
            return localVarFp.shippingLitUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 배송지 수정
         * @summary shppingAddressChange
         * @param {ShippingAddress} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAddressUsingPUT(param: ShippingAddress, options?: any): AxiosPromise<object> {
            return localVarFp.updateAddressUsingPUT(param, options).then((request) => request(axios, basePath));
        },
        /**
         * 배송지 수정
         * @summary orderShippingAddressUpdate
         * @param {ShippingParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderAddressUsingPUT(param: ShippingParamDTO, options?: any): AxiosPromise<object> {
            return localVarFp.updateOrderAddressUsingPUT(param, options).then((request) => request(axios, basePath));
        },
        /**
         * 주문취소
         * @summary orderCancel
         * @param {OrderCancelParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderCancelUsingPUT(param: OrderCancelParamDTO, options?: any): AxiosPromise<object> {
            return localVarFp.updateOrderCancelUsingPUT(param, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addAddressUsingPOST operation in MyPageControllerApi.
 * @export
 * @interface MyPageControllerApiAddAddressUsingPOSTRequest
 */
export interface MyPageControllerApiAddAddressUsingPOSTRequest {
    /**
     * param
     * @type {ShippingAddress}
     * @memberof MyPageControllerApiAddAddressUsingPOST
     */
    readonly param: ShippingAddress
}

/**
 * Request parameters for addQnaUsingPOST operation in MyPageControllerApi.
 * @export
 * @interface MyPageControllerApiAddQnaUsingPOSTRequest
 */
export interface MyPageControllerApiAddQnaUsingPOSTRequest {
    /**
     * param
     * @type {ProductQnaDTO}
     * @memberof MyPageControllerApiAddQnaUsingPOST
     */
    readonly param: ProductQnaDTO
}

/**
 * Request parameters for confirmOrderCompleteUsingPOST operation in MyPageControllerApi.
 * @export
 * @interface MyPageControllerApiConfirmOrderCompleteUsingPOSTRequest
 */
export interface MyPageControllerApiConfirmOrderCompleteUsingPOSTRequest {
    /**
     * param
     * @type {OrderCompleteConfirmDTO}
     * @memberof MyPageControllerApiConfirmOrderCompleteUsingPOST
     */
    readonly param: OrderCompleteConfirmDTO
}

/**
 * Request parameters for deleteAddressUsingDELETE operation in MyPageControllerApi.
 * @export
 * @interface MyPageControllerApiDeleteAddressUsingDELETERequest
 */
export interface MyPageControllerApiDeleteAddressUsingDELETERequest {
    /**
     * shippingAddressId
     * @type {number}
     * @memberof MyPageControllerApiDeleteAddressUsingDELETE
     */
    readonly shippingAddressId: number
}

/**
 * Request parameters for getShippingTrackingUsingGET operation in MyPageControllerApi.
 * @export
 * @interface MyPageControllerApiGetShippingTrackingUsingGETRequest
 */
export interface MyPageControllerApiGetShippingTrackingUsingGETRequest {
    /**
     * ordernum
     * @type {string}
     * @memberof MyPageControllerApiGetShippingTrackingUsingGET
     */
    readonly ordernum: string
}

/**
 * Request parameters for handleFileUploadUsingPOST1 operation in MyPageControllerApi.
 * @export
 * @interface MyPageControllerApiHandleFileUploadUsingPOST1Request
 */
export interface MyPageControllerApiHandleFileUploadUsingPOST1Request {
    /**
     * maxSize
     * @type {number}
     * @memberof MyPageControllerApiHandleFileUploadUsingPOST1
     */
    readonly maxSize?: number

    /**
     * resize
     * @type {boolean}
     * @memberof MyPageControllerApiHandleFileUploadUsingPOST1
     */
    readonly resize?: boolean

    /**
     * 
     * @type {any}
     * @memberof MyPageControllerApiHandleFileUploadUsingPOST1
     */
    readonly file?: any
}

/**
 * Request parameters for joinPartnerUsingPUT operation in MyPageControllerApi.
 * @export
 * @interface MyPageControllerApiJoinPartnerUsingPUTRequest
 */
export interface MyPageControllerApiJoinPartnerUsingPUTRequest {
    /**
     * param
     * @type {MemberUpdateDTO}
     * @memberof MyPageControllerApiJoinPartnerUsingPUT
     */
    readonly param: MemberUpdateDTO
}

/**
 * Request parameters for listMyBidUsingGET operation in MyPageControllerApi.
 * @export
 * @interface MyPageControllerApiListMyBidUsingGETRequest
 */
export interface MyPageControllerApiListMyBidUsingGETRequest {
    /**
     * page
     * @type {number}
     * @memberof MyPageControllerApiListMyBidUsingGET
     */
    readonly page: number

    /**
     * pageSize
     * @type {number}
     * @memberof MyPageControllerApiListMyBidUsingGET
     */
    readonly pageSize: number
}

/**
 * Request parameters for listMyOrderUsingGET operation in MyPageControllerApi.
 * @export
 * @interface MyPageControllerApiListMyOrderUsingGETRequest
 */
export interface MyPageControllerApiListMyOrderUsingGETRequest {
    /**
     * page
     * @type {number}
     * @memberof MyPageControllerApiListMyOrderUsingGET
     */
    readonly page: number

    /**
     * pageSize
     * @type {number}
     * @memberof MyPageControllerApiListMyOrderUsingGET
     */
    readonly pageSize: number
}

/**
 * Request parameters for listMyOrderUsingGET1 operation in MyPageControllerApi.
 * @export
 * @interface MyPageControllerApiListMyOrderUsingGET1Request
 */
export interface MyPageControllerApiListMyOrderUsingGET1Request {
    /**
     * ordernum
     * @type {string}
     * @memberof MyPageControllerApiListMyOrderUsingGET1
     */
    readonly ordernum: string
}

/**
 * Request parameters for myLikeListUsingGET operation in MyPageControllerApi.
 * @export
 * @interface MyPageControllerApiMyLikeListUsingGETRequest
 */
export interface MyPageControllerApiMyLikeListUsingGETRequest {
    /**
     * page
     * @type {number}
     * @memberof MyPageControllerApiMyLikeListUsingGET
     */
    readonly page: number

    /**
     * pageSize
     * @type {number}
     * @memberof MyPageControllerApiMyLikeListUsingGET
     */
    readonly pageSize: number
}

/**
 * Request parameters for myLikePartnerListUsingGET operation in MyPageControllerApi.
 * @export
 * @interface MyPageControllerApiMyLikePartnerListUsingGETRequest
 */
export interface MyPageControllerApiMyLikePartnerListUsingGETRequest {
    /**
     * page
     * @type {number}
     * @memberof MyPageControllerApiMyLikePartnerListUsingGET
     */
    readonly page: number

    /**
     * pageSize
     * @type {number}
     * @memberof MyPageControllerApiMyLikePartnerListUsingGET
     */
    readonly pageSize: number
}

/**
 * Request parameters for myQnaListUsingGET operation in MyPageControllerApi.
 * @export
 * @interface MyPageControllerApiMyQnaListUsingGETRequest
 */
export interface MyPageControllerApiMyQnaListUsingGETRequest {
    /**
     * productId
     * @type {number}
     * @memberof MyPageControllerApiMyQnaListUsingGET
     */
    readonly productId: number
}

/**
 * Request parameters for qnaProductInfoUsingGET operation in MyPageControllerApi.
 * @export
 * @interface MyPageControllerApiQnaProductInfoUsingGETRequest
 */
export interface MyPageControllerApiQnaProductInfoUsingGETRequest {
    /**
     * productId
     * @type {number}
     * @memberof MyPageControllerApiQnaProductInfoUsingGET
     */
    readonly productId: number
}

/**
 * Request parameters for requestReturnUsingPUT operation in MyPageControllerApi.
 * @export
 * @interface MyPageControllerApiRequestReturnUsingPUTRequest
 */
export interface MyPageControllerApiRequestReturnUsingPUTRequest {
    /**
     * param
     * @type {OrderCancelParamDTO}
     * @memberof MyPageControllerApiRequestReturnUsingPUT
     */
    readonly param: OrderCancelParamDTO
}

/**
 * Request parameters for updateAddressUsingPUT operation in MyPageControllerApi.
 * @export
 * @interface MyPageControllerApiUpdateAddressUsingPUTRequest
 */
export interface MyPageControllerApiUpdateAddressUsingPUTRequest {
    /**
     * param
     * @type {ShippingAddress}
     * @memberof MyPageControllerApiUpdateAddressUsingPUT
     */
    readonly param: ShippingAddress
}

/**
 * Request parameters for updateOrderAddressUsingPUT operation in MyPageControllerApi.
 * @export
 * @interface MyPageControllerApiUpdateOrderAddressUsingPUTRequest
 */
export interface MyPageControllerApiUpdateOrderAddressUsingPUTRequest {
    /**
     * param
     * @type {ShippingParamDTO}
     * @memberof MyPageControllerApiUpdateOrderAddressUsingPUT
     */
    readonly param: ShippingParamDTO
}

/**
 * Request parameters for updateOrderCancelUsingPUT operation in MyPageControllerApi.
 * @export
 * @interface MyPageControllerApiUpdateOrderCancelUsingPUTRequest
 */
export interface MyPageControllerApiUpdateOrderCancelUsingPUTRequest {
    /**
     * param
     * @type {OrderCancelParamDTO}
     * @memberof MyPageControllerApiUpdateOrderCancelUsingPUT
     */
    readonly param: OrderCancelParamDTO
}

/**
 * MyPageControllerApi - object-oriented interface
 * @export
 * @class MyPageControllerApi
 * @extends {BaseAPI}
 */
export class MyPageControllerApi extends BaseAPI {
    /**
     * 배송지 추가
     * @summary shppingAddressChange
     * @param {MyPageControllerApiAddAddressUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPageControllerApi
     */
    public addAddressUsingPOST(requestParameters: MyPageControllerApiAddAddressUsingPOSTRequest, options?: any) {
        return MyPageControllerApiFp(this.configuration).addAddressUsingPOST(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Qna 등록
     * @summary myQnaAdd
     * @param {MyPageControllerApiAddQnaUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPageControllerApi
     */
    public addQnaUsingPOST(requestParameters: MyPageControllerApiAddQnaUsingPOSTRequest, options?: any) {
        return MyPageControllerApiFp(this.configuration).addQnaUsingPOST(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 구매확정
     * @summary orderCompleteConfirm
     * @param {MyPageControllerApiConfirmOrderCompleteUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPageControllerApi
     */
    public confirmOrderCompleteUsingPOST(requestParameters: MyPageControllerApiConfirmOrderCompleteUsingPOSTRequest, options?: any) {
        return MyPageControllerApiFp(this.configuration).confirmOrderCompleteUsingPOST(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 배송지 삭제
     * @summary shppingAddressChange
     * @param {MyPageControllerApiDeleteAddressUsingDELETERequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPageControllerApi
     */
    public deleteAddressUsingDELETE(requestParameters: MyPageControllerApiDeleteAddressUsingDELETERequest, options?: any) {
        return MyPageControllerApiFp(this.configuration).deleteAddressUsingDELETE(requestParameters.shippingAddressId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 배송조회
     * @summary shippingTracking
     * @param {MyPageControllerApiGetShippingTrackingUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPageControllerApi
     */
    public getShippingTrackingUsingGET(requestParameters: MyPageControllerApiGetShippingTrackingUsingGETRequest, options?: any) {
        return MyPageControllerApiFp(this.configuration).getShippingTrackingUsingGET(requestParameters.ordernum, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 회원 프로필 파일업로드
     * @summary signupFileUpload
     * @param {MyPageControllerApiHandleFileUploadUsingPOST1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPageControllerApi
     */
    public handleFileUploadUsingPOST1(requestParameters: MyPageControllerApiHandleFileUploadUsingPOST1Request = {}, options?: any) {
        return MyPageControllerApiFp(this.configuration).handleFileUploadUsingPOST1(requestParameters.maxSize, requestParameters.resize, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 회원 정보 수정
     * @summary memberUpdate
     * @param {MyPageControllerApiJoinPartnerUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPageControllerApi
     */
    public joinPartnerUsingPUT(requestParameters: MyPageControllerApiJoinPartnerUsingPUTRequest, options?: any) {
        return MyPageControllerApiFp(this.configuration).joinPartnerUsingPUT(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 내경매리스트
     * @summary myBidList
     * @param {MyPageControllerApiListMyBidUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPageControllerApi
     */
    public listMyBidUsingGET(requestParameters: MyPageControllerApiListMyBidUsingGETRequest, options?: any) {
        return MyPageControllerApiFp(this.configuration).listMyBidUsingGET(requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 회원정보 조회
     * @summary myinfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPageControllerApi
     */
    public listMyBidUsingGET1(options?: any) {
        return MyPageControllerApiFp(this.configuration).listMyBidUsingGET1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 내낙찰/주문리스트
     * @summary myOrderList
     * @param {MyPageControllerApiListMyOrderUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPageControllerApi
     */
    public listMyOrderUsingGET(requestParameters: MyPageControllerApiListMyOrderUsingGETRequest, options?: any) {
        return MyPageControllerApiFp(this.configuration).listMyOrderUsingGET(requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 인보이스
     * @summary invoice
     * @param {MyPageControllerApiListMyOrderUsingGET1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPageControllerApi
     */
    public listMyOrderUsingGET1(requestParameters: MyPageControllerApiListMyOrderUsingGET1Request, options?: any) {
        return MyPageControllerApiFp(this.configuration).listMyOrderUsingGET1(requestParameters.ordernum, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 좋아요 리스트
     * @summary myLikeList
     * @param {MyPageControllerApiMyLikeListUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPageControllerApi
     */
    public myLikeListUsingGET(requestParameters: MyPageControllerApiMyLikeListUsingGETRequest, options?: any) {
        return MyPageControllerApiFp(this.configuration).myLikeListUsingGET(requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 파트너좋아요 리스트
     * @summary myLikePartnerList
     * @param {MyPageControllerApiMyLikePartnerListUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPageControllerApi
     */
    public myLikePartnerListUsingGET(requestParameters: MyPageControllerApiMyLikePartnerListUsingGETRequest, options?: any) {
        return MyPageControllerApiFp(this.configuration).myLikePartnerListUsingGET(requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * qna 리스트
     * @summary myQnaList
     * @param {MyPageControllerApiMyQnaListUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPageControllerApi
     */
    public myQnaListUsingGET(requestParameters: MyPageControllerApiMyQnaListUsingGETRequest, options?: any) {
        return MyPageControllerApiFp(this.configuration).myQnaListUsingGET(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * qna 상품  리스트
     * @summary myQnaProductList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPageControllerApi
     */
    public myQnaProductListUsingGET(options?: any) {
        return MyPageControllerApiFp(this.configuration).myQnaProductListUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * qna 추가 상품
     * @summary myQnaProductInfo
     * @param {MyPageControllerApiQnaProductInfoUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPageControllerApi
     */
    public qnaProductInfoUsingGET(requestParameters: MyPageControllerApiQnaProductInfoUsingGETRequest, options?: any) {
        return MyPageControllerApiFp(this.configuration).qnaProductInfoUsingGET(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 반품요청
     * @summary requestReturn
     * @param {MyPageControllerApiRequestReturnUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPageControllerApi
     */
    public requestReturnUsingPUT(requestParameters: MyPageControllerApiRequestReturnUsingPUTRequest, options?: any) {
        return MyPageControllerApiFp(this.configuration).requestReturnUsingPUT(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 배송지 리스트
     * @summary shippingAddressList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPageControllerApi
     */
    public shippingLitUsingGET(options?: any) {
        return MyPageControllerApiFp(this.configuration).shippingLitUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 배송지 수정
     * @summary shppingAddressChange
     * @param {MyPageControllerApiUpdateAddressUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPageControllerApi
     */
    public updateAddressUsingPUT(requestParameters: MyPageControllerApiUpdateAddressUsingPUTRequest, options?: any) {
        return MyPageControllerApiFp(this.configuration).updateAddressUsingPUT(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 배송지 수정
     * @summary orderShippingAddressUpdate
     * @param {MyPageControllerApiUpdateOrderAddressUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPageControllerApi
     */
    public updateOrderAddressUsingPUT(requestParameters: MyPageControllerApiUpdateOrderAddressUsingPUTRequest, options?: any) {
        return MyPageControllerApiFp(this.configuration).updateOrderAddressUsingPUT(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 주문취소
     * @summary orderCancel
     * @param {MyPageControllerApiUpdateOrderCancelUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyPageControllerApi
     */
    public updateOrderCancelUsingPUT(requestParameters: MyPageControllerApiUpdateOrderCancelUsingPUTRequest, options?: any) {
        return MyPageControllerApiFp(this.configuration).updateOrderCancelUsingPUT(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnerAuthApi - axios parameter creator
 * @export
 */
export const PartnerAuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 이메일체크
         * @summary checkEmail
         * @param {string} email email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkEmailUsingPOST1: async (email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('checkEmailUsingPOST1', 'email', email)
            const localVarPath = `/api/partner/email-check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 계좌 확인
         * @summary bankCheck
         * @param {PartnerBankCheckDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyCheckUsingPOST: async (param: PartnerBankCheckDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('companyCheckUsingPOST', 'param', param)
            const localVarPath = `/api/partner/bank-check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 사업자확인
         * @summary companyCheck
         * @param {PartnerCompanCheckDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyCheckUsingPOST1: async (param: PartnerCompanCheckDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('companyCheckUsingPOST1', 'param', param)
            const localVarPath = `/api/partner/company-check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 파트너가입 파일업로드
         * @summary signupFileUpload
         * @param {number} [maxSize] maxSize
         * @param {boolean} [resize] resize
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFileUploadUsingPOST2: async (maxSize?: number, resize?: boolean, file?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/partner/signup-upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (maxSize !== undefined) {
                localVarQueryParameter['maxSize'] = maxSize;
            }

            if (resize !== undefined) {
                localVarQueryParameter['resize'] = resize;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 파트너 가입
         * @summary join
         * @param {PartnerJoinDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinPartnerUsingPOST1: async (param: PartnerJoinDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('joinPartnerUsingPOST1', 'param', param)
            const localVarPath = `/api/partner/join`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 로그인
         * @summary login
         * @param {AuthParamDTO} authParamDTO authParamDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUsingPOST2: async (authParamDTO: AuthParamDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authParamDTO' is not null or undefined
            assertParamExists('loginUsingPOST2', 'authParamDTO', authParamDTO)
            const localVarPath = `/api/partner/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authParamDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 로그아웃
         * @summary logout
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutUsingGET2: async (creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/partner/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (creationTime !== undefined) {
                localVarQueryParameter['creationTime'] = creationTime;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (lastAccessedTime !== undefined) {
                localVarQueryParameter['lastAccessedTime'] = lastAccessedTime;
            }

            if (maxInactiveInterval !== undefined) {
                localVarQueryParameter['maxInactiveInterval'] = maxInactiveInterval;
            }

            if (_new !== undefined) {
                localVarQueryParameter['new'] = _new;
            }

            if (valueNames) {
                localVarQueryParameter['valueNames'] = valueNames;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerAuthApi - functional programming interface
 * @export
 */
export const PartnerAuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerAuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 이메일체크
         * @summary checkEmail
         * @param {string} email email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkEmailUsingPOST1(email: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkEmailUsingPOST1(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 계좌 확인
         * @summary bankCheck
         * @param {PartnerBankCheckDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyCheckUsingPOST(param: PartnerBankCheckDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyCheckUsingPOST(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 사업자확인
         * @summary companyCheck
         * @param {PartnerCompanCheckDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyCheckUsingPOST1(param: PartnerCompanCheckDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyCheckUsingPOST1(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 파트너가입 파일업로드
         * @summary signupFileUpload
         * @param {number} [maxSize] maxSize
         * @param {boolean} [resize] resize
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleFileUploadUsingPOST2(maxSize?: number, resize?: boolean, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleFileUploadUsingPOST2(maxSize, resize, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 파트너 가입
         * @summary join
         * @param {PartnerJoinDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async joinPartnerUsingPOST1(param: PartnerJoinDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.joinPartnerUsingPOST1(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 로그인
         * @summary login
         * @param {AuthParamDTO} authParamDTO authParamDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginUsingPOST2(authParamDTO: AuthParamDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginUsingPOST2(authParamDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 로그아웃
         * @summary logout
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutUsingGET2(creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logoutUsingGET2(creationTime, id, lastAccessedTime, maxInactiveInterval, _new, valueNames, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerAuthApi - factory interface
 * @export
 */
export const PartnerAuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerAuthApiFp(configuration)
    return {
        /**
         * 이메일체크
         * @summary checkEmail
         * @param {string} email email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkEmailUsingPOST1(email: string, options?: any): AxiosPromise<object> {
            return localVarFp.checkEmailUsingPOST1(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 계좌 확인
         * @summary bankCheck
         * @param {PartnerBankCheckDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyCheckUsingPOST(param: PartnerBankCheckDTO, options?: any): AxiosPromise<object> {
            return localVarFp.companyCheckUsingPOST(param, options).then((request) => request(axios, basePath));
        },
        /**
         * 사업자확인
         * @summary companyCheck
         * @param {PartnerCompanCheckDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyCheckUsingPOST1(param: PartnerCompanCheckDTO, options?: any): AxiosPromise<object> {
            return localVarFp.companyCheckUsingPOST1(param, options).then((request) => request(axios, basePath));
        },
        /**
         * 파트너가입 파일업로드
         * @summary signupFileUpload
         * @param {number} [maxSize] maxSize
         * @param {boolean} [resize] resize
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFileUploadUsingPOST2(maxSize?: number, resize?: boolean, file?: any, options?: any): AxiosPromise<string> {
            return localVarFp.handleFileUploadUsingPOST2(maxSize, resize, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 파트너 가입
         * @summary join
         * @param {PartnerJoinDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinPartnerUsingPOST1(param: PartnerJoinDTO, options?: any): AxiosPromise<object> {
            return localVarFp.joinPartnerUsingPOST1(param, options).then((request) => request(axios, basePath));
        },
        /**
         * 로그인
         * @summary login
         * @param {AuthParamDTO} authParamDTO authParamDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUsingPOST2(authParamDTO: AuthParamDTO, options?: any): AxiosPromise<object> {
            return localVarFp.loginUsingPOST2(authParamDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 로그아웃
         * @summary logout
         * @param {number} [creationTime] 
         * @param {string} [id] 
         * @param {number} [lastAccessedTime] 
         * @param {number} [maxInactiveInterval] 
         * @param {boolean} [_new] 
         * @param {Array<string>} [valueNames] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutUsingGET2(creationTime?: number, id?: string, lastAccessedTime?: number, maxInactiveInterval?: number, _new?: boolean, valueNames?: Array<string>, options?: any): AxiosPromise<string> {
            return localVarFp.logoutUsingGET2(creationTime, id, lastAccessedTime, maxInactiveInterval, _new, valueNames, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for checkEmailUsingPOST1 operation in PartnerAuthApi.
 * @export
 * @interface PartnerAuthApiCheckEmailUsingPOST1Request
 */
export interface PartnerAuthApiCheckEmailUsingPOST1Request {
    /**
     * email
     * @type {string}
     * @memberof PartnerAuthApiCheckEmailUsingPOST1
     */
    readonly email: string
}

/**
 * Request parameters for companyCheckUsingPOST operation in PartnerAuthApi.
 * @export
 * @interface PartnerAuthApiCompanyCheckUsingPOSTRequest
 */
export interface PartnerAuthApiCompanyCheckUsingPOSTRequest {
    /**
     * param
     * @type {PartnerBankCheckDTO}
     * @memberof PartnerAuthApiCompanyCheckUsingPOST
     */
    readonly param: PartnerBankCheckDTO
}

/**
 * Request parameters for companyCheckUsingPOST1 operation in PartnerAuthApi.
 * @export
 * @interface PartnerAuthApiCompanyCheckUsingPOST1Request
 */
export interface PartnerAuthApiCompanyCheckUsingPOST1Request {
    /**
     * param
     * @type {PartnerCompanCheckDTO}
     * @memberof PartnerAuthApiCompanyCheckUsingPOST1
     */
    readonly param: PartnerCompanCheckDTO
}

/**
 * Request parameters for handleFileUploadUsingPOST2 operation in PartnerAuthApi.
 * @export
 * @interface PartnerAuthApiHandleFileUploadUsingPOST2Request
 */
export interface PartnerAuthApiHandleFileUploadUsingPOST2Request {
    /**
     * maxSize
     * @type {number}
     * @memberof PartnerAuthApiHandleFileUploadUsingPOST2
     */
    readonly maxSize?: number

    /**
     * resize
     * @type {boolean}
     * @memberof PartnerAuthApiHandleFileUploadUsingPOST2
     */
    readonly resize?: boolean

    /**
     * 
     * @type {any}
     * @memberof PartnerAuthApiHandleFileUploadUsingPOST2
     */
    readonly file?: any
}

/**
 * Request parameters for joinPartnerUsingPOST1 operation in PartnerAuthApi.
 * @export
 * @interface PartnerAuthApiJoinPartnerUsingPOST1Request
 */
export interface PartnerAuthApiJoinPartnerUsingPOST1Request {
    /**
     * param
     * @type {PartnerJoinDTO}
     * @memberof PartnerAuthApiJoinPartnerUsingPOST1
     */
    readonly param: PartnerJoinDTO
}

/**
 * Request parameters for loginUsingPOST2 operation in PartnerAuthApi.
 * @export
 * @interface PartnerAuthApiLoginUsingPOST2Request
 */
export interface PartnerAuthApiLoginUsingPOST2Request {
    /**
     * authParamDTO
     * @type {AuthParamDTO}
     * @memberof PartnerAuthApiLoginUsingPOST2
     */
    readonly authParamDTO: AuthParamDTO
}

/**
 * Request parameters for logoutUsingGET2 operation in PartnerAuthApi.
 * @export
 * @interface PartnerAuthApiLogoutUsingGET2Request
 */
export interface PartnerAuthApiLogoutUsingGET2Request {
    /**
     * 
     * @type {number}
     * @memberof PartnerAuthApiLogoutUsingGET2
     */
    readonly creationTime?: number

    /**
     * 
     * @type {string}
     * @memberof PartnerAuthApiLogoutUsingGET2
     */
    readonly id?: string

    /**
     * 
     * @type {number}
     * @memberof PartnerAuthApiLogoutUsingGET2
     */
    readonly lastAccessedTime?: number

    /**
     * 
     * @type {number}
     * @memberof PartnerAuthApiLogoutUsingGET2
     */
    readonly maxInactiveInterval?: number

    /**
     * 
     * @type {boolean}
     * @memberof PartnerAuthApiLogoutUsingGET2
     */
    readonly _new?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof PartnerAuthApiLogoutUsingGET2
     */
    readonly valueNames?: Array<string>
}

/**
 * PartnerAuthApi - object-oriented interface
 * @export
 * @class PartnerAuthApi
 * @extends {BaseAPI}
 */
export class PartnerAuthApi extends BaseAPI {
    /**
     * 이메일체크
     * @summary checkEmail
     * @param {PartnerAuthApiCheckEmailUsingPOST1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAuthApi
     */
    public checkEmailUsingPOST1(requestParameters: PartnerAuthApiCheckEmailUsingPOST1Request, options?: any) {
        return PartnerAuthApiFp(this.configuration).checkEmailUsingPOST1(requestParameters.email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 계좌 확인
     * @summary bankCheck
     * @param {PartnerAuthApiCompanyCheckUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAuthApi
     */
    public companyCheckUsingPOST(requestParameters: PartnerAuthApiCompanyCheckUsingPOSTRequest, options?: any) {
        return PartnerAuthApiFp(this.configuration).companyCheckUsingPOST(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 사업자확인
     * @summary companyCheck
     * @param {PartnerAuthApiCompanyCheckUsingPOST1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAuthApi
     */
    public companyCheckUsingPOST1(requestParameters: PartnerAuthApiCompanyCheckUsingPOST1Request, options?: any) {
        return PartnerAuthApiFp(this.configuration).companyCheckUsingPOST1(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 파트너가입 파일업로드
     * @summary signupFileUpload
     * @param {PartnerAuthApiHandleFileUploadUsingPOST2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAuthApi
     */
    public handleFileUploadUsingPOST2(requestParameters: PartnerAuthApiHandleFileUploadUsingPOST2Request = {}, options?: any) {
        return PartnerAuthApiFp(this.configuration).handleFileUploadUsingPOST2(requestParameters.maxSize, requestParameters.resize, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 파트너 가입
     * @summary join
     * @param {PartnerAuthApiJoinPartnerUsingPOST1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAuthApi
     */
    public joinPartnerUsingPOST1(requestParameters: PartnerAuthApiJoinPartnerUsingPOST1Request, options?: any) {
        return PartnerAuthApiFp(this.configuration).joinPartnerUsingPOST1(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 로그인
     * @summary login
     * @param {PartnerAuthApiLoginUsingPOST2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAuthApi
     */
    public loginUsingPOST2(requestParameters: PartnerAuthApiLoginUsingPOST2Request, options?: any) {
        return PartnerAuthApiFp(this.configuration).loginUsingPOST2(requestParameters.authParamDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 로그아웃
     * @summary logout
     * @param {PartnerAuthApiLogoutUsingGET2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAuthApi
     */
    public logoutUsingGET2(requestParameters: PartnerAuthApiLogoutUsingGET2Request = {}, options?: any) {
        return PartnerAuthApiFp(this.configuration).logoutUsingGET2(requestParameters.creationTime, requestParameters.id, requestParameters.lastAccessedTime, requestParameters.maxInactiveInterval, requestParameters._new, requestParameters.valueNames, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnerDashboardApi - axios parameter creator
 * @export
 */
export const PartnerDashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 대시보드 조회
         * @summary dashboard
         * @param {'all' | 'last_month' | 'this_month' | 'two_month_ago'} [dashboardTerm] dashboardTerm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingGET: async (dashboardTerm?: 'all' | 'last_month' | 'this_month' | 'two_month_ago', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/partner/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dashboardTerm !== undefined) {
                localVarQueryParameter['dashboardTerm'] = dashboardTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerDashboardApi - functional programming interface
 * @export
 */
export const PartnerDashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerDashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 대시보드 조회
         * @summary dashboard
         * @param {'all' | 'last_month' | 'this_month' | 'two_month_ago'} [dashboardTerm] dashboardTerm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsingGET(dashboardTerm?: 'all' | 'last_month' | 'this_month' | 'two_month_ago', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsingGET(dashboardTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerDashboardApi - factory interface
 * @export
 */
export const PartnerDashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerDashboardApiFp(configuration)
    return {
        /**
         * 대시보드 조회
         * @summary dashboard
         * @param {'all' | 'last_month' | 'this_month' | 'two_month_ago'} [dashboardTerm] dashboardTerm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingGET(dashboardTerm?: 'all' | 'last_month' | 'this_month' | 'two_month_ago', options?: any): AxiosPromise<object> {
            return localVarFp.getUsingGET(dashboardTerm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getUsingGET operation in PartnerDashboardApi.
 * @export
 * @interface PartnerDashboardApiGetUsingGETRequest
 */
export interface PartnerDashboardApiGetUsingGETRequest {
    /**
     * dashboardTerm
     * @type {'all' | 'last_month' | 'this_month' | 'two_month_ago'}
     * @memberof PartnerDashboardApiGetUsingGET
     */
    readonly dashboardTerm?: 'all' | 'last_month' | 'this_month' | 'two_month_ago'
}

/**
 * PartnerDashboardApi - object-oriented interface
 * @export
 * @class PartnerDashboardApi
 * @extends {BaseAPI}
 */
export class PartnerDashboardApi extends BaseAPI {
    /**
     * 대시보드 조회
     * @summary dashboard
     * @param {PartnerDashboardApiGetUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerDashboardApi
     */
    public getUsingGET(requestParameters: PartnerDashboardApiGetUsingGETRequest = {}, options?: any) {
        return PartnerDashboardApiFp(this.configuration).getUsingGET(requestParameters.dashboardTerm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnerGuideApi - axios parameter creator
 * @export
 */
export const PartnerGuideApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 파트너가이드 조회
         * @summary guideSingle
         * @param {number} partnerGuideId partnerGuideId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingGET4: async (partnerGuideId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerGuideId' is not null or undefined
            assertParamExists('getUsingGET4', 'partnerGuideId', partnerGuideId)
            const localVarPath = `/api/partner/guide/{partnerGuideId}`
                .replace(`{${"partnerGuideId"}}`, encodeURIComponent(String(partnerGuideId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 파트너가이드 리스트
         * @summary guideList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingGET3: async (page: number, pageSize: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('listUsingGET3', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('listUsingGET3', 'pageSize', pageSize)
            const localVarPath = `/api/partner/guides`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerGuideApi - functional programming interface
 * @export
 */
export const PartnerGuideApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerGuideApiAxiosParamCreator(configuration)
    return {
        /**
         * 파트너가이드 조회
         * @summary guideSingle
         * @param {number} partnerGuideId partnerGuideId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsingGET4(partnerGuideId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsingGET4(partnerGuideId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 파트너가이드 리스트
         * @summary guideList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsingGET3(page: number, pageSize: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsingGET3(page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerGuideApi - factory interface
 * @export
 */
export const PartnerGuideApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerGuideApiFp(configuration)
    return {
        /**
         * 파트너가이드 조회
         * @summary guideSingle
         * @param {number} partnerGuideId partnerGuideId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingGET4(partnerGuideId: number, options?: any): AxiosPromise<object> {
            return localVarFp.getUsingGET4(partnerGuideId, options).then((request) => request(axios, basePath));
        },
        /**
         * 파트너가이드 리스트
         * @summary guideList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingGET3(page: number, pageSize: number, options?: any): AxiosPromise<object> {
            return localVarFp.listUsingGET3(page, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getUsingGET4 operation in PartnerGuideApi.
 * @export
 * @interface PartnerGuideApiGetUsingGET4Request
 */
export interface PartnerGuideApiGetUsingGET4Request {
    /**
     * partnerGuideId
     * @type {number}
     * @memberof PartnerGuideApiGetUsingGET4
     */
    readonly partnerGuideId: number
}

/**
 * Request parameters for listUsingGET3 operation in PartnerGuideApi.
 * @export
 * @interface PartnerGuideApiListUsingGET3Request
 */
export interface PartnerGuideApiListUsingGET3Request {
    /**
     * page
     * @type {number}
     * @memberof PartnerGuideApiListUsingGET3
     */
    readonly page: number

    /**
     * pageSize
     * @type {number}
     * @memberof PartnerGuideApiListUsingGET3
     */
    readonly pageSize: number
}

/**
 * PartnerGuideApi - object-oriented interface
 * @export
 * @class PartnerGuideApi
 * @extends {BaseAPI}
 */
export class PartnerGuideApi extends BaseAPI {
    /**
     * 파트너가이드 조회
     * @summary guideSingle
     * @param {PartnerGuideApiGetUsingGET4Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerGuideApi
     */
    public getUsingGET4(requestParameters: PartnerGuideApiGetUsingGET4Request, options?: any) {
        return PartnerGuideApiFp(this.configuration).getUsingGET4(requestParameters.partnerGuideId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 파트너가이드 리스트
     * @summary guideList
     * @param {PartnerGuideApiListUsingGET3Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerGuideApi
     */
    public listUsingGET3(requestParameters: PartnerGuideApiListUsingGET3Request, options?: any) {
        return PartnerGuideApiFp(this.configuration).listUsingGET3(requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnerMakeOfferApi - axios parameter creator
 * @export
 */
export const PartnerMakeOfferApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 오퍼상세
         * @summary makeOfferInfo
         * @param {number} [memberId] memberId
         * @param {number} [productId] productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeOfferDetailUsingGET1: async (memberId?: number, productId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/partner/makeoffer/detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 메이크오퍼리스트
         * @summary makeOfferList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {string} [keyword] keyword
         * @param {string} [sortBy] sortBy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeOfferListUsingGET: async (page: number, pageSize: number, keyword?: string, sortBy?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('makeOfferListUsingGET', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('makeOfferListUsingGET', 'pageSize', pageSize)
            const localVarPath = `/api/partner/makeoffer/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 오퍼수락및거절
         * @summary makeOfferUpdate
         * @param {PartnerMakeOfferParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMakeOfferUsingPUT: async (param: PartnerMakeOfferParamDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('updateMakeOfferUsingPUT', 'param', param)
            const localVarPath = `/api/partner/makeoffer/accept`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerMakeOfferApi - functional programming interface
 * @export
 */
export const PartnerMakeOfferApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerMakeOfferApiAxiosParamCreator(configuration)
    return {
        /**
         * 오퍼상세
         * @summary makeOfferInfo
         * @param {number} [memberId] memberId
         * @param {number} [productId] productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makeOfferDetailUsingGET1(memberId?: number, productId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makeOfferDetailUsingGET1(memberId, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 메이크오퍼리스트
         * @summary makeOfferList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {string} [keyword] keyword
         * @param {string} [sortBy] sortBy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makeOfferListUsingGET(page: number, pageSize: number, keyword?: string, sortBy?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makeOfferListUsingGET(page, pageSize, keyword, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 오퍼수락및거절
         * @summary makeOfferUpdate
         * @param {PartnerMakeOfferParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMakeOfferUsingPUT(param: PartnerMakeOfferParamDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMakeOfferUsingPUT(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerMakeOfferApi - factory interface
 * @export
 */
export const PartnerMakeOfferApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerMakeOfferApiFp(configuration)
    return {
        /**
         * 오퍼상세
         * @summary makeOfferInfo
         * @param {number} [memberId] memberId
         * @param {number} [productId] productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeOfferDetailUsingGET1(memberId?: number, productId?: number, options?: any): AxiosPromise<object> {
            return localVarFp.makeOfferDetailUsingGET1(memberId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 메이크오퍼리스트
         * @summary makeOfferList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {string} [keyword] keyword
         * @param {string} [sortBy] sortBy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeOfferListUsingGET(page: number, pageSize: number, keyword?: string, sortBy?: string, options?: any): AxiosPromise<object> {
            return localVarFp.makeOfferListUsingGET(page, pageSize, keyword, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 오퍼수락및거절
         * @summary makeOfferUpdate
         * @param {PartnerMakeOfferParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMakeOfferUsingPUT(param: PartnerMakeOfferParamDTO, options?: any): AxiosPromise<object> {
            return localVarFp.updateMakeOfferUsingPUT(param, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for makeOfferDetailUsingGET1 operation in PartnerMakeOfferApi.
 * @export
 * @interface PartnerMakeOfferApiMakeOfferDetailUsingGET1Request
 */
export interface PartnerMakeOfferApiMakeOfferDetailUsingGET1Request {
    /**
     * memberId
     * @type {number}
     * @memberof PartnerMakeOfferApiMakeOfferDetailUsingGET1
     */
    readonly memberId?: number

    /**
     * productId
     * @type {number}
     * @memberof PartnerMakeOfferApiMakeOfferDetailUsingGET1
     */
    readonly productId?: number
}

/**
 * Request parameters for makeOfferListUsingGET operation in PartnerMakeOfferApi.
 * @export
 * @interface PartnerMakeOfferApiMakeOfferListUsingGETRequest
 */
export interface PartnerMakeOfferApiMakeOfferListUsingGETRequest {
    /**
     * page
     * @type {number}
     * @memberof PartnerMakeOfferApiMakeOfferListUsingGET
     */
    readonly page: number

    /**
     * pageSize
     * @type {number}
     * @memberof PartnerMakeOfferApiMakeOfferListUsingGET
     */
    readonly pageSize: number

    /**
     * keyword
     * @type {string}
     * @memberof PartnerMakeOfferApiMakeOfferListUsingGET
     */
    readonly keyword?: string

    /**
     * sortBy
     * @type {string}
     * @memberof PartnerMakeOfferApiMakeOfferListUsingGET
     */
    readonly sortBy?: string
}

/**
 * Request parameters for updateMakeOfferUsingPUT operation in PartnerMakeOfferApi.
 * @export
 * @interface PartnerMakeOfferApiUpdateMakeOfferUsingPUTRequest
 */
export interface PartnerMakeOfferApiUpdateMakeOfferUsingPUTRequest {
    /**
     * param
     * @type {PartnerMakeOfferParamDTO}
     * @memberof PartnerMakeOfferApiUpdateMakeOfferUsingPUT
     */
    readonly param: PartnerMakeOfferParamDTO
}

/**
 * PartnerMakeOfferApi - object-oriented interface
 * @export
 * @class PartnerMakeOfferApi
 * @extends {BaseAPI}
 */
export class PartnerMakeOfferApi extends BaseAPI {
    /**
     * 오퍼상세
     * @summary makeOfferInfo
     * @param {PartnerMakeOfferApiMakeOfferDetailUsingGET1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerMakeOfferApi
     */
    public makeOfferDetailUsingGET1(requestParameters: PartnerMakeOfferApiMakeOfferDetailUsingGET1Request = {}, options?: any) {
        return PartnerMakeOfferApiFp(this.configuration).makeOfferDetailUsingGET1(requestParameters.memberId, requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 메이크오퍼리스트
     * @summary makeOfferList
     * @param {PartnerMakeOfferApiMakeOfferListUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerMakeOfferApi
     */
    public makeOfferListUsingGET(requestParameters: PartnerMakeOfferApiMakeOfferListUsingGETRequest, options?: any) {
        return PartnerMakeOfferApiFp(this.configuration).makeOfferListUsingGET(requestParameters.page, requestParameters.pageSize, requestParameters.keyword, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 오퍼수락및거절
     * @summary makeOfferUpdate
     * @param {PartnerMakeOfferApiUpdateMakeOfferUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerMakeOfferApi
     */
    public updateMakeOfferUsingPUT(requestParameters: PartnerMakeOfferApiUpdateMakeOfferUsingPUTRequest, options?: any) {
        return PartnerMakeOfferApiFp(this.configuration).updateMakeOfferUsingPUT(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnerMemberApi - axios parameter creator
 * @export
 */
export const PartnerMemberApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 파트너 회원조회
         * @summary partnerMember
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerMember: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/partner/{memberId}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 파트너 회원비밀번호재발급
         * @summary partnerMemberResetPassword
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerMemberResetPassword: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/partner/member/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 파트너 회원정보 수정
         * @summary partnerMemberUpdate
         * @param {AdminMemberDTO} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerMemberUpdate: async (body: AdminMemberDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('partnerMemberUpdate', 'body', body)
            const localVarPath = `/api/partner/member`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerMemberApi - functional programming interface
 * @export
 */
export const PartnerMemberApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerMemberApiAxiosParamCreator(configuration)
    return {
        /**
         * 파트너 회원조회
         * @summary partnerMember
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerMember(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerMember(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 파트너 회원비밀번호재발급
         * @summary partnerMemberResetPassword
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerMemberResetPassword(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerMemberResetPassword(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 파트너 회원정보 수정
         * @summary partnerMemberUpdate
         * @param {AdminMemberDTO} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerMemberUpdate(body: AdminMemberDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerMemberUpdate(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerMemberApi - factory interface
 * @export
 */
export const PartnerMemberApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerMemberApiFp(configuration)
    return {
        /**
         * 파트너 회원조회
         * @summary partnerMember
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerMember(options?: any): AxiosPromise<RestDTO> {
            return localVarFp.partnerMember(options).then((request) => request(axios, basePath));
        },
        /**
         * 파트너 회원비밀번호재발급
         * @summary partnerMemberResetPassword
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerMemberResetPassword(options?: any): AxiosPromise<RestDTO> {
            return localVarFp.partnerMemberResetPassword(options).then((request) => request(axios, basePath));
        },
        /**
         * 파트너 회원정보 수정
         * @summary partnerMemberUpdate
         * @param {AdminMemberDTO} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerMemberUpdate(body: AdminMemberDTO, options?: any): AxiosPromise<RestDTO> {
            return localVarFp.partnerMemberUpdate(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for partnerMemberUpdate operation in PartnerMemberApi.
 * @export
 * @interface PartnerMemberApiPartnerMemberUpdateRequest
 */
export interface PartnerMemberApiPartnerMemberUpdateRequest {
    /**
     * body
     * @type {AdminMemberDTO}
     * @memberof PartnerMemberApiPartnerMemberUpdate
     */
    readonly body: AdminMemberDTO
}

/**
 * PartnerMemberApi - object-oriented interface
 * @export
 * @class PartnerMemberApi
 * @extends {BaseAPI}
 */
export class PartnerMemberApi extends BaseAPI {
    /**
     * 파트너 회원조회
     * @summary partnerMember
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerMemberApi
     */
    public partnerMember(options?: any) {
        return PartnerMemberApiFp(this.configuration).partnerMember(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 파트너 회원비밀번호재발급
     * @summary partnerMemberResetPassword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerMemberApi
     */
    public partnerMemberResetPassword(options?: any) {
        return PartnerMemberApiFp(this.configuration).partnerMemberResetPassword(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 파트너 회원정보 수정
     * @summary partnerMemberUpdate
     * @param {PartnerMemberApiPartnerMemberUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerMemberApi
     */
    public partnerMemberUpdate(requestParameters: PartnerMemberApiPartnerMemberUpdateRequest, options?: any) {
        return PartnerMemberApiFp(this.configuration).partnerMemberUpdate(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnerMultiProductApi - axios parameter creator
 * @export
 */
export const PartnerMultiProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 임시상품들삭제
         * @summary deleteTempProductList
         * @param {string} ids ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteColumnUsingDELETE: async (ids: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('deleteColumnUsingDELETE', 'ids', ids)
            const localVarPath = `/api/partner/product-multi/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 임시상품삭제
         * @summary deleteTempProduct
         * @param {number} productTempId productTempId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTempProductUsingDELETE: async (productTempId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productTempId' is not null or undefined
            assertParamExists('deleteTempProductUsingDELETE', 'productTempId', productTempId)
            const localVarPath = `/api/partner/product-multi/{productTempId}`
                .replace(`{${"productTempId"}}`, encodeURIComponent(String(productTempId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 상품대용량업로드
         * @summary uploadProductXls
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFileUploadUsingPOST: async (file?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/partner/product-multi/upload-product-xls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 임시저장리스트
         * @summary tempProductList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTempProductUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/partner/product-multi/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 상품대용량등록
         * @summary saveProductList
         * @param {Array<ProductTemp>} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveProductListUsingPOST: async (param: Array<ProductTemp>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('saveProductListUsingPOST', 'param', param)
            const localVarPath = `/api/partner/product-multi/save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 상품대용량등록 검증
         * @summary validationProductList
         * @param {Array<ProductTemp>} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validationProductListUsingPOST: async (param: Array<ProductTemp>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('validationProductListUsingPOST', 'param', param)
            const localVarPath = `/api/partner/product-multi/validation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerMultiProductApi - functional programming interface
 * @export
 */
export const PartnerMultiProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerMultiProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 임시상품들삭제
         * @summary deleteTempProductList
         * @param {string} ids ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteColumnUsingDELETE(ids: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteColumnUsingDELETE(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 임시상품삭제
         * @summary deleteTempProduct
         * @param {number} productTempId productTempId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTempProductUsingDELETE(productTempId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTempProductUsingDELETE(productTempId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 상품대용량업로드
         * @summary uploadProductXls
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleFileUploadUsingPOST(file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleFileUploadUsingPOST(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 임시저장리스트
         * @summary tempProductList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTempProductUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTempProductUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 상품대용량등록
         * @summary saveProductList
         * @param {Array<ProductTemp>} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveProductListUsingPOST(param: Array<ProductTemp>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveProductListUsingPOST(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 상품대용량등록 검증
         * @summary validationProductList
         * @param {Array<ProductTemp>} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validationProductListUsingPOST(param: Array<ProductTemp>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validationProductListUsingPOST(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerMultiProductApi - factory interface
 * @export
 */
export const PartnerMultiProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerMultiProductApiFp(configuration)
    return {
        /**
         * 임시상품들삭제
         * @summary deleteTempProductList
         * @param {string} ids ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteColumnUsingDELETE(ids: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteColumnUsingDELETE(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 임시상품삭제
         * @summary deleteTempProduct
         * @param {number} productTempId productTempId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTempProductUsingDELETE(productTempId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteTempProductUsingDELETE(productTempId, options).then((request) => request(axios, basePath));
        },
        /**
         * 상품대용량업로드
         * @summary uploadProductXls
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFileUploadUsingPOST(file?: any, options?: any): AxiosPromise<object> {
            return localVarFp.handleFileUploadUsingPOST(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 임시저장리스트
         * @summary tempProductList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTempProductUsingGET(options?: any): AxiosPromise<object> {
            return localVarFp.listTempProductUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 상품대용량등록
         * @summary saveProductList
         * @param {Array<ProductTemp>} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveProductListUsingPOST(param: Array<ProductTemp>, options?: any): AxiosPromise<object> {
            return localVarFp.saveProductListUsingPOST(param, options).then((request) => request(axios, basePath));
        },
        /**
         * 상품대용량등록 검증
         * @summary validationProductList
         * @param {Array<ProductTemp>} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validationProductListUsingPOST(param: Array<ProductTemp>, options?: any): AxiosPromise<object> {
            return localVarFp.validationProductListUsingPOST(param, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteColumnUsingDELETE operation in PartnerMultiProductApi.
 * @export
 * @interface PartnerMultiProductApiDeleteColumnUsingDELETERequest
 */
export interface PartnerMultiProductApiDeleteColumnUsingDELETERequest {
    /**
     * ids
     * @type {string}
     * @memberof PartnerMultiProductApiDeleteColumnUsingDELETE
     */
    readonly ids: string
}

/**
 * Request parameters for deleteTempProductUsingDELETE operation in PartnerMultiProductApi.
 * @export
 * @interface PartnerMultiProductApiDeleteTempProductUsingDELETERequest
 */
export interface PartnerMultiProductApiDeleteTempProductUsingDELETERequest {
    /**
     * productTempId
     * @type {number}
     * @memberof PartnerMultiProductApiDeleteTempProductUsingDELETE
     */
    readonly productTempId: number
}

/**
 * Request parameters for handleFileUploadUsingPOST operation in PartnerMultiProductApi.
 * @export
 * @interface PartnerMultiProductApiHandleFileUploadUsingPOSTRequest
 */
export interface PartnerMultiProductApiHandleFileUploadUsingPOSTRequest {
    /**
     * 
     * @type {any}
     * @memberof PartnerMultiProductApiHandleFileUploadUsingPOST
     */
    readonly file?: any
}

/**
 * Request parameters for saveProductListUsingPOST operation in PartnerMultiProductApi.
 * @export
 * @interface PartnerMultiProductApiSaveProductListUsingPOSTRequest
 */
export interface PartnerMultiProductApiSaveProductListUsingPOSTRequest {
    /**
     * param
     * @type {Array<ProductTemp>}
     * @memberof PartnerMultiProductApiSaveProductListUsingPOST
     */
    readonly param: Array<ProductTemp>
}

/**
 * Request parameters for validationProductListUsingPOST operation in PartnerMultiProductApi.
 * @export
 * @interface PartnerMultiProductApiValidationProductListUsingPOSTRequest
 */
export interface PartnerMultiProductApiValidationProductListUsingPOSTRequest {
    /**
     * param
     * @type {Array<ProductTemp>}
     * @memberof PartnerMultiProductApiValidationProductListUsingPOST
     */
    readonly param: Array<ProductTemp>
}

/**
 * PartnerMultiProductApi - object-oriented interface
 * @export
 * @class PartnerMultiProductApi
 * @extends {BaseAPI}
 */
export class PartnerMultiProductApi extends BaseAPI {
    /**
     * 임시상품들삭제
     * @summary deleteTempProductList
     * @param {PartnerMultiProductApiDeleteColumnUsingDELETERequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerMultiProductApi
     */
    public deleteColumnUsingDELETE(requestParameters: PartnerMultiProductApiDeleteColumnUsingDELETERequest, options?: any) {
        return PartnerMultiProductApiFp(this.configuration).deleteColumnUsingDELETE(requestParameters.ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 임시상품삭제
     * @summary deleteTempProduct
     * @param {PartnerMultiProductApiDeleteTempProductUsingDELETERequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerMultiProductApi
     */
    public deleteTempProductUsingDELETE(requestParameters: PartnerMultiProductApiDeleteTempProductUsingDELETERequest, options?: any) {
        return PartnerMultiProductApiFp(this.configuration).deleteTempProductUsingDELETE(requestParameters.productTempId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 상품대용량업로드
     * @summary uploadProductXls
     * @param {PartnerMultiProductApiHandleFileUploadUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerMultiProductApi
     */
    public handleFileUploadUsingPOST(requestParameters: PartnerMultiProductApiHandleFileUploadUsingPOSTRequest = {}, options?: any) {
        return PartnerMultiProductApiFp(this.configuration).handleFileUploadUsingPOST(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 임시저장리스트
     * @summary tempProductList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerMultiProductApi
     */
    public listTempProductUsingGET(options?: any) {
        return PartnerMultiProductApiFp(this.configuration).listTempProductUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 상품대용량등록
     * @summary saveProductList
     * @param {PartnerMultiProductApiSaveProductListUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerMultiProductApi
     */
    public saveProductListUsingPOST(requestParameters: PartnerMultiProductApiSaveProductListUsingPOSTRequest, options?: any) {
        return PartnerMultiProductApiFp(this.configuration).saveProductListUsingPOST(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 상품대용량등록 검증
     * @summary validationProductList
     * @param {PartnerMultiProductApiValidationProductListUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerMultiProductApi
     */
    public validationProductListUsingPOST(requestParameters: PartnerMultiProductApiValidationProductListUsingPOSTRequest, options?: any) {
        return PartnerMultiProductApiFp(this.configuration).validationProductListUsingPOST(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnerNoticeApi - axios parameter creator
 * @export
 */
export const PartnerNoticeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 공지조회
         * @summary noticeSingle
         * @param {number} noticeId noticeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingGET3: async (noticeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'noticeId' is not null or undefined
            assertParamExists('getUsingGET3', 'noticeId', noticeId)
            const localVarPath = `/api/partner/notice/{noticeId}`
                .replace(`{${"noticeId"}}`, encodeURIComponent(String(noticeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 공지리스트
         * @summary noticeList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingGET1: async (page: number, pageSize: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('listUsingGET1', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('listUsingGET1', 'pageSize', pageSize)
            const localVarPath = `/api/partner/notices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerNoticeApi - functional programming interface
 * @export
 */
export const PartnerNoticeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerNoticeApiAxiosParamCreator(configuration)
    return {
        /**
         * 공지조회
         * @summary noticeSingle
         * @param {number} noticeId noticeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsingGET3(noticeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsingGET3(noticeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 공지리스트
         * @summary noticeList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsingGET1(page: number, pageSize: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsingGET1(page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerNoticeApi - factory interface
 * @export
 */
export const PartnerNoticeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerNoticeApiFp(configuration)
    return {
        /**
         * 공지조회
         * @summary noticeSingle
         * @param {number} noticeId noticeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingGET3(noticeId: number, options?: any): AxiosPromise<object> {
            return localVarFp.getUsingGET3(noticeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 공지리스트
         * @summary noticeList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingGET1(page: number, pageSize: number, options?: any): AxiosPromise<object> {
            return localVarFp.listUsingGET1(page, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getUsingGET3 operation in PartnerNoticeApi.
 * @export
 * @interface PartnerNoticeApiGetUsingGET3Request
 */
export interface PartnerNoticeApiGetUsingGET3Request {
    /**
     * noticeId
     * @type {number}
     * @memberof PartnerNoticeApiGetUsingGET3
     */
    readonly noticeId: number
}

/**
 * Request parameters for listUsingGET1 operation in PartnerNoticeApi.
 * @export
 * @interface PartnerNoticeApiListUsingGET1Request
 */
export interface PartnerNoticeApiListUsingGET1Request {
    /**
     * page
     * @type {number}
     * @memberof PartnerNoticeApiListUsingGET1
     */
    readonly page: number

    /**
     * pageSize
     * @type {number}
     * @memberof PartnerNoticeApiListUsingGET1
     */
    readonly pageSize: number
}

/**
 * PartnerNoticeApi - object-oriented interface
 * @export
 * @class PartnerNoticeApi
 * @extends {BaseAPI}
 */
export class PartnerNoticeApi extends BaseAPI {
    /**
     * 공지조회
     * @summary noticeSingle
     * @param {PartnerNoticeApiGetUsingGET3Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerNoticeApi
     */
    public getUsingGET3(requestParameters: PartnerNoticeApiGetUsingGET3Request, options?: any) {
        return PartnerNoticeApiFp(this.configuration).getUsingGET3(requestParameters.noticeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 공지리스트
     * @summary noticeList
     * @param {PartnerNoticeApiListUsingGET1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerNoticeApi
     */
    public listUsingGET1(requestParameters: PartnerNoticeApiListUsingGET1Request, options?: any) {
        return PartnerNoticeApiFp(this.configuration).listUsingGET1(requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnerOrderApi - axios parameter creator
 * @export
 */
export const PartnerOrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 세금계산서 조회
         * @summary taxBillInfo
         * @param {number} orderId orderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxBillUsingGET: async (orderId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('getTaxBillUsingGET', 'orderId', orderId)
            const localVarPath = `/api/partner/taxbill/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 인보이스
         * @summary invoice
         * @param {number} orderId orderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyOrderUsingGET2: async (orderId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('listMyOrderUsingGET2', 'orderId', orderId)
            const localVarPath = `/api/partner/invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 정산 리스트
         * @summary orderSettlementList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {string} [end] end
         * @param {string} [keyword] keyword
         * @param {'bid' | 'buynow'} [orderType] orderType
         * @param {'complete' | 'hold' | 'purchase_complete' | 'waiting'} [settlementStatus] settlementStatus
         * @param {string} [sortBy] sortBy
         * @param {string} [start] start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingGET2: async (page: number, pageSize: number, categoryDepth1Id?: number, enableBid?: boolean, enableBuyNow?: boolean, end?: string, keyword?: string, orderType?: 'bid' | 'buynow', settlementStatus?: 'complete' | 'hold' | 'purchase_complete' | 'waiting', sortBy?: string, start?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('listUsingGET2', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('listUsingGET2', 'pageSize', pageSize)
            const localVarPath = `/api/partner/settlements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (categoryDepth1Id !== undefined) {
                localVarQueryParameter['categoryDepth1Id'] = categoryDepth1Id;
            }

            if (enableBid !== undefined) {
                localVarQueryParameter['enableBid'] = enableBid;
            }

            if (enableBuyNow !== undefined) {
                localVarQueryParameter['enableBuyNow'] = enableBuyNow;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (orderType !== undefined) {
                localVarQueryParameter['orderType'] = orderType;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (settlementStatus !== undefined) {
                localVarQueryParameter['settlementStatus'] = settlementStatus;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 정산 상세
         * @summary settlementInfo
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settlementInfoUsingGET: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('settlementInfoUsingGET', 'productId', productId)
            const localVarPath = `/api/partner/settlements/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 정산 요약
         * @summary orderSettlementList
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {string} [end] end
         * @param {string} [keyword] keyword
         * @param {'bid' | 'buynow'} [orderType] orderType
         * @param {'complete' | 'hold' | 'purchase_complete' | 'waiting'} [settlementStatus] settlementStatus
         * @param {string} [start] start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        summaryUsingGET: async (categoryDepth1Id?: number, enableBid?: boolean, enableBuyNow?: boolean, end?: string, keyword?: string, orderType?: 'bid' | 'buynow', settlementStatus?: 'complete' | 'hold' | 'purchase_complete' | 'waiting', start?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/partner/settlement-summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (categoryDepth1Id !== undefined) {
                localVarQueryParameter['categoryDepth1Id'] = categoryDepth1Id;
            }

            if (enableBid !== undefined) {
                localVarQueryParameter['enableBid'] = enableBid;
            }

            if (enableBuyNow !== undefined) {
                localVarQueryParameter['enableBuyNow'] = enableBuyNow;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (orderType !== undefined) {
                localVarQueryParameter['orderType'] = orderType;
            }

            if (settlementStatus !== undefined) {
                localVarQueryParameter['settlementStatus'] = settlementStatus;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerOrderApi - functional programming interface
 * @export
 */
export const PartnerOrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerOrderApiAxiosParamCreator(configuration)
    return {
        /**
         * 세금계산서 조회
         * @summary taxBillInfo
         * @param {number} orderId orderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaxBillUsingGET(orderId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaxBillUsingGET(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 인보이스
         * @summary invoice
         * @param {number} orderId orderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMyOrderUsingGET2(orderId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMyOrderUsingGET2(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 정산 리스트
         * @summary orderSettlementList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {string} [end] end
         * @param {string} [keyword] keyword
         * @param {'bid' | 'buynow'} [orderType] orderType
         * @param {'complete' | 'hold' | 'purchase_complete' | 'waiting'} [settlementStatus] settlementStatus
         * @param {string} [sortBy] sortBy
         * @param {string} [start] start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsingGET2(page: number, pageSize: number, categoryDepth1Id?: number, enableBid?: boolean, enableBuyNow?: boolean, end?: string, keyword?: string, orderType?: 'bid' | 'buynow', settlementStatus?: 'complete' | 'hold' | 'purchase_complete' | 'waiting', sortBy?: string, start?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsingGET2(page, pageSize, categoryDepth1Id, enableBid, enableBuyNow, end, keyword, orderType, settlementStatus, sortBy, start, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 정산 상세
         * @summary settlementInfo
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settlementInfoUsingGET(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settlementInfoUsingGET(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 정산 요약
         * @summary orderSettlementList
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {string} [end] end
         * @param {string} [keyword] keyword
         * @param {'bid' | 'buynow'} [orderType] orderType
         * @param {'complete' | 'hold' | 'purchase_complete' | 'waiting'} [settlementStatus] settlementStatus
         * @param {string} [start] start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async summaryUsingGET(categoryDepth1Id?: number, enableBid?: boolean, enableBuyNow?: boolean, end?: string, keyword?: string, orderType?: 'bid' | 'buynow', settlementStatus?: 'complete' | 'hold' | 'purchase_complete' | 'waiting', start?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.summaryUsingGET(categoryDepth1Id, enableBid, enableBuyNow, end, keyword, orderType, settlementStatus, start, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerOrderApi - factory interface
 * @export
 */
export const PartnerOrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerOrderApiFp(configuration)
    return {
        /**
         * 세금계산서 조회
         * @summary taxBillInfo
         * @param {number} orderId orderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxBillUsingGET(orderId: number, options?: any): AxiosPromise<object> {
            return localVarFp.getTaxBillUsingGET(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 인보이스
         * @summary invoice
         * @param {number} orderId orderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMyOrderUsingGET2(orderId: number, options?: any): AxiosPromise<object> {
            return localVarFp.listMyOrderUsingGET2(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 정산 리스트
         * @summary orderSettlementList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {string} [end] end
         * @param {string} [keyword] keyword
         * @param {'bid' | 'buynow'} [orderType] orderType
         * @param {'complete' | 'hold' | 'purchase_complete' | 'waiting'} [settlementStatus] settlementStatus
         * @param {string} [sortBy] sortBy
         * @param {string} [start] start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingGET2(page: number, pageSize: number, categoryDepth1Id?: number, enableBid?: boolean, enableBuyNow?: boolean, end?: string, keyword?: string, orderType?: 'bid' | 'buynow', settlementStatus?: 'complete' | 'hold' | 'purchase_complete' | 'waiting', sortBy?: string, start?: string, options?: any): AxiosPromise<object> {
            return localVarFp.listUsingGET2(page, pageSize, categoryDepth1Id, enableBid, enableBuyNow, end, keyword, orderType, settlementStatus, sortBy, start, options).then((request) => request(axios, basePath));
        },
        /**
         * 정산 상세
         * @summary settlementInfo
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settlementInfoUsingGET(productId: number, options?: any): AxiosPromise<object> {
            return localVarFp.settlementInfoUsingGET(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 정산 요약
         * @summary orderSettlementList
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {string} [end] end
         * @param {string} [keyword] keyword
         * @param {'bid' | 'buynow'} [orderType] orderType
         * @param {'complete' | 'hold' | 'purchase_complete' | 'waiting'} [settlementStatus] settlementStatus
         * @param {string} [start] start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        summaryUsingGET(categoryDepth1Id?: number, enableBid?: boolean, enableBuyNow?: boolean, end?: string, keyword?: string, orderType?: 'bid' | 'buynow', settlementStatus?: 'complete' | 'hold' | 'purchase_complete' | 'waiting', start?: string, options?: any): AxiosPromise<object> {
            return localVarFp.summaryUsingGET(categoryDepth1Id, enableBid, enableBuyNow, end, keyword, orderType, settlementStatus, start, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getTaxBillUsingGET operation in PartnerOrderApi.
 * @export
 * @interface PartnerOrderApiGetTaxBillUsingGETRequest
 */
export interface PartnerOrderApiGetTaxBillUsingGETRequest {
    /**
     * orderId
     * @type {number}
     * @memberof PartnerOrderApiGetTaxBillUsingGET
     */
    readonly orderId: number
}

/**
 * Request parameters for listMyOrderUsingGET2 operation in PartnerOrderApi.
 * @export
 * @interface PartnerOrderApiListMyOrderUsingGET2Request
 */
export interface PartnerOrderApiListMyOrderUsingGET2Request {
    /**
     * orderId
     * @type {number}
     * @memberof PartnerOrderApiListMyOrderUsingGET2
     */
    readonly orderId: number
}

/**
 * Request parameters for listUsingGET2 operation in PartnerOrderApi.
 * @export
 * @interface PartnerOrderApiListUsingGET2Request
 */
export interface PartnerOrderApiListUsingGET2Request {
    /**
     * page
     * @type {number}
     * @memberof PartnerOrderApiListUsingGET2
     */
    readonly page: number

    /**
     * pageSize
     * @type {number}
     * @memberof PartnerOrderApiListUsingGET2
     */
    readonly pageSize: number

    /**
     * categoryDepth1Id
     * @type {number}
     * @memberof PartnerOrderApiListUsingGET2
     */
    readonly categoryDepth1Id?: number

    /**
     * enableBid
     * @type {boolean}
     * @memberof PartnerOrderApiListUsingGET2
     */
    readonly enableBid?: boolean

    /**
     * enableBuyNow
     * @type {boolean}
     * @memberof PartnerOrderApiListUsingGET2
     */
    readonly enableBuyNow?: boolean

    /**
     * end
     * @type {string}
     * @memberof PartnerOrderApiListUsingGET2
     */
    readonly end?: string

    /**
     * keyword
     * @type {string}
     * @memberof PartnerOrderApiListUsingGET2
     */
    readonly keyword?: string

    /**
     * orderType
     * @type {'bid' | 'buynow'}
     * @memberof PartnerOrderApiListUsingGET2
     */
    readonly orderType?: 'bid' | 'buynow'

    /**
     * settlementStatus
     * @type {'complete' | 'hold' | 'purchase_complete' | 'waiting'}
     * @memberof PartnerOrderApiListUsingGET2
     */
    readonly settlementStatus?: 'complete' | 'hold' | 'purchase_complete' | 'waiting'

    /**
     * sortBy
     * @type {string}
     * @memberof PartnerOrderApiListUsingGET2
     */
    readonly sortBy?: string

    /**
     * start
     * @type {string}
     * @memberof PartnerOrderApiListUsingGET2
     */
    readonly start?: string
}

/**
 * Request parameters for settlementInfoUsingGET operation in PartnerOrderApi.
 * @export
 * @interface PartnerOrderApiSettlementInfoUsingGETRequest
 */
export interface PartnerOrderApiSettlementInfoUsingGETRequest {
    /**
     * productId
     * @type {number}
     * @memberof PartnerOrderApiSettlementInfoUsingGET
     */
    readonly productId: number
}

/**
 * Request parameters for summaryUsingGET operation in PartnerOrderApi.
 * @export
 * @interface PartnerOrderApiSummaryUsingGETRequest
 */
export interface PartnerOrderApiSummaryUsingGETRequest {
    /**
     * categoryDepth1Id
     * @type {number}
     * @memberof PartnerOrderApiSummaryUsingGET
     */
    readonly categoryDepth1Id?: number

    /**
     * enableBid
     * @type {boolean}
     * @memberof PartnerOrderApiSummaryUsingGET
     */
    readonly enableBid?: boolean

    /**
     * enableBuyNow
     * @type {boolean}
     * @memberof PartnerOrderApiSummaryUsingGET
     */
    readonly enableBuyNow?: boolean

    /**
     * end
     * @type {string}
     * @memberof PartnerOrderApiSummaryUsingGET
     */
    readonly end?: string

    /**
     * keyword
     * @type {string}
     * @memberof PartnerOrderApiSummaryUsingGET
     */
    readonly keyword?: string

    /**
     * orderType
     * @type {'bid' | 'buynow'}
     * @memberof PartnerOrderApiSummaryUsingGET
     */
    readonly orderType?: 'bid' | 'buynow'

    /**
     * settlementStatus
     * @type {'complete' | 'hold' | 'purchase_complete' | 'waiting'}
     * @memberof PartnerOrderApiSummaryUsingGET
     */
    readonly settlementStatus?: 'complete' | 'hold' | 'purchase_complete' | 'waiting'

    /**
     * start
     * @type {string}
     * @memberof PartnerOrderApiSummaryUsingGET
     */
    readonly start?: string
}

/**
 * PartnerOrderApi - object-oriented interface
 * @export
 * @class PartnerOrderApi
 * @extends {BaseAPI}
 */
export class PartnerOrderApi extends BaseAPI {
    /**
     * 세금계산서 조회
     * @summary taxBillInfo
     * @param {PartnerOrderApiGetTaxBillUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerOrderApi
     */
    public getTaxBillUsingGET(requestParameters: PartnerOrderApiGetTaxBillUsingGETRequest, options?: any) {
        return PartnerOrderApiFp(this.configuration).getTaxBillUsingGET(requestParameters.orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 인보이스
     * @summary invoice
     * @param {PartnerOrderApiListMyOrderUsingGET2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerOrderApi
     */
    public listMyOrderUsingGET2(requestParameters: PartnerOrderApiListMyOrderUsingGET2Request, options?: any) {
        return PartnerOrderApiFp(this.configuration).listMyOrderUsingGET2(requestParameters.orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 정산 리스트
     * @summary orderSettlementList
     * @param {PartnerOrderApiListUsingGET2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerOrderApi
     */
    public listUsingGET2(requestParameters: PartnerOrderApiListUsingGET2Request, options?: any) {
        return PartnerOrderApiFp(this.configuration).listUsingGET2(requestParameters.page, requestParameters.pageSize, requestParameters.categoryDepth1Id, requestParameters.enableBid, requestParameters.enableBuyNow, requestParameters.end, requestParameters.keyword, requestParameters.orderType, requestParameters.settlementStatus, requestParameters.sortBy, requestParameters.start, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 정산 상세
     * @summary settlementInfo
     * @param {PartnerOrderApiSettlementInfoUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerOrderApi
     */
    public settlementInfoUsingGET(requestParameters: PartnerOrderApiSettlementInfoUsingGETRequest, options?: any) {
        return PartnerOrderApiFp(this.configuration).settlementInfoUsingGET(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 정산 요약
     * @summary orderSettlementList
     * @param {PartnerOrderApiSummaryUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerOrderApi
     */
    public summaryUsingGET(requestParameters: PartnerOrderApiSummaryUsingGETRequest = {}, options?: any) {
        return PartnerOrderApiFp(this.configuration).summaryUsingGET(requestParameters.categoryDepth1Id, requestParameters.enableBid, requestParameters.enableBuyNow, requestParameters.end, requestParameters.keyword, requestParameters.orderType, requestParameters.settlementStatus, requestParameters.start, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnerProductApi - axios parameter creator
 * @export
 */
export const PartnerProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 카테고리 리스트
         * @summary categoryList
         * @param {number} depth depth
         * @param {number} [parentCategoryId] parentCategoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryListUsingGET1: async (depth: number, parentCategoryId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'depth' is not null or undefined
            assertParamExists('categoryListUsingGET1', 'depth', depth)
            const localVarPath = `/api/partner/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (depth !== undefined) {
                localVarQueryParameter['depth'] = depth;
            }

            if (parentCategoryId !== undefined) {
                localVarQueryParameter['parentCategoryId '] = parentCategoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 상품정보 등록
         * @summary productAddStep1
         * @param {ProductParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStep1UsingPOST: async (param: ProductParamDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('createStep1UsingPOST', 'param', param)
            const localVarPath = `/api/partner/product/step1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 판매정보 등록
         * @summary productAddStep2
         * @param {SalesInfoParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStep2UsingPOST: async (param: SalesInfoParamDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('createStep2UsingPOST', 'param', param)
            const localVarPath = `/api/partner/product/step2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 상품등록
         * @summary productStatusUpdate
         * @param {number} productId productId
         * @param {'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out'} salesStatus salesStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsingPUT: async (productId: number, salesStatus: 'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('createUsingPUT', 'productId', productId)
            // verify required parameter 'salesStatus' is not null or undefined
            assertParamExists('createUsingPUT', 'salesStatus', salesStatus)
            const localVarPath = `/api/partner/product/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (salesStatus !== undefined) {
                localVarQueryParameter['salesStatus'] = salesStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 상품삭제
         * @summary productDelete
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingDELETE: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('deleteUsingDELETE', 'productId', productId)
            const localVarPath = `/api/partner/product/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 배송조회
         * @summary shippingTracking
         * @param {string} ordernum ordernum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShippingTrackingUsingGET1: async (ordernum: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ordernum' is not null or undefined
            assertParamExists('getShippingTrackingUsingGET1', 'ordernum', ordernum)
            const localVarPath = `/api/partner/shipping-tracking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ordernum !== undefined) {
                localVarQueryParameter['ordernum'] = ordernum;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 상품조회
         * @summary productSingle
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingGET5: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getUsingGET5', 'productId', productId)
            const localVarPath = `/api/partner/product/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 파일업로드
         * @summary fileUpload
         * @param {number} [maxSize] maxSize
         * @param {boolean} [resize] resize
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFileUploadUsingPOST3: async (maxSize?: number, resize?: boolean, file?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/partner/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (maxSize !== undefined) {
                localVarQueryParameter['maxSize'] = maxSize;
            }

            if (resize !== undefined) {
                localVarQueryParameter['resize'] = resize;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 상품숨김
         * @summary productHide
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hideUsingPUT: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('hideUsingPUT', 'productId', productId)
            const localVarPath = `/api/partner/visibility/product/hide/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 상품리스트
         * @summary productList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {number} [categoryDepth2Id] categoryDepth2Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {string} [end] end
         * @param {string} [keyword] keyword
         * @param {'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out'} [salesStatus] salesStatus
         * @param {boolean} [showInvisibled] showInvisibled
         * @param {string} [sortBy] sortBy
         * @param {string} [start] start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingGET4: async (page: number, pageSize: number, categoryDepth1Id?: number, categoryDepth2Id?: number, enableBid?: boolean, enableBuyNow?: boolean, end?: string, keyword?: string, salesStatus?: 'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out', showInvisibled?: boolean, sortBy?: string, start?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('listUsingGET4', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('listUsingGET4', 'pageSize', pageSize)
            const localVarPath = `/api/partner/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (categoryDepth1Id !== undefined) {
                localVarQueryParameter['categoryDepth1Id'] = categoryDepth1Id;
            }

            if (categoryDepth2Id !== undefined) {
                localVarQueryParameter['categoryDepth2Id'] = categoryDepth2Id;
            }

            if (enableBid !== undefined) {
                localVarQueryParameter['enableBid'] = enableBid;
            }

            if (enableBuyNow !== undefined) {
                localVarQueryParameter['enableBuyNow'] = enableBuyNow;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (salesStatus !== undefined) {
                localVarQueryParameter['salesStatus'] = salesStatus;
            }

            if (showInvisibled !== undefined) {
                localVarQueryParameter['showInvisibled'] = showInvisibled;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 옵션 리스트 ( type : period , status , country , artist , medium )
         * @summary optionList
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionListUsingGET1: async (type?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/partner/options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 판매상세
         * @summary salesInfo
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesInfo: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('salesInfo', 'productId', productId)
            const localVarPath = `/api/partner/sales/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 판매리스트
         * @summary salesList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {boolean} [biddingExist] biddingExist
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {number} [categoryDepth2Id] categoryDepth2Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {string} [end] end
         * @param {string} [keyword] keyword
         * @param {'complete' | 'delivered' | 'deposit_wait' | 'none' | 'payment_complete' | 'payment_time_out' | 'payment_wait' | 'proccessed' | 'purchase_cancel' | 'return_complete' | 'return_request' | 'shipped'} [orderStatus] orderStatus
         * @param {'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out'} [salesStatus] salesStatus
         * @param {boolean} [showInvisibled] showInvisibled
         * @param {string} [sortBy] sortBy
         * @param {string} [start] start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesListUsingGET: async (page: number, pageSize: number, biddingExist?: boolean, categoryDepth1Id?: number, categoryDepth2Id?: number, enableBid?: boolean, enableBuyNow?: boolean, end?: string, keyword?: string, orderStatus?: 'complete' | 'delivered' | 'deposit_wait' | 'none' | 'payment_complete' | 'payment_time_out' | 'payment_wait' | 'proccessed' | 'purchase_cancel' | 'return_complete' | 'return_request' | 'shipped', salesStatus?: 'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out', showInvisibled?: boolean, sortBy?: string, start?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('salesListUsingGET', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('salesListUsingGET', 'pageSize', pageSize)
            const localVarPath = `/api/partner/sales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (biddingExist !== undefined) {
                localVarQueryParameter['biddingExist'] = biddingExist;
            }

            if (categoryDepth1Id !== undefined) {
                localVarQueryParameter['categoryDepth1Id'] = categoryDepth1Id;
            }

            if (categoryDepth2Id !== undefined) {
                localVarQueryParameter['categoryDepth2Id'] = categoryDepth2Id;
            }

            if (enableBid !== undefined) {
                localVarQueryParameter['enableBid'] = enableBid;
            }

            if (enableBuyNow !== undefined) {
                localVarQueryParameter['enableBuyNow'] = enableBuyNow;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (orderStatus !== undefined) {
                localVarQueryParameter['orderStatus'] = orderStatus;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (salesStatus !== undefined) {
                localVarQueryParameter['salesStatus'] = salesStatus;
            }

            if (showInvisibled !== undefined) {
                localVarQueryParameter['showInvisibled'] = showInvisibled;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 배송타입 리스트
         * @summary shippingTypeList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippingTypeListUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/partner/shippingTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 상품게시
         * @summary productShow
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showUsingPUT: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('showUsingPUT', 'productId', productId)
            const localVarPath = `/api/partner/visibility/product/show/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 상품주문상태값수정
         * @summary orderStatusUpdate
         * @param {OrderStatusParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderStatusUsingPUT: async (param: OrderStatusParamDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('updateOrderStatusUsingPUT', 'param', param)
            const localVarPath = `/api/partner/order-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 반품완료
         * @summary returnMemoUpdate
         * @param {ReturnMemoParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReturnMemoUsingPUT: async (param: ReturnMemoParamDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('updateReturnMemoUsingPUT', 'param', param)
            const localVarPath = `/api/partner/order-return-memo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 트래킹코드입력
         * @summary trackingCodeUpdate
         * @param {TrackingCodeParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrackingCodeUsingPUT: async (param: TrackingCodeParamDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('updateTrackingCodeUsingPUT', 'param', param)
            const localVarPath = `/api/partner/update-tracking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 상품수정
         * @summary productUpdate
         * @param {ProductUpdateParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUsingPUT: async (param: ProductUpdateParamDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('updateUsingPUT', 'param', param)
            const localVarPath = `/api/partner/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerProductApi - functional programming interface
 * @export
 */
export const PartnerProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 카테고리 리스트
         * @summary categoryList
         * @param {number} depth depth
         * @param {number} [parentCategoryId] parentCategoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryListUsingGET1(depth: number, parentCategoryId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoryListUsingGET1(depth, parentCategoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 상품정보 등록
         * @summary productAddStep1
         * @param {ProductParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStep1UsingPOST(param: ProductParamDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStep1UsingPOST(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 판매정보 등록
         * @summary productAddStep2
         * @param {SalesInfoParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStep2UsingPOST(param: SalesInfoParamDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createStep2UsingPOST(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 상품등록
         * @summary productStatusUpdate
         * @param {number} productId productId
         * @param {'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out'} salesStatus salesStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUsingPUT(productId: number, salesStatus: 'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUsingPUT(productId, salesStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 상품삭제
         * @summary productDelete
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUsingDELETE(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUsingDELETE(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 배송조회
         * @summary shippingTracking
         * @param {string} ordernum ordernum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShippingTrackingUsingGET1(ordernum: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShippingTrackingUsingGET1(ordernum, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 상품조회
         * @summary productSingle
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsingGET5(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsingGET5(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 파일업로드
         * @summary fileUpload
         * @param {number} [maxSize] maxSize
         * @param {boolean} [resize] resize
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleFileUploadUsingPOST3(maxSize?: number, resize?: boolean, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleFileUploadUsingPOST3(maxSize, resize, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 상품숨김
         * @summary productHide
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hideUsingPUT(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hideUsingPUT(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 상품리스트
         * @summary productList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {number} [categoryDepth2Id] categoryDepth2Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {string} [end] end
         * @param {string} [keyword] keyword
         * @param {'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out'} [salesStatus] salesStatus
         * @param {boolean} [showInvisibled] showInvisibled
         * @param {string} [sortBy] sortBy
         * @param {string} [start] start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsingGET4(page: number, pageSize: number, categoryDepth1Id?: number, categoryDepth2Id?: number, enableBid?: boolean, enableBuyNow?: boolean, end?: string, keyword?: string, salesStatus?: 'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out', showInvisibled?: boolean, sortBy?: string, start?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsingGET4(page, pageSize, categoryDepth1Id, categoryDepth2Id, enableBid, enableBuyNow, end, keyword, salesStatus, showInvisibled, sortBy, start, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 옵션 리스트 ( type : period , status , country , artist , medium )
         * @summary optionList
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionListUsingGET1(type?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionListUsingGET1(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 판매상세
         * @summary salesInfo
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salesInfo(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salesInfo(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 판매리스트
         * @summary salesList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {boolean} [biddingExist] biddingExist
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {number} [categoryDepth2Id] categoryDepth2Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {string} [end] end
         * @param {string} [keyword] keyword
         * @param {'complete' | 'delivered' | 'deposit_wait' | 'none' | 'payment_complete' | 'payment_time_out' | 'payment_wait' | 'proccessed' | 'purchase_cancel' | 'return_complete' | 'return_request' | 'shipped'} [orderStatus] orderStatus
         * @param {'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out'} [salesStatus] salesStatus
         * @param {boolean} [showInvisibled] showInvisibled
         * @param {string} [sortBy] sortBy
         * @param {string} [start] start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salesListUsingGET(page: number, pageSize: number, biddingExist?: boolean, categoryDepth1Id?: number, categoryDepth2Id?: number, enableBid?: boolean, enableBuyNow?: boolean, end?: string, keyword?: string, orderStatus?: 'complete' | 'delivered' | 'deposit_wait' | 'none' | 'payment_complete' | 'payment_time_out' | 'payment_wait' | 'proccessed' | 'purchase_cancel' | 'return_complete' | 'return_request' | 'shipped', salesStatus?: 'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out', showInvisibled?: boolean, sortBy?: string, start?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salesListUsingGET(page, pageSize, biddingExist, categoryDepth1Id, categoryDepth2Id, enableBid, enableBuyNow, end, keyword, orderStatus, salesStatus, showInvisibled, sortBy, start, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 배송타입 리스트
         * @summary shippingTypeList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shippingTypeListUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shippingTypeListUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 상품게시
         * @summary productShow
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showUsingPUT(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showUsingPUT(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 상품주문상태값수정
         * @summary orderStatusUpdate
         * @param {OrderStatusParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrderStatusUsingPUT(param: OrderStatusParamDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrderStatusUsingPUT(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 반품완료
         * @summary returnMemoUpdate
         * @param {ReturnMemoParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReturnMemoUsingPUT(param: ReturnMemoParamDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReturnMemoUsingPUT(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 트래킹코드입력
         * @summary trackingCodeUpdate
         * @param {TrackingCodeParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTrackingCodeUsingPUT(param: TrackingCodeParamDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTrackingCodeUsingPUT(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 상품수정
         * @summary productUpdate
         * @param {ProductUpdateParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUsingPUT(param: ProductUpdateParamDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUsingPUT(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerProductApi - factory interface
 * @export
 */
export const PartnerProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerProductApiFp(configuration)
    return {
        /**
         * 카테고리 리스트
         * @summary categoryList
         * @param {number} depth depth
         * @param {number} [parentCategoryId] parentCategoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryListUsingGET1(depth: number, parentCategoryId?: number, options?: any): AxiosPromise<object> {
            return localVarFp.categoryListUsingGET1(depth, parentCategoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 상품정보 등록
         * @summary productAddStep1
         * @param {ProductParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStep1UsingPOST(param: ProductParamDTO, options?: any): AxiosPromise<object> {
            return localVarFp.createStep1UsingPOST(param, options).then((request) => request(axios, basePath));
        },
        /**
         * 판매정보 등록
         * @summary productAddStep2
         * @param {SalesInfoParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStep2UsingPOST(param: SalesInfoParamDTO, options?: any): AxiosPromise<object> {
            return localVarFp.createStep2UsingPOST(param, options).then((request) => request(axios, basePath));
        },
        /**
         * 상품등록
         * @summary productStatusUpdate
         * @param {number} productId productId
         * @param {'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out'} salesStatus salesStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsingPUT(productId: number, salesStatus: 'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out', options?: any): AxiosPromise<object> {
            return localVarFp.createUsingPUT(productId, salesStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 상품삭제
         * @summary productDelete
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingDELETE(productId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteUsingDELETE(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 배송조회
         * @summary shippingTracking
         * @param {string} ordernum ordernum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShippingTrackingUsingGET1(ordernum: string, options?: any): AxiosPromise<object> {
            return localVarFp.getShippingTrackingUsingGET1(ordernum, options).then((request) => request(axios, basePath));
        },
        /**
         * 상품조회
         * @summary productSingle
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsingGET5(productId: number, options?: any): AxiosPromise<object> {
            return localVarFp.getUsingGET5(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 파일업로드
         * @summary fileUpload
         * @param {number} [maxSize] maxSize
         * @param {boolean} [resize] resize
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleFileUploadUsingPOST3(maxSize?: number, resize?: boolean, file?: any, options?: any): AxiosPromise<string> {
            return localVarFp.handleFileUploadUsingPOST3(maxSize, resize, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 상품숨김
         * @summary productHide
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hideUsingPUT(productId: number, options?: any): AxiosPromise<object> {
            return localVarFp.hideUsingPUT(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 상품리스트
         * @summary productList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {number} [categoryDepth2Id] categoryDepth2Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {string} [end] end
         * @param {string} [keyword] keyword
         * @param {'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out'} [salesStatus] salesStatus
         * @param {boolean} [showInvisibled] showInvisibled
         * @param {string} [sortBy] sortBy
         * @param {string} [start] start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsingGET4(page: number, pageSize: number, categoryDepth1Id?: number, categoryDepth2Id?: number, enableBid?: boolean, enableBuyNow?: boolean, end?: string, keyword?: string, salesStatus?: 'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out', showInvisibled?: boolean, sortBy?: string, start?: string, options?: any): AxiosPromise<object> {
            return localVarFp.listUsingGET4(page, pageSize, categoryDepth1Id, categoryDepth2Id, enableBid, enableBuyNow, end, keyword, salesStatus, showInvisibled, sortBy, start, options).then((request) => request(axios, basePath));
        },
        /**
         * 옵션 리스트 ( type : period , status , country , artist , medium )
         * @summary optionList
         * @param {string} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionListUsingGET1(type?: string, options?: any): AxiosPromise<object> {
            return localVarFp.optionListUsingGET1(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 판매상세
         * @summary salesInfo
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesInfo(productId: number, options?: any): AxiosPromise<object> {
            return localVarFp.salesInfo(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 판매리스트
         * @summary salesList
         * @param {number} page page
         * @param {number} pageSize pageSize
         * @param {boolean} [biddingExist] biddingExist
         * @param {number} [categoryDepth1Id] categoryDepth1Id
         * @param {number} [categoryDepth2Id] categoryDepth2Id
         * @param {boolean} [enableBid] enableBid
         * @param {boolean} [enableBuyNow] enableBuyNow
         * @param {string} [end] end
         * @param {string} [keyword] keyword
         * @param {'complete' | 'delivered' | 'deposit_wait' | 'none' | 'payment_complete' | 'payment_time_out' | 'payment_wait' | 'proccessed' | 'purchase_cancel' | 'return_complete' | 'return_request' | 'shipped'} [orderStatus] orderStatus
         * @param {'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out'} [salesStatus] salesStatus
         * @param {boolean} [showInvisibled] showInvisibled
         * @param {string} [sortBy] sortBy
         * @param {string} [start] start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesListUsingGET(page: number, pageSize: number, biddingExist?: boolean, categoryDepth1Id?: number, categoryDepth2Id?: number, enableBid?: boolean, enableBuyNow?: boolean, end?: string, keyword?: string, orderStatus?: 'complete' | 'delivered' | 'deposit_wait' | 'none' | 'payment_complete' | 'payment_time_out' | 'payment_wait' | 'proccessed' | 'purchase_cancel' | 'return_complete' | 'return_request' | 'shipped', salesStatus?: 'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out', showInvisibled?: boolean, sortBy?: string, start?: string, options?: any): AxiosPromise<object> {
            return localVarFp.salesListUsingGET(page, pageSize, biddingExist, categoryDepth1Id, categoryDepth2Id, enableBid, enableBuyNow, end, keyword, orderStatus, salesStatus, showInvisibled, sortBy, start, options).then((request) => request(axios, basePath));
        },
        /**
         * 배송타입 리스트
         * @summary shippingTypeList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippingTypeListUsingGET(options?: any): AxiosPromise<object> {
            return localVarFp.shippingTypeListUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 상품게시
         * @summary productShow
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showUsingPUT(productId: number, options?: any): AxiosPromise<object> {
            return localVarFp.showUsingPUT(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 상품주문상태값수정
         * @summary orderStatusUpdate
         * @param {OrderStatusParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderStatusUsingPUT(param: OrderStatusParamDTO, options?: any): AxiosPromise<object> {
            return localVarFp.updateOrderStatusUsingPUT(param, options).then((request) => request(axios, basePath));
        },
        /**
         * 반품완료
         * @summary returnMemoUpdate
         * @param {ReturnMemoParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReturnMemoUsingPUT(param: ReturnMemoParamDTO, options?: any): AxiosPromise<object> {
            return localVarFp.updateReturnMemoUsingPUT(param, options).then((request) => request(axios, basePath));
        },
        /**
         * 트래킹코드입력
         * @summary trackingCodeUpdate
         * @param {TrackingCodeParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrackingCodeUsingPUT(param: TrackingCodeParamDTO, options?: any): AxiosPromise<object> {
            return localVarFp.updateTrackingCodeUsingPUT(param, options).then((request) => request(axios, basePath));
        },
        /**
         * 상품수정
         * @summary productUpdate
         * @param {ProductUpdateParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUsingPUT(param: ProductUpdateParamDTO, options?: any): AxiosPromise<object> {
            return localVarFp.updateUsingPUT(param, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for categoryListUsingGET1 operation in PartnerProductApi.
 * @export
 * @interface PartnerProductApiCategoryListUsingGET1Request
 */
export interface PartnerProductApiCategoryListUsingGET1Request {
    /**
     * depth
     * @type {number}
     * @memberof PartnerProductApiCategoryListUsingGET1
     */
    readonly depth: number

    /**
     * parentCategoryId 
     * @type {number}
     * @memberof PartnerProductApiCategoryListUsingGET1
     */
    readonly parentCategoryId?: number
}

/**
 * Request parameters for createStep1UsingPOST operation in PartnerProductApi.
 * @export
 * @interface PartnerProductApiCreateStep1UsingPOSTRequest
 */
export interface PartnerProductApiCreateStep1UsingPOSTRequest {
    /**
     * param
     * @type {ProductParamDTO}
     * @memberof PartnerProductApiCreateStep1UsingPOST
     */
    readonly param: ProductParamDTO
}

/**
 * Request parameters for createStep2UsingPOST operation in PartnerProductApi.
 * @export
 * @interface PartnerProductApiCreateStep2UsingPOSTRequest
 */
export interface PartnerProductApiCreateStep2UsingPOSTRequest {
    /**
     * param
     * @type {SalesInfoParamDTO}
     * @memberof PartnerProductApiCreateStep2UsingPOST
     */
    readonly param: SalesInfoParamDTO
}

/**
 * Request parameters for createUsingPUT operation in PartnerProductApi.
 * @export
 * @interface PartnerProductApiCreateUsingPUTRequest
 */
export interface PartnerProductApiCreateUsingPUTRequest {
    /**
     * productId
     * @type {number}
     * @memberof PartnerProductApiCreateUsingPUT
     */
    readonly productId: number

    /**
     * salesStatus
     * @type {'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out'}
     * @memberof PartnerProductApiCreateUsingPUT
     */
    readonly salesStatus: 'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out'
}

/**
 * Request parameters for deleteUsingDELETE operation in PartnerProductApi.
 * @export
 * @interface PartnerProductApiDeleteUsingDELETERequest
 */
export interface PartnerProductApiDeleteUsingDELETERequest {
    /**
     * productId
     * @type {number}
     * @memberof PartnerProductApiDeleteUsingDELETE
     */
    readonly productId: number
}

/**
 * Request parameters for getShippingTrackingUsingGET1 operation in PartnerProductApi.
 * @export
 * @interface PartnerProductApiGetShippingTrackingUsingGET1Request
 */
export interface PartnerProductApiGetShippingTrackingUsingGET1Request {
    /**
     * ordernum
     * @type {string}
     * @memberof PartnerProductApiGetShippingTrackingUsingGET1
     */
    readonly ordernum: string
}

/**
 * Request parameters for getUsingGET5 operation in PartnerProductApi.
 * @export
 * @interface PartnerProductApiGetUsingGET5Request
 */
export interface PartnerProductApiGetUsingGET5Request {
    /**
     * productId
     * @type {number}
     * @memberof PartnerProductApiGetUsingGET5
     */
    readonly productId: number
}

/**
 * Request parameters for handleFileUploadUsingPOST3 operation in PartnerProductApi.
 * @export
 * @interface PartnerProductApiHandleFileUploadUsingPOST3Request
 */
export interface PartnerProductApiHandleFileUploadUsingPOST3Request {
    /**
     * maxSize
     * @type {number}
     * @memberof PartnerProductApiHandleFileUploadUsingPOST3
     */
    readonly maxSize?: number

    /**
     * resize
     * @type {boolean}
     * @memberof PartnerProductApiHandleFileUploadUsingPOST3
     */
    readonly resize?: boolean

    /**
     * 
     * @type {any}
     * @memberof PartnerProductApiHandleFileUploadUsingPOST3
     */
    readonly file?: any
}

/**
 * Request parameters for hideUsingPUT operation in PartnerProductApi.
 * @export
 * @interface PartnerProductApiHideUsingPUTRequest
 */
export interface PartnerProductApiHideUsingPUTRequest {
    /**
     * productId
     * @type {number}
     * @memberof PartnerProductApiHideUsingPUT
     */
    readonly productId: number
}

/**
 * Request parameters for listUsingGET4 operation in PartnerProductApi.
 * @export
 * @interface PartnerProductApiListUsingGET4Request
 */
export interface PartnerProductApiListUsingGET4Request {
    /**
     * page
     * @type {number}
     * @memberof PartnerProductApiListUsingGET4
     */
    readonly page: number

    /**
     * pageSize
     * @type {number}
     * @memberof PartnerProductApiListUsingGET4
     */
    readonly pageSize: number

    /**
     * categoryDepth1Id
     * @type {number}
     * @memberof PartnerProductApiListUsingGET4
     */
    readonly categoryDepth1Id?: number

    /**
     * categoryDepth2Id
     * @type {number}
     * @memberof PartnerProductApiListUsingGET4
     */
    readonly categoryDepth2Id?: number

    /**
     * enableBid
     * @type {boolean}
     * @memberof PartnerProductApiListUsingGET4
     */
    readonly enableBid?: boolean

    /**
     * enableBuyNow
     * @type {boolean}
     * @memberof PartnerProductApiListUsingGET4
     */
    readonly enableBuyNow?: boolean

    /**
     * end
     * @type {string}
     * @memberof PartnerProductApiListUsingGET4
     */
    readonly end?: string

    /**
     * keyword
     * @type {string}
     * @memberof PartnerProductApiListUsingGET4
     */
    readonly keyword?: string

    /**
     * salesStatus
     * @type {'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out'}
     * @memberof PartnerProductApiListUsingGET4
     */
    readonly salesStatus?: 'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out'

    /**
     * showInvisibled
     * @type {boolean}
     * @memberof PartnerProductApiListUsingGET4
     */
    readonly showInvisibled?: boolean

    /**
     * sortBy
     * @type {string}
     * @memberof PartnerProductApiListUsingGET4
     */
    readonly sortBy?: string

    /**
     * start
     * @type {string}
     * @memberof PartnerProductApiListUsingGET4
     */
    readonly start?: string
}

/**
 * Request parameters for optionListUsingGET1 operation in PartnerProductApi.
 * @export
 * @interface PartnerProductApiOptionListUsingGET1Request
 */
export interface PartnerProductApiOptionListUsingGET1Request {
    /**
     * type
     * @type {string}
     * @memberof PartnerProductApiOptionListUsingGET1
     */
    readonly type?: string
}

/**
 * Request parameters for salesInfo operation in PartnerProductApi.
 * @export
 * @interface PartnerProductApiSalesInfoRequest
 */
export interface PartnerProductApiSalesInfoRequest {
    /**
     * productId
     * @type {number}
     * @memberof PartnerProductApiSalesInfo
     */
    readonly productId: number
}

/**
 * Request parameters for salesListUsingGET operation in PartnerProductApi.
 * @export
 * @interface PartnerProductApiSalesListUsingGETRequest
 */
export interface PartnerProductApiSalesListUsingGETRequest {
    /**
     * page
     * @type {number}
     * @memberof PartnerProductApiSalesListUsingGET
     */
    readonly page: number

    /**
     * pageSize
     * @type {number}
     * @memberof PartnerProductApiSalesListUsingGET
     */
    readonly pageSize: number

    /**
     * biddingExist
     * @type {boolean}
     * @memberof PartnerProductApiSalesListUsingGET
     */
    readonly biddingExist?: boolean

    /**
     * categoryDepth1Id
     * @type {number}
     * @memberof PartnerProductApiSalesListUsingGET
     */
    readonly categoryDepth1Id?: number

    /**
     * categoryDepth2Id
     * @type {number}
     * @memberof PartnerProductApiSalesListUsingGET
     */
    readonly categoryDepth2Id?: number

    /**
     * enableBid
     * @type {boolean}
     * @memberof PartnerProductApiSalesListUsingGET
     */
    readonly enableBid?: boolean

    /**
     * enableBuyNow
     * @type {boolean}
     * @memberof PartnerProductApiSalesListUsingGET
     */
    readonly enableBuyNow?: boolean

    /**
     * end
     * @type {string}
     * @memberof PartnerProductApiSalesListUsingGET
     */
    readonly end?: string

    /**
     * keyword
     * @type {string}
     * @memberof PartnerProductApiSalesListUsingGET
     */
    readonly keyword?: string

    /**
     * orderStatus
     * @type {'complete' | 'delivered' | 'deposit_wait' | 'none' | 'payment_complete' | 'payment_time_out' | 'payment_wait' | 'proccessed' | 'purchase_cancel' | 'return_complete' | 'return_request' | 'shipped'}
     * @memberof PartnerProductApiSalesListUsingGET
     */
    readonly orderStatus?: 'complete' | 'delivered' | 'deposit_wait' | 'none' | 'payment_complete' | 'payment_time_out' | 'payment_wait' | 'proccessed' | 'purchase_cancel' | 'return_complete' | 'return_request' | 'shipped'

    /**
     * salesStatus
     * @type {'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out'}
     * @memberof PartnerProductApiSalesListUsingGET
     */
    readonly salesStatus?: 'bid_complete' | 'bid_payment_cancel' | 'bid_payment_time_out' | 'display' | 'invisible' | 'sale' | 'sold_out' | 'time_out'

    /**
     * showInvisibled
     * @type {boolean}
     * @memberof PartnerProductApiSalesListUsingGET
     */
    readonly showInvisibled?: boolean

    /**
     * sortBy
     * @type {string}
     * @memberof PartnerProductApiSalesListUsingGET
     */
    readonly sortBy?: string

    /**
     * start
     * @type {string}
     * @memberof PartnerProductApiSalesListUsingGET
     */
    readonly start?: string
}

/**
 * Request parameters for showUsingPUT operation in PartnerProductApi.
 * @export
 * @interface PartnerProductApiShowUsingPUTRequest
 */
export interface PartnerProductApiShowUsingPUTRequest {
    /**
     * productId
     * @type {number}
     * @memberof PartnerProductApiShowUsingPUT
     */
    readonly productId: number
}

/**
 * Request parameters for updateOrderStatusUsingPUT operation in PartnerProductApi.
 * @export
 * @interface PartnerProductApiUpdateOrderStatusUsingPUTRequest
 */
export interface PartnerProductApiUpdateOrderStatusUsingPUTRequest {
    /**
     * param
     * @type {OrderStatusParamDTO}
     * @memberof PartnerProductApiUpdateOrderStatusUsingPUT
     */
    readonly param: OrderStatusParamDTO
}

/**
 * Request parameters for updateReturnMemoUsingPUT operation in PartnerProductApi.
 * @export
 * @interface PartnerProductApiUpdateReturnMemoUsingPUTRequest
 */
export interface PartnerProductApiUpdateReturnMemoUsingPUTRequest {
    /**
     * param
     * @type {ReturnMemoParamDTO}
     * @memberof PartnerProductApiUpdateReturnMemoUsingPUT
     */
    readonly param: ReturnMemoParamDTO
}

/**
 * Request parameters for updateTrackingCodeUsingPUT operation in PartnerProductApi.
 * @export
 * @interface PartnerProductApiUpdateTrackingCodeUsingPUTRequest
 */
export interface PartnerProductApiUpdateTrackingCodeUsingPUTRequest {
    /**
     * param
     * @type {TrackingCodeParamDTO}
     * @memberof PartnerProductApiUpdateTrackingCodeUsingPUT
     */
    readonly param: TrackingCodeParamDTO
}

/**
 * Request parameters for updateUsingPUT operation in PartnerProductApi.
 * @export
 * @interface PartnerProductApiUpdateUsingPUTRequest
 */
export interface PartnerProductApiUpdateUsingPUTRequest {
    /**
     * param
     * @type {ProductUpdateParamDTO}
     * @memberof PartnerProductApiUpdateUsingPUT
     */
    readonly param: ProductUpdateParamDTO
}

/**
 * PartnerProductApi - object-oriented interface
 * @export
 * @class PartnerProductApi
 * @extends {BaseAPI}
 */
export class PartnerProductApi extends BaseAPI {
    /**
     * 카테고리 리스트
     * @summary categoryList
     * @param {PartnerProductApiCategoryListUsingGET1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerProductApi
     */
    public categoryListUsingGET1(requestParameters: PartnerProductApiCategoryListUsingGET1Request, options?: any) {
        return PartnerProductApiFp(this.configuration).categoryListUsingGET1(requestParameters.depth, requestParameters.parentCategoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 상품정보 등록
     * @summary productAddStep1
     * @param {PartnerProductApiCreateStep1UsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerProductApi
     */
    public createStep1UsingPOST(requestParameters: PartnerProductApiCreateStep1UsingPOSTRequest, options?: any) {
        return PartnerProductApiFp(this.configuration).createStep1UsingPOST(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 판매정보 등록
     * @summary productAddStep2
     * @param {PartnerProductApiCreateStep2UsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerProductApi
     */
    public createStep2UsingPOST(requestParameters: PartnerProductApiCreateStep2UsingPOSTRequest, options?: any) {
        return PartnerProductApiFp(this.configuration).createStep2UsingPOST(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 상품등록
     * @summary productStatusUpdate
     * @param {PartnerProductApiCreateUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerProductApi
     */
    public createUsingPUT(requestParameters: PartnerProductApiCreateUsingPUTRequest, options?: any) {
        return PartnerProductApiFp(this.configuration).createUsingPUT(requestParameters.productId, requestParameters.salesStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 상품삭제
     * @summary productDelete
     * @param {PartnerProductApiDeleteUsingDELETERequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerProductApi
     */
    public deleteUsingDELETE(requestParameters: PartnerProductApiDeleteUsingDELETERequest, options?: any) {
        return PartnerProductApiFp(this.configuration).deleteUsingDELETE(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 배송조회
     * @summary shippingTracking
     * @param {PartnerProductApiGetShippingTrackingUsingGET1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerProductApi
     */
    public getShippingTrackingUsingGET1(requestParameters: PartnerProductApiGetShippingTrackingUsingGET1Request, options?: any) {
        return PartnerProductApiFp(this.configuration).getShippingTrackingUsingGET1(requestParameters.ordernum, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 상품조회
     * @summary productSingle
     * @param {PartnerProductApiGetUsingGET5Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerProductApi
     */
    public getUsingGET5(requestParameters: PartnerProductApiGetUsingGET5Request, options?: any) {
        return PartnerProductApiFp(this.configuration).getUsingGET5(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 파일업로드
     * @summary fileUpload
     * @param {PartnerProductApiHandleFileUploadUsingPOST3Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerProductApi
     */
    public handleFileUploadUsingPOST3(requestParameters: PartnerProductApiHandleFileUploadUsingPOST3Request = {}, options?: any) {
        return PartnerProductApiFp(this.configuration).handleFileUploadUsingPOST3(requestParameters.maxSize, requestParameters.resize, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 상품숨김
     * @summary productHide
     * @param {PartnerProductApiHideUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerProductApi
     */
    public hideUsingPUT(requestParameters: PartnerProductApiHideUsingPUTRequest, options?: any) {
        return PartnerProductApiFp(this.configuration).hideUsingPUT(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 상품리스트
     * @summary productList
     * @param {PartnerProductApiListUsingGET4Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerProductApi
     */
    public listUsingGET4(requestParameters: PartnerProductApiListUsingGET4Request, options?: any) {
        return PartnerProductApiFp(this.configuration).listUsingGET4(requestParameters.page, requestParameters.pageSize, requestParameters.categoryDepth1Id, requestParameters.categoryDepth2Id, requestParameters.enableBid, requestParameters.enableBuyNow, requestParameters.end, requestParameters.keyword, requestParameters.salesStatus, requestParameters.showInvisibled, requestParameters.sortBy, requestParameters.start, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 옵션 리스트 ( type : period , status , country , artist , medium )
     * @summary optionList
     * @param {PartnerProductApiOptionListUsingGET1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerProductApi
     */
    public optionListUsingGET1(requestParameters: PartnerProductApiOptionListUsingGET1Request = {}, options?: any) {
        return PartnerProductApiFp(this.configuration).optionListUsingGET1(requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 판매상세
     * @summary salesInfo
     * @param {PartnerProductApiSalesInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerProductApi
     */
    public salesInfo(requestParameters: PartnerProductApiSalesInfoRequest, options?: any) {
        return PartnerProductApiFp(this.configuration).salesInfo(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 판매리스트
     * @summary salesList
     * @param {PartnerProductApiSalesListUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerProductApi
     */
    public salesListUsingGET(requestParameters: PartnerProductApiSalesListUsingGETRequest, options?: any) {
        return PartnerProductApiFp(this.configuration).salesListUsingGET(requestParameters.page, requestParameters.pageSize, requestParameters.biddingExist, requestParameters.categoryDepth1Id, requestParameters.categoryDepth2Id, requestParameters.enableBid, requestParameters.enableBuyNow, requestParameters.end, requestParameters.keyword, requestParameters.orderStatus, requestParameters.salesStatus, requestParameters.showInvisibled, requestParameters.sortBy, requestParameters.start, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 배송타입 리스트
     * @summary shippingTypeList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerProductApi
     */
    public shippingTypeListUsingGET(options?: any) {
        return PartnerProductApiFp(this.configuration).shippingTypeListUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 상품게시
     * @summary productShow
     * @param {PartnerProductApiShowUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerProductApi
     */
    public showUsingPUT(requestParameters: PartnerProductApiShowUsingPUTRequest, options?: any) {
        return PartnerProductApiFp(this.configuration).showUsingPUT(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 상품주문상태값수정
     * @summary orderStatusUpdate
     * @param {PartnerProductApiUpdateOrderStatusUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerProductApi
     */
    public updateOrderStatusUsingPUT(requestParameters: PartnerProductApiUpdateOrderStatusUsingPUTRequest, options?: any) {
        return PartnerProductApiFp(this.configuration).updateOrderStatusUsingPUT(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 반품완료
     * @summary returnMemoUpdate
     * @param {PartnerProductApiUpdateReturnMemoUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerProductApi
     */
    public updateReturnMemoUsingPUT(requestParameters: PartnerProductApiUpdateReturnMemoUsingPUTRequest, options?: any) {
        return PartnerProductApiFp(this.configuration).updateReturnMemoUsingPUT(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 트래킹코드입력
     * @summary trackingCodeUpdate
     * @param {PartnerProductApiUpdateTrackingCodeUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerProductApi
     */
    public updateTrackingCodeUsingPUT(requestParameters: PartnerProductApiUpdateTrackingCodeUsingPUTRequest, options?: any) {
        return PartnerProductApiFp(this.configuration).updateTrackingCodeUsingPUT(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 상품수정
     * @summary productUpdate
     * @param {PartnerProductApiUpdateUsingPUTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerProductApi
     */
    public updateUsingPUT(requestParameters: PartnerProductApiUpdateUsingPUTRequest, options?: any) {
        return PartnerProductApiFp(this.configuration).updateUsingPUT(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnerQnaApi - axios parameter creator
 * @export
 */
export const PartnerQnaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Qna 등록
         * @summary addQna
         * @param {ProductQnaDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addQnaUsingPOST1: async (param: ProductQnaDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('addQnaUsingPOST1', 'param', param)
            const localVarPath = `/api/partner/qna/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Qna상세리스트
         * @summary qnaDetailList
         * @param {number} memberId memberId
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDetailUsingGET: async (memberId: number, productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('listDetailUsingGET', 'memberId', memberId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('listDetailUsingGET', 'productId', productId)
            const localVarPath = `/api/partner/qna/details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (memberId !== undefined) {
                localVarQueryParameter['memberId'] = memberId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Qna상품별회원리스트
         * @summary qnaProductMemberList
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMemberUsingGET: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('listMemberUsingGET', 'productId', productId)
            const localVarPath = `/api/partner/qna/members`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Qna상품리스트
         * @summary qnaProductList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProductUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/partner/qna/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerQnaApi - functional programming interface
 * @export
 */
export const PartnerQnaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerQnaApiAxiosParamCreator(configuration)
    return {
        /**
         * Qna 등록
         * @summary addQna
         * @param {ProductQnaDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addQnaUsingPOST1(param: ProductQnaDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addQnaUsingPOST1(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Qna상세리스트
         * @summary qnaDetailList
         * @param {number} memberId memberId
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDetailUsingGET(memberId: number, productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDetailUsingGET(memberId, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Qna상품별회원리스트
         * @summary qnaProductMemberList
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMemberUsingGET(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMemberUsingGET(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Qna상품리스트
         * @summary qnaProductList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProductUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProductUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerQnaApi - factory interface
 * @export
 */
export const PartnerQnaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerQnaApiFp(configuration)
    return {
        /**
         * Qna 등록
         * @summary addQna
         * @param {ProductQnaDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addQnaUsingPOST1(param: ProductQnaDTO, options?: any): AxiosPromise<object> {
            return localVarFp.addQnaUsingPOST1(param, options).then((request) => request(axios, basePath));
        },
        /**
         * Qna상세리스트
         * @summary qnaDetailList
         * @param {number} memberId memberId
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDetailUsingGET(memberId: number, productId: number, options?: any): AxiosPromise<object> {
            return localVarFp.listDetailUsingGET(memberId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * Qna상품별회원리스트
         * @summary qnaProductMemberList
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMemberUsingGET(productId: number, options?: any): AxiosPromise<object> {
            return localVarFp.listMemberUsingGET(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * Qna상품리스트
         * @summary qnaProductList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProductUsingGET(options?: any): AxiosPromise<object> {
            return localVarFp.listProductUsingGET(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addQnaUsingPOST1 operation in PartnerQnaApi.
 * @export
 * @interface PartnerQnaApiAddQnaUsingPOST1Request
 */
export interface PartnerQnaApiAddQnaUsingPOST1Request {
    /**
     * param
     * @type {ProductQnaDTO}
     * @memberof PartnerQnaApiAddQnaUsingPOST1
     */
    readonly param: ProductQnaDTO
}

/**
 * Request parameters for listDetailUsingGET operation in PartnerQnaApi.
 * @export
 * @interface PartnerQnaApiListDetailUsingGETRequest
 */
export interface PartnerQnaApiListDetailUsingGETRequest {
    /**
     * memberId
     * @type {number}
     * @memberof PartnerQnaApiListDetailUsingGET
     */
    readonly memberId: number

    /**
     * productId
     * @type {number}
     * @memberof PartnerQnaApiListDetailUsingGET
     */
    readonly productId: number
}

/**
 * Request parameters for listMemberUsingGET operation in PartnerQnaApi.
 * @export
 * @interface PartnerQnaApiListMemberUsingGETRequest
 */
export interface PartnerQnaApiListMemberUsingGETRequest {
    /**
     * productId
     * @type {number}
     * @memberof PartnerQnaApiListMemberUsingGET
     */
    readonly productId: number
}

/**
 * PartnerQnaApi - object-oriented interface
 * @export
 * @class PartnerQnaApi
 * @extends {BaseAPI}
 */
export class PartnerQnaApi extends BaseAPI {
    /**
     * Qna 등록
     * @summary addQna
     * @param {PartnerQnaApiAddQnaUsingPOST1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerQnaApi
     */
    public addQnaUsingPOST1(requestParameters: PartnerQnaApiAddQnaUsingPOST1Request, options?: any) {
        return PartnerQnaApiFp(this.configuration).addQnaUsingPOST1(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Qna상세리스트
     * @summary qnaDetailList
     * @param {PartnerQnaApiListDetailUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerQnaApi
     */
    public listDetailUsingGET(requestParameters: PartnerQnaApiListDetailUsingGETRequest, options?: any) {
        return PartnerQnaApiFp(this.configuration).listDetailUsingGET(requestParameters.memberId, requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Qna상품별회원리스트
     * @summary qnaProductMemberList
     * @param {PartnerQnaApiListMemberUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerQnaApi
     */
    public listMemberUsingGET(requestParameters: PartnerQnaApiListMemberUsingGETRequest, options?: any) {
        return PartnerQnaApiFp(this.configuration).listMemberUsingGET(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Qna상품리스트
     * @summary qnaProductList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerQnaApi
     */
    public listProductUsingGET(options?: any) {
        return PartnerQnaApiFp(this.configuration).listProductUsingGET(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PurchaseControllerApi - axios parameter creator
 * @export
 */
export const PurchaseControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 배송지 리스트
         * @summary shippingAddressList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bidderListUsingGET1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/purchase/addresses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 카드 리스트
         * @summary creditCardList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCardListUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/purchase/creditcard-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 나이스 파라미터 조회
         * @summary getNiceParam
         * @param {string} ordernum ordernum
         * @param {number} price price
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNiceParamUsingPOST: async (ordernum: string, price: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ordernum' is not null or undefined
            assertParamExists('getNiceParamUsingPOST', 'ordernum', ordernum)
            // verify required parameter 'price' is not null or undefined
            assertParamExists('getNiceParamUsingPOST', 'price', price)
            const localVarPath = `/api/purchase/nice-param`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ordernum !== undefined) {
                localVarQueryParameter['ordernum'] = ordernum;
            }

            if (price !== undefined) {
                localVarQueryParameter['price'] = price;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 주문 조회
         * @summary orderInfo
         * @param {string} ordernum ordernum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderUsingGET: async (ordernum: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ordernum' is not null or undefined
            assertParamExists('getOrderUsingGET', 'ordernum', ordernum)
            const localVarPath = `/api/purchase/order/{ordernum}`
                .replace(`{${"ordernum"}}`, encodeURIComponent(String(ordernum)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 낙찰 상품 주문
         * @summary order
         * @param {OrderParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderBuynowUsingPOST: async (param: OrderParamDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'param' is not null or undefined
            assertParamExists('orderBuynowUsingPOST', 'param', param)
            const localVarPath = `/api/purchase/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(param, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 주문 사전 검증
         * @summary orderPreCheck
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderPreCheckUsingGET: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('orderPreCheckUsingGET', 'productId', productId)
            const localVarPath = `/api/purchase/order-pre-check/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 결재 확인
         * @summary payResult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payResultUsingPOST: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/purchase/pay/result`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 입금 확인
         * @summary depositDetection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payResultUsingPOST1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/purchase/deposit/detection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PurchaseControllerApi - functional programming interface
 * @export
 */
export const PurchaseControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PurchaseControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 배송지 리스트
         * @summary shippingAddressList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bidderListUsingGET1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bidderListUsingGET1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 카드 리스트
         * @summary creditCardList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditCardListUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creditCardListUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 나이스 파라미터 조회
         * @summary getNiceParam
         * @param {string} ordernum ordernum
         * @param {number} price price
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNiceParamUsingPOST(ordernum: string, price: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNiceParamUsingPOST(ordernum, price, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 주문 조회
         * @summary orderInfo
         * @param {string} ordernum ordernum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderUsingGET(ordernum: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderUsingGET(ordernum, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 낙찰 상품 주문
         * @summary order
         * @param {OrderParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderBuynowUsingPOST(param: OrderParamDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderBuynowUsingPOST(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 주문 사전 검증
         * @summary orderPreCheck
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderPreCheckUsingGET(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderPreCheckUsingGET(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 결재 확인
         * @summary payResult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payResultUsingPOST(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payResultUsingPOST(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 입금 확인
         * @summary depositDetection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payResultUsingPOST1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payResultUsingPOST1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PurchaseControllerApi - factory interface
 * @export
 */
export const PurchaseControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PurchaseControllerApiFp(configuration)
    return {
        /**
         * 배송지 리스트
         * @summary shippingAddressList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bidderListUsingGET1(options?: any): AxiosPromise<object> {
            return localVarFp.bidderListUsingGET1(options).then((request) => request(axios, basePath));
        },
        /**
         * 카드 리스트
         * @summary creditCardList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditCardListUsingGET(options?: any): AxiosPromise<object> {
            return localVarFp.creditCardListUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 나이스 파라미터 조회
         * @summary getNiceParam
         * @param {string} ordernum ordernum
         * @param {number} price price
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNiceParamUsingPOST(ordernum: string, price: number, options?: any): AxiosPromise<object> {
            return localVarFp.getNiceParamUsingPOST(ordernum, price, options).then((request) => request(axios, basePath));
        },
        /**
         * 주문 조회
         * @summary orderInfo
         * @param {string} ordernum ordernum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderUsingGET(ordernum: string, options?: any): AxiosPromise<object> {
            return localVarFp.getOrderUsingGET(ordernum, options).then((request) => request(axios, basePath));
        },
        /**
         * 낙찰 상품 주문
         * @summary order
         * @param {OrderParamDTO} param param
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderBuynowUsingPOST(param: OrderParamDTO, options?: any): AxiosPromise<object> {
            return localVarFp.orderBuynowUsingPOST(param, options).then((request) => request(axios, basePath));
        },
        /**
         * 주문 사전 검증
         * @summary orderPreCheck
         * @param {number} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderPreCheckUsingGET(productId: number, options?: any): AxiosPromise<object> {
            return localVarFp.orderPreCheckUsingGET(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 결재 확인
         * @summary payResult
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payResultUsingPOST(options?: any): AxiosPromise<object> {
            return localVarFp.payResultUsingPOST(options).then((request) => request(axios, basePath));
        },
        /**
         * 입금 확인
         * @summary depositDetection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payResultUsingPOST1(options?: any): AxiosPromise<object> {
            return localVarFp.payResultUsingPOST1(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getNiceParamUsingPOST operation in PurchaseControllerApi.
 * @export
 * @interface PurchaseControllerApiGetNiceParamUsingPOSTRequest
 */
export interface PurchaseControllerApiGetNiceParamUsingPOSTRequest {
    /**
     * ordernum
     * @type {string}
     * @memberof PurchaseControllerApiGetNiceParamUsingPOST
     */
    readonly ordernum: string

    /**
     * price
     * @type {number}
     * @memberof PurchaseControllerApiGetNiceParamUsingPOST
     */
    readonly price: number
}

/**
 * Request parameters for getOrderUsingGET operation in PurchaseControllerApi.
 * @export
 * @interface PurchaseControllerApiGetOrderUsingGETRequest
 */
export interface PurchaseControllerApiGetOrderUsingGETRequest {
    /**
     * ordernum
     * @type {string}
     * @memberof PurchaseControllerApiGetOrderUsingGET
     */
    readonly ordernum: string
}

/**
 * Request parameters for orderBuynowUsingPOST operation in PurchaseControllerApi.
 * @export
 * @interface PurchaseControllerApiOrderBuynowUsingPOSTRequest
 */
export interface PurchaseControllerApiOrderBuynowUsingPOSTRequest {
    /**
     * param
     * @type {OrderParamDTO}
     * @memberof PurchaseControllerApiOrderBuynowUsingPOST
     */
    readonly param: OrderParamDTO
}

/**
 * Request parameters for orderPreCheckUsingGET operation in PurchaseControllerApi.
 * @export
 * @interface PurchaseControllerApiOrderPreCheckUsingGETRequest
 */
export interface PurchaseControllerApiOrderPreCheckUsingGETRequest {
    /**
     * productId
     * @type {number}
     * @memberof PurchaseControllerApiOrderPreCheckUsingGET
     */
    readonly productId: number
}

/**
 * PurchaseControllerApi - object-oriented interface
 * @export
 * @class PurchaseControllerApi
 * @extends {BaseAPI}
 */
export class PurchaseControllerApi extends BaseAPI {
    /**
     * 배송지 리스트
     * @summary shippingAddressList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseControllerApi
     */
    public bidderListUsingGET1(options?: any) {
        return PurchaseControllerApiFp(this.configuration).bidderListUsingGET1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 카드 리스트
     * @summary creditCardList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseControllerApi
     */
    public creditCardListUsingGET(options?: any) {
        return PurchaseControllerApiFp(this.configuration).creditCardListUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 나이스 파라미터 조회
     * @summary getNiceParam
     * @param {PurchaseControllerApiGetNiceParamUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseControllerApi
     */
    public getNiceParamUsingPOST(requestParameters: PurchaseControllerApiGetNiceParamUsingPOSTRequest, options?: any) {
        return PurchaseControllerApiFp(this.configuration).getNiceParamUsingPOST(requestParameters.ordernum, requestParameters.price, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 주문 조회
     * @summary orderInfo
     * @param {PurchaseControllerApiGetOrderUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseControllerApi
     */
    public getOrderUsingGET(requestParameters: PurchaseControllerApiGetOrderUsingGETRequest, options?: any) {
        return PurchaseControllerApiFp(this.configuration).getOrderUsingGET(requestParameters.ordernum, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 낙찰 상품 주문
     * @summary order
     * @param {PurchaseControllerApiOrderBuynowUsingPOSTRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseControllerApi
     */
    public orderBuynowUsingPOST(requestParameters: PurchaseControllerApiOrderBuynowUsingPOSTRequest, options?: any) {
        return PurchaseControllerApiFp(this.configuration).orderBuynowUsingPOST(requestParameters.param, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 주문 사전 검증
     * @summary orderPreCheck
     * @param {PurchaseControllerApiOrderPreCheckUsingGETRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseControllerApi
     */
    public orderPreCheckUsingGET(requestParameters: PurchaseControllerApiOrderPreCheckUsingGETRequest, options?: any) {
        return PurchaseControllerApiFp(this.configuration).orderPreCheckUsingGET(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 결재 확인
     * @summary payResult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseControllerApi
     */
    public payResultUsingPOST(options?: any) {
        return PurchaseControllerApiFp(this.configuration).payResultUsingPOST(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 입금 확인
     * @summary depositDetection
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseControllerApi
     */
    public payResultUsingPOST1(options?: any) {
        return PurchaseControllerApiFp(this.configuration).payResultUsingPOST1(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RootControllerApi - axios parameter creator
 * @export
 */
export const RootControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary testString
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testStringUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RootControllerApi - functional programming interface
 * @export
 */
export const RootControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RootControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary testString
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testStringUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testStringUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RootControllerApi - factory interface
 * @export
 */
export const RootControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RootControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary testString
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testStringUsingGET(options?: any): AxiosPromise<string> {
            return localVarFp.testStringUsingGET(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RootControllerApi - object-oriented interface
 * @export
 * @class RootControllerApi
 * @extends {BaseAPI}
 */
export class RootControllerApi extends BaseAPI {
    /**
     * 
     * @summary testString
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RootControllerApi
     */
    public testStringUsingGET(options?: any) {
        return RootControllerApiFp(this.configuration).testStringUsingGET(options).then((request) => request(this.axios, this.basePath));
    }
}


