import {
  Configuration,
  AdminAuthApi,
  AdminSalesApi,
  AdminFileApi,
  AdminLotInsideApi,
  AdminEventApi,
  AdminQnaApi,
  AdminOrderApi,
  AdminMemberApi,
  AdminProductApi,
  AdminBannerApi,
  AdminFeaturedSellerApi,
  MemberAuthApi,
  PartnerAuthApi,
  PartnerDashboardApi,
  PartnerGuideApi,
  PartnerNoticeApi,
  PartnerOrderApi,
  PartnerProductApi,
  PartnerQnaApi,
  CommonLotControllerApi,
  AdminRegisterTokenApi,
} from '@/openapi';

const configuration = new Configuration({
  basePath: process.env.VUE_APP_API_HOST,
});

class PartnerAPI {
  product = new PartnerProductApi(configuration);
  adminAuth = new AdminAuthApi(configuration);
  adminSales = new AdminSalesApi(configuration);
  adminProduct = new AdminProductApi(configuration);
  adminMember = new AdminMemberApi(configuration);
  adminLotInside = new AdminLotInsideApi(configuration);
  adminQna = new AdminQnaApi(configuration);
  adminEvent = new AdminEventApi(configuration);
  adminOrder = new AdminOrderApi(configuration);
  adminFile = new AdminFileApi(configuration);
  adminBanner = new AdminBannerApi(configuration);
  adminFeaturedSeller = new AdminFeaturedSellerApi(configuration);
  memberAuth = new MemberAuthApi(configuration);
  partnerAuth = new PartnerAuthApi(configuration);
  partnerDashboard = new PartnerDashboardApi(configuration);
  partnerGuide = new PartnerGuideApi(configuration);
  partnerNotice = new PartnerNoticeApi(configuration);
  partnerOrder = new PartnerOrderApi(configuration);
  partnerProduct = new PartnerProductApi(configuration);
  partnerQna = new PartnerQnaApi(configuration);
  commonLot = new CommonLotControllerApi(configuration);
  adminRegisterToken = new AdminRegisterTokenApi(configuration);
}

const partnerAPI = new PartnerAPI();

export default partnerAPI;
